var isRecording=false;
let audioinfoDivElement = document.querySelector("#audioRecorderDiv");
stop = false
var audblob;
let once = true;

var recorded_audio_file = ''

export function delete_Recording(){
    const recMediaFile = document.getElementById("audio");
    recMediaFile.src = '';
    audblob = '';
    var buttonDelete = document.getElementById("aud_del")
    buttonDelete.disabled=true
    buttonDelete.innerHTML="<span style='color:grey;'><i class='fa fa-trash fa-lg'></i></span>"
}

export function get_Blob(){
  return audblob;
}

export function stop_Recording(){
  stop=true;
}

export function start_audio_Recording(recorded_audio_file){

  var i = 0;
  stop=false;
  document.getElementById("myBar").style.width="0%";
  document.getElementById("label").innerHTML = '0%';
  document.getElementById("myProgress").style.width="100%";
  var maxRectime = 30;

  function move(recorded_audio_file) {
    if (i == 0) {
      i = 1;
      var elem = document.getElementById("myBar");
      var width = 0;

  var id = setInterval(frame, maxRectime+1);
      
      function frame() {
        if (stop) {
          clearInterval(id);
          stopMp3Recording(recorded_audio_file);
        }
        if (width >= 100) {
          clearInterval(id);
          stopMp3Recording(recorded_audio_file);
          i = 0;
        } else {
          width = width + .1
          elem.style.width = width + "%";
          if (width.toFixed(2)%1==0) {
            document.getElementById("label").innerHTML = width.toFixed(2) * 1 + '%';
          }
        }
      }
    }
  }

  // New instance
  var recorder = new MicRecorder({
    bitRate: 128,
  });

  if (once) {
    recorder.start().then(() => {
      recorder.stop().getMp3()
    })
    once = false;
}

  


  var buttonStart = document.getElementById("aud_st")
  buttonStart.disabled=true
  buttonStart.innerHTML="<span style='color:grey;'><i class='fa fa-circle fa-2x'></i></span>"

  
  function wait(secs){
    secs = secs - 1
    if (secs <= 0) {
      clearInterval(waitintervalId);
    }
  }
  
  const waitintervalId = setInterval(wait(6), 1000);

  var index = 6
  function countDown() {
    
    index = index - 1
    document.getElementById("CountDown").innerHTML=`Recording starts in ${index}`
    document.getElementById("CountDown").focus()
    if (index <= 0) {
      clearInterval(intervalId);
      document.getElementById("CountDown").innerHTML=''
      
      recorder.start().then(() => {
        stop=false;
        maxRectime = 30;
        i=0;
    
        var recAudio = document.getElementById("audio");
        recAudio.src = '';
        var buttonDelete = document.getElementById("aud_del")
        buttonDelete.disabled=true
        buttonDelete.innerHTML="<span style='color:grey;'><i class='fa fa-trash fa-lg'></i></span>"
    
        move(recorded_audio_file);
        }).catch((e) => {
          console.error(e);
      });
    }
  }

  const intervalId = setInterval(countDown, 1000);

  function stopMp3Recording(recorded_audio_file) {
    recorder.stop().getMp3().then(([buffer, blob]) => {
      stop = true; 
      const mp3blob = new Blob(buffer, {type: "audio/mpeg"});
      const recMediaFile = document.getElementById("audio");
      const RecUrl = URL.createObjectURL(mp3blob);
      recMediaFile.src = RecUrl;
      recMediaFile.value = 30000;
      audblob = mp3blob;

      var buttonDelete = document.getElementById("aud_del")
      buttonDelete.disabled=false
      buttonDelete.innerHTML="<span style='color:red;'><i class='fa fa-trash fa-lg'></i></span>"

      setTimeout(function() {
        document.getElementById("myBar").style.width="0%";
        document.getElementById("label").innerHTML = '0%';
        document.getElementById("aud_st").disabled=false
        document.getElementById("aud_st").innerHTML="<span style='color:red;'><i class='fa fa-circle fa-2x'></i></span>"
      }, 1000)
    });
  }

}// start_audio_Recording

  function getAudioFiles(recorded_audio_file) {
    let uri = "/api/audio_files?recorded_audio_file=" + recorded_audio_file
    return fetch(uri, {
      method: "GET",
      responseType:'blob',
      header: {"Content-Type": "audio/mpeg"},
    })
      .then((response) => {
        return response.blob()
      })
  }

  export function save_Recording(blob, event_id, recorded_audio_file) {
    //let uri = "/api/savecontribute_audio?event_id="+event_id+"&recorded_audio_file=" + recorded_audio_file
    let template_url = "/api/savecontribute_audio?event_id=:event_id&recorded_audio_file=:filename"
    template_url = template_url.replace(":event_id", event_id).replace(":filename", recorded_audio_file);
    return fetch(template_url, {
      method: "POST",
      body: blob,
      header: {"Content-Type": "audio/mpeg", "Accept":"audio/mpeg"},
    })
    .then((response) => response.json())
    .then((response) => {
      if (response.data==="available") {
         //alert("Audio successfully saved")
        return 0;
      }
      else {
        const recMediaFile = document.getElementById("audio");
        recMediaFile.src = '';
        audblob = '';
        alert("Save of Audio recording failed. Kindly Record again.")
        return -1;
      }
    });
  }
