import React, { Component, Fragment } from "react";
import { ENDPOINTS } from "../constants/endpoints";
import { FieldGroup } from "./bootstrap/Forms.FieldGroup";
import { formDataToJSON } from "../adukku_utils/array";
import { Radio, Button, Modal, Form, Dropdown, Row, Col, FormGroup } from "react-bootstrap";
import EventPayment from "./UserEvent/EventPayment";
import RPEventPayment from "./UserEvent/RPEventPayment";
import { formatCurrency } from "../adukku_utils/formatCurrency";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { SPLoader } from "./Spinner.js"
import './DragDropTouch'

import { start_video_Recording, delete_Recording, get_Blob, get_Time, getRecordingState } from "./VideoHelpers";

export class EventsHomePage extends React.Component {
    constructor(props) {
        super(props);
        let customState = { ...props, events: [], presetTemplates: [], invitationProducts: [], chooseEventPictures: false, eventPictures: [], event_video: false,
          birthday_uniqueImages: [], wedding_uniqueImages: [], contributes: [], user_file: false, choosePreset: false, vidFlg: false, uploadVidFlg: false,
          isCreatingEvent: false, isContributions: false, colorPicker: '#293c80', isPayingEvent: false, link: false, presetFolders: [],
          product_id: 0, event_id: "", event_image: "", event_type: "Wedding", title: "", file: "", img_url: "", event_dt: "", prepaid_ct: 0, postpaid_ct: 0,
          event_dt: "", edit_flg: false, del_flg: false, bride: "", bridegroom: "", bday_name: "", product_id: "", status: '', isArrange: false,
          mobile:false, isPaymentModal:false, price: 0.15, showSpinner: false, no_of_pages: 0, eventImg: '', extraWishers: 1, uploadedVideo: '',
          isFileManagerOpen: false, isEditProductClicked: false,  purchaseHistory: [], currentOrder: null, isExtraPayingEvent: false,
          updVidTime:'', recVidTime:'', 
        };
        this.state = customState
        this.getEventsfromDB();   
        this.getPresetEvents();
        this.getInvitationProducts();
        
      }
    
      UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps !== this.state) {
          this.setState(nextProps);
        }
      }
    
     UNSAFE_componentWillMount() {
        this.checkDevice();
        this.getPrice();
        this.getEventsfromDB();
      }

      componentDidMount() {
        // code to execute
     //   this.callCreateEvent();
        this.getEventsfromDB();
      }

     
      callCreateEvent() {
        let cook = this.getCookie("editorFlg").split('_')[0]
        let ev_type = this.getCookie("editorFlg").split('_')[1]
        if (cook.split('_')[0] === 'New') {
          //document.getElementById("createNewEvent").click();
          this.setCookie("editorFlg", '');
        }
        if (cook.split('_')[0]  === 'Edit') {
          //document.getElementById("createNewEvent").click();
          this.setCookie("editorFlg", '');
        }
        
      }

      
    createEditInvitation = () => {  
      var mylist = document.getElementById("myList");  
      //document.getElementById("favourite").value = mylist.options[mylist.selectedIndex].text;  
      var event_type = mylist.options[mylist.selectedIndex].text;
      let invitationproductID = this.invitationProductsindexOf(this.state.invitationProducts, event_type, 'product_id')
      if (invitationproductID === 0) {
        this.cloneInvitationProduct(event_type).then(function(product_id){
          if (product_id > 0) 
            window.location = "/products/" + product_id + "/invitation/" + "new";
          else
            alert("Operation Failed. Try again.")
        })
      }
      else{
        window.location = "/products/" + invitationproductID + "/invitation/" + "edit";
      }

    }  


     removeDuplicates = (arr) => {
           return arr.filter((item,
            index) => arr.indexOf(item) === index);
    }

    _handleValidSort = () => {
      const sortIds = []
      const hides = []
      const draggables = document.querySelectorAll(".myDraggable")
      draggables.forEach (id => {
        hides.push(id.dataset.hide === 'true'? 1 : 0) 
        sortIds.push(id.id.split('_')[0])
      })

      let payload = {
        sortIds: sortIds, 
        hides: hides,
      };
      let endpoint = ENDPOINTS["contributes"]["update"];
      // uri: "/api/contributes?sort=:sortflg"
      let url = endpoint.uri.replace(':event_id', this.state.event_id);
      let method = endpoint.method;
    
      let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
       .then((data) => { 
         alert("Saved successfully")
      });

    }

    
    handleVideoSubmit = (event) => {
      event.preventDefault()
      var files = event.target.files;
      if (files.length > 0){
        let videoElementDiv = document.getElementById("videoRecordedDiv");
        videoElementDiv.style.display = "";
         let videoElement = document.getElementById("videoRecorded");
         let oldVideoElement = videoElement.src
         const RecUrl = URL.createObjectURL(files[0]);
         videoElement.src = RecUrl
         videoElement.load();
         setTimeout(() => {
            // Code goes here
            var maxRectime = 46
            if (videoElement.duration > maxRectime) {
               videoElement.setAttribute('src', '');
               alert("Max Video duration allowed is only " + maxRectime + " Seconds!\nUploaded video duration exceeds max allowed\nRestoring previous video if any...")
               videoElement.src = oldVideoElement
               document.getElementById("upload").value=null;
               //videoElement.play();
            }
            else {
              this.setState({
                uploadedVideo: files[0],
                uploadVidFlg: true,
                updVidTime: new Date()
              })
              videoElement.play()
            }
         }, 100)
      }
   }

    setDrag = () => {
      const draggables = document.querySelectorAll(".myDraggable")
      const containers = document.querySelectorAll(".myContainer")
      
      const content = document.querySelector(".modal-content")
      if (!this.state.mobile){
        content.style.height = '100vh'
        content.style.width = '90vh'
        content.style.marginLeft = '-50px'
      }
      else {
        content.style.height = '100vh'
      }

      draggables.forEach(draggable => {
        draggable.addEventListener('dragstart', () => {
          draggable.classList.add('dragging')
        })
        draggable.addEventListener('dragend', () => {
          draggable.classList.remove('dragging')
        })
 
      })

      containers.forEach(container => {
        container.addEventListener('dragover', e => {
          e.preventDefault()
          const afterElement = getDragAfterElement(container, e.clientY)
          const draggable = document.querySelector('.dragging')
          if (afterElement == null) {
            container.appendChild(draggable)
          }
          else {
            container.insertBefore(draggable, afterElement)
          }

        })

      })

      function getDragAfterElement(container, y) {
        const draggableElements = [...container.querySelectorAll('.myDraggable:not(.dragging)')]
        return draggableElements.reduce((closest, child) => {
          const box = child.getBoundingClientRect()
          const offset = y - box.top - box.height / 2

          if (offset < 0 && offset > closest.offset) {
            child.scrollIntoView({ behavior: "smooth", block: "end"});
            return {offset: offset, element: child}
          }
          else {
            return closest
          }
        }, {offset: Number.NEGATIVE_INFINITY}).element
      }

    }

    presetFoldersindexOf = (array, item) => {
      for (var i = 0; i < array.length; i++) {
          if (array[i][0] === item.toLowerCase()) {
              return array[i][1]
          }
      }
      return '';   // Not found
    }

    invitationProductsindexOf = (array, item, type) => {
      for (var i = 0; i < array.length; i++) {
        if (array[i].event_type === item) {
          if (type === 'layout_id')
            return array[i].layout_id
            if (type === 'product_id')
            return array[i].product_id  
        }
      }
      return 0;   // Not found
    }

    videoSetup = () => {
      const buttonStart = document.querySelector('#buttonStart')
      const buttonStop = document.querySelector('#buttonStop')
      const videoLive = document.querySelector('#videoLive')
      const videoRecorded = document.querySelector('#videoRecorded')
    
      const stream = navigator.mediaDevices.getUserMedia({ // <1>
        video: true,
        audio: true,
      })
    
      videoLive.srcObject = stream
    
      if (!MediaRecorder.isTypeSupported('video/webm')) { // <2>
        console.warn('video/webm is not supported')
      }
    
      const mediaRecorder = new MediaRecorder(stream, { // <3>
        mimeType: 'video/webm',
      })
    
      buttonStart.addEventListener('click', () => {
        mediaRecorder.start() // <4>
        buttonStart.setAttribute('disabled', '')
        buttonStop.removeAttribute('disabled')
      })
    
      buttonStop.addEventListener('click', () => {
        mediaRecorder.stop() // <5>
        buttonStart.removeAttribute('disabled')
        buttonStop.setAttribute('disabled', '')
      })
    
      mediaRecorder.addEventListener('dataavailable', event => {
        videoRecorded.src = URL.createObjectURL(event.data) // <6>
      })
    }






    getPrice = () => {
      this.countryCode!=="IN"?this.setState({price:0.15}):this.setState({price:10.00})
    }

    getPurchaseHistory = (event_id) => {

      let uri = ENDPOINTS.user_events.list.uri;
      uri = uri + "?event_id=" + event_id
      let that = this;
      return fetch(uri, {
        method: "GET",
        headers: { "content-type": "application/json" },
      })
        .then((response) => response.json())
        .then((response) => {
          that.setState({
            purchaseHistory: response.user_events,
            convenienceCharges: parseFloat(response.convience_charges.transaction_fee || 0.15),
            host_url: response.host_url,
          });
        });
    };
  
    openFileManager = () => {
      this.setState({ isFileManagerOpen: !this.state.isFileManagerOpen });
      this.getPurchaseHistory();
    };


    getOrderInvoice = (order) => {
      
      
      
      return (
        <Modal
          dialogClassName="modal-90w"
          centered="true"
          show={this.state.isEditProductClicked}
          onHide={(e) => {
            this.setState({ currentOrder: null, isEditProductClicked: !this.state.isEditProductClicked });
          }}
        >
          <Form className="override_form" onSubmit={this.handleEditProduct}>
            <Modal.Header closeButton>
              <Modal.Title>Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="divToPrint" style={{ justifyContent: "center" }}>
                <div
                  id="divToPrint2"
                  style={{
                    backgroundColor: "#fff",
                    width: "60%",
                    padding: "50px",
                    minHeight: "297mm",
                    marginLeft: "auto",
                    marginRight: "auto",
                    border: "1px solid",
                    boxShadow: `0 10px 25px rgba(0, 0, 0, 0.5)`,
                  }}
                >
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td>
                        <img
                          src={`${this.state.host_url}/wishCollectorLogoOfficialBlackTrans.png`}
                          style={{ height: "70px" , marginBottom: "10px" }}
                        />
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <span style={{ fontSize: "34px", color: "#0070c0", fontWeight: "34px" }}>
                          <b>INVOICE</b>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>COMPOSZ LLC,</td>{" "}
                      <td style={{ textAlign: "right" }}>Invoice #: INVSTO00000{order.id}</td>
                    </tr>
                    <tr>
                      <td>#121 HAUT BRION AVENUE</td>{" "}
                      <td style={{ textAlign: "right" }}>Invoice Date: {order.updated_at}</td>
                    </tr>
                    <tr>
                      <td>NEWARK, DE-19702.</td>{" "}
                      <td style={{ textAlign: "right" }}>
                        Invoice Amount:
                        {formatCurrency(order.total_amount, 1, "USD")} 
                        {/* {(order.total_amount / 100).toFixed(2)} */}
                      </td>
                    </tr>
                    <tr>
                      <td></td>{" "}
                      <td style={{ textAlign: "right" }}>
                        Status: <span style={{ color: "#92d050", fontSize: "20px" }}>PAID</span>
                      </td>
                    </tr>
                  </table>
                  <span>
                    <b>BILLED TO</b>
                  </span>
                  <p>
                    <span>{order.full_name}</span>
                    <br />
                    <span>{order.email}</span>
                    <br />
                    <span>{order.pg_user_email}</span>
                    <br />
                    <span>{order.address}</span>
                    <br />
                    {/* <span>Bangalore, 560076.</span> */}
                  </p>
                  <br />
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr
                        style={{
                          borderBottom: "0.5px solid black",
                          borderTop: "0.5px solid black",
                        }}
                      >
                        <th>Description</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
  
                    <tbody>
                      <tr>
                        <td>Wish Collection, Online Services</td>
                        <td>${order.price}</td>
                        <td>{order.no_of_wishers}</td>
                        <td>
                          {formatCurrency(order.no_of_wishers * order.price, 1, "USD")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>PAYMENT DETAILS</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Paypal Transaction ID: {order.paypal_capture_id}</td>
                        <td style={{ textAlign: "right" }}>
                          Item (s) Subtotal:{" "}
                          {formatCurrency(order.no_of_wishers * order.price, 1, "USD")}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td style={{ textAlign: "right" }}>
                          Convenience charges: {formatCurrency(order.no_of_wishers * order.price * order.pg_transaction_fee, 1, "USD")}
                          {/* {(order.pg_transaction_fee / 100).toFixed(2)} */}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td style={{ textAlign: "right" }}>
                          Tax to be collected: {formatCurrency(0, 1, "USD")}
                          {/* ${(order.pg_fixed_fee / 100).toFixed(2)} */}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td style={{ textAlign: "right" }}>
                          <b>
                            GRAND TOTAL: {formatCurrency(order.total_amount, 1, "USD")}
                            {/* {(order.total_amount / 100).toFixed(2)} */}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
  
                  
                  <p>Thanks for your purchase</p>
                  <p>-- team wishcollector</p>      

                  <br></br>
                  <br></br>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr
                        style={{
                          borderBottom: "0.5px solid black",
                          borderTop: "0.5px solid black",
                        }}
                      >
                        <th style={{ textAlign: "center", color: "darkgreen" }}>
                          wishcollector.com is owned, operated & managed by COMPOSZ LLC
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="button"
                onClick={() => {
                  this.printDocument(`INVEVT000000${order.id}`);
                }}
              >
                Download
              </Button>
              <Button
                onClick={(e) => {
                  this.setState({ isEditProductClicked: !this.state.isEditProductClicked });
                }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      );
    };
  













    getrazorpayOrderInvoice = (order) => {
      let user = {};
      return (
        
        <Modal id="Mymodal"
          dialogClassName="modal-90w"
          centered="true"
          show={this.state.isEditProductClicked}
          onHide={(e) => {
            this.setState({ currentOrder: null, isEditProductClicked: false });
          }}
        >
          <Form className="override_form" onSubmit={this.handleEditProduct}>
            <Modal.Header closeButton>
              <Modal.Title>Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="divToPrint" style={{ justifyContent: "center" }}>
                <div
                  id="divToPrint2"
                  // className="mt4"
                  style={{
                    backgroundColor: "#fff",
                    width: "60%",
                    padding: "50px",
                    minHeight: "297mm",
                    marginLeft: "auto",
                    marginRight: "auto",
                    border: "1px solid",
                    boxShadow: `0 10px 25px rgba(0, 0, 0, 0.5)`,
                  }}
                >
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td>
                        <img
                          src={`${this.state.host_url}/wishCollectorLogoOfficialBlackTrans.png`}
                          style={{ height: "70px", marginBottom: '5px' }}
                        />
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <span style={{ fontSize: "34px", color: "#0070c0", fontWeight: "34px" }}>
                          <b>INVOICE</b>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>LIFE COLORS,</td>{" "}
                      <td style={{ textAlign: "right" }}>Invoice #: INVEVT000000{order.id}</td>
                    </tr>
                    <tr>
                      <td>#9, 4th Cross, Kothanur Main Road,</td>{" "}
                      <td style={{ textAlign: "right" }}>Invoice Date: {order.updated_at}</td>
                    </tr>
                    <tr>
                      <td>Bangalore 560 062.</td>{" "}
                      <td style={{ textAlign: "right" }}>
                        Invoice Amount: {" "}
                        {formatCurrency(order.total_amount, 1, "INR")} 
                        {/* {(order.total_amount / 100).toFixed(2)} */}
                      </td>
                    </tr>
                    <tr>
                      <td>GSTIN: 29AUTPB5544P1ZS; GST SAC Code: 998434</td>{" "}
                      <td style={{ textAlign: "right" }}>
                        Status: <span style={{ color: "#92d050", fontSize: "20px" }}>PAID</span>
                      </td>
                    </tr>
                  </table>
                  <span>
                    <b>BILLED TO</b>
                  </span>
                  <p>
                    <br></br>
                    <span>{order.user_name}</span>
                    <br></br>
                    <span>{order.razorpay_capture.email}</span>
                    <br></br>
                    <span>{order.razorpay_capture.contact}</span>
                    <br />
                  </p>
                  <br />
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr
                        style={{
                          borderBottom: "0.5px solid black",
                          borderTop: "0.5px solid black",
                        }}
                      >
                        <th>Description</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
  
                    <tbody>
                      <tr>
                        <td>Wish Collection, Online Services</td>
                        <td>₹{order.price}</td>
                        <td>{order.no_of_wishers}</td>
                        <td>
                          {formatCurrency(order.no_of_wishers * order.price, 1, "INR")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>PAYMENT DETAILS</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Razorpay Payment ID: {order.razorpay_capture.id}</td>
                        <td style={{ textAlign: "right" }}>
                          Item (s) Subtotal:{" "}
                          {formatCurrency(order.no_of_wishers * order.price, 1, "INR")}
                        </td>
                      </tr>
                      <tr>
                        <td>Payment Mode: {order.razorpay_capture.method} 
                        {order.razorpay_capture.method == 'netbanking' ? "-" + order.razorpay_capture.bank : ''}
                        {order.razorpay_capture.method == 'card' ? "-" + order.razorpay_capture.card.network + "-xxxx-" + order.razorpay_capture.card.last4 : ''}
                        {order.razorpay_capture.method == 'upi' ? "-" + order.razorpay_capture.vpa + "-Ph:" + order.razorpay_capture.acquirer_data.rrn : ''}
                        {order.razorpay_capture.method == 'wallet' ? "-" + order.razorpay_capture.wallet : ''}
                        </td>
                        <td style={{ textAlign: "right" }}>
                          Convenience charges: {formatCurrency(order.no_of_wishers * order.price * order.pg_transaction_fee, 1, "INR")}
                          {/* {(order.pg_transaction_fee / 100).toFixed(2)} */}
                        </td>
                      </tr>
                      <tr>
                        <td>Transaction ID: 
                          {order.razorpay_capture.method == 'netbanking' ? order.razorpay_capture.acquirer_data.bank_transaction_id : ''}
                          {order.razorpay_capture.method == 'card' ? order.razorpay_capture.acquirer_data.auth_code : ''}
                          {order.razorpay_capture.method == 'upi' ? order.razorpay_capture.acquirer_data.upi_transaction_id : ''}
                          {order.razorpay_capture.method == 'wallet' ? " N/A" : ''}
                          </td>
                        <td style={{ textAlign: "right" }}>
                          GST 18%: {formatCurrency((order.no_of_wishers * 10.00 + order.no_of_wishers * order.pg_transaction_fee) * 0.18, 1, "INR")}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td style={{ textAlign: "right" }}>
                          <b>
                            GRAND TOTAL (rounded): {formatCurrency(order.total_amount, 1, "INR")}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
  
                  <p> Thanks for your purchase</p>
                  <p> --team wishcollector</p>

                  <br></br>
                  <br></br>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr
                        style={{
                          borderBottom: "0.5px solid black",
                          borderTop: "0.5px solid black",
                        }}
                      >
                        <th style={{ textAlign: "center", color: "darkgreen" }}>wishcollector.com is owned, operated & managed by LIFE COLORS</th>
                      </tr>
                    </thead>
                  </table>
                  
                </div>
                
              </div>
              
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="button"
                onClick={() => {
                  this.printDocument(`INVEVTO00000${order.id}`);
                }}
              >
                Download
              </Button>
              <Button
                onClick={(e) => {
                  this.setState({ isEditProductClicked: false });
                }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      );
    };
  
    printDocument = (orderId) => {
      const input = document.getElementById("divToPrint2");
      html2canvas(input).then((canvas) => {
        
        const imgData = canvas.toDataURL("image/jpeg");
        const pdf = new jsPDF();
        var width = pdf.internal.pageSize.getWidth();
        var height = pdf.internal.pageSize.getHeight();

        
        pdf.addImage(imgData, "JPEG", 0, 0, width, height);
        
        //pdf.output('dataurlnewwindow');
        pdf.save(`${orderId}.pdf`);
        
      });
    };

    setCookie(cname, cvalue) {
      document.cookie = cname + "=" + cvalue + ";";
    }
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
  
    //countryCode = this.getCookie("countryCode");
    countryCode = "IN";
    // countryCode = "US";


    checkDevice = () => {
      /* Storing user's device details in a variable*/
      let details = navigator.userAgent; 
        
      /* Creating a regular expression  
      containing some mobile devices keywords  
      to search it in details string*/
      let regexp = /android|iphone|kindle|ipad/i; 
        
      /* Using test() method to search regexp in details 
      it returns boolean value*/
      let isMobileDevice = regexp.test(details); 
        
      if (isMobileDevice) { 
          this.setState({mobile: true})
      } else { 
          this.setState({mobile: false})
      }
    }



    changeColor = () => {
      let colorpicker = document.getElementById('colorpicker');
      this.state.contributes.map( item =>{
        let element = 'myContribute_' + item.id
      //let destColor = document.getElementById('myContribute');
      let destColor = document.getElementById(element);
      // setInterval(()=>{
          let color = colorpicker.value;
          destColor.style.backgroundColor = color;
      // }, 200);
    })
    }

    cloneProduct = (event_id, event_type, preset_product_id) => {

      let payload = {
        product_id: preset_product_id, 
        name: event_id + '_' + event_type,
        cloneType: 'preset',
      };
      let endpoint = ENDPOINTS["products"]["clone"];
      let url = endpoint.uri;
      let method = endpoint.method;
    
      let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
       .then((data) => { 
         this.updateEventProductIdAfterClone(event_id, data.product.id, 'CLOSED');
      });
     
    }

    cloneInvitationProduct = (event_type) => {

      let payload = {
        product_id: 462, // hardcoded for Invitation Preset
        name: event_type + '_Invitation',
        cloneType: 'preset',
      };
      let endpoint = ENDPOINTS["products"]["clone"];
      let url = endpoint.uri;
      let method = endpoint.method;

      let ajaxCall = fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: method,
        body: JSON.stringify(payload),
        async: false,
      });
      return ajaxCall
      .then((response) => {
        return response.json();
      })
      .then((data) => { 
        this.getInvitationProducts();
        return data.product.id;
      });
     
    }


    generateEventFiles = (event_id, event_type, preset_product_id, product_id) => {

    //   let payload = {
    //     product_id: preset_product_id, 
    //     name: event_id + '_' + event_type,
    //     cloneType: 'preset',
    //   };
    //   let endpoint = ENDPOINTS["products"]["clone"];
    //   let url = endpoint.uri;
    //   let method = endpoint.method;
    
    //   let ajaxCall = fetch(url, {
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   method: method,
    //   body: JSON.stringify(payload),
    // });
    // return ajaxCall
    //   .then((response) => {
    //     return response.json();
    //   })
    //    .then((data) => { 
    //      this.updateEventProductId(event_id, data.product.id, 'CLOSED');
    //   });

    this.uploadDataStartBatch(event_id, product_id, 'CLOSED');

    }

    uploadDataStartBatch = (event_id, product_id, status) => {

      let formData = new FormData();
      let endpoint = ENDPOINTS.events.update;
      let url = endpoint.uri.replace(":id", event_id)
      let method = endpoint.method;
      formData.append("product_id", product_id)
      formData.append("event_id", event_id)
      formData.append("status", status)
      formData.append("update_only", "N") // start batch indicator for update method
      
      let ajaxCall = fetch(url, {
        headers: {
          Accept: "application/json",
          //"Content-Type": "application/json",
        },
        method: method,
        body: formData,
      });
      return ajaxCall
      .then((response) => response.json())
         .then((data) => { 
           if (status === 'CLOSED' && product_id!=0) {
            this.startBatch(event_id, product_id, "batch_process");
            ;;
           }
           if (status === 'CLOSED' && product_id ===0) {
                alert('Event closed successfully.\nProceed to Pay/Reject for extra wishes if any\nYou can now "Arrange" wishes\nFinally proceed to Generate Event Files');
                window.location.reload()
            }
        });
    }

    updateEventProductId = (event_id, product_id, status) => {

      let formData = new FormData();
      let endpoint = ENDPOINTS.events.update;
      let url = endpoint.uri.replace(":id", event_id)
      let method = endpoint.method;
      formData.append("product_id", product_id)
      formData.append("event_id", event_id)
      formData.append("status", status)
      
      
      let ajaxCall = fetch(url, {
        headers: {
          Accept: "application/json",
          //"Content-Type": "application/json",
        },
        method: method,
        body: formData,
      });
      return ajaxCall
      .then((response) => response.json())
         .then((data) => { 
           if (status === 'CLOSED' && product_id!=0) {
            this.startBatch(event_id, product_id, "batch_process");
            ;;
           }
           if (status === 'CLOSED' && product_id ===0) {
                alert('Event closed successfully.\nProceed to Pay/Reject for extra wishes if any\nYou can now "Arrange" wishes\nFinally proceed to Generate Event Files');
                window.location.reload()
            }
        });
    }
    
    updateEventProductIdAfterClone = (event_id, product_id, status) => {

      let formData = new FormData();
      let endpoint = ENDPOINTS.events.update;
      let url = endpoint.uri.replace(":id", event_id)
      let method = endpoint.method;
      formData.append("product_id", product_id)
      formData.append("event_id", event_id)
      formData.append("status", status)
      formData.append("update_only", "Y")
      
      let ajaxCall = fetch(url, {
        headers: {
          Accept: "application/json",
          //"Content-Type": "application/json",
        },
        method: method,
        body: formData,
      });
      return ajaxCall
      .then((response) => response.json())
         .then((data) => { 
           if (status === 'CLOSED' && product_id!=0) {
            alert ("Template selection saved successfully")
            window.location.reload()
           }
           
        });
    }

    updateEventPresetProductId = (event_id, preset_product_id) => {

      let formData = new FormData();
      let endpoint = ENDPOINTS.events.update;
      let url = endpoint.uri.replace(":id", event_id)
      let method = endpoint.method;
      formData.append("preset_product_id", preset_product_id)
      formData.append("event_id", event_id)
      
      
      let ajaxCall = fetch(url, {
        headers: {
          Accept: "application/json",
          //"Content-Type": "application/json",
        },
        method: method,
        body: formData,
      });
      return ajaxCall
      .then((response) => {
        alert ("Template selection saved successfully")
        window.location.reload()
        // return response.json()
      })
      .catch((error) => {
        throw error;
      });     
        
    }

    updateEventPresetProductIdAndCloneProduct = (event_id, event_type, preset_product_id, existing_product_id) => {

      let formData = new FormData();
      let endpoint = ENDPOINTS.events.update;
      let url = endpoint.uri.replace(":id", event_id)
      let method = endpoint.method;
      formData.append("preset_product_id", preset_product_id)
      formData.append("event_id", event_id)
      formData.append("existing_product_id", existing_product_id)
      
      
      let ajaxCall = fetch(url, {
        headers: {
          Accept: "application/json",
          //"Content-Type": "application/json",
        },
        method: method,
        body: formData,
      });
      return ajaxCall
      .then((response) => {
        this.cloneProduct (event_id, event_type, preset_product_id)
        // alert ("Template selection saved successfully")
        // window.location.reload()
        // return response.json()
      })
      .catch((error) => {
        throw error;
      });     
        
    }

    
    updateCounts = (event_id, extraWishers) => {

      let formData = new FormData();
      let endpoint = ENDPOINTS.events.update;
      let url = endpoint.uri.replace(":id", event_id)
      let method = endpoint.method;
      formData.append("extraWishers", extraWishers)
      formData.append("event_id", event_id)
      
      
      let ajaxCall = fetch(url, {
        headers: {
          Accept: "application/json",
          //"Content-Type": "application/json",
        },
        method: method,
        body: formData,
      });
      return ajaxCall
      .then((response) => {
        if (extraWishers===0)
        alert ("Deleted successfully. Proceed to Generate Event Files")
        window.location.reload()
        // return response.json()
      })
      .catch((error) => {
        throw error;
      });     
        
    }


    startBatch = (event_id, product_id, productState) => {
      
      let endpoint = ENDPOINTS.products.update;
      let url = endpoint.uri;
      let method = endpoint.method;
      
      let payload = {product_state: productState,}
      let ajaxCall = fetch(url + product_id, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: method,
        body: JSON.stringify(payload),
      });
      return ajaxCall
        .then((response) => {
          if (productState === "batch_process") {
            this.updateEventProductId(event_id, product_id, 'BATCH');
            alert("Your request is received, queued and will be serviced at the earliest\nCheck back in few minutes for Files Generation Completion...");
            window.location.reload();
          }
        })
        .catch((error) => {
          throw error;
        });
    };


    showInvoices = (event_id) => {
      this.getPurchaseHistory(event_id)
      this.setState({isFileManagerOpen: true})
    }

    

    checkBatch = (event_id, product_id) => {
      
      let endpoint = ENDPOINTS.events.batch_status;
      let url = endpoint.uri.replace(":product_id", product_id);
      let method = endpoint.method;
      let ajaxCall = fetch(url , {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: method,
      });
      return ajaxCall
      .then((response) => response.json())
      .then((data) => { 
        if (data.product_state != "completed") {
          alert ("Event Files generation still in progress...\nCheck back again in a few moments...")
        }
        if (data.product_state === "completed") {
          this.updateEventProductId(event_id, product_id, 'DOWNLOAD');
          alert ("Event Files generation Completed.\nProceed to Download files immediately.")
          window.location.reload();
          }
        
     });
    };

    

    getEventsfromDB = () => {
        let events_uri = ENDPOINTS.events.list.uri;
        var user_id = this.props.user_id;
        
        var where =  "user_id=" + user_id;
        var where =  ""
        
        events_uri = `${events_uri}?${where}`;
      
        let noRecords;
        
        noRecords =  fetch(events_uri, {
          method: "GET", headers: { "content-type": "application/json" }, 
          })
        .then((response) => response.json())
        .then((data) => { 
          this.setState({events: data.events, presetFolders: data.preset_folders});
        //#populate event_type presets (hard coded for now)
          data.preset_folders.map((folder, index) => {
            if (folder[0]==="birthday") {
              let fileName = folder[1]
              fileName = fileName.split('public/')[1]
              this.state.birthday_uniqueImages.push(fileName)
            }
            if (folder[0]==="wedding") {
              let fileName = folder[1]
              fileName = fileName.split('public/')[1]
              this.state.wedding_uniqueImages.push(fileName)
            }
          })

          //set default event_image
          this.setState({event_image: this.state.wedding_uniqueImages[0]})
      });
        

    }

    getContributions = (event_id, sortFlg) => {

    let template_url = "/api/contributes?event_id=:event_id"
    template_url = template_url.replace(":event_id", event_id);

    if (sortFlg) {
      template_url = template_url + '&&sort=true'
    }
    
    let noRecords;
    
    noRecords =  fetch(template_url, {
      method: "GET", headers: { "content-type": "application/json" }, 
      })
    .then((response) => response.json())
    .then((data) => { 
      this.setState({contributes: data.contributes});
      if (sortFlg) this.setDrag()
    });

  }

  getInvitationProducts = () => {
    let template_url = "/productid"
    let ajaxCall =  fetch(template_url, {
      method: "GET", 
      headers: {
        //Accept: "application/json",
        "Content-Type": "application/json",
      },
      })
    .then((response) => response.json())
    .then((data) => { 
      this.setState({invitationProducts: data.products});
    });
  }


  getPresetEvents = () => {
    let template_url = "/presetsevents"
    let noRecords;
    noRecords =  fetch(template_url, {
      method: "GET", headers: { "content-type": "application/json" }, 
      })
    .then((response) => response.json())
    .then((data) => { 
      this.setState({presetTemplates: data.presets});
    });
  }

  getEventPictures = (event_id, img_url, create_flg) => {
    let payload = {
      event_id: event_id,
      img_url: img_url,
      create_flg: create_flg,
    }
    let template_url = "/eventpictures"
    template_url = template_url + "?event_id=" + event_id + '&&img_url='+img_url + '&&create_flg=' + create_flg
    let noRecords;
    let ajaxCall =  fetch(template_url, {
      method: "GET", 
      headers: {
        //Accept: "application/json",
        "Content-Type": "application/json",
      },
      })
    .then((response) => response.json())
    .then((data) => { 
      this.setState({no_of_pages: data.no_of_pages, eventPictures: data.eventFiles})
    });
  }

    
    getEventImage = (event_id, img_url) => {
      let template_url
      let that = this;
    
      template_url = ENDPOINTS.eventfiles.index;
      template_url = template_url.replace(":event_id", event_id).replace(":img_url", img_url)
      return fetch(uri, {
        method: "GET",
        headers: { "content-type": "application/json" },
      })
        .then((response) => response.json())
        .then((response) => {
          that.setState({
             img_url: response.file_name,
             file: response.file
          })
        })

    }




    deleteEvent = (event_id) => {
      
      let endpoint, url
      let formData = new FormData();
     
      endpoint = ENDPOINTS.events.delete;
      url = endpoint.uri.replace(":event_id", event_id)
      formData.append("event_id", event_id)
      let method = endpoint.method;

      let $ajax = $.ajax({
        url: url,
        type: "POST",
        method: "DELETE",
        data: formData,
        processData: false,
        contentType: false,
        async: false,
      });
      $ajax.done(function () {
        alert("Successfully, Deleted Event");
        return;
      });
      $ajax.fail(function (data, status) {
        ;;
        //alert("Delete failed!");
      });

      this.setState({del_flg: false})
      window.location.reload();
     
    } // deleteEvent


    _handleValidSubmit = (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (getRecordingState()) return

      let formData = formDataToJSON(new FormData(e.target));

      let endpoint, url
      if (!this.state.edit_flg && !this.state.del_flg){
        endpoint = ENDPOINTS.events.create;
        url = endpoint.uri;
        formData.status = 'CREATE';
      }
      if (this.state.edit_flg) {
        endpoint = ENDPOINTS.events.update;
        url = endpoint.uri
        formData.event_id = this.state.event_id;
      }
      
      let method = endpoint.method;

      if (formData.img_url.name === "" || this.state.img_url === "") {
        formData.img_url = this.state.event_image.split('/').pop().toLowerCase()
      }
      else {
        formData.img_url = formData.img_url.name.toLowerCase();
      }

      formData.event_image = this.state.event_image
      
      let ajaxCall = fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: method,
        body: JSON.stringify(formData),
      });
      return ajaxCall
      .then((response) => response.json())
          .then((data) => { 
            this.setState({event_id: data});
            let vidblob
            if (this.state.vidFlg || this.state.uploadVidFlg) {
              let recVidTime = get_Time()
              if (recVidTime === 'none' || this.state.updVidTime > recVidTime) {
                vidblob = this.state.uploadedVideo  
              }
              else{
                vidblob = get_Blob()
              }
            }
            
            if ( (this.state.vidFlg || this.state.uploadVidFlg) && vidblob != 'noVideo') {
              let spinner=document.getElementById("mySpinner")  
              spinner.style.display=""
              let vid_fileName = this.state.event_id + '.mp4'
              this.save_video_Recording(vidblob, data, vid_fileName)
            }
            else 
              this.closeModal();

                
        });
        
    }

    save_video_Recording = (blob, event_id, recorded_video_file) => {
      //let uri = "/api/savecontribute_audio?event_id="+event_id+"&recorded_audio_file=" + recorded_audio_file
      
      let template_url = "/api/savecontribute_audio?event_id=:event_id&recorded_video_file=:filename"
      template_url = template_url.replace(":event_id", event_id).replace(":filename", recorded_video_file);
      let status = ''
      fetch(template_url, {
        method: "POST",
        body: blob,
        header: {"Content-Type": 'video/webm', "Accept":'video/webm'},
      })
      .then((response) => response.json())
      .then((response) => {
        
        if (response.data==="available") {
           //alert("Video successfully saved")
        }
        else {
          //alert("Save of Video recording failed. Kindly Record again.")
        }
        this.closeModal()
      });
    }

    save_user_event_image = (event_id, file, file_name, copyTerm) => {
      let template_url = "/api/saveevent_image?event_id=:event_id&&event_image_file=:filename&&user=true&&copyTerm=:copyTerm"
      template_url = template_url.replace(":event_id", event_id).replace(":filename", file_name).replace(":copyTerm", copyTerm);
      
      let formData = new FormData();
      formData.append("file", file)
      
      let $ajax = $.ajax({
        url: template_url,
        type: "POST",
        method: "POST",
        data: formData,
        processData: false,
        contentType: false,
      });
      $ajax.done(function () {
        //alert("Successfully, Uplaoded image");
      });
      $ajax.fail(function () {
        alert("Save of image failed!");
      });
    }

   
    save_event_image = () => {
      let template_url = "/api/saveevent_image?event_id=:event_id&&event_image_file=:filename"
      template_url = template_url.replace(":event_id", this.state.event_id).replace(":filename", this.state.img_url);

      let formData = new FormData();
      formData.append("file", this.state.file)
      
      let $ajax = $.ajax({
        url: template_url,
        type: "POST",
        method: "POST",
        data: formData,
        processData: false,
        contentType: false,
      });
      $ajax.done(function () {
        alert("Successfully, Uploaded image");
      });
      $ajax.fail(function () {
        //alert("Save of image failed!");
      });
    }

    closeModal = () => {

      if (this.state.user_file) {
        this.save_event_image()
        //this.setState({user_file: false})
      }

      let spinner=document.getElementById("mySpinner") 
      spinner.style.display="none"

      if (this.state.edit_flg) 
        alert("Event Saved successfully")
      else
        alert("Event Saved successfully\nProceed to Pay for the Event after completion.")

      // this.setState({ 
      //   isCreatingEvent: false,
      //   user_file: false,
      //   event_id: "", 
      //   event_image: "", 
      //   event_type: "Wedding", 
      //   title: "", 
      //   file: "", 
      //   img_url: "", 
      //   event_dt: "", 
      //   prepaid_ct: 0, 
      //   edit_flg: false, 
      //   del_flg: false, 
      //   bride: "", 
      //   bridegroom: "", 
      //   bday_name: "", 
      //   vidFlg: false
      //  })
       
      window.location.reload();
    }

    render() {
    
    let list
    let nolist
    let c_list

    if (this.state.events.length > 0) {
      if (this.state.mobile){
        list = this.state.events.map( item =>{

          return (
            
              <div key={item.id} style={{ display: 'flex', flexDirection: 'column' , boxShadow: '5px 10px 10px #888888', border: '1px solid black', marginBottom: '25px', paddingTop: '10px', marginLeft: '-15px', marginRight: '10px'}}>
                    <h4 style={{marginLeft: '10px', marginTop:'5px'}}>{item.title}</h4>
                <img id="eventImage" src={`uploads/events/${item.id}/pictures/`+item.img_url} alt=""  style={{width:'100%', marginBottom:"5px"}}/>
                {item.video?
                  <video playsInline autoPlay={false} controls id={`videoSaved${item.id}`} src={`uploads/events/${item.id}/${item.id}.mp4?${new Date().getTime()}}`}
                    poster={'poster.jpg'}
                    style={{width:'auto', boxShadow: '5px 5px 5px #888888', border: '10px solid black', marginBottom:'15px', marginTop:'10px' }}>
                  </video>
                 : ''} 
                <div style={{paddingLeft:'5px'}}>
              
                { item.event_type === "Wedding" || item.event_type === "Anniversary" || item.event_type === "Engagement"?
                  <span style={{fontSize:"15px", fontWeight:"bold"}}> {item.bride}</span>
                :
                  ''
                }
                <br></br>
                { item.event_type === "Wedding" || item.event_type === "Anniversary" || item.event_type === "Engagement"?
                    <span style={{fontSize:"15px", fontWeight:"bold"}}> {item.bridegroom}</span>
                :
                  ''
                }
                
                { item.event_type != "Wedding" && item.event_type != "Anniversary" && item.event_type != "Engagement"?
                  <span style={{fontSize:"15px", fontWeight:"bold"}}>Name: {item.bday_name}</span>
                :
                  ''
                }
                
                <br></br>
                  <span style={{fontSize:"15px", fontWeight:"bold"}}>Date: {item.event_dt}</span>
                <br></br>
                <br></br>


                {item.status === 'CREATE'?
                    <Button bsStyle="success" bsSize="small" style={{margin:"3px"}}
                      onClick={() => {
                        
                        this.setState({ 
                          event_id: item.id,
                          no_of_wishers: item.no_of_wishers,
                          //price: item.price,
                          //prepaid_ct: item.prepaid_ct,
                          isPayingEvent: true 
                        })
                      }}
                        
                    >
                    Pay for the Event
                    </Button>
                  : 
                  ''
                  }

                {item.status === 'ACTIVE' || item.status === 'CREATE'?
                
                
                <Fragment>
                  <Button bsStyle="success" bsSize="small" style={{margin:"3px"}}
                    onClick={() => {
                      this.setState({
                        edit_flg: true,
                        event_id: item.id,
                        event_type: item.event_type,
                        title: item.title,
                        bride: item.bride,
                        bridegroom: item.bridegroom,
                        bday_name: item.bday_name,
                        event_image: `uploads/events/${item.id}/pictures/`+item.img_url,
                        event_dt: item.event_dt,
                        prepaid_ct: item.prepaid_ct,
                        event_video: item.video,
                        isCreatingEvent: true,
                        product_id: item.product_id
                      })
                      //this.videoSetup();
                    }}
                  >
                  Edit Event
                  </Button>

                  
                  <Button bsStyle="danger" bsSize="small" style={{margin:"3px"}}
                    onClick={() => {
                      let proceed = confirm("Are you sure you want to DELETE this Event?")
                      if (proceed) {
                        this.setState({edit_flg: true})
                        this.setState({event_id: item.id})
                        this.deleteEvent(item.id);
                      }
                    }}
                    
                  >
                  Delete Event
                  </Button>

                    
                  {item.status != 'CREATE'?  
                    <a href={"/contribute?event_id="+item.id+"&event_dt="+item.event_dt}>
                    <Button bsStyle="success" bsSize="small" style={{margin:"5px"}}>
                      Wish Locally
                    </Button>
                    </a>
                  : ''}
                  </Fragment>
              : ''}

              {item.status !== 'CREATE'?
              <Fragment>
                  <Button bsStyle="info" bsSize="small" style={{margin:"5px"}}
                    onClick={() => {
                      this.setState({
                        event_id: item.id,
                        event_type: item.event_type,
                        title: item.title,
                        bride: item.bride,
                        bridegroom: item.bridegroom,
                        bday_name: item.bday_name,
                        event_image: `uploads/events/${item.id}/pictures/`+item.img_url,
                        event_dt: item.event_dt,
                        prepaid_ct: item.prepaid_ct,
                        event_video: item.video,
                        isContributions: true,
                        product_id: item.product_id
                      })
                      this.getContributions(item.id, false)
                    }}
                  >
                  Collected Wsihes
                  </Button>

                  {item.status == 'ACTIVE'?  
                    <Button bsStyle="info" bsSize="small" style={{margin:"5px"}}
                    onClick={() => {
                      this.setState({event_id: item.id, event_dt: item.event_dt, link: true})
                      // let clipBoard=document.getElementById("myClip") 
                      // clipBoard.style.display=""
                      // clipBoard.style.marginTop="-125%"
                    }}
                    >
                    Event Link
                    </Button>
                  : ''}
              </Fragment>
              : ''}

                  {item.status === 'ACTIVE'?
                    <Button bsStyle="danger" bsSize="small" style={{margin:"5px"}}
                      onClick={() => {
                        this.updateEventProductId(item.id, 0, 'CLOSED')
                      }}
                    >
                    Close Event
                    </Button>
                  : ''}

                  {item.status === 'CLOSED'?
                    <Button bsStyle="primary" bsSize="small" style={{margin:"5px"}}
                      onClick={() => {
                        this.getContributions(item.id, true)
                        this.setState({
                          event_id: item.id,
                          isArrange: true
                        })
                      }}
                    >
                    Arrange Wsihes
                    </Button>
                  : ''}

                  {item.status === 'CLOSED' && item.prepaid_ct === item.postpaid_ct?
                  <Fragment>
                  <Button id="btn-generate-mobile" bsStyle="success" bsSize="small" style={{margin:"5px"}}
                    onClick={() => {
                      var newArray = this.state.presetTemplates.filter(function (el) {
                        return el.sub_category === item.event_type.toUpperCase()
                      });

                      
                      var btnElem = "btn-template-mobile" + item.id
                      document.getElementById(btnElem).style.display=""
                      
                      var btnElemPic = "btn-pictures-mobile" + item.id
                      document.getElementById(btnElemPic).style.display=""

                      if (item.preset_product_id === null) {
                        this.getEventPictures(item.id, item.img_url, true)
                        alert("Proceed to 'Choose a Template' before Event Files generation.")
                        document.getElementById('btn-template-mobile').style.display=""
                      }
                      else {
                      //  alert("You have already chosen an Output Template.\n You can choose again.")
                        
                      let proceed = confirm("Have you uploaded your Event Pictures for each Page?\nIf not, choose 'Cancel' and click on 'Upload Event Pictures'")
                        if (proceed) {
                          let final = confirm("Warning: You cannot go back to change 'Template' or 'Upload Event Pictures'\nConfirm Event Files generation?")
                          if (final) {
                             this.generateEventFiles(item.id, item.event_type, item.preset_product_id, item.product_id)
                          }
                        }
                    }
                 }}
                  >
                  Generate Event Files
                  </Button>

                 {item.preset_product_id != null?
                  <Button id={`btn-template-mobile${item.id}`} bsStyle="warning" bsSize="small" style={{margin:"5px", display:""}}
                  onClick={() => {
                    var newArray = this.state.presetTemplates.filter(function (el) {
                      return el.sub_category === item.event_type.toUpperCase()
                    });
                    this.setState({choosePreset: true, event_id: item.id, event_type: item.event_type, product_id: item.product_id, presetTemplates: newArray})
                  }}
                  >
                    
                  Choose a Template 
                  </Button>  
                  :
                  <Button id={`btn-template-mobile${item.id}`} bsStyle="warning" bsSize="small" style={{margin:"5px", display:"none"}}
                  onClick={() => {
                    var newArray = this.state.presetTemplates.filter(function (el) {
                      return el.sub_category === item.event_type.toUpperCase()
                    });
                    this.setState({choosePreset: true, event_id: item.id, event_type: item.event_type, product_id: item.product_id, presetTemplates: newArray})
                  }}
                  >
                  Choose a Template
                  </Button>  
                }

                {item.product_id != null && item.product_id != 0?
                  <Button id={`btn-template-mobile${item.id}`} bsStyle="success" bsSize="small" style={{margin:"5px", display:""}}
                  onClick={() => {
                    // window.location = "/products/" + product.id + "/layouts/" + layout.id;
                    window.location = "/products/" + item.product_id + "/wishlayouts/" + "template";
                    // this.setState({choosePreset: true, event_id: item.id, event_type: item.event_type, product_id: item.product_id, presetTemplates: newArray})
                  }}
                  >
                    
                  Customize Template 
                  </Button>  
                  :
                  <Button id={`btn-template-mobile${item.id}`} bsStyle="success" bsSize="small" style={{margin:"5px", display:"none"}}
                  onClick={() => {
                    // window.location = "/products/" + product.id + "/layouts/" + layout.id;
                    window.location = "/products/" + item.product_id + "/wishlayouts/" + "template";
                    // this.setState({choosePreset: true, event_id: item.id, event_type: item.event_type, product_id: item.product_id, presetTemplates: newArray})
                  }}
                  >
                  Customize Template
                  </Button>  
                }

                {item.preset_product_id != null?
                  <Button id={`btn-pictures-mobile${item.id}`} bsStyle="warning" bsSize="small" style={{margin:"5px", display:""}}
                    onClick={() => {
                      this.getEventPictures(item.id, item.img_url, false)
                      this.setState({ chooseEventPictures: true, event_id: item.id })
                  }}
                  >
                  Upload Event Pictures
                  </Button>  
                :
                  <Button id={`btn-pictures-mobile${item.id}`} bsStyle="warning" bsSize="small" style={{margin:"5px", display:"none"}}
                    onClick={() => {
                      this.getEventPictures(item.id, item.img_url, false)
                      this.setState({ chooseEventPictures: true, event_id: item.id })
                    }}
                  >
                    Upload Event Pictures
                  </Button>  
                }
                </Fragment>

              : 
                <Fragment>
                  {item.status==="CLOSED"?
                    <Button bsStyle="danger" bsSize="small" style={{margin:"5px"}}
                      onClick={() => {
                        this.setState({
                          event_id: item.id,
                          prepaid_ct: item.prepaid_ct,
                          postpaid_ct: item.postpaid_ct,
                          status: item.status,
                          extraWishers: 1,
                          isExtraPayingEvent: true})
                        }}
                    >
                       Manage Additional Wishes
                    </Button>
                  :''}
                </Fragment>
              }
              
                {item.status === 'BATCH' ?
                  <Button bsStyle="success" bsSize="small" style={{margin:"5px"}}
                    onClick={() => {
                      this.checkBatch(item.id, item.product_id)
                    }}
                  >
                  Check Event Files Generation Progress
                  </Button>
                  : ''}

                  {item.status === 'DOWNLOAD' ?
                  <Fragment>
                  <a
                  target="_blank"
                  href={`${window.origin}/uploads/events/${       // Don't change this location to production as users has to download from public directory only
                    item.id
                  }/order-${
                    item.id
                  }.zip`}
                >
                  <Button bsStyle="success" bsSize="small" style={{margin:"5px"}}>
                    Download Event Files
                  </Button>
                  </a>{" "}
                  </Fragment>
                  : ''}

                  {item.status != 'CREATE' ?

                    <Button bsStyle="info" bsSize="small" style={{margin:"5px"}}
                    onClick={(e) => {
                      this.showInvoices(item.id)   
                    }}
                    
                  >
                  Invoice
                  </Button>
                  : ''}


                  <br></br>
                  <br></br>
            </div>
          </div>                
          
        )
      })
      }
      else {
        list = this.state.events.map( item =>{

            return (
              <div key={item.id} style={{ display: 'flex', flexDirection: 'column' , boxShadow: '5px 10px 10px #888888', border: '1px solid black', marginBottom: '25px', paddingLeft: '10px', paddingTop: '10px', marginRight: '25px'}}>
                <div style={{paddingLeft:'5px'}}>
                <div key={item.id} style={{ display: 'flex', flexDirection: 'row' , marginBottom: '5px', paddingLeft: '5px', paddingTop: '5px', marginRight: '25px'}}>
                  <img id="eventImage" src={`uploads/events/${item.id}/pictures/`+item.img_url + `?t=` + new Date().getTime()} alt="" height="330px" width="auto" 
                  onClick={()=>{window.open(`uploads/events/${item.id}/pictures/`+item.img_url, "_blank")}}
                  style={{cursor: 'pointer'}}
                  />
                  {item.video?
                    <video controls playsInline id={`videoSaved${item.id}`} src={`uploads/events/${item.id}/${item.id}.mp4?${new Date().getTime()}}`}
                      style={{marginLeft:'10px', height:"330px", boxShadow: '5px 10px 10px #888888', border: '10px solid black',}}>
                    </video>
                 : ''} 

                  <div style={{paddingLeft:'10px'}}>
                    <h3 style={{marginTop: '10px'}}>{item.title}</h3>
                  { item.event_type === "Wedding" || item.event_type === "Anniversary" || item.event_type === "Engagement"?
                    <h4> {item.bride}</h4>
                  :
                    ''
                  }
                  
                  { item.event_type === "Wedding" || item.event_type === "Anniversary" || item.event_type === "Engagement"?
                      <h4> {item.bridegroom}</h4>
                  :
                    ''
                  }
                  
                  { item.event_type != "Wedding" && item.event_type != "Anniversary" && item.event_type != "Engagement"?
                      <h4>Name: {item.bday_name}</h4>
                  :
                    ''
                  }
                    <h4>Date: {item.event_dt}</h4>
                    </div>                  
</div>

                  {item.status === 'CREATE'?
                    <Button style={{backgroundColor:"green", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px' }}  
                      onClick={() => {
                        
                        this.setState({ 
                          event_id: item.id,
                         // price: item.price,
                         // prepaid_ct: item.prepaid_ct,
                          isPayingEvent: true 
                        })
                      }}
                        
                    >
                    Pay for the Event
                    </Button>
                  : 
                  ''
                  }

                  {item.status === 'ACTIVE' || item.status === 'CREATE'?
                  
                  <Fragment>
                    <Button style={{backgroundColor:"green", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px'}}  
                      onClick={() => {
                        this.setState({
                          edit_flg: true,
                          event_id: item.id,
                          event_type: item.event_type,
                          title: item.title,
                          bride: item.bride,
                          bridegroom: item.bridegroom,
                          bday_name: item.bday_name,
                          event_image: `uploads/events/${item.id}/pictures/`+item.img_url,
                          event_dt: item.event_dt,
                          prepaid_ct: item.prepaid_ct,
                          event_video: item.video,
                          isCreatingEvent: true
                        })
                        //this.videoSetup();
                      }}
                    >
                    Edit Event
                    </Button>

                    
                    <Button style={{backgroundColor:"red", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px' }}  
                      onClick={() => {
                        let proceed = confirm("Are you sure you want to DELETE this Event?")
                        if (proceed) {
                          this.setState({edit_flg: true})
                          this.setState({event_id: item.id})
                          this.deleteEvent(item.id);
                        }
                      }}
                      
                    >
                    Delete Event
                    </Button>

                    {item.status != 'CREATE'?   
                    
                      <a href={"/contribute?event_id="+item.id+"&event_dt="+item.event_dt}>
                      <Button style={{backgroundColor:"green", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px' }} >
                        Wish Locally
                      </Button>
                      </a>
                    : ''} 
                    </Fragment>
                : ''} 

                {item.status != 'CREATE'?
                <Fragment>
                    <Button style={{backgroundColor:"blue", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px' }}  
                      onClick={() => {
                        this.setState({
                          event_id: item.id,
                          event_type: item.event_type,
                          title: item.title,
                          bride: item.bride,
                          bridegroom: item.bridegroom,
                          bday_name: item.bday_name,
                          event_image: `uploads/events/${item.id}/pictures/`+item.img_url,
                          event_dt: item.event_dt,
                          prepaid_ct: item.prepaid_ct,
                          event_video: item.video,
                          isContributions: true,
                        })
                        this.getContributions(item.id, false)
                      }}
                    >
                    Collected Wsihes
                    </Button>

                    {item.status == 'ACTIVE'?  
                        <Button style={{backgroundColor:"blue", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px' }}  
                        onClick={() => {
                          this.setState({event_id: item.id, event_dt: item.event_dt, link: true})
                          // let clipBoard=document.getElementById("myClip") 
                          // clipBoard.style.display=""
                          // clipBoard.style.marginTop="-25%"
                        }}
                        >
                        Event Link
                        </Button>
                      :''}  
                  </Fragment>
                  : 
                  ''
                  }

                    {item.status === 'ACTIVE'?
                      <Button style={{backgroundColor:"red", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px' }}  
                        onClick={() => {
                          this.updateEventProductId(item.id, 0, 'CLOSED')
                        }}
                      >
                      Close Event
                      </Button>
                    : ''}

                    {item.status === 'CLOSED'?
                      
                      <Button style={{backgroundColor:"purple", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px' }}  
                        onClick={() => {
                          this.getContributions(item.id, true)
                          this.setState({
                            event_id: item.id,
                            isArrange: true
                          })
                        }}
                      >
                      Arrange Wsihes
                      </Button>
                    : ''}
           
                    {item.status === 'CLOSED' && item.prepaid_ct === item.postpaid_ct?
                    <Fragment>
                      <Button id="btn-generate" style={{backgroundColor:"green", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px' }}  
                        onClick={() => {
                          var newArray = this.state.presetTemplates.filter(function (el) {
                            return el.sub_category === item.event_type.toUpperCase()
                          });

                          var btnElem = "btn-template" + item.id
                          document.getElementById(btnElem).style.display=""
                          
                          var btnElemPic = "btn-pictures-mobile" + item.id
                          document.getElementById(btnElemPic).style.display=""

                          if (item.preset_product_id === null) {
                            this.getEventPictures(item.id, item.img_url, true)
                            alert("Proceed to 'Choose a Template' before Event Files generation.")
                            
                          }
                          else {
                          //  alert("You have already chosen an Output Template.\n You can choose again.")
                            
                          let proceed = confirm("Have you uploaded your Event Pictures for each Page?\nIf not, choose 'Cancel' and click on 'Upload Event Pictures'")
                            if (proceed) {
                              let final = confirm("Warning: You cannot go back to change 'Template' or 'Upload Event Pictures'\nConfirm Event Files generation?")
                              if (final) {
                                 this.generateEventFiles(item.id, item.event_type, item.preset_product_id, item.product_id)
                              }
                            }
                        }
                     }}
                      >
                      Generate Event Files
                      </Button>

                    {item.preset_product_id!=null? 
                      <Button id={`btn-template${item.id}`} style={{backgroundColor:"black", color:"yellow", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px', display:"" }}  
                        onClick={() => {
                          var newArray = this.state.presetTemplates.filter(function (el) {
                            return el.sub_category === item.event_type.toUpperCase()
                          });
                          this.setState({choosePreset: true, event_id: item.id, event_type: item.event_type, product_id: item.product_id, presetTemplates: newArray})
                        }}
                      >
                      Choose a Template
                      </Button>  
                    :
                      <Button id={`btn-template${item.id}`} style={{backgroundColor:"black", color:"yellow", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px', display:"none" }}  
                        onClick={() => {
                          var newArray = this.state.presetTemplates.filter(function (el) {
                            return el.sub_category === item.event_type.toUpperCase()
                          });
                          this.setState({choosePreset: true, event_id: item.id, event_type: item.event_type, product_id: item.product_id, presetTemplates: newArray})
                        }}
                      >
                      Choose a Template
                      </Button>  
                    }

                    {item.product_id!=null && item.product_id !=0 ? 
                      <Button id={`btn-template${item.id}`} style={{backgroundColor:"blue", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px', display:"" }}  
                        onClick={() => {
                          // window.location = "/products/" + product.id + "/layouts/" + layout.id;
                          window.location = "/products/" + item.product_id + "/wishlayouts/" + "template";
                          // this.setState({choosePreset: true, event_id: item.id, event_type: item.event_type, product_id: item.product_id, presetTemplates: newArray})
                        }}
                      >
                      Customize Template
                      </Button>  
                    :
                      <Button id={`btn-template${item.id}`} style={{backgroundColor:"blue", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px', display:"none" }}  
                      onClick={() => {
                        // window.location = "/products/" + product.id + "/layouts/" + layout.id;
                        window.location = "/products/" + item.product_id + "/wishlayouts/" + "template";
                        // this.setState({choosePreset: true, event_id: item.id, event_type: item.event_type, product_id: item.product_id, presetTemplates: newArray})
                      }}
                      >
                      Customize Template
                      </Button>  
                    }

                    {item.preset_product_id!=null? 
                      <Button id={`btn-pictures-mobile${item.id}`} style={{backgroundColor:"black", color:"yellow", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px', display:"" }}  
                        onClick={() => {
                        this.getEventPictures(item.id, item.img_url, false)
                        this.setState({ chooseEventPictures: true, event_id: item.id })
                        
                        }}
                      >
                        Upload Event Pictures
                      </Button>  
                    :
                      <Button id={`btn-pictures-mobile${item.id}`} style={{backgroundColor:"black", color:"yellow", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px', display:"none" }}  
                        onClick={() => {
                          this.getEventPictures(item.id, item.img_url, false)
                          this.setState({ chooseEventPictures: true, event_id: item.id })
                        }}
                      >
                        Upload Event Pictures
                      </Button>  
                    }
                  </Fragment>


                    : 
                      <Fragment>
                      {item.status==="CLOSED"?
                      <Button style={{backgroundColor:"red", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px' }}  
                        onClick={() => {
                          this.setState({
                            event_id: item.id,
                            prepaid_ct: item.prepaid_ct,
                            postpaid_ct: item.postpaid_ct,
                            status: item.status,
                            extraWishers: 1,
                            isExtraPayingEvent: true})
                        }}
                      >
                      Manage Additional Wishes
                      </Button>
                      :''}
                      </Fragment>
                    }

                    {item.status === 'BATCH' ?
                    <Button style={{backgroundColor:"green", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px' }}  
                      onClick={() => {
                        this.checkBatch(item.id, item.product_id)
                      }}
                    >
                    Check Event Files Generation Progress
                    </Button>
                    : ''}
  
                    {item.status === 'DOWNLOAD' ?
                    <Fragment>
                    <a
                    target="_blank"
                    href={`${window.origin}/uploads/events/${       // Don't change this location to production as users has to download from public directory only
                      item.id
                    }/order-${
                      item.id
                    }.zip`}
                  >
                    <Button style={{backgroundColor:"green", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px' }} >
                      Download Event Files
                    </Button>
                    </a>{" "}
                    </Fragment>
                    : ''}

                  {item.status != 'CREATE' ?

                    <Button style={{backgroundColor:"#547ffc", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', marginLeft: '5px' }}  
                      onClick={(e) => {
                        this.showInvoices(item.id)   
                      }}
                      
                    >
                    Invoice
                    </Button>
                    : ''}

                      

              </div>
            </div>                
            // </div>         
          )
        })
      }
    }
    else {
        nolist = ["No Events Found! Create a New Event now!"]
    }

    const occassions = ['Wedding' , 'Birthday', 'Anniversary'];

    const convenienceCharges = .05;
    const gst = 0.18;

    return (
        <React.Fragment>


<Modal centered="true" show={this.state.isPaymentModal} onHide={() => {this.setState({ isPaymentModal: false })}}>
        <Modal.Header closeButton>
          <Modal.Title>Purchase Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul style={{ listStyleType: "none" }}>
            {this.countryCode!=="IN"?
            <Fragment>
              <li>
              <strong>
                Total Price for {this.state.prepaid_ct} Wishers: ${this.state.prepaid_ct * this.state.price}
              </strong>
            </li>
            <li>
              <strong>
                Convenience Charges : $
                {parseFloat(this.state.prepaid_ct * this.state.price * convenienceCharges).toFixed(2)}
              </strong>
            </li>
            <li>
              <strong>
                TAX : $0.0
              </strong>
            </li>
            <li>
              <strong>Grand Total: ₹ {(((this.state.prepaid_ct * this.state.price + this.state.prepaid_ct * this.state.price * convenienceCharges))).toFixed(2)}</strong>
            </li>
            </Fragment>
            :
            <Fragment>
              <li>
                <strong>
                  Total Price for {this.state.prepaid_ct} Wishers: ₹{this.state.prepaid_ct * this.state.price}
                </strong>
              </li>
              <li>
                <strong>
                  Convenience Charges : ₹
                  {parseFloat(this.state.prepaid_ct * this.state.price * convenienceCharges).toFixed(2)}
                </strong>
              </li>
              <li>
                <strong>
                  GST 18% : ₹
                  {parseFloat((this.state.prepaid_ct * this.state.price + this.state.prepaid_ct * this.state.price * convenienceCharges) * gst).toFixed(2)}
                </strong>
              </li>
              <li>
                <strong>Grand Total (rounded): ₹ {Math.round(((this.state.prepaid_ct * this.state.price + this.state.prepaid_ct * this.state.price * convenienceCharges))+(this.state.prepaid_ct * this.state.price + this.state.prepaid_ct * this.state.price * convenienceCharges) * gst).toFixed(2)}</strong>
              </li>
              </Fragment>
            }
            
          </ul>
          {this.countryCode!=="IN"?
            <Fragment>
              {this.state.extraWishers > 0?
                <EventPayment extraWishersFlg={true} event_id={this.state.event_id} price={this.state.price} no_of_wishers={this.state.prepaid_ct} amount={(((this.state.prepaid_ct * this.state.price + this.state.prepaid_ct * this.state.price * convenienceCharges))).toFixed(2)} /> 
              :
                <EventPayment extraWishersFlg={false} event_id={this.state.event_id} price={this.state.price} no_of_wishers={this.state.prepaid_ct} amount={(((this.state.prepaid_ct * this.state.price + this.state.prepaid_ct * this.state.price * convenienceCharges))).toFixed(2)} /> 
              }
            </Fragment>
           :
            <Fragment>
              {this.state.extraWishers > 0?
                <RPEventPayment extraWishersFlg={true} event_id={this.state.event_id} price={this.state.price} no_of_wishers={this.state.prepaid_ct} amount={Math.round(((this.state.prepaid_ct * this.state.price + this.state.prepaid_ct * this.state.price * convenienceCharges) + (this.state.prepaid_ct * this.state.price + this.state.prepaid_ct * this.state.price * convenienceCharges) * gst )).toFixed(2)}/> 
              :  
                <RPEventPayment extraWishersFlg={false} event_id={this.state.event_id} price={this.state.price} no_of_wishers={this.state.prepaid_ct} amount={Math.round(((this.state.prepaid_ct * this.state.price + this.state.prepaid_ct * this.state.price * convenienceCharges) + (this.state.prepaid_ct * this.state.price + this.state.prepaid_ct * this.state.price * convenienceCharges) * gst )).toFixed(2)}/> 
              }
            </Fragment>
           }
          {/* <UserEvent event_id={this.state.event_id} price={this.state.price} no_of_wishers={this.state.prepaid_ct} /> */}
        </Modal.Body>
      </Modal>
      

      <ul>
        <Modal id="Invoices"
          dialogClassName="modal-90w"
          centered="true"
          show={this.state.isFileManagerOpen}
          onHide={this.openFileManager}
        >
          <Modal.Header closeButton>
            <Modal.Title>Event Purchases</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.purchaseHistory && this.state.purchaseHistory.length > 0 && (
              <Row>
                <Col md={12}>
                  <table className="table table-bordered">
                    <caption className="event_history">Purchase Event - History</caption>
                    <thead>
                      <tr>
                      <th scope="col">Event ID</th>
                        <th scope="col">No of Wishers</th>
                      { this.state.mobile?
                        ''
                      :
                        <Fragment>
                          <th scope="col">Purchased On</th>
                          <th scope="col">Payment ID</th>
                        </Fragment>
                      }
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.purchaseHistory &&
                        this.state.purchaseHistory.map((event) => {
                          return (
                            <tr>
                              <td>{event.event_id}</td>
                              <td>{event.no_of_wishers}</td>
                            { this.state.mobile?
                              ''
                            :
                              <Fragment>
                                <td>{`${new Date(event.purchased_on).toLocaleString()}`}</td>
                                {this.countryCode!=="IN"?
                                  <td>{event.pg_reference_id}</td>
                                :
                                  <td>{event.razorpay_capture.id}</td>
                                }
                              </Fragment>
                            }
                              <td>
                                <Button className="btnsmall btn-success"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                      this.setState({
                                      currentOrder: event,
                                      isEditProductClicked: true,
                                      });
                                  }}
                                >
                                  Show Invoice
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </Col>
              </Row>
            )}
          </Modal.Body>
        </Modal>
        {this.state.isEditProductClicked && (this.countryCode!=="IN"?this.getOrderInvoice(this.state.currentOrder):this.getrazorpayOrderInvoice(this.state.currentOrder))}
        </ul>
      



        <Modal show={this.state.isExtraPayingEvent} onHide={() => {this.setState({ isExtraPayingEvent: false })}}
            style={{marginTop:'45px', }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Manage Additional Wishes
                </Modal.Title>
              </Modal.Header>
            <h4 style={{marginTop:"15px", marginLeft:"15px",}}>No. of Wishes already Paid for : {this.state.prepaid_ct}</h4>
            <h4 style={{marginTop:"15px", marginLeft:"15px",}}>No. of Collected Wsihes : {this.state.postpaid_ct}</h4>
            <h4 style={{marginTop:"15px", marginLeft:"15px",}}>No. of Additional Wsihes : {(this.state.postpaid_ct - this.state.prepaid_ct)}</h4>
            
            {this.countryCode!=='IN'?
              <h4 style={{marginTop:"15px", marginLeft:"15px",}}>Price for each 'Additional Wish' : $0.15</h4>
            :
              <h4 style={{marginTop:"15px", marginLeft:"15px",}}>Price for each 'Additional Wish' : ₹15</h4>
            }


              <Form onSubmit={this._handlePaymentSubmit} className="override_form">
                <Modal.Body>

                    

              <FormGroup>
              <h4 style={{marginTop:"5px", fontWeight:'bold', color:'green'}}>Choose your option below:</h4>    
                  <h4 style={{marginTop:"5px", marginLeft:"15px",}} >Pay for Additional Wishes :    
                    <Radio 
                      style={{marginTop:"-47px", marginLeft:"15px",}}
                      name="radioGroup" 
                      
                      onChange={ (e) => {
                        var divId = document.getElementById("payExtra")
                        divId.style.display=""
                        var divId1 = document.getElementById("payNothing")
                        divId1.style.display="none"
                      }} 
                      inline>
                    </Radio>
                  </h4> 
                
                
                  <h4 style={{marginTop:"10px", marginLeft:"15px",}} >Delete All Additional Wishes :    
                    <Radio 
                      style={{marginTop:"-47px", marginLeft:"15px",}}
                      name="radioGroup" 
                      onChange={ (e) => {
                        var divId = document.getElementById("payExtra")
                        divId.style.display="none"
                        var divId1 = document.getElementById("payNothing")
                        divId1.style.display=""
                      }} 
                      inline>
                    </Radio>
                  </h4> 
                
      
    </FormGroup>
                <div id="payExtra"  style={{display: "none"}}>
                  <br></br>
                  <br></br>
                <FieldGroup
                    type="number"
                    id="eventWishers"
                    label="Number of additional Wishes you want to pay for"
                    name="extraWishers"
                    placeholder=""
                    step="1"
                    min="1"
                    max={(this.state.postpaid_ct - this.state.prepaid_ct)}
                    required="required"
                    onChange ={({ target: { name, value } }) => {
                          this.setState({ [name]: value });
                    }}
                    onBlur={({ target: { name, value } }) => {
                      if (value < 1) {
                        alert("Value cannot be less than 1. Updated to 1")
                        //value={(Math.trunc((this.state.postpaid_ct - this.state.prepaid_ct)/20)+1)*20}
                        //value=((Math.trunc(value/20)+1)*20)
                          this.setState({ [name]: 1 });
                        return 
                      }
                     if (value > (this.state.postpaid_ct - this.state.prepaid_ct)) {
                         alert ("Value above Maximum value of " + (this.state.postpaid_ct - this.state.prepaid_ct)) 
                         this.setState({ 
                          extraWishes: ((this.state.postpaid_ct - this.state.prepaid_ct)) 
                        })
                        value=this.state.postpaid_ct - this.state.prepaid_ct
                         return 
                       }
                      
                    }}
                    value={this.state.extraWishers}
                  >
                  </FieldGroup>

                  <Button style={{backgroundColor:"green", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', }}  
                      onClick={() => {
                        if (this.state.extraWishers === 0) {
                          alert("Enter a value before proceeding...")
                          return
                        }
                        if (this.state.extraWishers <= (this.state.postpaid_ct - this.state.prepaid_ct) ) {
                          let remaining = this.state.postpaid_ct - this.state.prepaid_ct - this.state.extraWishers
                          
                          if (remaining > 0) {
                            if (this.countryCode!=='IN') 
                              this.setState({price:0.15})
                            else 
                              this.setState({price:15})

                            let proceed = confirm( "Warning:\n        " + remaining + " Wishes not paid for will be deleted permanently!!!\n        You cannot go back and pay for more wishers later.\n\nClick 'Ok' to Confirm or 'Cancel' to Stop")  
                            if (proceed)
                              this.setState({ 
                                isPaymentModal: true, 
                                isExtraPayingEvent:false, 
                                prepaid_ct: this.state.extraWishers,
                                })
                              else 
                                return false
                            }
                            else {
                              this.setState({ 
                                isPaymentModal: true, 
                                isExtraPayingEvent:false, 
                                price: 15,
                                prepaid_ct: this.state.extraWishers,
                                })
                            }
                        }
                        
                      }
                        
                    }
                    >
                    Proceed to Payment
                    </Button>
                    </div>

                  <div id="payNothing"  style={{display: "none"}}>
                  <br></br>
                  <br></br>
                  {this.state.mobile?
                    <Button bsStyle="danger" bsSize="small" style={{margin:"5px"}}
                      onClick={() => {
                        if (this.state.extraWishers < (this.state.postpaid_ct - this.state.prepaid_ct)) {
                          let remaining = this.state.postpaid_ct - this.state.prepaid_ct
                          let proceed = confirm( "Warning:\n        " + remaining + " Wishes not paid for will be deleted permanently!!!\n\n        Click 'Ok' to Confirm or 'Cancel' to Stop")  
                          if (proceed)
                            this.updateCounts(this.state.event_id, 0)
                          else 
                            return false
                        }
                      }}
                    >
                      Confirm Deletion of All Additional Wishes
                    </Button>
                  :
                    <Button style={{backgroundColor:"red", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', }}  
                      onClick={() => {
                        if (this.state.extraWishers < (this.state.postpaid_ct - this.state.prepaid_ct)) {
                          let remaining = this.state.postpaid_ct - this.state.prepaid_ct
                          let proceed = confirm( "Warning:\n        " + remaining + " Wishes not paid for will be deleted permanently!!!\n\n        Click 'Ok' to Confirm or 'Cancel' to Stop")  
                          if (proceed)
                            this.updateCounts(this.state.event_id, 0)
                          else 
                            return false
                          }
                      }}
                    >
                      Confirm Deletion of All Additional Wishes
                    </Button>
                  }
                </div>












                </Modal.Body>

                <Modal.Footer>
                  {/* <Button type = "submit" style={{ backgroundColor: "blue", borderRadius: '25%', color: "white"}}  >
                    Submit
                  </Button> */}
                </Modal.Footer>
              </Form>
            </Modal>























    
    <Modal show={this.state.isPayingEvent} onHide={() => {this.setState({ isPayingEvent: false })}}
            style={{marginTop:'45px', }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Event Payment
                </Modal.Title>
              </Modal.Header>
            <h3 style={{marginTop:"15px", marginLeft:"15px",}}>Note:</h3>
            <p style={{marginTop:"-20px", marginLeft:"15px", fontSize:"15px", fontWeight:"bold"}}>
               - You can invite many Wishers to record their wishes/messages for your event
              <br></br><br></br>
               - Price per Wish is {this.countryCode!=="IN"? '$0.10' : '₹10'}
               <br></br><br></br>
               - Choose your Wish counts wisely! 
               <br></br><br></br>
                 &nbsp;&nbsp;&nbsp;&nbsp;- as there is no REFUND once payment is made
                 <br></br>
                 &nbsp;&nbsp;&nbsp;&nbsp;- price per extra Wish will be {this.countryCode!=="IN"? '$0.15' : '₹15'} 
                 <br></br>
                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- you can pay for the extra wishes later
                 <br></br>
                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- or you can reject the extra wishes 
                 <br></br>
                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- minimum number of wish count is 20 
            </p>

              <Form onSubmit={this._handlePaymentSubmit} className="override_form">
                <Modal.Body>
                <FieldGroup
                    type="number"
                    id="eventWishers"
                    label="Enter Number of Wishes"
                    name="prepaid_ct"
                    placeholder="Minimum: 20"
                    step="20"
                    min="20"
                    max="2000"
                    required="required"
                    value={this.state.prepaid_ct === (0 || null) ? 20 : this.state.prepaid_ct}
                    onChange={({ target: { name, value } }) => {
                            this.setState({ [name]: value });
                    }}
                    onBlur={({ target: { name, value } }) => {
                      if (value < 20) {
                        alert("Minimum wish count is 20")
                        this.setState({ [name]: 20 });
                        // return false
                      }
                    }}
                    
                  >
                  </FieldGroup>

                  <Button style={{backgroundColor:"green", color:"white", fontSize:"20px", marginBottom: '10px', marginTop: '5px', }}  
                      onClick={() => {
                        if (this.state.prepaid_ct <20 || this.state.prepaid_ct > 2000) {
                          alert("Wisher count has to be within 20 and 2000. Please enter valid value")
                          this.setState({ prepaid_ct: 20 });
                          return false
                        }
                        else   
                        this.setState({ isPaymentModal: true, isPayingEvent:false, extraWishers:0, extraWishersFlg:false })}}
                    >
                    Proceed to Payment
                    </Button>

                </Modal.Body>

                <Modal.Footer>
                  {/* <Button type = "submit" style={{ backgroundColor: "blue", borderRadius: '25%', color: "white"}}  >
                    Submit
                  </Button> */}
                </Modal.Footer>
              </Form>
            </Modal>


            <Modal show={this.state.isCreatingEvent} onHide={() => {this.setState({ isCreatingEvent: false })}}
            style={{marginTop:'45px', }} 
            >
              <Modal.Header closeButton>
                <Modal.Title>
                {this.state.edit_flg?
                  "Edit Event"
                :
                  "Create New Event"
                }
                </Modal.Title>
              </Modal.Header>

              <Form onSubmit={this._handleValidSubmit} className="override_form">
                <Modal.Body>
                  <FieldGroup
                    componentClass="select"
                    id="formControlPreset"
                    label="Choose an Event:"
                    name="event_type"
                    required="required"
                    
                    onChange={({ target: { name, value } }) => {
                      this.setState({ [name]: value });
                      var output = document.getElementById('output');

                      var pfImage = this.presetFoldersindexOf(this.state.presetFolders, value).split('public/')[1]
                      this.setState({ 
                        img_url: '',
                        file: '',
                        event_image: pfImage,
                      })
                      output.src = pfImage
                      
                    }}
                    value={this.state.event_type}
                    
                  >

                  <option value="Wedding">Wedding</option>
                  <option value="Engagement">Engagement</option>
                  <option value="Birthday">Birthday</option>
                  <option value="Anniversary">Anniversary</option>
                  <option value="Graduation">Graduation</option>
                  <option value="Autographs">Student Autographs</option>
                  <option value="House">New House</option>
                  <option value="Job">New Job</option>
                  <option value="Baby">New Baby</option>
                  <option value="Promotion">Promotion</option>
                  <option value="Farewell">Farewell</option>
                  <option value="Retirement">Retirement</option>
                  <option value="Achievement">Achievement</option>
                  <option value="Getwell">Get Well</option>
                  <option value="Others">Name your event</option>

                  </FieldGroup>

                  <FieldGroup
                    type="text"
                    id="eventTitle"
                    label="Event Title"
                    name="title"
                    placeholder="Event Title"
                    required="required"
                    onChange={({ target: { name, value } }) => {
                      this.setState({ [name]: value });
                    }}
                    value={this.state.title}
                  >
                  </FieldGroup>

                { this.state.event_type === "Wedding" || this.state.event_type === "Anniversary" || this.state.event_type === "Engagement"?
                   
                  <FieldGroup
                    type="text"
                    id="bride"
                    label="Bride/Bridegroom Name"
                    name="bride"
                    placeholder="Name of Bride/Bridegroom"
                    required="required"
                    onChange={({ target: { name, value } }) => {
                      this.setState({ [name]: value });
                    }}
                    value={this.state.bride}
                  >
                  </FieldGroup>
    
                : 
                  ''
                }

                { this.state.event_type === "Wedding" || this.state.event_type === "Anniversary" || this.state.event_type === "Engagement"? 

                  <FieldGroup
                    type="text"
                    id="bridegroom"
                    label="Bride/Bridegroom Name"
                    name="bridegroom"
                    placeholder="Name of Bride/Bridegroom"
                    required="required"
                    onChange={({ target: { name, value } }) => {
                      this.setState({ [name]: value });
                    }}
                    value={this.state.bridegroom}
                  >
                  </FieldGroup>
                :
                    ''
                }


                { this.state.event_type != "Wedding" && this.state.event_type != "Anniversary" && this.state.event_type != "Engagement"? 
                  <FieldGroup
                    type="text"
                    id="bday_name"
                    label="Name of the person"
                    name="bday_name"
                    placeholder="Name of the Person"
                    required="required"
                    onChange={({ target: { name, value } }) => {
                      this.setState({ [name]: value });
                    }}
                    value={this.state.bday_name}
                  >
                  </FieldGroup>
                :
                  ''
                }

                    { this.state.edit_flg ? 
                      // <img id="output" src={`uploads/events/${this.state.event_id}/${this.state.event_image}`} alt="" height="150" width="auto" />
                      this.state.img_url === ""?
                      <img id="output" src={this.state.event_image} alt="" height="150" width="auto" 
                      onClick={()=>{this.state.mobile ? '' : window.open(this.state.event_image, "_blank")}}
                      style={{cursor: 'pointer'}}
                      /> : 
                      <img id="output" src={URL.createObjectURL(this.state.file)} alt="" height="150" width="auto" 
                      onClick={()=>{this.state.mobile ? '' : window.open(URL.createObjectURL(this.state.file), "_blank")}}
                      style={{cursor: 'pointer'}}
                      />
                     :
                    //  this.invitationProductsindexOf(this.state.invitationProducts, this.state.event_type, 'layout_id') != 0 ?
                    //     <img id="output" src={`uploads/layout/preview/${this.invitationProductsindexOf(this.state.invitationProducts, this.state.event_type, 'layout_id')}/preview.png`} alt="" height="150" width="auto" 
                    //     onClick={()=>{
                    //       this.state.mobile ? '' : window.open(`uploads/layout/preview/${this.invitationProductsindexOf(this.state.invitationProducts, this.state.event_type, 'layout_id')}/preview.png`, "_blank") }}
                    //     style={{cursor: 'pointer'}}
                    //     />
                    //   :
                        <img id="output" src={this.presetFoldersindexOf(this.state.presetFolders, `${this.state.event_type}`).split('public/')[1]} alt="" height="150" width="auto" 
                        onClick={()=>{this.state.mobile ? '' : window.open(this.presetFoldersindexOf(this.state.presetFolders, `${this.state.event_type}`).split('public/')[1], "_blank")}}
                        style={{cursor: 'pointer'}}
                        />
                    }
                    
                  <br></br><br></br>

                  <FieldGroup
                    type="button"
                    id="newinvite"
                    label="Use/Update your Invitation"
                    name="img_url"
                    placeholder="Use/Update your Invitation"
                    onClick={(event) => {
                      let invitationLayoutID = this.invitationProductsindexOf(this.state.invitationProducts, this.state.event_type, 'layout_id')
                      if (invitationLayoutID !== 0) {
                        var output = document.getElementById('output');
                        output.src= `uploads/layout/preview/${this.invitationProductsindexOf(this.state.invitationProducts, this.state.event_type, 'layout_id')}/preview.png`
                        this.setState({
                          file: '',
                          img_url: "",
                          event_image: `uploads/layout/preview/${this.invitationProductsindexOf(this.state.invitationProducts, this.state.event_type, 'layout_id')}/preview.png`,
                          user_file: false,
                        });
                      }
                      else
                        alert("Create your Invitation first")
                    }}
                    value="Use/Update your Invitation"
                  >
                  </FieldGroup>  

                  <FieldGroup
                    type="file"
                    id="eventImage"
                    label="Upload Event Invitation Image"
                    name="img_url"
                    placeholder="Upload event image"
                    onChange={(event) => {
                      this.setState({
                        file: event.target.files[0],
                        img_url: event.target.value,
                        event_image: '',
                        user_file: true,
                      })
                      var output = document.getElementById('output');
                      output.src = URL.createObjectURL(event.target.files[0]);
                      output.style.display='block'
                    }}
                    
                    value={this.state.img_url}
                  >
                    
                  </FieldGroup>

                  
                  <FieldGroup
                    type="button"
                    id="preset"
                    label="Or Choose a Preset image"
                    name="img_url"
                    placeholder="Choose Preset image"
                    onClick={(event) => {
                      this.setState({modal2: true})
                    }}
                    value="Presets"
                  >
                  </FieldGroup>

                  <FieldGroup
                    type="date"
                    id="eventDate"
                    label="Event date"
                    name="event_dt"
                    required="required"
                    placeholder="Select event date"
                    onChange={({ target: { name, value } }) => {
                      this.setState({ [name]: value });
                    }}
                    value={this.state.event_dt}
                  >
                  </FieldGroup>

     
    {this.state.mobile?
              <Fragment>

                <span style={{color:'black', fontSize:"18px", fontWeight:"bold"}}>Record your Video Invite</span>
                <br></br>
                <span style={{color:'grey', fontSize:"12px", }}>- Max 45 secs</span>
                <br></br>

                <div id="videoLiveDiv" style={{display:'none'}}>
                <video muted autoPlay={true} playsInline id="videoLive" style={{width:"362px"}}>
                  {/* style={{display:'inline-block', verticalAlign:'middle', height:'200px', paddingLeft:'50px', paddingTop:'0px', marginTop: '-10px', marginBottom: '20px', marginLeft:'-10px'}}> */}
                </video>
                <h2 id="CountDown" style={{marginTop:'-5px', marginLeft:'25px', color: 'red'}}></h2>
                </div>
                {this.state.edit_flg && this.state.event_video?
                  <div id="videoRecordedDiv" style={{display:''}}>
                  {this.state.uploadVidFlg?
                      <video controls playsInline id="videoRecorded" 
                      style={{width:"362px"}}>
                      </video>
                  :  
                    <video controls autoPlay={false} playsInline id="videoRecorded" src ={`uploads/events/${this.state.event_id}/${this.state.event_id}.mp4?${new Date().getTime()}}`}
                    poster={'poster.jpg'}
                    style={{width:"362px"}}>
                      {/* style={{display:'inline-block', verticalAlign:'middle', height:'200px', paddingLeft:'50px', paddingTop:'0px', marginTop: '-10px', marginBottom: '20px', marginLeft:'-10px'}}> */}
                    </video>
                  }
                  </div>
                :
                  <div id="videoRecordedDiv" style={{display:'none'}}>
                  <video controls playsInline id="videoRecorded" style={{width:"362px"}}>
                    {/* style={{display:'inline-block', verticalAlign:'middle', height:'200px', paddingLeft:'50px', paddingTop:'0px', marginTop: '-10px', marginBottom: '20px', marginLeft:'-10px'}}> */}
                  </video>
                  </div>
                }
                <button type="button" id="cam_st" onClick={() => {
                  //this.setState({recording:true,})
                  document.querySelector('#videoRecordedDiv').style.display="none"
                  document.querySelector('#videoLiveDiv').style.display=""
                  start_video_Recording()
                  this.setState({vidFlg: true})
                } 
                }
                // style='color:red;border: 1px solid #000;padding-right:5px;padding-left:5px;padding-top:16px;padding-bottom:4px'
                  style={{color:'black',marginLeft: '34px' }}>
                  <i className='fa fa-video-camera fa-2x'></i>
                </button>

                <button type="button" id="vid_st"
                // style='color:red;border: 1px solid #000;padding-right:5px;padding-left:5px;padding-top:16px;padding-bottom:4px'
                  style={{color:'red', border:'1px solid #000', marginLeft: '56px', paddingRight:'5px', paddingLeft:'5px', paddingTop:'5px', paddingBottom:'5px', marginTop: '10px' }}>
                  <i className='fa fa-circle fa-2x'></i>
                </button>

                <button type="button" id="vid_en"
                //  style={"display:inline-block;vertical-align:middle;padding-right:3px;padding-left:2px;"}>
                style={{color:'black', border:'1px solid #000', marginLeft: '44px', paddingRight:'5px', paddingLeft:'5px', paddingTop:'5px', paddingBottom:'5px', marginTop: '10px' }}>
                  <i className='fa fa-stop fa-2x'></i>
                </button>

                {this.state.edit_flg && this.state.event_video?
                  <button type="button" id="vid_del"  onClick={() => {delete_Recording(); this.setState({vidFlg:true})}} 
                    style={{color:'red', marginLeft: '44px', paddingTop:'7px'}}>
                    <i className='fa fa-trash fa-2x'></i>
                  </button>
                :
                  <button type="button" id="vid_del"  onClick={() => {delete_Recording(); this.setState({vidFlg:true})}}
                    style={{color:'grey', marginLeft: '44px', paddingTop:'7px'}}>
                    <i className='fa fa-trash fa-2x'></i>
                  </button>
                }

                <div style={{display: 'flex', flexDirection: 'row', margin: '5px'}}>
                <h5 style={{color:'black', marginLeft: '16px'}}>Camera</h5>
                  <h5 style={{color:'black', marginLeft: '50px'}}>Rec</h5>
                  <h5 style={{color:'black', marginLeft: '54px'}}>Stop</h5>
                  <h5 style={{color:'black', marginLeft: '48px'}}>Del</h5>
                </div>
                <div style={{display: 'flex', flexDirection: 'row',}}>
                <h5 style={{color:'black', marginLeft: '20px', marginTop: '-25px'}}>On / Off</h5>
                <h5 style={{color:'black', marginLeft: '38px', marginTop: '-25px'}}>Re-Rec</h5>
                </div>

              </Fragment>
              :
              <Fragment>

                <h4 style={{color:'grey'}}>Record your Video Invite - Max 45 secs</h4>
                
                <div id="videoLiveDiv" style={{display:'none'}}>
                <video muted autoPlay={true} playsInline id="videoLive" style={{width:"500px"}}>
                  {/* style={{display:'inline-block', verticalAlign:'middle', height:'200px', paddingLeft:'50px', paddingTop:'0px', marginTop: '-10px', marginBottom: '20px', marginLeft:'-10px'}}> */}
                </video>
                <h1 id="CountDown" style={{marginTop:'-50px', marginLeft:'25px', color: 'red'}}></h1>
                </div>
                {this.state.edit_flg && this.state.event_video?
                  <div id="videoRecordedDiv" style={{display:''}}>
                  {this.state.uploadVidFlg?
                      <video controls playsInline id="videoRecorded" 
                      style={{width:"500px"}}>
                      </video>
                  :
                    <video controls playsInline id="videoRecorded" src ={`uploads/events/${this.state.event_id}/${this.state.event_id}.mp4?${new Date().getTime()}}`}
                    style={{width:"500px"}}>
                      {/* style={{display:'inline-block', verticalAlign:'middle', height:'200px', paddingLeft:'50px', paddingTop:'0px', marginTop: '-10px', marginBottom: '20px', marginLeft:'-10px'}}> */}
                    </video>
                  }
                  </div>
                :
                  <div id="videoRecordedDiv" style={{display:'none'}}>
                  <video controls playsInline id="videoRecorded" style={{width:"500px"}}>
                    {/* style={{display:'inline-block', verticalAlign:'middle', height:'200px', paddingLeft:'50px', paddingTop:'0px', marginTop: '-10px', marginBottom: '20px', marginLeft:'-10px'}}> */}
                  </video>
                  </div>
                }
                <button type="button" id="cam_st" onClick={() => {
                  //this.setState({recording:true,})
                  document.querySelector('#videoLiveDiv').style.display=""
                  start_video_Recording()
                  this.setState({vidFlg: true})
                } 
                }
                // style='color:red;border: 1px solid #000;padding-right:5px;padding-left:5px;padding-top:16px;padding-bottom:4px'
                  style={{color:'black',marginLeft: '110px' }}>
                  <i className='fa fa-video-camera fa-2x'></i>
                </button>
                <button type="button" id="vid_st" 
                // style='color:red;border: 1px solid #000;padding-right:5px;padding-left:5px;padding-top:16px;padding-bottom:4px'
                  style={{color:'red', border:'1px solid #000', marginLeft: '50px', paddingRight:'5px', paddingLeft:'5px', paddingTop:'5px', paddingBottom:'5px', marginTop: '10px' }}>
                  <i className='fa fa-circle fa-2x'></i>
                </button>

                <button type="button" id="vid_en" onClick={() => {
                  //this.setState({recording:false,})
                  //stop_Recording()
                }
                }
                //  style={"display:inline-block;vertical-align:middle;padding-right:3px;padding-left:2px;"}>
                style={{color:'black', border:'1px solid #000', marginLeft: '50px', paddingRight:'5px', paddingLeft:'5px', paddingTop:'5px', paddingBottom:'5px', marginTop: '10px' }}>
                  <i className='fa fa-stop fa-2x'></i>
                </button>

                <div style={{display:'inline-block', verticalAlign:'middle', marginTop: '-11px'}}>

                {this.state.edit_flg && this.state.event_video?
                  <button type="button" id="vid_del"  onClick={() => {delete_Recording(); this.setState({vidFlg:true})}}
                    style={{color:'red', marginLeft: '50px'}}>
                    <i className='fa fa-trash fa-2x'></i>
                  </button>
                :
                  <button type="button" id="vid_del"  onClick={() => {delete_Recording(); this.setState({vidFlg:true})}}
                    style={{color:'grey', marginLeft: '50px'}}>
                    <i className='fa fa-trash fa-2x'></i>
                  </button>
                }

                </div>

                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <h5 style={{color:'black', marginLeft: '98px'}}>Camera</h5>
                  <h5 style={{color:'black', marginLeft: '52px'}}>Rec</h5>
                  <h5 style={{color:'black', marginLeft: '63px'}}>Stop</h5>
                  <h5 style={{color:'black', marginLeft: '56px'}}>Del</h5>
                </div>
                <div style={{display: 'flex', flexDirection: 'row',}}>
                <h5 style={{color:'black', marginLeft: '96px', marginTop: '-25px'}}>On / Off</h5>
                <h5 style={{color:'black', marginLeft: '42px', marginTop: '-25px'}}>Re-Rec</h5>
                </div>
              </Fragment>
              }  
                <div id="myProgress" style={{position: 'relative', marginBottom: '15px', marginTop: '-5px', width: '100%', height: '20px', backgroundColor: '#ddd'}}>
                  <div id="myBar" style={{position: 'absolute', width: '0%', height: '100%', backgroundColor: '#4CAF50'}}>
                    <div id="label" style={{textAlign: 'center', lineHeight: '20px', color: 'white'}}>
                      0%
                    </div>
                  </div>
                </div>

                <div id="uploadDiv"  style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  <label htmlFor="upload" style={{width: "50%"}} className="btn btn-success btn-sm">Upload Video (max 45 secs)</label>
                  {/* <input type="file" id="upload" style={{display:''}}  accept=".mp4" onClick={()=>{this.value=null}}/> */}
                  <input type="file" id="upload" style={{display:'none'}}  accept=".mp4" onChange={(event)=>{this.handleVideoSubmit(event)}}/>
                </div>







                </Modal.Body>

                <Modal.Footer>
                  <Button type = "submit" style={{ backgroundColor: "blue", borderRadius: '25%', color: "white"}} 
                          // onClick={() => {
                          //    let spinner=document.getElementById("mySpinner") 
                          //    spinner.style.display=""
                          // }}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>


            <Modal show={this.state.modal2} onHide={() => {this.setState({ modal2: false })}}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Preset Images - Click to choose
                </Modal.Title>
              </Modal.Header>

              <Form onSubmit={this._handleValidSubmit} className="override_form">
                <Modal.Body>
                  <div style={{display: 'flex', flexDirection: 'column', rowGap: '25px',  justifyContent: 'space-between',  alignItems: 'center'}}>
                    {
                      this.state.presetFolders.map((pf, index) => (
                        pf[0] === this.state.event_type.toLowerCase()?
                          <img key={index} src={pf[1].split('public/')[1]} alt={`image-${index}`} height="300" width="auto" style={{cursor: 'pointer'}} title="Choose me"
                          onClick={() => {
                            var output = document.getElementById('output');
                            output.src = pf[1].split('public/')[1]
                            this.setState({
                              file: '',
                              img_url: "",
                              event_image: pf[1].split('public/')[1],
                              user_file: false,
                              modal2: false
                            })
                          }}
                          />
                        :''
                      ))
                    }
                  </div>
                </Modal.Body>
              </Form>
            </Modal>


            <Modal show={this.state.isArrange} onHide={() => {this.setState({ isArrange: false })}}>
              <Modal.Header style={{padding:'5px', backgroundColor: "grey"}} closeButton>
                <Modal.Title style={{paddingLeft:'1px', color: "white"}}>
                  Arrange Wishes-Move Up/Down
                </Modal.Title>
              </Modal.Header>
{/* Bala */}
              <Form onSubmit={this._handleValidSort} className="override_form">
                <Modal.Body style={{height:'70vh', overflow:'auto'}}>
                {this.state.mobile?
                
                  <div className="myContainer" style={{display: 'flex', flexDirection: 'column', rowGap: '25px',  justifyContent: 'space-between',  margin: '5px', maginRight:'10px'}}>
                    {
                      this.state.contributes.map((cont, index) => (
                        <Fragment>
                          <div key = {index} id={cont.id+'_'+cont.page_no+'_'+cont.seq_no} data-hide={cont.hide} className = "myDraggable" draggable='true' style={{display: 'flex', flexDirection: 'row', boxShadow: '3px 5px 5px #888888', border: '1px solid black', verticalAlign:'middle', marginBottom:'-5px', cursor: 'move', }}>
                            <img key={index} src={`uploads/events/${cont.event_id}/contributes/${cont.c_img_url}`} alt={`image-${index}`}
                              style={{height:'100px', maxWidth:'150px'}}/>
                            
                            <div  style={{display: 'flex', flexDirection: 'column', marginLeft:'5px', width: '60vw', marginBottom: '-14px'}}>
                              <h6>{cont.c_name}</h6>
                              <h6>{cont.c_place}</h6>
                              {cont.sort_order != null?
                                <h6>Sort-order: <span style={{color:'red'}}>{cont.sort_order}</span></h6>
                              :
                                <h6>Sort-order: <span style={{color:'red'}}>{cont.page_no * cont.seq_no}</span></h6>
                              }
                            </div>
                            <div  style={{display: 'flex', flexDirection: 'row', margin:'5px', justifyContent: 'flex-end', alignItems:"flex-end"}}>
                            <Button bsStyle="danger" bsSize="small"
                                onClick={(el) => {
                                  var Id = `${cont.id}_${cont.page_no}_${cont.seq_no}`
                                  var curId = document.getElementById(`${Id}`)
                                  curId.dataset.hide = curId.dataset.hide === 'true' ? 'false' : 'true'
                                  el.target.innerHTML = el.target.innerHTML === 'Exclude'? 'Include' : 'Exclude'
                                }}
                              >
                                {cont.hide?'Include':'Exclude'}
                              </Button>          
                           </div>
                          </div>
                          </Fragment>
                      ))
                    }
                  </div>
                :
                  <div className="myContainer" style={{display: 'flex', flexDirection: 'column', rowGap: '25px',  justifyContent: 'space-between',  margin: '10px', marginTop:'20px'}}>
                  {
                    this.state.contributes.map((cont, index) => (
                      <Fragment>
                        <div id={cont.id+'_'+cont.page_no+'_'+cont.seq_no} data-hide={cont.hide} className = "myDraggable" draggable='true' style={{display: 'flex', flexDirection: 'row', boxShadow: '5px 10px 10px #888888', border: '1px solid black', verticalAlign:'middle', cursor:'move'}}>
                          <img key={index} src={`uploads/events/${cont.event_id}/contributes/${cont.c_img_url}`} alt={`image-${index}`} 
                            style={{height:'150px', maxWidth:'200px'}}/>
                          <div  style={{display: 'flex', flexDirection: 'column', margin:'10px', width: '90vw'}}>
                            <h5>{cont.c_name}</h5>
                            <h5>{cont.c_place}</h5>
                            {cont.sort_order != null?
                              <h5>Sort-order: <span style={{color:'red'}}>{cont.sort_order}</span></h5>
                            :
                              <h5>Sort-order: <span style={{color:'red'}}>{cont.page_no * cont.seq_no}</span></h5>
                            }
                          </div>
                          <div  style={{display: 'flex', flexDirection: 'row', margin:'5px', width: '10vw', justifyContent: 'flex-end', alignItems:"flex-end"}}>
                            <Button style={{ backgroundColor: "red", color: "white", fontWeight:'bold', }} 
                                onClick={(el) => {
                                  var Id = `${cont.id}_${cont.page_no}_${cont.seq_no}`
                                  var curId = document.getElementById(`${Id}`)
                                  curId.dataset.hide = curId.dataset.hide === 'true' ? 'false' : 'true'
                                  el.target.innerHTML = el.target.innerHTML === 'Exclude'? 'Include' : 'Exclude'
                                }}
                              >
                                {cont.hide?'Include':'Exclude'}
                              </Button>          
                           </div>
                        </div>
                        </Fragment>
                    ))
                  }
                  </div>  
                }
                </Modal.Body>
              </Form>
              <Modal.Footer style={{padding:'5px', backgroundColor: "grey"}}>
                {this.state.mobile?
                  <Button type='submit' style={{ backgroundColor: "blue", color: "white", width:'80px', fontSize:'11px', fontWeight:'bold', height:'25px', marginTop:'-5px'}} 
                          onClick={() => {
                             this._handleValidSort()
                             this.setState({isArrange:false})
                          }}
                  >
                    Save Order
                  </Button>
                :
                <Button type='submit' style={{ backgroundColor: "blue", color: "white", width:'80px', fontSize:'13px', fontWeight:'bold', height:'32px' }} 
                  onClick={() => {
                    this._handleValidSort()
                    this.setState({isArrange:false})
                  }}
                >
                  Save Order
                </Button>
                }
                </Modal.Footer>
            </Modal>




            <Modal show={this.state.choosePreset} onHide={() => {this.setState({ choosePreset: false })}}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Choose an Output Template
                </Modal.Title>
              </Modal.Header>
              <Form onSubmit={this._handleValidSubmit} className="override_form">
                <Modal.Body>
                  <div style={{display: 'flex', flexDirection: 'column', rowGap: '25px',  justifyContent: 'space-between',  alignItems: 'center'}}>
                { this.state.mobile?
                  this.state.presetTemplates.map((rec, index) => (
                      <img key={index} src={rec.img_url} alt={`image-${index}`} height="auto" width="300" style={{cursor: 'pointer'}} title="Choose me"
                      onClick={() => {
                        let proceed = confirm("Are you sure you want to use this Output Template?")
                        if (proceed) {
                          // this.updateEventPresetProductId(this.state.event_id, rec.product_id, 'CLOSED');
                          this.updateEventPresetProductIdAndCloneProduct(this.state.event_id, this.state.event_type, rec.product_id, this.state.product_id);
                          this.setState({ choosePreset: false})
                        }
                      }}
                      />
                    ))
                    :
                    this.state.presetTemplates.map((rec, index) => (
                      <img key={index} src={rec.img_url} alt={`image-${index}`} height="300" width="auto" style={{cursor: 'pointer'}} title="Choose me"
                      onClick={() => {
                        let proceed = confirm("Are you sure you want to use this Output Template?")
                        if (proceed) {
                          // this.updateEventPresetProductId(this.state.event_id, rec.product_id, 'CLOSED');
                          this.updateEventPresetProductIdAndCloneProduct(this.state.event_id, this.state.event_type, rec.product_id, this.state.product_id);
                          this.setState({ choosePreset: false})
                        }
                      }}
                      />
                    ))  
                }   
                  </div>
                </Modal.Body>
              </Form>
            </Modal>


            <Modal show={this.state.chooseEventPictures} onHide={() => {this.setState({ chooseEventPictures: false })}}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Upload Event Pictures
                </Modal.Title>
              </Modal.Header>
              <Form onSubmit={this._handleValidSubmit} className="override_form">
                <Modal.Body>
                
                <div style={{display: 'flex', flexDirection: 'column', rowGap: '25px',  justifyContent: 'space-between',  alignItems: 'center'}}>
                { this.state.mobile?
                  this.state.eventPictures.map((rec, index) => (
                    <div key={index} style={{paddingLeft:'5px', paddingRight:'5px'}}>
                      <div style={{ display: 'flex', flexDirection: 'column' , boxShadow: '5px 10px 10px #888888', border: '1px solid black', marginBottom: '25px',  padding: '5px'}}>
                        <h4>Page - {index+1}</h4>
                        <img id={rec.page_no} src={rec.img_url} alt={`image-${index}`} height="auto" width="300px" 
                          style={{marginBottom: '10px'}} 
                        />
                        <FieldGroup
                          type="file"
                          id="eventImage"
                          label="Upload/Change image"
                          name="img_url"
                          placeholder="Upload event image"
                          onChange={(event) => {
                            let file_name = rec.page_no // Note no extension
                            let proceed = confirm("Do you also want to copy this image to rest of the pages?\nChoose 'Cancel' to change only this image")
                            if (proceed){
                              let other_id = "Page"
                              this.save_user_event_image(this.state.event_id, event.target.files[0], file_name, "copy")  
                              for(let i=index; i<this.state.eventPictures.length; i++){
                                other_id = "Page" + (i+1)
                                var output = document.getElementById(other_id);
                                output.src = URL.createObjectURL(event.target.files[0]);    
                              }
                            }
                            else {
                              this.save_user_event_image(this.state.event_id, event.target.files[0], file_name, "nocopy")
                              var output = document.getElementById(rec.page_no);
                            output.src = URL.createObjectURL(event.target.files[0]);
                            }
                          }}
                        >
                        </FieldGroup>
                      </div>
                    </div>
                  ))
                :

                  this.state.eventPictures.map((rec, index) => (
                    <div key={index} style={{paddingLeft:'5px', paddingRight:'5px'}}>
                      <div style={{ display: 'flex', flexDirection: 'column' , boxShadow: '5px 10px 10px #888888', border: '1px solid black', marginBottom: '25px',  padding: '5px'}}>
                        <h4>Page - {index+1}</h4>
                        <img id={rec.page_no} src={rec.img_url} alt={`image-${index}`} height="auto" width="450px" 
                          style={{marginBottom: '10px'}} 
                        />
                        <FieldGroup
                          type="file"
                          id="eventImage"
                          label="Upload/Change image"
                          name="img_url"
                          placeholder="Upload event image"
                          onChange={(event) => {
                            let file_name = rec.page_no // Note no extension
                            let proceed = confirm("Do you also want to copy this image to rest of the pages?\nChoose 'Cancel' to change only this image")
                            if (proceed){
                              let other_id = "Page"
                              this.save_user_event_image(this.state.event_id, event.target.files[0], file_name, "copy")  
                              for(let i=index; i<this.state.eventPictures.length; i++){
                                other_id = "Page" + (i+1)
                                var output = document.getElementById(other_id);
                                output.src = URL.createObjectURL(event.target.files[0]);    
                              }
                            }
                            else {
                              this.save_user_event_image(this.state.event_id, event.target.files[0], file_name, "nocopy")
                              var output = document.getElementById(rec.page_no);
                            output.src = URL.createObjectURL(event.target.files[0]);
                            }
                          }}
                        >
                        </FieldGroup>
                      </div>
                    </div>
                  ))
                }   
                  
                </div>
                </Modal.Body>
              </Form>
            </Modal>







            
            <Modal show={this.state.isContributions} onHide={() => {this.setState({ isContributions: false })}}
                    style={{marginTop:'45px', }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {`Collected Wishes - ${this.state.event_type}`}
                </Modal.Title>
              </Modal.Header>

              <Form onSubmit={this._handleValidSubmit} className="override_form">
                <Modal.Body >

                
                <FieldGroup
                    type="color"
                    id="colorpicker"
                    name="colorPicker"
                    label="Choose Background Color"
                    className="field-radio"
                    onChange={({ target: { name, value } }) => {
                      this.setState({ [name]: value });
                      this.changeColor()
                    }}
                    value={this.state.colorPicker}
                  >
                  </FieldGroup>
                
                

                {this.state.contributes.length > 0 ?
                  c_list = this.state.contributes.map( (item, index) =>{
                    //const contributionNo = item.page_no * item.seq_no
                    const contributionNo = this.state.contributes.length - index
                    const date = new Date(item.created_at)
                    const formattedDate = date.toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric"
                      })
                    const formattedTime = date.toLocaleTimeString("en-US")
                      
                if (this.state.mobile){
                  return (
                    <Fragment>
                  <div id={"myContribute_"+item.id} key={item.id} style={{ display: 'flex', flexDirection: 'column', backgroundColor: this.state.colorPicker,  width: '100%', alignItems:'middle', boxShadow: '2px 5px 5px #888888', border: '3px solid black', marginTop:"5px", marginBottom: '15px'}}>
                  <h5 style={{color:'yellow', marginTop: '10px', marginLeft:'10px', marginBottom:'10px'}}>Wish No: {contributionNo} @ {formattedDate} {formattedTime}</h5>
                  
                    <img id="contributeImage" src={`uploads/events/${item.event_id}/contributes/`+item.c_img_url} alt="" width="100%"  
                    style={{boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.6)"}}/>
                  
                      <br></br>
                    <h5 style={{marginLeft:'5px', color: 'white',}}>Name: {item.c_name}</h5>                        
                    <h5 style={{marginLeft:'5px', color: 'grey', marginTop: '-10px'}}>Place: {item.c_place} </h5>                        
                    <br></br>
                    
                    <div style={{ alignSelf:'center', marginBottom:'20px'}}>
                      <h5 style={{marginLeft: '30px', color: 'white', marginTop:'-35px'}}>Audio Wishes (if recordered)</h5>
                      {/* <h5 style={{color: 'white', }}>Audio Wishes (if recordered)</h5> */}
                      
                      <audio id="audio"  src={`uploads/events/${item.event_id}/contributes/`+item.c_aud_url} controls={true}
                        style={{display:'inline-block', verticalAlign:'middle', height:'30px', paddingLeft:'15px', paddingTop:'0px', marginTop: '-15px', }}>
                      </audio>
                    </div>
                 
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', marginTop:'-10px'}}>
                      <textarea defaultValue={item.message} 
                          style={{ height: '110px', color: 'blue', fontFamily: 'Almendra', fontWeight: "bold", fontSize: '15px', 
                          display: 'flex', flexDirection: 'column', marginBottom: '5px', padding: '5px'}}
                        readOnly>
                      </textarea>
                    </div>
                </div>
                </Fragment>
                  )
                }
                else {
                  return (
                    <Fragment>
                  <div id={"myContribute_"+item.id} key={item.id} style={{ display: 'flex', flexDirection: 'column', backgroundColor: this.state.colorPicker,  width: '100%', alignItems:'middle', boxShadow: '2px 5px 5px #888888', border: '3px solid black', marginTop:"5px", marginBottom: '15px'}}>
                  <h5 style={{color:'yellow', marginTop: '10px', marginLeft:'10px', marginBottom:'20px'}}>Wish No: {contributionNo} @ {formattedDate} {formattedTime}</h5>
                  
                    <img id="contributeImage" src={`uploads/events/${item.event_id}/contributes/`+item.c_img_url} alt="" width="100%"   
                    style={{boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.6)", marginBottom:'20px'}}/>
                  
                      
                    <h5 style={{marginLeft:'5px', color: 'white',}}>Name: {item.c_name}</h5>                        
                    <h5 style={{marginLeft:'5px', color: 'grey', marginTop: '-10px'}}>Place: {item.c_place} </h5>                        
                    <br></br>
                  
                    <div style={{ alignSelf:'center', marginBottom:'20px'}}>
                      <h5 style={{marginLeft: '70px', color: 'white', marginTop:'-35px'}}>Audio Wishes (if recordered)</h5>
                      {/* <h5 style={{color: 'white', }}>Audio Wishes (if recordered)</h5> */}
                      
                      <audio id="audio"  src={`uploads/events/${item.event_id}/contributes/`+item.c_aud_url} controls={true}
                        style={{display:'inline-block', verticalAlign:'middle', height:'30px', paddingLeft:'15px', paddingTop:'0px', marginTop: '-15px', }}>
                      </audio>
                    </div>
                  
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', marginTop:'-10px'}}>
                    <textarea defaultValue={item.message} 
                        style={{ height: '110px', color: 'blue', fontFamily: 'Almendra', fontWeight: "bold", fontSize: '15px', 
                        display: 'flex', flexDirection: 'column', marginBottom: '5px', padding: '5px'}}
                       readOnly>
                    </textarea>
                    </div>
                </div>
                
                
                
                    </Fragment>
                    
                    
                  )
                }
                  })
                  :
                  <h3>No Wsihes yet!</h3>
                }

                  
                </Modal.Body>

              </Form>

            </Modal>

            <Modal show={this.state.link} onHide={() => {this.setState({ link: false })}}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Event Link
                </Modal.Title>
              </Modal.Header>

              <Form  className="override_form">
                <Modal.Body>
              
                    <Fragment>
                         <div style={{display: 'flex', flexDirection: 'row', 
                        paddingTop:'30px', paddingRight:'10px', paddingLeft:'10px',
                                fontSize:'12px', fontWeight:'bold', }}> 

                    {this.state.mobile?
                      <h3 style={{fontSize:'10px', color:'blue',}}>
                        https://wishcollector.com/contribute?event_id={this.state.event_id}&&event_dt={this.state.event_dt}</h3>
                      
                      :
                      <h3 style={{fontSize:'15px', color:'blue',}}>
                        https://wishcollector.com/contribute?event_id={this.state.event_id}&&event_dt={this.state.event_dt}</h3>
                      
                    }
                    <button style={{color:'black', marginLeft:'10px', marginTop:'-25px', marginRight:'5px' }}
                      onClick={() => {
                          navigator.clipboard.writeText("https://wishcollector.com/contribute?event_id="+this.state.event_id+"&&event_dt='"+this.state.event_dt+"'")
                          alert("Event Link copied to Clip Board")
                          let clipBoard=document.getElementById("myClip")
                          clipBoard.style.display="none"
                        }}
                      >
                      <i className='fa fa-copy fa-3x' style={{marginRight:'5px'}}></i>
                      Copy
                    </button>
                  </div> 
                  <div>
                    <p style={{fontFamily:'Salsa', fontSize:'15px', color:'green'}}>
                      Before sending the 'Event Link' to your Friends & Relatives, test 'Event Link' yourselves on mobile and PC or any device first.
                    </p>
                    <p style={{fontFamily:'Salsa', fontSize:'15px', color:'green'}}>
                      One can also 'Wish Locally' by clicking on the button 'Wish Locally' which will test the 'Event Link' as well.
                    </p>
                  </div>
                </Fragment>
                </Modal.Body>
              </Form>
            </Modal>







            {this.state.mobile?
            <Fragment>
              
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: '15px', marginRight: '10px'}}>
              {/* <span style={{fontSize:"30px"}}>My Events</span> */}
              <h3>My Events</h3>
              <Button id="createNewEvent" style={{backgroundColor:"blue", color:"white", fontSize:"14.3px", marginTop: "-55px", boxShadow: "1px 1px 1px rgba(46, 46, 46, 0.32)"}}  
                onClick={() => {
                  this.setState({ isCreatingEvent: true })
              }}
              >
              Create New Event
              </Button>
              
              
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center', marginLeft: '15px', marginRight: '10px'}}>
              <Button id="createNewInvite" style={{backgroundColor:"green", color:"white", fontSize:"12px", marginTop: "-45px", boxShadow: "1px 1px 1px rgba(46, 46, 46, 0.32)"}}  
                onClick={() => {
                  alert("Please Use a\nPC/Mac/Laptop\nto Create/Edit an Inviation")
                }}
              >
              Create/Edit Invitation
              </Button>
            </div>

            <ul style={{marginLeft:"-15px"}}>{this.state.events.length>0 ? list : nolist}</ul>
            </Fragment>
            :
            <Fragment>
              
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: '45px', marginRight: '45px'}}>

                <Button id="createNewEvent" style={{backgroundColor:"blue", color:"white", fontSize:"20px", marginBottom: '43px', boxShadow: "3px 3px 1px rgba(46, 46, 46, 0.32)"}}  
                  onClick={() => {
                    this.setState({ isCreatingEvent: true })
                }}
                >
                    Create New Event
                </Button>

                <h1>My Events</h1>

                  <div style={{position: "relative", top: "-15px"}}>
                    <select id = "myList" onChange={()=> this.createEditInvitation()} 
                    style={{backgroundColor:"green", color:"white", fontSize:"15px", boxShadow: "3px 3px 1px rgba(46, 46, 46, 0.32)", borderRight: "3px solid green"}} >  
                    <option selected disabled>Create/Edit Invitation </option>  
                    <option value="Wedding">Wedding</option>
                    <option value="Engagement">Engagement</option>
                    <option value="Birthday">Birthday</option>
                    <option value="Anniversary">Anniversary</option>
                    <option value="Graduation">Graduation</option>
                    <option value="Autographs">Student Autographs</option>
                    <option value="House">New House</option>
                    <option value="Job">New Job</option>
                    <option value="Baby">New Baby</option>
                    <option value="Promotion">Promotion</option>
                    <option value="Farewell">Farewell</option>
                    <option value="Retirement">Retirement</option>
                    <option value="Achievement">Achievement</option>
                    <option value="Getwell">Get Well</option>
                    <option value="Others">Name your event</option>
                    
                    </select>  
                    <input type = "hidden" id = "favourite" size = "20"></input>
                  </div>
              </div>

              <ul>{this.state.events.length>0 ? list : nolist}</ul>
              
              </Fragment>
            }

            
              <div id="mySpinner" style={{display:"none", zIndex:'9999'}}>
                <SPLoader></SPLoader>
              </div>

              {/* <div id="myClip" style={{display:"none", margin:'20px',}}>
                <ClipBoard event_id={this.state.event_id} event_dt={this.state.event_dt}></ClipBoard>
              </div> */}
              
        </React.Fragment>
    );

  }
}

export default EventsHomePage 

