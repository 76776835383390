import React from "react";
import { Modal } from "react-bootstrap";

//import StoragePayment from "./StoragePayment";
import RPEventPayment from "./RPEventPayment";

export default class RPEventPaymentModal extends React.Component {
  
  render() {
    //const { no_of_gb, storage_unit, convenienceCharges } = this.props.fields;
    const { no_of_wishers, event_id, price } = this.props.fields;
    const convenienceCharges = 0.05;
    const gst = 0.18;

    return (
      <Modal
        centered="true"
        show={no_of_wishers > 0}
        onHide={(e) => {''}}
      >
        <Modal.Header closeButton>
          <Modal.Title>Purchase This Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul style={{ listStyleType: "none" }}>
            <li>
              <strong>
                Total Price for {no_of_wishers} Wishers: ₹{no_of_wishers * price}
              </strong>
            </li>
            <li>
              <strong>
                Convenience Charges : ₹
                {parseFloat(no_of_wishers * convenienceCharges).toFixed(2)}
              </strong>
            </li>
            <li>
              <strong>
                GST 18% : ₹
                {parseFloat((no_of_wishers * price + no_of_wishers * convenienceCharges) * gst).toFixed(2)}
              </strong>
            </li>
            <li>
              <strong>Grand Total: ₹ {Math.round(((no_of_wishers * price + no_of_wishers * convenienceCharges))+(no_of_wishers * price + no_of_wishers * convenienceCharges) * gst).toFixed(2)}</strong>
            </li>
          </ul>
          <RPEventPayment no_of_wishers={no_of_wishers} event_id={event_id} price={price} />
        </Modal.Body>
      </Modal>
    );
  }
}
