import React, { useState } from "react";
import { FormGroup, ControlLabel, FormControl, Modal } from "react-bootstrap";
import ImageEditor from "@toast-ui/react-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
// import { FieldGroup } from "./bootstrap/Forms.FieldGroup";
import { ENDPOINTS } from "../../constants/endpoints";
// import { formDataToJSON } from "../adukku_utils/array";
import ThumbnailPreview from "../layouts/ThumbnailPreview";
import EditProductImage from "../EditProductImage";
import { FieldGroup } from "../bootstrap/Forms.FieldGroup";

let inputWidth = null;
let inputHeight = null;
let $inputCheckInvert = null;
let $inputCheckSepia = null;
let $inputCheckSepia2 = null;
let $inputCheckBlur = null;
let $inputCheckSharpen = null;
let $inputCheckEmboss = null;
let $inputCheckGrayscale = null;
let $inputCheckRemoveWhite = null;
let $inputCheckBrightness = null;
let $inputCheckNoise = null;
let $inputCheckColorFilter = null;
let $inputSlideRemoveWhite = null;
let $inputSlideBrightness = null;
let $inputSlideNoise = null;
let $inputSlideColorFilter = null;

export default class FileManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isFetching: false,
      overlay: props.overlay || { visible: false },
      is_preset_display: false,
      categories: props.categories,
      role: null,
      isFileManagerOpen: false,
      folders: [],
      user_products: {},
      folderHierarchy: {},
      basePath: "",
      show: false,
      currentFile: {},
      showImageEditor: null,
      imageEditorOptions: {
        includeUI: {
          initMenu: "mask",
          menuBarPosition: "bottom",
          menu: ["crop", "flip", "rotate", "text", "draw", "mask"],
        },
        cssMaxWidth: 700,
        cssMaxHeight: 500,
        selectionStyle: {
          cornerSize: 20,
          rotatingPointOffset: 70,
        },
        usageStatistics: false,
      },
      uploadedImages: {
        none: [],
      },
      uploadedImageNames: {
        none: [],
      },
      avail_space: 1,
      total_space: 1,
      reclaimable_space: 0,
      sortBy: "name",
      sortType: "asc",
      isShowAll: true,
      page: 1,
      perPage: 10,
      storage_unit: "GB",
      deletableFiles: {},
    };
  }
  editorRef = React.createRef();

  // componentDidMount() {
  //   this.getUserFolders({});
  // }

  openFileManager = () => {
    this.setState({
      isFileManagerOpen: !this.state.isFileManagerOpen,
    });
    this.getUserFolders({});
  };

  getUserFolders = (payload) => {
    const product = this.state.product;
    let uri = ENDPOINTS.files.index.uri;
    let that = this;
    let { folderHierarchy, rootFolders } = this.state;
    const { product_id, dlname, current_path } = payload;

    this.setState({ isFetching: true });

    uri = current_path ? `${uri}?current_path=${current_path}` : `${uri}?`;
    return fetch(uri, {
      method: "GET",
      headers: { "content-type": "application/json" },
    })
      .then((response) => response.json())
      .then((response) => {
        if (payload.product_id) {
          folderHierarchy[payload.product_id] = folderHierarchy[payload.product_id] || {};
          folderHierarchy[payload.product_id] = response.folders;
        }
        if (payload.dlname) {
          folderHierarchy[payload.product_id] = folderHierarchy[payload.product_id] || {};

          folderHierarchy[payload.product_id][dlname] =
            folderHierarchy[payload.product_id][dlname] || response.folders;
        }
        that.setState({
          rootFolders: current_path ? rootFolders : response.folders,
          user_products: response.products,
          folderHierarchy: folderHierarchy,
          folders: this.sortFolders(response.folders),
          basePath: response.base_path,
          current_path: response.current_path,
          prev_path: response.prev_path,
          product_id: product_id,
          avail_space: response.avail_space,
          total_space: response.total_space,
          reclaimable_space: response.reclaimable_space,
          page: 1,
          isFetching: false,
        });
      });
  };

  deleteFile = (type) => {
    let uri = ENDPOINTS.files.delete.uri;
    let that = this;
    let { folderHierarchy, rootFolders, folders, deletableFiles } = this.state;
    let deletableFilePaths = [];
    if (type == "all") {
      folders.map((folder) => {
        if (folder.is_file) deletableFilePaths.push(folder.path);
      });
    } else {
      deletableFiles &&
        Object.keys(deletableFiles).map((file_path) => {
          if (file_path && deletableFiles[file_path]) deletableFilePaths.push(file_path);
        });
    }
    uri = uri.replace(":file_path", "123");
    uri = `${uri}?`;
    let updatedFolders = folders.filter((folder) => !deletableFilePaths.includes(folder.path));
    if (confirm("Are you sure you wanna delete?")) {
      return fetch(uri, {
        method: "DELETE",
        body: JSON.stringify({
          file_path: deletableFilePaths.join(","),
        }),
        headers: { "content-type": "application/json" },
      })
        .then((response) => response.json())
        .then((response) => {
          that.setState({
            folders: this.sortFolders(updatedFolders),
            avail_space: response.avail_space,
            total_space: response.total_space,
            reclaimable_space: response.reclaimable_space,
            deletableFiles: {},
          });
        });
    }
  };

  sortFolders = (folders) => {
    switch (this.state.sortBy) {
      case "name":
        return folders.sort((a, b) => a.name.localeCompare(b.name));
      case "size":
        return folders.sort((a, b) => a.size - b.size);
      case "time":
        return folders.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      default:
        return folders.sort((a, b) => a.name.localeCompare(b.name));
    }
  };

  checkAvailSpace = async (fileSize, avail_space) => {
    let uri = `${ENDPOINTS.files.get.uri}?file_size=${fileSize}`;
    return fetch(uri, {
      method: "GET",
      headers: { "content-type": "application/json" },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.data == "available") {
          return true;
        } else {
          return false;
        }
      });
  };
  formatBytes = (bytes, decimals = 2, type) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    let i = Math.floor(Math.log(bytes) / Math.log(k));
    if (type == "global") i = sizes.indexOf(this.state.storage_unit) || i;

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  uploadImages = async (dlname) => {
    const that = this;
    const product_id = this.state.product_id;
    if (!product_id) return;
    
    const uploadedImages = this.state.uploadedImages;
    let template_url = ENDPOINTS.images.upload;

    this.setState({ isLoading: true });
    
    let { folderHierarchy, rootFolders, folders, current_path, basePath } = this.state;
    // const { current_path } = payload;
    let updatedFolders = folders;
    template_url = template_url.replace(":product_id", product_id).replace(":dlname", dlname);
    let formData = new FormData();
    let totalFilesSize = 0;
    for (let index = 0; index < uploadedImages[dlname].length; index++) {
      let file = uploadedImages[dlname][index];
      formData.append("files[]", uploadedImages[dlname][index]);
      totalFilesSize += file.size;
    }
    if (await this.checkAvailSpace(totalFilesSize, this.state.avail_space)) {
      let $ajax = $.ajax({
        url: template_url,
        type: "POST",
        method: "POST",
        data: formData,
        processData: false,
        contentType: false,
      });
      $ajax.done(function () {
        that.setState({ isLoading: false });
        alert("Successfully, uploaded file(s)");
      });
      $ajax.fail(function (jqXHR, textStatus, errorThrown) {
        that.setState({ isLoading: false });
        if (jqXHR.status == 406) {
            alert("One or more files exceeded the file size of 5MB\n\nPlease Upload files again with sizes less than 5MB");
        }
        if (jqXHR.status == 500) {
          alert("only Max of 500 files allowed at a time\n\nPlease batch upload 500 or less files at a time");
        }
        else {
          alert("An internal server error occured");
          //alert (jqXHR.status);
        }
      });
      $ajax.always(function () {
        that.getUserFolders({
          current_path: "/home/ubuntu/webapps/wishcollector.com/current/public/uploads/products/" + current_path,
          product_id: current_path.split("/")[0],
        });
      });
    } else {
      this.setState({
        uploadedImages: { none: [] },
        uploadedImageNames: { none: [] },
        isLoading: false,
      });
      alert("Available space limit exceeding, cannot upload files");
    }
  };

  handleSubmit(e, dlname) {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.uploadImages(dlname);
  }

  fileSelectedHandler = (e, dlname) => {
    // let uploadedImages = this.state.uploadedImages;
    // let uploadedImageNames = this.state.uploadedImageNames;
    // discard the old files if upload files is clicked again
    let uploadedImages = { none: [] };
    let uploadedImageNames = { none: [] };
    let files = e.target.files;
    for (let index = 0; index < files.length; index++) {
      uploadedImages[dlname].push(files[index]);
      uploadedImageNames[dlname].push(files[index].name);
    }
    this.setState({
      uploadedImages: uploadedImages,
      uploadedImageNames: uploadedImageNames,
    });
  };

  toggleImageEditor = (val) => {
    if (!val) {
      this.setState({ showImageEditor: null });
      document.querySelector("#manage_image_editor").classList.remove("show_editor");
    } else {
      if (val.name.split(".").pop() !== "svg") {
        document.querySelector(".modal").removeAttribute("tabindex");
        document.querySelector("#manage_image_editor").classList.add("show_editor");
        this.setState({ showImageEditor: val });
        setTimeout(() => {
          this.initializeEditor();
        }, 100);
      } else {
        alert("Can't edit the svg images");
      }
    }
  };

  initializeEditor = () => {
    let refInstance = this.editorRef.current.getInstance();
    // Changing buttons
    $(".tui-image-editor-header-logo").replaceWith("");
    $(".tui-image-editor-header-buttons").replaceWith(
      `<div class="tui-image-editor-header-buttons">
        <button class="tui-image-editor-close-btn">Close</button>
        <button class="tui-image-editor-save-btn">Save</button>
        <button class="tui-image-editor-save-as-btn">Save As</button>
        <section class="save-as-container">
          <input class="save-as-input" type="text" placeholder="File name" />
          <button class="submit_save_as_file">Submit</button>
        </section>
      </div>`
    );

    if (!$(".tie-btn-filter").html()) {
      const filterBtn = `<li tooltip-content="Filter" class="filter-btn tie-btn-filter tui-image-editor-item normal">
        <img alt="Filter" class="filter_filter filter_image" src="icons/filter.png" />
      </li>
      `;
      $(".tui-image-editor-menu").prepend(filterBtn);
      $(".tie-btn-filter").bind("click", this.onFilterSelect);
    }

    refInstance.loadImageFromURL(this.state.showImageEditor.image, "Adukku image").then(() => {
      refInstance.ui.activeMenuEvent();

      $(".image_editor_file_name").remove();
      $(".tui-image-editor-header").append(
        `<p class="image_editor_file_name">${this.state.showImageEditor.name}</p>`
      );

      if (!$(".preset-fixed").html()) {
        const fixedCrop = `<div class="tui-image-editor-button preset preset-fixed">
            <div>
              <div class="crop_icon"></div>
            </div>
            <label>Fixed</label>
          </div>`;
        $(".tie-crop-preset-button").append(fixedCrop);
      }
      document.querySelector(".save-as-container").style.display = "none";
      this.bindActions();
    });

    refInstance.on("objectScaled", () => {
      if (inputWidth && inputHeight) {
        this.customImageSizing();
      }
    });
  };

  bindActions = () => {
    $(".tie-btn-crop").bind("mousedown", this.onCropSelect);
    $(".tie-btn-flip").bind("mousedown", this.onFlipSelect);
    $(".tie-btn-text").bind("mousedown", this.onTextSelect);
    $(".tie-btn-rotate").bind("mousedown", this.onRotateSelect);
    $(".tie-btn-draw").bind("mousedown", this.onDrawSelect);
    $(".tie-btn-mask").bind("mousedown", this.onMaskSelect);
    $(".tui-image-editor-close-btn").bind("click", this.onClose);
    $(".tui-image-editor-save-btn").bind("click", this.onSave);
    $(".tui-image-editor-save-as-btn").bind("click", this.onSaveas);
    $(".submit_save_as_file").bind("click", this.onSubmitFile);
    $(".preset-fixed").bind("click", this.onClickFix);
    $(".preset-none").bind("click", this.onClickCustom);
    $(".preset-square").bind("click", this.onClickSq);
    $(".preset-3-2").bind("click", this.onClick32);
    $(".preset-4-3").bind("click", this.onClick43);
    $(".preset-5-4").bind("click", this.onClick54);
    $(".preset-7-5").bind("click", this.onClick75);
    $(".preset-16-9").bind("click", this.onClick169);
  };

  unBindActions = () => {
    $(".tie-btn-crop").unbind("mousedown", this.onCropSelect);
    $(".tie-btn-flip").unbind("mousedown", this.onFlipSelect);
    $(".tie-btn-text").unbind("mousedown", this.onTextSelect);
    $(".tie-btn-rotate").unbind("mousedown", this.onRotateSelect);
    $(".tie-btn-draw").unbind("mousedown", this.onDrawSelect);
    $(".tie-btn-mask").unbind("mousedown", this.onMaskSelect);
    $(".tui-image-editor-close-btn").unbind("click", this.onClose);
    $(".tui-image-editor-save-btn").unbind("click", this.onSave);
    $(".tui-image-editor-save-as-btn").unbind("click", this.onSaveas);
    $(".submit_save_as_file").unbind("click", this.onSubmitFile);
    $(".preset-fixed").unbind("click", this.onClickFix);
    $(".preset-none").unbind("click", this.onClickCustom);
    $(".preset-square").unbind("click", this.onClickSq);
    $(".preset-3-2").unbind("click", this.onClick32);
    $(".preset-4-3").unbind("click", this.onClick43);
    $(".preset-5-4").unbind("click", this.onClick54);
    $(".preset-7-5").unbind("click", this.onClick75);
    $(".preset-16-9").unbind("click", this.onClick169);
  };

  addCropFields = () => {
    const customCrop = `<div id="crop_fields_1" class="custom-crop-container">
      <div class="custom-crop-width">
        <label> Width: </label>
        <input id="tui_widt_1" type="number" class="custom-crop-input" value="0" />
      </div>
      <div class="custom-crop-height">
        <label> Height: </label>
        <input id="tui_heig_1" type="number" class="custom-crop-input" value="0" />
      </div>
    </div>`;
    if (!$("#crop_fields_1").html()) {
      $(".tie-crop-preset-button").append(customCrop);
      inputWidth = document.querySelector("#tui_widt_1");
      inputHeight = document.querySelector("#tui_heig_1");
      inputWidth.oninput = this.updatingWidth;
      inputHeight.oninput = this.updatingHeight;
    }
  };

  onSaveas = () => {
    document.querySelector(".save-as-container").style.display = "flex";
  };

  removeCropFields = () => {
    if ($(".custom-crop-container").html()) {
      $(".custom-crop-container").remove();
    }
  };

  removeNotWorkingFilters = () => {
    const filterParent = document.querySelector(".tui-image-editor-menu-filter");
    const middleFilter = document.querySelectorAll(".tui-image-editor-submenu-align");
    const lines = filterParent.querySelectorAll(".tui-image-editor-partition");
    if (middleFilter.length === 3) {
      $(".tui-image-editor-submenu-align").eq(1).remove();
      $(".tui-image-editor-submenu-align").eq(1).remove();
    }
    if (lines.length === 3) {
      $(".tui-image-editor-menu-filter .tui-image-editor-partition").eq(0).remove();
    }
  };

  onCropSelect = () => {
    $(".tie-btn-filter").removeClass("active");
    $(".filter_image").addClass("filter_filter");
    this.removeCropFields();
    this.removeFilters();
    // setTimeout(() => {
    //   let refInstance = this.editorRef.current.getInstance();
    //   refInstance.setCropzoneRect(1);
    // }, 1000);
  };

  onFlipSelect = () => {
    $(".tie-btn-filter").removeClass("active");
    $(".filter_image").addClass("filter_filter");
    this.removeCropFields();
    this.removeFilters();
  };

  onTextSelect = () => {
    $(".tie-btn-filter").removeClass("active");
    $(".filter_image").addClass("filter_filter");
    this.removeCropFields();
    this.removeFilters();
  };

  onRotateSelect = () => {
    $(".tie-btn-filter").removeClass("active");
    $(".filter_image").addClass("filter_filter");
    this.removeCropFields();
    this.removeFilters();
  };

  onFilterSelect = () => {
    this.removeCropFields();
    if ($(".tie-btn-mask").hasClass("active")) {
      $(".tie-btn-mask").trigger("click");
    }
    if ($(".tie-btn-draw").hasClass("active")) {
      $(".tie-btn-draw").trigger("click");
    }
    if ($(".tie-btn-text").hasClass("active")) {
      $(".tie-btn-text").trigger("click");
    }
    if ($(".tie-btn-rotate").hasClass("active")) {
      $(".tie-btn-rotate").trigger("click");
    }
    if ($(".tie-btn-flip").hasClass("active")) {
      $(".tie-btn-flip").trigger("click");
    }
    if ($(".tie-btn-crop").hasClass("active")) {
      $(".tie-btn-crop").trigger("click");
    }
    $(".tui-image-editor-item").removeClass("active");
    $(".tie-btn-filter").addClass("active");
    $(".filter_image").removeClass("filter_filter");
    this.addFilterFields();
    // this.removeNotWorkingFilters();
  };

  onDrawSelect = () => {
    $(".tie-btn-filter").removeClass("active");
    $(".filter_image").addClass("filter_filter");
    this.removeCropFields();
    this.removeFilters();
  };

  onMaskSelect = () => {
    $(".tie-btn-filter").removeClass("active");
    $(".filter_image").addClass("filter_filter");
    this.removeCropFields();
    this.removeFilters();
  };

  onClickFix = () => {
    this.addCropFields();
    setTimeout(() => {
      let refInstance = this.editorRef.current.getInstance();
      refInstance.setCropzoneRect(1);
      this.initializeSizeCal();
    }, 500);
  };

  onClickCustom = () => {
    // setTimeout(() => {
    //   let refInstance = this.editorRef.current.getInstance();
    //   refInstance.setCropzoneRect(1);
    // }, 500);
    this.removeCropFields();
  };

  onClickSq = () => {
    this.removeCropFields();
  };

  onClick32 = () => {
    this.removeCropFields();
  };

  onClick43 = () => {
    this.removeCropFields();
  };

  onClick54 = () => {
    this.removeCropFields();
  };

  onClick75 = () => {
    this.removeCropFields();
  };

  onClick169 = () => {
    this.removeCropFields();
  };

  addFilterFields = () => {
    if (!$(".tui-image-editor-menu-filter").html()) {
      $(".tui-image-editor-submenu").css({ display: "table" });
      const filFields = `
        <div class="tui-image-editor-menu-filter" style="display: table-cell !important">
          <ul class="tui-image-editor-submenu-item">
            <li>
              <div class="checkbox_filters">
                <label>
                  <input class="gray_check" type="checkbox" value="Grayscale">
                  <span>Grayscale</span>
                </label>
                <label>
                  <input class="sepia_check" type="checkbox" value="Sepia">
                  <span>Sepia</span>
                </label>
                <label>
                  <input class="invert_check" type="checkbox" value="Invert">
                  <span>Invert</span>
                </label>
                <label>
                  <input class="sepia2_check" type="checkbox" value="Sepia2">
                  <span>Sepia2</span>
                </label>
                <label>
                  <input class="blur_check" type="checkbox" value="Blur">
                  <span>Blur</span>
                </label>
                <label>
                  <input class="sharpen_check" type="checkbox" value="Sharpen">
                  <span>Sharpen</span>
                </label>
                <label>
                  <input class="emboss_check" type="checkbox" value="Emboss">
                  <span>Emboss</span>
                </label>
              </div>
            </li>
            <li>
              <div class="slide_filter">
                <div class="each_label two_labels">
                  <label>
                    <input class="remove_white_check" type="checkbox" >
                    <span>Remove White</span>
                  </label>
                  <div class="range_slider">
                    <span>Distance</span>
                    <input type="range" class="disabled" min="0" max="100" value="0" id="remove_white_check">
                  </div>
                </div>
                <div class="each_label">
                  <label>
                    <input class="brightness_check" type="checkbox" >
                    <span>Brightness</span>
                  </label>
                  <div class="range_slider">
                    <input type="range" class="disabled" min="0" max="100" value="0" id="brightness_check">
                  </div>
                </div>
                <div class="each_label two_labels">
                  <label>
                    <input class="color_check" type="checkbox" >
                    <span>Color Filter</span>
                  </label>
                  <div class="range_slider">
                    <span>Threshold</span>
                    <input type="range" class="disabled" min="0" max="100" value="0" id="color_check">
                  </div>
                </div>
                <div class="each_label">
                  <label>
                    <input class="noise_check" type="checkbox" >
                    <span>Noise</span>
                  </label>
                  <div class="range_slider">
                    <input type="range" class="disabled" min="0" max="100" value="0" id="noise_check">
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
    `;
      $(".tui-image-editor-submenu").append(filFields);
      const imageRef = this.editorRef.current.getInstance();
      $inputCheckBlur = $(".blur_check");
      $inputCheckEmboss = $(".emboss_check");
      $inputCheckInvert = $(".invert_check");
      $inputCheckSepia = $(".sepia_check");
      $inputCheckSepia2 = $(".sepia2_check");
      $inputCheckSharpen = $(".sharpen_check");
      $inputCheckGrayscale = $(".gray_check");
      $inputCheckRemoveWhite = $(".remove_white_check");
      $inputCheckBrightness = $(".brightness_check");
      $inputCheckNoise = $(".noise_check");
      $inputCheckColorFilter = $(".color_check");
      $inputSlideRemoveWhite = $("#remove_white_check");
      $inputSlideBrightness = $("#brightness_check");
      $inputSlideNoise = $("#noise_check");
      $inputSlideColorFilter = $("#color_check");

      $inputCheckBlur.on("change", (e) => {
        if (e.target.checked) {
          imageRef.applyFilter("Blur", { blur: 0.1 });
        } else {
          imageRef.removeFilter("Blur");
        }
      });
      $inputCheckEmboss.on("change", (e) => {
        if (e.target.checked) {
          imageRef.applyFilter("Emboss");
        } else {
          imageRef.removeFilter("Emboss");
        }
      });
      $inputCheckInvert.on("change", (e) => {
        if (e.target.checked) {
          imageRef.applyFilter("Invert");
        } else {
          imageRef.removeFilter("Invert");
        }
      });
      $inputCheckSepia.on("change", (e) => {
        if (e.target.checked) {
          imageRef.applyFilter("Sepia");
        } else {
          imageRef.removeFilter("Sepia");
        }
      });
      $inputCheckSepia2.on("change", (e) => {
        if (e.target.checked) {
          imageRef.applyFilter("vintage");
        } else {
          imageRef.removeFilter("vintage");
        }
      });
      $inputCheckSharpen.on("change", (e) => {
        if (e.target.checked) {
          imageRef.applyFilter("Sharpen");
        } else {
          imageRef.removeFilter("Sharpen");
        }
      });
      $inputCheckGrayscale.on("change", (e) => {
        if (e.target.checked) {
          imageRef.applyFilter("Grayscale");
        } else {
          imageRef.removeFilter("Grayscale");
        }
      });
      $inputCheckRemoveWhite.on("change", (e) => {
        if (e.target.checked) {
          $("#remove_white_check").removeClass("disabled");
          imageRef.applyFilter("removeColor", {
            color: "#FFFFFF",
            useAlpha: false,
            distance: parseInt($inputSlideRemoveWhite.val(), 10) / 255,
          });
        } else {
          $("#remove_white_check").addClass("disabled");
          imageRef.removeFilter("removeColor");
        }
      });
      $inputCheckBrightness.on("change", (e) => {
        if (e.target.checked) {
          $("#brightness_check").removeClass("disabled");
          imageRef.applyFilter("brightness", {
            brightness: parseInt($inputSlideBrightness.val(), 10) / 255,
          });
        } else {
          $("#brightness_check").addClass("disabled");
          imageRef.removeFilter("brightness");
        }
      });
      $inputCheckNoise.on("change", (e) => {
        if (e.target.checked) {
          $("#noise_check").removeClass("disabled");
          imageRef.applyFilter("noise", {
            noise: parseInt($inputSlideNoise.val(), 10),
          });
        } else {
          $("#noise_check").addClass("disabled");
          imageRef.removeFilter("noise");
        }
      });
      $inputCheckColorFilter.on("change", (e) => {
        if (e.target.checked) {
          $("#color_check").removeClass("disabled");
          imageRef.applyFilter("removeColor", {
            color: "#FFFFFF",
            distance: $inputSlideColorFilter.val() / 255,
          });
        } else {
          $("#color_check").addClass("disabled");
          imageRef.removeFilter("removeColor");
        }
      });
      $inputSlideRemoveWhite.on("input", (e) => {
        imageRef.applyFilter("removeColor", {
          color: "#FFFFFF",
          useAlpha: false,
          distance: parseInt(e.target.value, 10) / 255,
        });
      });
      $inputSlideBrightness.on("input", (e) => {
        imageRef.applyFilter("brightness", {
          brightness: parseInt(e.target.value, 10) / 255,
        });
      });
      $inputSlideNoise.on("input", (e) => {
        imageRef.applyFilter("noise", {
          noise: parseInt(e.target.value, 10),
        });
      });
      $inputSlideColorFilter.on("input", (e) => {
        imageRef.applyFilter("removeColor", {
          color: "#FFFFFF",
          distance: e.target.value / 255,
        });
      });
    }
  };

  removeFilters = () => {
    if ($(".tui-image-editor-menu-filter").html()) {
      $(".tui-image-editor-menu-filter").remove();
    }
  };

  initializeSizeCal = () => {
    document.querySelector(".preset-fixed").classList.add("active");
    this.customImageSizing();
  };

  updatingWidth = (e) => {
    if (parseInt(e.data) >= 0 && parseInt(e.data) <= 9) {
      inputWidth.value = e.target.value;
      const imageRef = this.editorRef.current.getInstance();
      imageRef.setCropzoneRect(inputWidth.value / inputHeight.value);
    }
  };

  updatingHeight = (e) => {
    if (parseInt(e.data) >= 0 && parseInt(e.data) <= 9) {
      inputHeight.value = e.target.value;
      const imageRef = this.editorRef.current.getInstance();
      imageRef.setCropzoneRect(inputWidth.value / inputHeight.value);
    }
  };

  customImageSizing = () => {
    const imageRef = this.editorRef.current.getInstance();
    let imageMode = imageRef.getDrawingMode();
    if (imageMode === "CROPPER") {
      let imgCrop = imageRef.getCropzoneRect();
      if (imgCrop.width == 0.5 && imgCrop.height == 0.5) {
        let imageCanvs = imageRef.getCanvasSize();
        inputWidth.value = imageCanvs.width.toFixed(2);
        inputHeight.value = imageCanvs.height.toFixed(2);
      } else {
        inputWidth.value = imgCrop.width.toFixed(2);
        inputHeight.value = imgCrop.height.toFixed(2);
      }
    }
  };

  onClose = () => {
    this.unBindActions();
    this.removeCropFields();
    $(".preset-fixed").remove();
    $(".image_editor_file_name").remove();
    this.toggleImageEditor(null);
  };

  onSave = async () => {
    const extension = this.state.showImageEditor.image.split(".").pop().split("?")[0];
    const imageRef = this.editorRef.current.getInstance();
    let metadata = `image/${extension}`;
    const dataURL = imageRef.toDataURL(metadata, 1);
    let file = await this.dataURLtoFile(dataURL, this.state.showImageEditor.name, metadata);
    this.submitImage(file, this.state.showImageEditor);
    this.onClose();
  };

  dataURLtoFile = async (dataurl, filename, metadata) => {
    let response = await fetch(dataurl);
    let data = await response.blob();
    let bgImgFile = new File([data], filename, { type: metadata });
    return bgImgFile;
  };

  submitImage = (file, showImageEditor) => {
    let uploadedImages = { none: [] };
    let uploadedImageNames = { none: [] };
    uploadedImages["none"].push(file);
    uploadedImageNames["none"].push(file.name);
    this.setState(
      {
        uploadedImages: uploadedImages,
        uploadedImageNames: uploadedImageNames,
      },
      () => {
        this.uploadImages("none");
      }
    );
    // this.setImageFromFile(file, showImageEditor.data.id);
  };

  onSubmitFile = async () => {
    let imageName = $(".save-as-input").val();
    const extension = this.state.showImageEditor.image.split(".").pop().split("?")[0];
    const imageRef = this.editorRef.current.getInstance();
    let metadata = `image/${extension}`;
    const dataURL = imageRef.toDataURL(metadata, 1);
    let file = await this.dataURLtoFile(
      dataURL,
      `${imageName.trim().replaceAll(" ", "_")}.${extension}`,
      metadata
    );
    this.submitImage(file, this.state.showImageEditor);
    this.onClose();
  };

  setImageFromFile = (rawFile, dataID) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      let dataURL = e.target.result;
      let newEditedFiles = this.state.editedFiles;
      newEditedFiles[dataID] = dataURL;
      this.setState({ editedFiles: newEditedFiles });
    };
    reader.readAsDataURL(rawFile);
  };

  render() {
    const {
      isFileManagerOpen,
      folders,
      user_products,
      basePath,
      current_path,
      prev_path,
      isShowAll,
      searchResults,
      page,
      perPage,
      deletableFiles,
    } = this.state;

    let folderPath = (current_path && current_path.split("/")) || [];
    let updatedFolders = isShowAll ? folders : searchResults;
    let total_pages = Math.ceil(updatedFolders && updatedFolders.length / perPage);
    if (folderPath.length > 0) folderPath[0] = user_products[folderPath[0]];

    const isShowPagination =
      this.state.product_id && current_path && current_path.includes("images") ? true : false;
    const indexOfLastTodo = page * perPage;
    const indexOfFirstTodo = indexOfLastTodo - perPage;
    const currentFolders = isShowPagination
      ? updatedFolders.slice(indexOfFirstTodo, indexOfLastTodo)
      : updatedFolders;
    return (
      <li>
        <a onClick={this.openFileManager}>{"View Image Files"}</a>
        {/* onClick={() => this.deleteFile("all")} */}
        <Modal
          dialogClassName="modal-90w"
          centered="true"
          show={isFileManagerOpen}
          onHide={this.openFileManager}
        >
          <Modal.Header closeButton className="filemanager_header">
            <Modal.Title>
              <div className="product_data_header">
                <div className="product_data_heading">
                  File Manager: <span className="dlname">{folderPath.join(" / ")}</span>
                  {folderPath.length > 0 && (
                    <img
                      src="/icons/collapse.png"
                      alt="Back"
                      className="product_crumb"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.getUserFolders({ current_path: prev_path, page: 1 });
                      }}
                    />
                  )}
                </div>
                <div className="product_data_info">
                  <FieldGroup
                    componentClass="select"
                    id="formControlPreset"
                    label="Show Size In:"
                    name="storage_unit"
                    placeholder="Select Product Category"
                    value={this.state.storage_unit ? this.state.storage_unit : ""}
                    className="product_data_control"
                    onChange={({ target: { name, value } }) => {
                      this.setState({ [name]: value });
                    }}
                  >
                    <option key="GB" value="GB">
                      GB
                    </option>
                    <option key="MB" value="MB">
                      MB
                    </option>
                    <option key="KB" value="KB">
                      KB
                    </option>
                  </FieldGroup>
                  <ControlLabel>
                    Avail Space: {this.formatBytes(this.state.avail_space || 0, 3, "global")}
                  </ControlLabel>
                  <ControlLabel>
                    Total Space: {this.formatBytes(this.state.total_space || 0, 3, "global")}
                  </ControlLabel>
                  <ControlLabel>
                    Reclaim Space:{" "}
                    {this.formatBytes(this.state.reclaimable_space || 0, 3, "global")}
                  </ControlLabel>
                </div>
              </div>
              {this.state.product_id && current_path && current_path.includes("images") && (
                <div
                  className="product_data_header"
                  style={{ borderTop: "2px solid gray", backgroundColor: "lightgray" }}
                >
                  <div className="product_data_info">
                    <ControlLabel>Pages: {total_pages}</ControlLabel>
                    <ControlLabel>Rows: {updatedFolders && updatedFolders.length}</ControlLabel>
                    <FormGroup controlId="per_page">
                      <ControlLabel>Per page:</ControlLabel>
                      <FormControl
                        componentClass="select"
                        required="required"
                        value={this.state.perPage}
                        // name={"lyslotfor[dlname]"}
                        onChange={({ target: { name, value } }) => {
                          this.setState({ perPage: value });
                        }}
                        className="product_data_control"
                      >
                        <option value="5"> 5</option>
                        <option value="10"> 10</option>
                        <option value="25">25 </option>
                      </FormControl>
                    </FormGroup>
                    <img
                      src="/icons/icon_head.png"
                      data-toggle="tooltip"
                      title="Go to Previous page"
                      alt="Prev"
                      className="product_page_prev"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (page > 1 && page <= total_pages) {
                          this.setState({ page: page - 1 });
                        }
                      }}
                    />
                    <FormGroup controlId="page">
                      <ControlLabel>Page:</ControlLabel>
                      <FormControl
                        componentClass="input"
                        required="required"
                        type="number"
                        value={this.state.page}
                        // name={"lyslotfor[dlname]"}
                        onChange={({ target: { name, value } }) => {
                          if (value && value >= 1 && value <= total_pages) {
                            this.setState({ page: value });
                          } else if (value > total_pages) {
                            this.setState({ page: total_pages });
                          }
                        }}
                        className="product_data_input"
                      ></FormControl>
                    </FormGroup>
                    <img
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (page >= 1 && page < total_pages) {
                          this.setState({ page: page + 1 });
                        }
                      }}
                      data-toggle="tooltip"
                      title="Go to Next page"
                      src="/icons/icon_head.png"
                      alt="Next"
                      className="product_page_next"
                    />
                  </div>
                  <div className="product_data_info">
                    <div className="flex_align" style={{ marginRight: "20px" }}>
                      <input
                        type="text"
                        multiple
                        value={this.state.search_text}
                        name="search_text"
                        onChange={({ target: { name, value } }) => {
                          this.setState({ search_text: value });
                        }}
                        className="search_input"
                        placeholder="Search filename"
                        id={"search_text"}
                      />
                      <button
                        className="btn btn-default search_btn"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (this.state.search_text) {
                            let searchText = this.state.search_text;
                            searchText = searchText.split("*"); //("*", "");
                            // searchText = searchText.replace(/[^\w\s]/gi, "");
                            let searchResults = folders.filter((f) => {
                              // f.name.toLowerCase().includes(searchText)
                              let name = f.name.toLowerCase();
                              if (searchText.every((s) => name.includes(s))) {
                                return f;
                              }
                            });
                            this.setState({
                              searchResults: searchResults,
                              isShowAll: false,
                              page: 1,
                            });
                          }
                        }}
                      >
                        Go
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.setState({ isShowAll: true, searchText: "" });
                        }}
                        className="btn btn-default search_btn btn-danger"
                      >
                        Clear
                      </button>
                    </div>
                    <form
                      onSubmit={(e) => {
                        this.handleSubmit(e, "none");
                      }}
                      encType="multipart/form-data"
                      className="flex_align override_form"
                    >
                      <input
                        type="file"
                        multiple
                        value={this.state.studentImages}
                        onChange={(e) => {
                          this.fileSelectedHandler(e, "none");
                        }}
                        accept="image/x-png,image/jpeg"
                        style={{ display: "none" }}
                        id={"upload_images" + "none"}
                      />
                      <label htmlFor={"upload_images" + "none"} className="flex_align pointer">
                        Upload Files
                        <img
                          className="upload_icon"
                          src="icons/add.png"
                          alt="Upload"
                        />
                      </label>
                      {this.state.isLoading ?  <div style={{color: 'red'}}>Loading...</div> :
                        <label title="Upload Images">
                          <input type="submit" value="upload" required style={{ display: "none" }} />
                          <img
                            src="icons/UploadImagesIcon.png"
                            alt="Upload"
                            className="pointer upload_btn"
                          />
                        </label>
                      }
                    </form>
                    {Object.keys(deletableFiles).length > 0 && (
                      <button
                        className="btn btn-default search_btn"
                        onClick={() => this.deleteFile("selected")}
                      >
                        Delete Selected
                      </button>
                    )}
                  </div>
                </div>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-bordered override_form">
              <thead>
                <tr>
                  <th
                    scope="col"
                    onClick={(e) => {
                      let sortedFolders = [];
                      if (this.state.sortBy != "name") {
                        sortedFolders = folders.sort((a, b) => a.name.localeCompare(b.name));
                      }
                      this.setState({
                        folders: this.state.sortBy == "name" ? folders.reverse() : sortedFolders,
                        sortBy: "name",
                        sortType: this.state.sortType == "asc" ? "des" : "asc",
                      });
                    }}
                  >
                    Name
                    {this.state.sortType == "asc" ? (
                      <span
                        className="fa fa-caret-down"
                        style={{
                          display: this.state.sortBy == "name" ? "inline-block" : "none",
                        }}
                      ></span>
                    ) : (
                      <span
                        className="fa fa-caret-up"
                        style={{
                          display: this.state.sortBy == "name" ? "inline-block" : "none",
                        }}
                      ></span>
                    )}
                  </th>
                  <th
                    scope="col"
                    onClick={(e) => {
                      let sortedFolders = [];
                      if (this.state.sortBy != "size") {
                        sortedFolders = folders.sort((a, b) => a.size - b.size);
                        // .sort(function (a, b) {  return a - b;  });
                      }
                      this.setState({
                        folders: this.state.sortBy == "size" ? folders.reverse() : sortedFolders,
                        sortBy: "size",
                        sortType: this.state.sortType == "asc" ? "des" : "asc",
                      });
                    }}
                  >
                    Size
                    {this.state.sortType == "asc" ? (
                      <span
                        className="fa fa-caret-down"
                        style={{
                          display: this.state.sortBy == "size" ? "inline-block" : "none",
                        }}
                      ></span>
                    ) : (
                      <span
                        className="fa fa-caret-up"
                        style={{
                          display: this.state.sortBy == "size" ? "inline-block" : "none",
                        }}
                      ></span>
                    )}
                  </th>

                  <th
                    scope="col"
                    onClick={(e) => {
                      let sortedFolders = [];
                      if (this.state.sortBy != "time") {
                        sortedFolders = folders.sort(
                          (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
                        );

                        // .sort(function (a, b) {  return a - b;  });
                      }
                      this.setState({
                        folders: this.state.sortBy == "time" ? folders.reverse() : sortedFolders,
                        sortBy: "time",
                        sortType: this.state.sortType == "asc" ? "des" : "asc",
                      });
                    }}
                  >
                    Uploaded Time
                    {this.state.sortType == "asc" ? (
                      <span
                        className="fa fa-caret-down"
                        style={{
                          display: this.state.sortBy == "time" ? "inline-block" : "none",
                        }}
                      ></span>
                    ) : (
                      <span
                        className="fa fa-caret-up"
                        style={{
                          display: this.state.sortBy == "time" ? "inline-block" : "none",
                        }}
                      ></span>
                    )}
                  </th>
                  {/* <th scope="col">Uploaded Time</th> */}
                  <th scope="col">View/Edit</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {currentFolders &&
                  currentFolders.map((folder, fIndex) => {
                    let currentFolderPaths = folder.path
                      .split("public/uploads/products")[1]
                      .split("/");
                    let [em, product_id, dlname] = currentFolderPaths;
                    let folderJSX = (
                      <span style={{ cursor: "pointer" }}>
                        <i className="glyphicon glyphicon-folder-open" aria-hidden="true"></i>
                        <span style={{ marginLeft: "5px" }}>{folder.name}</span>
                      </span>
                    );
                    if (folder.is_file) {
                      folderJSX = (
                        <span style={{ cursor: "pointer" }}>
                          <img
                            src={`${basePath}${folder.path.split("public")[1]}?${new Date()}`}
                            height="100"
                          />
                          <span style={{ marginLeft: "5px" }}>
                            {folder.name}
                            {` (${folder.height}X${folder.width})`}
                          </span>
                        </span>
                      );
                    }
                    return (
                      <tr key={folder.updated_at}>
                        {this.state.isFetching ?  <td><div style={{color: 'red'}}>Fetching...</div></td> :
                          <td
                            onClick={(e) => {
                              if (!folder.is_file) {
                                this.getUserFolders({
                                  product_id,
                                  current_path: folder.path,
                                  dlname,
                                });
                              } else {
                                if(folder.name.split(".")[1]!=="m4a") {
                                  this.toggleImageEditor({
                                    image: `${basePath}${folder.path.split("public")[1]}?${new Date()}`,
                                    name: folder.name,
                                  });
                                }
                              }
                            }}
                          >
                            {folderJSX}
                          </td>
                        }
                        {/* <td>{folder.is_file ? this.formatBytes(folder.size, 3) : `${folder.size || 0}MB`}</td> */}

                        <td>{this.formatBytes(folder.size || 0, 3)}</td>
                        <td>{`${new Date(folder.updated_at).toLocaleString()}`}</td>
                        <td>
                          {folder.is_file && (
                            <a
                              className="no-textcolor"
                              onClick={() =>
                                this.setState({
                                  show: true,
                                  currentFile: {
                                    desc: folder.name,
                                    preview_url: folder.path.split("public")[1],
                                  },
                                })
                              }
                            >
                              View
                            </a>
                          )}
                        </td>
                        <td>
                          {folder.is_file && (
                            <input
                              type="checkbox"
                              className="form-control override_checkbox no_margin"
                              name={"Mark for deletion"}
                              onChange={({ target: { name, value, checked } }) => {
                                let deletableFiles = this.state.deletableFiles;
                                if (checked) {
                                  deletableFiles[folder.path] = checked;
                                } else {
                                  delete deletableFiles[folder.path];
                                }
                                this.setState({ deletableFiles });
                              }}
                              value={deletableFiles[folder.path] || false}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button onClick={this.openFileManager}>Cancel</Button>
          </Modal.Footer> */}
        </Modal>
        {this.state.show && this.state.currentFile && (
          <ThumbnailPreview
            layoutDetails={this.state.currentFile}
            imageSrc={`${this.state.currentFile.preview_url}?${new Date()}`}
            onClose={() => this.setState({ show: false })}
          />
        )}
        {this.state.showImageEditor && (
          <EditProductImage portalClass="manage_image_editor">
            <ImageEditor ref={this.editorRef} {...this.state.imageEditorOptions} />
          </EditProductImage>
        )}
      </li>
    );
  }
}
