import React, { Fragment, useState } from 'react';
import { Navbar, Nav, NavItem, MenuItem, NavDropdown, Modal, Button } from 'react-bootstrap';
import logo from 'images/wishCollectorLogoOfficial.png';

import profile from 'images/blankProfile.png';
//import {useLocation} from 'react-router-dom'
//import { render } from 'react-dom';

function Mynavbar (user_id, user_name, has_view_products) {

  const [show3, setShow3] = useState(false);
  const showDropdown3 = (e)=>{
      setShow3(true);
   }
  const hideDropdown3 = e => {
     setShow3(false);
   }
  
  const [show2, setShow2] = useState(false);
  const showDropdown2 = (e)=>{
      setShow2(true);
  }
  const hideDropdown2 = e => {
    setShow2(false);
  }
  
  const [show1, setShow1] = useState(false);
  const showDropdown1 = (e)=>{
      setShow1(true);
      setShow(false);
   }
   const hideDropdown1 = e => {
     setShow1(false);
   }
   
   const [show, setShow] = useState(false);
   const showDropdown = (e)=>{
       setShow(true);
    }
   const hideDropdown = e => {
      setShow(false);
    }
   const toggleShow = e => {
     setShow(!show)
     setShow1(false)
     setShow2(false)
     setShow3(false)
   }
   const toggleShow1 = e => {
     setShow1(!show1)
     setShow(false)
     setShow2(false)
     setShow3(false)
   }
   const toggleShow2 = e => {
     setShow2(!show2)
     setShow(false)
     setShow1(false)
     setShow3(false)
    }
   const toggleShow3 = e => {
     setShow3(!show3)
     setShow(false)
     setShow1(false)
     setShow2(false)
   }

   const hideDD = e => {
    toggleShow();
    $("#responsive-navbar-nav").removeClass("navbar-collapse collapse in");
    $("#responsive-navbar-nav").addClass("navbar-collapse collapse");
    const el=document.getElementById("nav-toggle")
    el.click();
   }

   const hideDD1 = e => {
    toggleShow1();
    $("#responsive-navbar-nav").removeClass("navbar-collapse collapse in");
    $("#responsive-navbar-nav").addClass("navbar-collapse collapse");
    const el=document.getElementById("nav-toggle")
    el.click();
   }
  
   const handleToggle = e => {
    ;; // do nothing
   }

   const [showModal, setshowModal] = useState(false);
   function handlePricing ()  {
    setshowModal(true);
   }

   function handleClosePricing ()  {
    setshowModal(false);
   }

   const [showAbout, setshowAbout] = useState(false);
   function handleAbout ()  {
    setshowAbout(true);
   }

   function handleCloseAbout ()  {
    setshowAbout(false);
   }



   function getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
   }

   const countryCode = getCookie("countryCode")
//    const countryCode = "US"


   return (
        window.innerWidth > 450 ?
        <Navbar expand="lg" inverse collapseOnSelect style={{borderRadius:"0px", backgroundColor: "#000", color:"#fff", position: "sticky",  minHeight:"55px", maxHeight:"55px"}} variant="dark" fixed="top">
          <Navbar.Brand >
          {/* <a href="/" className="navbar-left"  ><img height="40px" src={logo} style={{paddingTop:"-7px"}}/></a> */}
                
                <a href="/" style={{marginTop:"-7px"}}>
                 <img height="40px" src={logo} /> 
                </a>

          
          </Navbar.Brand>
          {user_id!=-1?
            <Fragment>
                  <Nav pullRight >

                  {/* {user_id===1? */}
                    {user_id!=-1?
                      <NavDropdown eventKey={3} style={{marginTop:'5px'}} title={<span style={{color:'white'}}>Dashboard</span>} id="3"
                        onClick={toggleShow2} open={show2}
                        onToggle={handleToggle}>
                        <div style={{display:show2?"block":"none"}}>
                          <MenuItem eventKey={3.1} href="/products" style={{ backgroundColor: "#000000"}}><span style={{ padding:'5px', color: "white", fontWeight : "normal", fontSize: "125%"}}>My Products</span></MenuItem>
                      </div> 
                      </NavDropdown>
                    :''}
                    <NavDropdown eventKey={2} id="4" title={<img height="20px" src={profile} style={{marginTop:'-5px', paddingTop:"3px"}}/>  }  
                      onMouseEnter={showDropdown3} onMouseLeave={hideDropdown3} open = {show3}
                      style={{paddingTop:"3px"}}
                      onToggle={handleToggle}>
                      <div style={{display:show3?"block":"none"}}>
                        <li className = "dropdown-header" ><span style={{ marginLeft:'-15px', textAlign:"center", color: "gray", fontWeight : "normal", fontSize: "125%"}}>Logged in as: </span><br></br><span style={{ marginLeft:'-15px',textAlign:"center", color: "black", fontWeight : "bold", fontSize: "125%"}}>{user_name}</span></li>
                        <MenuItem eventKey={3.1} href="/users/sign_out" data-method="delete" style={{ backgroundColor: "#ff0000"}}><span style={{ paddingLeft:'5px', color: "white", fontWeight : "normal", fontSize: "125%"}}>Log out</span></MenuItem>
                      </div>
                    </NavDropdown>
                  </Nav>
              </Fragment>    
                  : 
                  
          
          <Navbar.Collapse>
            <Nav pullRight >

            <NavItem eventKey={0} onClick={()=>handleAbout()} > 
                About
              </NavItem>
              
              <NavItem eventKey={1} onClick={()=>handlePricing()} > 
                Pricing
              </NavItem>

              <NavItem  eventKey={3} href="/users/sign_in">
                Login
              </NavItem>
            </Nav>

            {countryCode!=="IN" ?
                <Modal show={showModal} size="xl" centered="true" onHide={() => setshowModal(false)} style={{marginTop:'7vh'}}>
                <Modal.Header closeButton>
                      <Modal.Title>Pricing</Modal.Title>
                    </Modal.Header>
                    <br></br>
                    <p style={{marginLeft:'1vw',marginRight:'1vw', color:'green', fontFamily:'Salsa'}}>
                      Pricing is based on number of wishes per event.<br></br><br></br>If payment is made for 20 wishes at the creation of the event,
                      then it is considered as prepaid cost.<br></br><br></br>Let's say 30 wishers send their wishes, which is 10 wishes above what was prepaid,
                      cost for additional 10 wishes are considered as postpaid.
                    </p>
                    <hr></hr>
                    <b> <h4 style={{marginLeft:'1vw'}}>&bull; Prepaid cost per wish : </h4></b>
                    <p style={{marginLeft:'2vw'}}>
                      <span style={{color:"green", fontWeight:"bold"}}>$0.10</span> per wish&nbsp;<span style={{color:"red", fontWeight:"bold", fontSize:"20px"}}> + </span><br></br>
                      <span style={{color:"green", fontWeight:"bold"}}>$0.15 + 3% of total wishes cost</span> (convenience charges)
                    </p>
                    <hr></hr>
                    <b> <h4 style={{marginLeft:'1vw'}}>&bull; Postpaid cost per wish : </h4></b>
                    <p style={{marginLeft:'2vw'}}>
                      <span style={{color:"green", fontWeight:"bold"}}>$0.15</span> per wish&nbsp;<span style={{color:"red", fontWeight:"bold", fontSize:"20px"}}> + </span><br></br>
                      <span style={{color:"green", fontWeight:"bold"}}>$0.15 + 3% of total wishes cost</span> (convenience charges)
                    </p>  
                    <hr></hr>
                    <b> <h4 style={{marginLeft:'1vw'}}>Note :</h4></b>
                    <p style={{marginLeft:'2vw', marginRight:'1vw', color:'green', fontFamily:'Salsa'}}>
                    Minimum number of wishes is 20.
                    </p>
                    <hr></hr>
                  </Modal>
                :
                <Modal show={showModal} size="xl" centered="true" onHide={() => setshowModal(false)} style={{marginTop:'7vh'}}>
                  <Modal.Header closeButton>
                      <Modal.Title>Pricing</Modal.Title>
                    </Modal.Header>
                    <br></br>
                    <p style={{marginLeft:'1vw',marginRight:'1vw', color:'green', fontFamily:'Salsa'}}>
                      Pricing is based on number of wishes per event.<br></br><br></br>If payment is made for 20 wishes at the creation of the event,
                      then it is considered as prepaid cost.<br></br><br></br>Let's say 30 wishers send their wishes, which is 10 wishes above what was prepaid,
                      cost for additional 10 wishes are considered as postpaid.
                    </p>
                    <b> <h4 style={{marginLeft:'1vw'}}>&bull; Prepaid cost per wish : </h4></b>
                    <p style={{marginLeft:'2vw'}}>
                      <span style={{color:"green", fontWeight:"bold"}}>₹10</span> per wish&nbsp;<span style={{color:"red", fontWeight:"bold", fontSize:"20px"}}> + </span><br></br>
                      <span style={{color:"green", fontWeight:"bold"}}>3%</span> (convenience charges)&nbsp;<span style={{color:"red", fontWeight:"bold", fontSize:"20px"}}> + </span> GST<br></br>
                    </p>
                    <hr></hr>
                    <b> <h4 style={{marginLeft:'1vw'}}>&bull; Postpaid cost per wish : </h4></b>
                    <p style={{marginLeft:'2vw'}}>
                      <span style={{color:"green", fontWeight:"bold"}}>₹15</span> per wish&nbsp;<span style={{color:"red", fontWeight:"bold", fontSize:"20px"}}> + </span><br></br>
                      <span style={{color:"green", fontWeight:"bold"}}>3%</span> (convenience charges)&nbsp;<span style={{color:"red", fontWeight:"bold", fontSize:"20px"}}> + </span> GST<br></br>
                    </p>  
                    <hr></hr>
                    <b> <h4 style={{marginLeft:'1vw'}}>Note :</h4></b>
                    <p style={{marginLeft:'2vw', marginRight:'1vw', color:'green', fontFamily:'Salsa'}}>
                      Minimum number of wishes is 20.
                    </p>
                    <hr></hr>
                  </Modal>                
              }

              <Modal show={showAbout} size="xl" centered="true" onHide={() => setshowAbout(false)} style={{marginTop:'7vh'}}>
                <Modal.Header closeButton>
                      <Modal.Title>About</Modal.Title>
                    </Modal.Header>
                    <br></br>
                    <p style={{marginLeft:'1vw',marginRight:'1vw', color:'green', fontFamily:'Salsa'}}>
                      Welcome to Wish Collector.
                      <br></br>   <br></br>
                      A whole new exciting way to easily collect 100's of 1000's of wishes all in one place from across the globe for various events/occassions like Marriages, Engagements, New Job, New House, etc. 
                      <br></br>   <br></br>
                      A must have for collecting student Autographs and for celebrations like Graduations, Promotions, etc.
                      <br></br>   <br></br>
                      Collect 'Text' wishes, along with 'Audio' messages and also the wisher's pictures.
                      <br></br>   <br></br>
                      All wishes are private and only the event creator can see and hear. 
                      <br></br>   <br></br>
                      All anyone need is a mobile to collect wishes or to wish. 
                      <br></br>   <br></br>
                      Takes less than 5 minutes to setup an event, upload event picture like a Marriage Invitation or a picture of graduating person, etc., record a short Video invitation or upload pre-recorded video, and share the event wish link to anyone you want to invite to provide their wishes.
                      <br></br>   <br></br>
                      Watch and hear real time as wishes arrive.
                      <br></br>   <br></br>
                      Download & Carry collected wishes easily even in your mobile and re-live the moment on the go!
                      <br></br>
                    </p>
                    <hr></hr>

                    <p style={{marginLeft:'1vw',marginRight:'1vw', color:'green', fontFamily:'Salsa'}}>
                    <b style={{fontSize:'30px'}}>History</b>
                      <br></br>   <br></br>
                      We all like celebrating events or occasions, both personally and for someone else.
                      <br></br>   <br></br>
                      We all feel good when we receive wishes irrespective of from Family, Friends, Relatives, Colleagues, Classmates, Neighbors, sometimes from strangers and even occasionally from enemies!
                      <br></br>   <br></br>
                      We receive wishes via lots of medium like a simple phone call, a crisp SMS, a lengthy email,  via messaging platform like WhatsApp, via social media apps like Meta or Instagram or even via snail mail, flowers, chocolates, gifts and finally via greeting cards physical or digital.
                      <br></br>   <br></br>
                      We feel like we are over the moon…the feeling is priceless for each and every wish.
                      <br></br>   <br></br>
                      But it lasts for only a few days at most...
                      <br></br>
                    </p>
                    <hr></hr>
                    <p style={{marginLeft:'1vw',marginRight:'1vw', color:'green', fontFamily:'Salsa'}}>
                      While we try to re-live that moment by searching for the received wishes in messages, emails, picture books, albums, videos, greeting cards and snail mails, it always was and is very difficult to retrieve some if not all thus making it difficult to re-live that moment and not always possible to even trace the wishes sometimes.
                      <br></br>
                    </p>
                    <hr></hr>
                    <p style={{marginLeft:'1vw',marginRight:'1vw', color:'green', fontFamily:'Salsa'}}>
                      Wish collector was created exactly for this one singular purpose, to re-live that moment easily and readily for years to come.
                      <br></br> <br></br>
                      How good will that be if you can just open up few files, see all your wishers’ pictures, just tap on each of them, and out pops a window with their text wishes and also hear their wishes in their own voice.
                      <br></br> <br></br>
                      Not only that, you can store them in various devices like a Desktop, Laptop, Tablets and even carry them in your mobile so that you can re-live that moment on-the-go.
                      <br></br>
                    </p>
                    <hr></hr>
                    <p style={{marginLeft:'1vw',marginRight:'1vw', color:'green', fontFamily:'Salsa'}}>
                      Wish Collector was directly inspired from our other successful product, namely “Audio School Pictures” by Composz, wherein each student in a School can leave audio messages in turn for their classmates, and even Teachers and Principal can leave audio messages for each student.
                      <br></br>
                    </p>
                    <hr></hr>
                    <p style={{marginLeft:'1vw',marginRight:'1vw', color:'green', fontFamily:'Salsa'}}>
                      We sincerely hope that Wish Collector will be lots of fun and also of immense help in easily collecting your wishes for self or others for various events or occassions. All one needs is a mobile with internet access.
                      <br></br><br></br>
                      Good Luck.
                      <br></br><br></br>
                      Cheers,
                      <br></br>
                      --team Wish Collector.
                      <br></br>
                    </p>
                    <hr></hr>
                  </Modal>


          </Navbar.Collapse>
        }
        </Navbar>

        : // mobile below

        <Navbar  collapseOnSelect bg="dark" variant="dark" style={{backgroundColor: "#000"}} position="top">
          <div style={{paddingTop:"10px", marginBottom:'10px', display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
            <a href="/" className="navbar-left"  ><img height="40px" src={logo} /></a>
            <Navbar.Toggle id="nav-toggle" style={{backgroundColor: "#fff", height: "25px", paddingTop:"6px", marginTop:"7px"}} />
          </div>
          <div style={{position: "absolute", right: '1px', zIndex:'1000', width:'40vw' }}>
          <Navbar.Collapse id='responsive-navbar-nav' >
          {user_id!=-1?

            <Nav pullRight >
              
              
              {/* {user_id===1? */}
              {user_id!=-1?
                <Fragment>
              
                  <NavDropdown className="dropdown open" eventKey={3} style={{borderBottom:'solid 1px white', marginTop:'5px'}} title={<span style={{color:'white'}}>Dashboard</span>} id="3"
                    onClick={toggleShow2} open={show2}
                    onToggle={handleToggle}>
                    <div style={{display:show2?"block":"none"}}>
                      <MenuItem eventKey={3.1} href="/products" style={{ backgroundColor: "#000000"}}><span style={{ padding:'5px', color: "white", fontWeight : "normal", fontSize: "125%"}}>My Products</span></MenuItem>
                    </div> 
                  </NavDropdown>  
                </Fragment>
              : '' }
              

              <NavDropdown className="dropdown open" eventKey={2} id="4" title={<img height="20px" src={profile}/>} 
                onClick={toggleShow3} open={show3}
                onToggle={handleToggle}>
                <div style={{display:show3?"block":"none"}}>
                <li className = "dropdown-header" ><span style={{ marginLeft:'-20px', textAlign:"center", color: "yellow", fontWeight : "normal", fontSize: "125%"}}>Logged in as: </span><br></br><span style={{ marginLeft:'-20px',textAlign:"center", color: "white", fontWeight : "bold", fontSize: "125%"}}>{user_name}</span></li>
                  <MenuItem eventKey={3.1} href="/users/sign_out" data-method="delete" style={{ backgroundColor: "#ff0000"}}><span style={{ paddingLeft:'5px', color: "white", fontWeight : "normal", fontSize: "125%"}}>Log out</span></MenuItem>
                </div>                      
              </NavDropdown>
            </Nav>
            :
            <Nav>
              <NavItem eventKey={1} onClick={()=>handleAbout()} > 
                <span style={{color:"white", marginLeft:'5px',marginRight: '5px' }}>About</span>
              </NavItem>
              <NavItem divider="true" style={{backgroundColor:'white', height:'1px'}}/>
              <NavItem eventKey={1} onClick={()=>handlePricing()} style={{color:'white'}}> 
                  <span style={{color:"white"}}>Pricing</span>
              </NavItem>
              <NavItem divider="true" style={{backgroundColor:'white', height:'1px'}}/>
              <NavItem  href="/users/sign_in">
                <span style={{color:"white", marginLeft:'5px', }}>Login</span>
              </NavItem>
            </Nav>
        }

        {countryCode!=="IN" ?
                <Modal show={showModal} size="xl" centered="true" onHide={() => setshowModal(false)} style={{marginTop:'7vh'}}>
                <Modal.Header closeButton>
                      <Modal.Title>Pricing</Modal.Title>
                    </Modal.Header>
                    <br></br>
                    <p style={{marginLeft:'2vw',marginRight:'2vw', color:'green', fontFamily:'Salsa'}}>
                      Pricing is based on number of wishers per event.<br></br><br></br>If payment is made for 20 wishers at the creation of the event,
                      then it is considered as prepaid cost.<br></br><br></br>Let's say 30 wishers send their wishes, which is 10 wishers above what was prepaid,
                      cost for additional 10 wishers are considered as postpaid.
                    </p>
                    <b> <h4 style={{marginLeft:'1vw'}}>&bull; Prepaid cost per wisher : </h4></b>
                    <p style={{marginLeft:'2vw'}}>
                      <span style={{color:"green", fontWeight:"bold"}}>$0.10</span> per wisher&nbsp;<span style={{color:"red", fontWeight:"bold", fontSize:"20px"}}> + </span><br></br>
                      <span style={{color:"green", fontWeight:"bold"}}>$0.15 + 3% of total wishers cost</span> (convenience charges)
                    </p>
                    <hr></hr>
                    <b> <h4 style={{marginLeft:'1vw'}}>&bull; Postpaid cost per wisher : </h4></b>
                    <p style={{marginLeft:'2vw'}}>
                      <span style={{color:"green", fontWeight:"bold"}}>$0.15</span> per wisher&nbsp;<span style={{color:"red", fontWeight:"bold", fontSize:"20px"}}> + </span><br></br>
                      <span style={{color:"green", fontWeight:"bold"}}>$0.15 + 3% of total wishers cost</span> (convenience charges)
                    </p>  
                    <hr></hr>
                    <b> <h4 style={{marginLeft:'1vw'}}>Note : </h4></b>
                    <b> <h4 style={{marginLeft:'1vw'}}>Wishers count are in multiples of 20</h4></b>
                    <p style={{marginLeft:'2vw', marginRight:'1vw', color:'green', fontFamily:'Salsa'}}>
                      For example if requirement is for 30 wishers, payment has to be made for next nearest multiple of 20 which is 40 wishers
                    </p>
                    <b> <h4 style={{marginLeft:'1vw'}}>Output files :</h4></b>
                    <p style={{marginLeft:'2vw', marginRight:'1vw', color:'green', fontFamily:'Salsa'}}>
                      Wishes will be downloaded as '.html' files embedded with pictures, audio & text content. Each '.html' file typically will have 20 wishers and hence the cost is in multiples of 20
                    </p>
                    <hr></hr>
                  </Modal>
                :
                <Modal show={showModal} size="xl" centered="true" onHide={() => setshowModal(false)} style={{marginTop:'7vh'}}>
                  <Modal.Header closeButton>
                      <Modal.Title>Pricing</Modal.Title>
                    </Modal.Header>
                    <br></br>
                    <p style={{marginLeft:'2vw',marginRight:'2vw', color:'green', fontFamily:'Salsa'}}>
                      Pricing is based on number of wishers per event.<br></br><br></br>If payment is made for 20 wishers at the creation of the event,
                      then it is considered as prepaid cost.<br></br><br></br>Let's say 30 wishers send their wishes, which is 10 wishers above what was prepaid,
                      cost for additional 10 wishers are considered as postpaid.
                    </p>
                    <b> <h4 style={{marginLeft:'1vw'}}>&bull; Prepaid cost per wisher : </h4></b>
                    <p style={{marginLeft:'2vw'}}>
                      <span style={{color:"green", fontWeight:"bold"}}>₹10</span> per wisher&nbsp;<span style={{color:"red", fontWeight:"bold", fontSize:"20px"}}> + </span><br></br>
                      <span style={{color:"green", fontWeight:"bold"}}>3%</span> (convenience charges)&nbsp;<span style={{color:"red", fontWeight:"bold", fontSize:"20px"}}> + </span> GST<br></br>
                    </p>
                    <hr></hr>
                    <b> <h4 style={{marginLeft:'1vw'}}>&bull; Postpaid cost per wisher : </h4></b>
                    <p style={{marginLeft:'2vw'}}>
                      <span style={{color:"green", fontWeight:"bold"}}>₹15</span> per wisher&nbsp;<span style={{color:"red", fontWeight:"bold", fontSize:"20px"}}> + </span><br></br>
                      <span style={{color:"green", fontWeight:"bold"}}>3%</span> (convenience charges)&nbsp;<span style={{color:"red", fontWeight:"bold", fontSize:"20px"}}> + </span> GST<br></br>
                    </p>  
                    <hr></hr>
                    <b> <h4 style={{marginLeft:'1vw'}}>Note : </h4></b>
                    <b> <h4 style={{marginLeft:'1vw'}}>Wishers count are in multiples of 20</h4></b>
                    <p style={{marginLeft:'2vw', marginRight:'1vw', color:'green', fontFamily:'Salsa'}}>
                      For example if requirement is for 30 wishers, payment has to be made for next nearest multiple of 20 which is 40 wishers
                    </p>
                    <b> <h4 style={{marginLeft:'1vw'}}>Output files :</h4></b>
                    <p style={{marginLeft:'2vw', marginRight:'1vw', color:'green', fontFamily:'Salsa'}}>
                      Wishes will be downloaded as '.html' files embedded with pictures, audio & text content. Each '.html' file typically will have 20 wishers and hence the cost is in multiples of 20
                    </p>
                    <hr></hr>
                  </Modal>                
              }

              <Modal show={showAbout} size="xl" centered="true" onHide={() => setshowAbout(false)} style={{marginTop:'7vh'}}>
                <Modal.Header closeButton>
                      <Modal.Title>About</Modal.Title>
                    </Modal.Header>
                    <br></br>
                    <p style={{margin:'10px', color:'green', fontFamily:'Salsa'}}>
                    Welcome to Wish Collector.
                      <br></br>   <br></br>
                      A whole new exciting way to easily collect 100's of 1000's of wishes all in one place from across the globe for various events/occassions like Marriages, Engagements, New Job, New House, etc. 
                      <br></br>   <br></br>
                      A must have for collecting student Autographs and for celebrations like Graduations, Promotions, etc.
                      <br></br>   <br></br>
                      Collect 'Text' wishes, along with 'Audio' messages and also the wisher's pictures.
                      <br></br>   <br></br>
                      All wishes are private and only the event creator can see and hear. 
                      <br></br>   <br></br>
                      All anyone need is a mobile to collect wishes or to wish. 
                      <br></br>   <br></br>
                      Takes less than 5 minutes to setup an event, upload event picture like a Marriage Invitation or a picture of graduating person, etc., record a short Video invitation or upload pre-recorded video, and share the event wish link to anyone you want to invite to provide their wishes.
                      <br></br>   <br></br>
                      Watch and hear real time as wishes arrive.
                      <br></br>   <br></br>
                      Download & Carry collected wishes easily even in your mobile and re-live the moment on the go!
                      <br></br>
                    </p>
                    <hr></hr>

                    <p style={{margin:'10px', color:'green', fontFamily:'Salsa'}}>
                      <b style={{fontSize:'30px'}}>History</b>
                      <br></br>   <br></br>
                      We all like celebrating events or occasions, both personally and for someone else.
                      <br></br>   <br></br>
                      We all feel good when we receive wishes irrespective of from Family, Friends, Relatives, Colleagues, Classmates, Neighbors, sometimes from strangers and even occasionally from enemies!
                      <br></br>   <br></br>
                      We receive wishes via lots of medium like a simple phone call, a crisp SMS, a lengthy email,  via messaging platform like WhatsApp, via social media apps like Meta or Instagram or even via snail mail, flowers, chocolates, gifts and finally via greeting cards physical or digital.
                      <br></br>   <br></br>
                      We feel like we are over the moon…the feeling is priceless for each and every wish.
                      <br></br>   <br></br>
                      But it lasts for only a few days at most...
                      <br></br>
                    </p>
                    <hr></hr>
                    <p style={{margin:'10px', color:'green', fontFamily:'Salsa'}}>
                      While we try to re-live that moment by searching for the received wishes in messages, emails, picture books, albums, videos, greeting cards and snail mails, it always was and is very difficult to retrieve some if not all thus making it difficult to re-live that moment and not always possible to even trace the wishes sometimes.
                      <br></br>
                    </p>
                    <hr></hr>
                    <p style={{margin:'10px', color:'green', fontFamily:'Salsa'}}>
                      Wish collector was created exactly for this one singular purpose, to re-live that moment easily and readily for years to come.
                      <br></br> <br></br>
                      How good will that be if you can just open up few files, see all your wishers’ pictures, just tap on each of them, and out pops a window with their text wishes and also hear their wishes in their own voice.
                      <br></br> <br></br>
                      Not only that, you can store them in various devices like a Desktop, Laptop, Tablets and even carry them in your mobile so that you can re-live that moment on-the-go.
                      <br></br>
                    </p>
                    <hr></hr>
                    <p style={{margin:'10px', color:'green', fontFamily:'Salsa'}}>
                      Wish Collector was directly inspired from our other successful product, namely “Audio School Pictures” by Composz, wherein each student in a School can leave audio messages in turn for their classmates, and even Teachers and Principal can leave audio messages for each student.
                      <br></br>
                    </p>
                    <hr></hr>
                    <p style={{margin:'10px', color:'green', fontFamily:'Salsa'}}>
                      We sincerely hope that Wish Collector will be lots of fun and also of immense help in easily collecting your wishes for self or others for various events or occassions. All one needs is a mobile with internet access.
                      <br></br><br></br>
                      Good Luck.
                      <br></br><br></br>
                      Cheers,
                      <br></br>
                      --team Wish Collector.
                      <br></br>
                    </p>
                    <hr></hr>
              </Modal>

          </Navbar.Collapse>
          </div>
        </Navbar>

      );
}

export default Mynavbar
