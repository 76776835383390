import React from "react";
import "./spinner.css";

// export default function LoadingSpinner() {
  //<div className="spinner-container">
  // <div className="loading-spinner">Loading Images...</div>
  // <div className="blink-me">Loading Images...</div>
  //</div>
  export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner"><p style="background-color: #FBD603">Loading Images...</p></div>
    </div>
  );
}