import React, { Component } from "react";
import { TOGGLE_PRODUCT_EXPAND } from "../../actions";
import OrderProductSetListContainer from "../../containers/orders/OrderProductSetListContainer";
import PaymentContainer from "../../containers/orders/PaymentContainer";
import CCavenuePaymentContainer from "../../containers/orders/CCavenuePaymentContainer";
import ShareProductContainer from "../../containers/orders/ShareProductContainer";

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }
  onExpandToggle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ expand: !this.state.expand });
    // this.props.getSets(this.props.product.id);
  };

  onProductSetsToggle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ isProductSetsExpanded: !this.state.isProductSetsExpanded });
    this.props.getSets(this.props.product.id);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.product !== this.state.product ||
      (nextProps.product && nextProps.product.product_sets)
    ) {
      this.setState({ product: nextProps.product });
    }
  }

  componentDidMount() {
    // this.props.getPrice(this.props.product.id);
    // this.props.getOrders({ status: "pending" });
    // this.props.getSets(this.props.product.id);
  }

  getPaymentDetails = (order, product) => {
    if (
      (order && order.status == "paid") ||
      (product.pricing && product.pricing.price_for_files === 0)
    ) {
      return (
        <ul>
          <li>Download</li>
        </ul>
      );
    } else if (order && order.status == "pending") {
      return (
        <ul>
          <PaymentContainer
            product_id={product.id}
            pg_order_id={order.pg_order_id}
            // isRedirect={true}
          />
        </ul>
      );
    } else {
      return (
        <ul>
          <PaymentContainer
            product_id={product.id}
            // isRedirect={true}
          />
        </ul>
      );
    }
  };

  getCartDetails = (order, product) => {
    if (order && order.status == "paid" && this.isProductAddToCart(product.id, "order")) {
      return (
        <ul>
          <li>Download</li>
        </ul>
      );
    } else {
      return (
        <ul>
          <li>
            {this.isProductAddToCart(product.id, "cart") ? (
              <a
                className="no-textcolor"
                onClick={() => {
                  this.props.addToCart({ product_id: product.id, type: "remove", setno: "all" });
                }}
              >
                Remove from Cart
              </a>
            ) : (
              <a
                className="no-textcolor"
                onClick={() => {
                  this.props.addToCart({ product_id: product.id });
                }}
              >
                Add to Cart
              </a>
            )}
          </li>
        </ul>
      );
    }
  };

  isProductAddToCart = (setno) => {
    const { cart_product } = this.props;
    if (cart_product && cart_product.order_details) {
      let sets =
        (cart_product && cart_product.order_details && cart_product.order_details.sets) || {};
      if (Object.keys(sets).includes("all") || sets[setno]) return true;
      return false;
    }
    return false;
  };

  getOrderDetails(product) {
    const pricing = product.pricing;
    var costPerAudioFile=0.00;
    var audioFileduration = 0; 
    
    product.prtype!=="NORMAL"? audioFileduration = parseInt(product.prtype.split('-')[1].split('SEC')[0]) : 0;
    
    if (this.getCookie("countryCode")!=="IN") {
      costPerAudioFile = 4.00;
    }
    else { 
      if (audioFileduration===15) {
        costPerAudioFile = 127.00
      }
      if (audioFileduration===30) {
        costPerAudioFile = 200.00
      }
    }

    const order = (product.order || []).find((o) => o.status == "pending");
    if (order && order.status == "pending") {
      return (
        this.getCookie("countryCode")!=="IN"?
        <ul>
          <li>Total no. of Records: {order.num_files / product.numpages}</li>
          <li>Total no. of Image files: {order.num_files}</li>
          <li>Price per Image File: ${(order.price_first_page / 100).toFixed(2)}</li>
          <li>Total Image files amount: ${(order.total_files_amount / 100).toFixed(2)}</li>
          {product.prtype!=="NORMAL"? <li>Price for Audio Files / Record (.html): ${costPerAudioFile.toFixed(2)}</li>: null}
          {product.prtype!=="NORMAL"? <li>Total Audio files: Upto {order.num_files}</li>: null}
          {product.prtype!=="NORMAL"? <li>Total Audio files amount: ${(order.num_files / product.numpages * costPerAudioFile).toFixed(2)}</li>: null}
          <li>
            Convenience Charges: $
            {((order.total_amount - order.total_files_amount) / 100).toFixed(2)}
          </li>
          {product.prtype!=="NORMAL"? 
            <li>Grand Total Amount: ${((order.total_amount / 100) + (order.num_files / product.numpages * costPerAudioFile)).toFixed(2)}</li>
            : 
            <li>Grand Total Amount: ${(order.total_amount / 100).toFixed(2)}</li>
          } 
        </ul> :
        <ul>
          <li>Total no. of Records: {order.num_files / product.numpages}</li>
          <li>Total no. of Image files: {order.num_files}</li>
          <li>Price per Image File: ₹75.00</li>
          <li>Total Image files amount: ₹{(order.num_files * 75).toFixed(2)}</li>
          {product.prtype!=="NORMAL"? <li>Price for Audio Files / Record (.html): ${costPerAudioFile.toFixed(2)}</li>: null}
          {product.prtype!=="NORMAL"? <li>Total Audio files: Upto {order.num_files}</li>: null}
          {product.prtype!=="NORMAL"? <li>Total Audio files amount: ₹{(order.num_files / product.numpages * costPerAudioFile).toFixed(2)}</li>: null}
          <li>
            Convenience Charges: ₹{(order.num_files * 10).toFixed(2)}
          </li>
          <li>
            GST 18% : ₹
            {product.prtype!=="NORMAL"? (((order.num_files / product.numpages * costPerAudioFile) + (order.num_files * 75 + order.num_files * 10)) * 0.18).toFixed(2)
            : ((order.num_files * 75 + order.num_files * 10) * 0.18).toFixed(2)}
          </li>
          {product.prtype!=="NORMAL"? 
            <li>Grand Total Amount: ₹{Math.round(((order.num_files * 75) + (order.num_files * 10)) + (order.num_files / product.numpages * costPerAudioFile) + (((order.num_files / product.numpages * costPerAudioFile) + (order.num_files * 75 + order.num_files * 10)) * 0.18)).toFixed(2)}</li>
          : <li>Grand Total Amount: ₹{Math.round(((order.num_files * 75) + (order.num_files * 10)) + (order.num_files * 75 + order.num_files * 10) * 0.18).toFixed(2)}</li>}
        </ul>
      
      );
    }
    // if (order && order.status === "paid") {
    //   return (
    //     <ul>
    //       <li>No. of files: {order.num_files}</li>
    //       <li>Total Amount: {order.total_amount}</li>
    //     </ul>
    //   );
    // } else if (pricing) {
    //   return (
    //     <ul>
    //       <li>{pricing && pricing.pages_count && pricing.pages_count.total} Files</li>
    //       <li>Total Amount: {pricing.price_total}</li>
    //     </ul>
    //   );
    // }
  }

  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  countryCode = this.getCookie("countryCode");

  render() {
    const product = this.state.product;
    const expand = this.state.expand;
    const order = this.state.product.order;
    const product_sets = this.props.product_sets;
    const cart_product = this.state.cart_product;
    const sets =
      (cart_product && cart_product.order_details && cart_product.order_details.sets) || {};
    const readOnly = (
      <a className="no-textcolor" onClick={this.onExpandToggle}>
        {this.getExpandJSX(expand)} {product.name}
      </a>
    );
    // if (product_sets && product_sets.length) {
    //   return null;
    // }

    let productSets = null;
    if (expand == true) {
      productSets = (
        <React.Fragment>
          <li>
            <a className="no-textcolor" onClick={this.onProductSetsToggle}>
              {this.getExpandJSX(this.state.isProductSetsExpanded)} Product Sets
            </a>
            {this.state.isProductSetsExpanded &&
              product_sets.length &&
              product_sets.map((ps) => {
                let t = [];
                if (this.isProductAddToCart(ps.setno)) {
                  const { cart_product } = this.props;
                  let sets = [];
                  let counts = (ps.counts || []).find((f) => f.dlname == product.main_dlname);
                  if (cart_product && cart_product.order_details) {
                    sets =
                      (cart_product &&
                        cart_product.order_details &&
                        cart_product.order_details.sets) ||
                      {};

                    let isAllRecords =
                      Object.keys(sets).includes("all") ||
                      (sets[ps.setno] &&
                        (Object.keys(sets[ps.setno]).includes("all") ||
                          Object.keys(sets[ps.setno]).length ==
                            ((counts && counts.main_occurence_count) || 0)));

                    let keys = Object.keys(sets[ps.setno] || {});
                    for (let i = 0; i < keys.length; i++) {
                      t.push(<li>Record no {parseInt(keys[i]) + 1}</li>);
                    }
                    // if (isAllRecords && counts.main_occurence_count) {
                    //   for (let index = 1; index <= counts.main_occurence_count; index++) {
                    //     t.push(<li>Record no {index}</li>);
                    //   }
                    // } else {
                    //   // Object.keys(sets[ps.setno]).forEach((recordNo) => {
                    //   //   t.push(<li>Record no {recordNo}</li>);
                    //   // });
                    //   let keys = Object.keys(sets[ps.setno] || {});
                    //   for (let i = 0; i < keys.length; i++) {
                    //     t.push(<li>Record no {parseInt(keys[i]) + 1}</li>);
                    //   }
                    // }
                  }
                  return (
                    <ul>
                      <li
                        className="no-textcolor"
                        onClick={() => {
                          this.setState({
                            [`p_set_${ps.setno}`]: !(this.state[`p_set_${ps.setno}`] || false),
                          });
                        }}
                      >
                        {this.getExpandJSX(this.state[`p_set_${ps.setno}`] || false)}
                        {" " + ps.desc}
                      </li>
                      {this.state[`p_set_${ps.setno}`] && <ul>{t}</ul>}
                      {/* <ul>{t}</ul> */}
                    </ul>
                  );
                }
              })}
            {/* <OrderProductSetListContainer product_id={product.id} order_status={order.status} /> */}
          </li>
          {cart_product.pg_order_id && (
            <li>
              {this.countryCode!=="IN"?
                <PaymentContainer
                  product_id={cart.product.id}
                  pg_order_id={cart.pg_order_id || null}
                  prtype = {cart_product.product.prtype}
                  numpages = {cart_product.product.numpages}
                  // isRedirect={true}
                /> :
                <CCavenuePaymentContainer
                  product_id={cart.product.id}
                  pg_order_id={cart.pg_order_id || null}
                  no_of_files = {cart_product.num_files}
                  prtype = {cart_product.product.prtype}
                  numpages = {cart_product.product.numpages}
                  // isRedirect={true}
                />
              }
            </li>
          )}
          <li>{this.getOrderDetails(this.state.product)}</li>
          {!cart_product.pg_order_id && (
            <li>
              {this.countryCode!=="IN"?
                <PaymentContainer
                  product_id={cart_product.product.id}
                  prtype = {cart_product.product.prtype}
                  numpages = {cart_product.product.numpages}
                  // isRedirect={true}
                /> :
                <CCavenuePaymentContainer
                  product_id={cart_product.product.id}
                  no_of_files = {cart_product.num_files}
                  prtype = {cart_product.product.prtype}
                  numpages = {cart_product.product.numpages}
                  // isRedirect={true}
                />
              }
            </li>
          )}
        </React.Fragment>
      );
    }
    if (cart_product) {
      return (
        <React.Fragment>
          <li>{readOnly}</li>
          <ul>{productSets}</ul>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export default Order;
