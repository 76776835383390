import React, { Component } from "react";
import { FormGroup, ControlLabel, FormControl, Modal } from "react-bootstrap";
import ImageEditor from "@toast-ui/react-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";

// import { FieldGroup } from "./bootstrap/Forms.FieldGroup";
import { formDataToJSON } from "../adukku_utils/array";
import { ENDPOINTS } from "../constants/endpoints";
import EditProductImage from "./EditProductImage";

let inputWidth = null;
let inputHeight = null;
let $inputCheckInvert = null;
let $inputCheckSepia = null;
let $inputCheckSepia2 = null;
let $inputCheckBlur = null;
let $inputCheckSharpen = null;
let $inputCheckEmboss = null;
let $inputCheckGrayscale = null;
let $inputCheckRemoveWhite = null;
let $inputCheckBrightness = null;
let $inputCheckNoise = null;
let $inputCheckColorFilter = null;
let $inputSlideRemoveWhite = null;
let $inputSlideBrightness = null;
let $inputSlideNoise = null;
let $inputSlideColorFilter = null;

export default class EditProductData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDataId: null,
      isCurrentSaved: false,
      isFileName: null,
      editedFiles: {},
      page: 1,
      perPage: 10,
      showImageEditor: null,
      imageEditorOptions: {
        includeUI: {
          initMenu: "mask",
          menuBarPosition: "bottom",
          menu: ["crop", "flip", "rotate", "text", "draw", "mask"],
        },
        cssMaxWidth: 700,
        cssMaxHeight: 500,
        selectionStyle: {
          cornerSize: 20,
          rotatingPointOffset: 70,
        },
        usageStatistics: false,
      },
      searchData: {},
      ...props,
    };
  }
  editorRef = React.createRef();

  _handleValidSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let formData = formDataToJSON(new FormData(e.target));
    this.setState({ show: false });
  };

  getUploadedData = (isSearch) => {
    if (this.state.dlname == "DL0") {
      this.props.getProducts();
    }
    this.props.getUploadedData({
      isSearch: isSearch,
      searchData: this.state.searchData,
      product_id: this.state.product_id,
      dlname: this.state.dlname,
      page: this.state.page,
      per_page: this.state.perPage,
    });
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    // i indicates always in KB.
    const i = 1; //Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  };

  checkAvailSpace = async (fileSize, avail_space) => {
    let uri = `${ENDPOINTS.files.get.uri}?file_size=${fileSize}`;
    return await fetch(uri, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.data == "available") {
          return true;
        } else {
          return false;
        }
      });
  };

  UNSAFE_componentWillReceiveProps(ownProps) {
    if (ownProps.uploaded_data != this.state.uploaded_data) {
      let mapped_uploaded_data = {};
      if (ownProps.uploaded_data && ownProps.uploaded_data.uploaded_data) {
        ownProps.uploaded_data.uploaded_data.map((data) => {
          if (data.data) {
            mapped_uploaded_data[data.id] = data.data;
          }
        });
        this.setState({
          uploaded_data: ownProps.uploaded_data,
          mapped_uploaded_data: mapped_uploaded_data,
          product_keys: ownProps.product_keys,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.currentDataId &&
      prevState.currentDataId &&
      this.state.currentDataId !== prevState.currentDataId &&
      !this.state.currentDataId.toString().includes("add_new_")
    ) {
      if (confirm("Do you want to save the previous data?")) {
        this.handleSaveUploadData(prevState.currentDataId);
      } else {
        let { uploaded_data, mapped_uploaded_data } = this.state;
        uploaded_data.uploaded_data.map((data) => {
          if (data.data && data.id === prevState.currentDataId) {
            mapped_uploaded_data[data.id] = data.data;
          }
        });
        this.setState({
          mapped_uploaded_data: mapped_uploaded_data,
        });
      }
    }

    if (
      this.state.page &&
      this.state.perPage &&
      (this.state.page != prevState.page || this.state.perPage != prevState.perPage)
    ) {
      let isSearch = Object.keys(this.state.searchData || {}).length > 0;
      this.props.getUploadedData({
        isSearch: isSearch,
        searchData: this.state.searchData,
        product_id: this.state.product_id,
        dlname: this.state.dlname,
        page: this.state.page,
        per_page: this.state.perPage,
      });
    }
  }

  handleSaveUploadData = (id, isEdited = false) => {
    if (!id) return;
    let { mapped_uploaded_data, uploaded_data } = this.state;
    let that = this;
    let formData = new FormData();
    mapped_uploaded_data &&
      mapped_uploaded_data[id] &&
      Object.keys(mapped_uploaded_data[id]).forEach((key) => {
        formData.append(key, mapped_uploaded_data[id][key]);
        console.log(`${key}: ${mapped_uploaded_data[id][key]}`)
      });
    if (id.toString().startsWith("add_new_")) {
      let url = ENDPOINTS.csv.create.uri;
      url = url.replace(":product_id", this.state.product_id);
      url = url.replace(":dlname", this.state.dlname);
      console.log("csv_create_url: ", url)
      
      
      let ajaxCall = fetch(url, {
        method: ENDPOINTS.csv.create.method,
        body: formData,
      });
      return ajaxCall
        .then((response) => response.json())
        .then((response) => {
          if (response.errors) {
            alert(response.errors);
          } else {
            mapped_uploaded_data[response.data.id] = response.data.data;
            uploaded_data.uploaded_data = uploaded_data.uploaded_data.map((dt) => {
              if (dt.id == id) return response.data;
              else return dt;
            });
            that.setState({
              mapped_uploaded_data: mapped_uploaded_data,
              uploaded_data: uploaded_data,
              isFileName: null,
            });
            this.getUploadedData();
            alert("Saved Successfully");
          }
        })
        .catch((error) => {
          throw error;
        });
    } else {
      this.props.updateUploadedData({
        id: id,
        product_id: this.state.product_id,
        dlname: this.state.dlname,
        data: formData,
      });
      // this.getUploadedData();
      if (isEdited) {
        alert("Successfully, uploaded file");
      }
    }
    this.setState({
      currentDataId: null,
      isFileName: null,
      page: 1,
      perPage: 10,
    });
  };

  handleAddNewData = (id) => {
    const { mapped_uploaded_data, uploaded_data } = this.state;
    // let newUploadedData = uploaded_data;
    // let formData = new FormData();
    let that = this;
    let newRow = {};
    if (((uploaded_data && uploaded_data.uploaded_data) || []).length == 0) {
      newRow.data = {};
      let data = {};
      uploaded_data.datalevel_attributes.forEach((att) => {
        data[att.name] = null;
      });
      newRow.data = data;
    } else {
      newRow = JSON.parse(
        JSON.stringify(
          uploaded_data &&
            uploaded_data.uploaded_data &&
            uploaded_data.uploaded_data[uploaded_data.uploaded_data.length - 1]
        )
      );
    }
    newRow.id = `add_new_${uploaded_data.uploaded_data.length}`;
    newRow.data &&
      Object.keys(newRow.data).length > 0 &&
      Object.keys(newRow.data).forEach((attribute) => {
        newRow.data[attribute] = null;
      });
    mapped_uploaded_data[newRow.id] = newRow.data;
    uploaded_data.uploaded_data = [newRow, ...uploaded_data.uploaded_data];
    if (Object.keys(newRow.data).length > 0) {
      this.setState({
        uploaded_data: uploaded_data,
        mapped_uploaded_data: mapped_uploaded_data,
      });
    } else {
      alert("No datalevels attributes added");
    }
  };

  handleDeleteUploadData = (id) => {
    if (!id) return;
    if (confirm("Are you are sure, you want to delete?")) {
      const { mapped_uploaded_data, uploaded_data } = this.state;
      let newUploadedData = uploaded_data;
      let formData = new FormData();
      let that = this;
      Object.keys(mapped_uploaded_data[id]).forEach((key) => {
        formData.append(key, mapped_uploaded_data[id][key]);
      });
      newUploadedData.uploaded_data =
        (uploaded_data &&
          uploaded_data.uploaded_data &&
          uploaded_data.uploaded_data.filter((data) => data.id !== id)) ||
        [];
      let url = ENDPOINTS.csv.delete.uri;
      url = url.replace(":product_id", this.state.product_id);
      url = url.replace(":dlname", this.state.dlname);
      url = url.replace(":data_upload_id", id);
      if (id.toString().startsWith("add_new_")) {
        that.setState({
          uploaded_data: newUploadedData,
        });
      } else {
        let ajaxCall = fetch(url, {
          method: ENDPOINTS.csv.delete.method,
        });
        return ajaxCall
          .then((response) => {
            that.setState({
              uploaded_data: newUploadedData,
            });
          })
          .catch((error) => {
            throw error;
          });
      }
    }
  };

  handleImageEdit = (val) => {
    if (!val) {
      this.setState({ showImageEditor: null });
      document.querySelector("#portal_image_editor").classList.remove("show_editor");
    } else {
      if (
        (typeof val.data.data[val.attribute.name] === "string" &&
          val.data.data[val.attribute.name].split(".").pop() !== "svg") ||
        (typeof val.data.data[val.attribute.name] === "object" &&
          val.data.data[val.attribute.name].name.split(".").pop() !== "svg")
      ) {
        document.querySelector("#portal_image_editor").classList.add("show_editor");
        this.setState({ showImageEditor: val });
        document.querySelector(".modal").removeAttribute("tabindex");
        setTimeout(() => {
          this.initializeEditor();
        }, 100);
      } else {
        alert("Can't edit the svg images");
      }
    }
  };

  initializeEditor = () => {
    let refInstance = this.editorRef.current.getInstance();
    // Changing buttons
    $(".tui-image-editor-header-logo").replaceWith("");
    $(".tui-image-editor-header-buttons").replaceWith(
      `<div class="tui-image-editor-header-buttons">
        <button class="tui-image-editor-close-btn">Close</button>
        <button class="tui-image-editor-save-btn">Save</button>
        <button class="tui-image-editor-save-as-btn">Save As</button>
        <section class="save-as-container">
          <input class="save-as-input" type="text" placeholder="File name" />
          <button class="submit_save_as_file">Submit</button>
        </section>
      </div>`
    );

    refInstance.loadImageFromURL(this.state.showImageEditor.image, "Adukku image").then(() => {
      refInstance.ui.activeMenuEvent();

      $(".image_editor_file_name").remove();
      $(".tui-image-editor-header").append(
        `<p class="image_editor_file_name">${
          this.state.showImageEditor.image.split("/").pop().split("?")[0]
        }</p>`
      );

      if (!$(".preset-fixed").html()) {
        const fixedCrop = `<div class="tui-image-editor-button preset preset-fixed">
            <div>
              <div class="crop_icon"></div>
            </div>
            <label>Fixed</label>
          </div>`;
        $(".tie-crop-preset-button").append(fixedCrop);
      }

      if (!$(".tie-btn-filter").html()) {
        const filterBtn = `<li tooltip-content="Filter" class="filter-btn tie-btn-filter tui-image-editor-item normal">
          <img alt="Filter" class="filter_filter filter_image" src="icons/filter.png" />
        </li>
        `;
        $(".tui-image-editor-menu").prepend(filterBtn);
        $(".tie-btn-filter").bind("click", this.onFilterSelect);
      }
      document.querySelector(".save-as-container").style.display = "none";
      this.bindActions();
    });

    refInstance.on("objectScaled", () => {
      if (inputWidth && inputHeight) {
        this.customImageSizing();
      }
    });
  };

  bindActions = () => {
    $(".tie-btn-crop").bind("mousedown", this.onCropSelect);
    $(".tie-btn-flip").bind("mousedown", this.onFlipSelect);
    $(".tie-btn-text").bind("mousedown", this.onTextSelect);
    $(".tie-btn-rotate").bind("mousedown", this.onRotateSelect);
    $(".tie-btn-draw").bind("mousedown", this.onDrawSelect);
    $(".tie-btn-mask").bind("mousedown", this.onMaskSelect);
    $(".tui-image-editor-close-btn").bind("click", this.onClose);
    $(".tui-image-editor-save-btn").bind("click", this.onSave);
    $(".tui-image-editor-save-as-btn").bind("click", this.onSaveas);
    $(".submit_save_as_file").bind("click", this.onSubmitFile);
    $(".preset-fixed").bind("click", this.onClickFix);
    $(".preset-none").bind("click", this.onClickCustom);
    $(".preset-square").bind("click", this.onClickSq);
    $(".preset-3-2").bind("click", this.onClick32);
    $(".preset-4-3").bind("click", this.onClick43);
    $(".preset-5-4").bind("click", this.onClick54);
    $(".preset-7-5").bind("click", this.onClick75);
    $(".preset-16-9").bind("click", this.onClick169);
  };

  unBindActions = () => {
    $(".tie-btn-crop").unbind("mousedown", this.onCropSelect);
    $(".tie-btn-flip").unbind("mousedown", this.onFlipSelect);
    $(".tie-btn-text").unbind("mousedown", this.onTextSelect);
    $(".tie-btn-rotate").unbind("mousedown", this.onRotateSelect);
    $(".tie-btn-draw").unbind("mousedown", this.onDrawSelect);
    $(".tie-btn-mask").unbind("mousedown", this.onMaskSelect);
    $(".tui-image-editor-close-btn").unbind("click", this.onClose);
    $(".tui-image-editor-save-btn").unbind("click", this.onSave);
    $(".tui-image-editor-save-as-btn").unbind("click", this.onSaveas);
    $(".submit_save_as_file").unbind("click", this.onSubmitFile);
    $(".preset-fixed").unbind("click", this.onClickFix);
    $(".preset-none").unbind("click", this.onClickCustom);
    $(".preset-square").unbind("click", this.onClickSq);
    $(".preset-3-2").unbind("click", this.onClick32);
    $(".preset-4-3").unbind("click", this.onClick43);
    $(".preset-5-4").unbind("click", this.onClick54);
    $(".preset-7-5").unbind("click", this.onClick75);
    $(".preset-16-9").unbind("click", this.onClick169);
  };

  addCropFields = () => {
    const customCrop = `<div id="crop_fields" class="custom-crop-container">
      <div class="custom-crop-width">
        <label> Width: </label>
        <input id="tui_width" type="number" class="custom-crop-input" value="0" />
      </div>
      <div class="custom-crop-height">
        <label> Height: </label>
        <input id="tui_heigt" type="number" class="custom-crop-input" value="0" />
      </div>
    </div>`;
    if (!$("#crop_fields").html()) {
      $(".tie-crop-preset-button").append(customCrop);
      inputWidth = document.querySelector("#tui_width");
      inputHeight = document.querySelector("#tui_heigt");
      inputWidth.oninput = this.updatingWidth;
      inputHeight.oninput = this.updatingHeight;
    }
  };

  onSaveas = () => {
    document.querySelector(".save-as-container").style.display = "flex";
  };

  removeCropFields = () => {
    if ($(".custom-crop-container").html()) {
      $(".custom-crop-container").remove();
    }
  };

  onCropSelect = () => {
    $(".tie-btn-filter").removeClass("active");
    $(".filter_image").addClass("filter_filter");
    this.removeCropFields();
    this.removeFilters();
    // setTimeout(() => {
    //   let refInstance = this.editorRef.current.getInstance();
    //   refInstance.setCropzoneRect(1);
    // }, 500);
  };

  onFlipSelect = () => {
    $(".tie-btn-filter").removeClass("active");
    $(".filter_image").addClass("filter_filter");
    this.removeCropFields();
    this.removeFilters();
  };

  onTextSelect = () => {
    $(".tie-btn-filter").removeClass("active");
    $(".filter_image").addClass("filter_filter");
    this.removeCropFields();
    this.removeFilters();
  };

  onRotateSelect = () => {
    $(".tie-btn-filter").removeClass("active");
    $(".filter_image").addClass("filter_filter");
    this.removeCropFields();
    this.removeFilters();
  };

  onFilterSelect = () => {
    this.removeCropFields();
    if ($(".tie-btn-mask").hasClass("active")) {
      $(".tie-btn-mask").trigger("click");
    }
    if ($(".tie-btn-draw").hasClass("active")) {
      $(".tie-btn-draw").trigger("click");
    }
    if ($(".tie-btn-text").hasClass("active")) {
      $(".tie-btn-text").trigger("click");
    }
    if ($(".tie-btn-rotate").hasClass("active")) {
      $(".tie-btn-rotate").trigger("click");
    }
    if ($(".tie-btn-flip").hasClass("active")) {
      $(".tie-btn-flip").trigger("click");
    }
    if ($(".tie-btn-crop").hasClass("active")) {
      $(".tie-btn-crop").trigger("click");
    }
    $(".tui-image-editor-item").removeClass("active");
    $(".tie-btn-filter").addClass("active");
    $(".filter_image").removeClass("filter_filter");
    this.addFilterFields();
    // this.removeNotWorkingFilters();
  };

  onDrawSelect = () => {
    $(".tie-btn-filter").removeClass("active");
    $(".filter_image").addClass("filter_filter");
    this.removeCropFields();
    this.removeFilters();
  };

  onMaskSelect = () => {
    $(".tie-btn-filter").removeClass("active");
    $(".filter_image").addClass("filter_filter");
    this.removeCropFields();
    this.removeFilters();
  };

  onClickFix = () => {
    this.addCropFields();
    setTimeout(() => {
      let refInstance = this.editorRef.current.getInstance();
      refInstance.setCropzoneRect(1);
      this.initializeSizeCal();
    }, 500);
  };

  onClickCustom = () => {
    // setTimeout(() => {
    //   let refInstance = this.editorRef.current.getInstance();
    //   refInstance.setCropzoneRect(1);
    // }, 500);
    this.removeCropFields();
  };

  onClickSq = () => {
    this.removeCropFields();
  };

  onClick32 = () => {
    this.removeCropFields();
  };

  onClick43 = () => {
    this.removeCropFields();
  };

  onClick54 = () => {
    this.removeCropFields();
  };

  onClick75 = () => {
    this.removeCropFields();
  };

  onClick169 = () => {
    this.removeCropFields();
  };

  addFilterFields = () => {
    if (!$(".tui-image-editor-menu-filter").html()) {
      $(".tui-image-editor-submenu").css({ display: "table" });
      const filFields = `
        <div class="tui-image-editor-menu-filter" style="display: table-cell !important">
          <ul class="tui-image-editor-submenu-item">
            <li>
              <div class="checkbox_filters">
                <label>
                  <input class="gray_check" type="checkbox" value="Grayscale">
                  <span>Grayscale</span>
                </label>
                <label>
                  <input class="sepia_check" type="checkbox" value="Sepia">
                  <span>Sepia</span>
                </label>
                <label>
                  <input class="invert_check" type="checkbox" value="Invert">
                  <span>Invert</span>
                </label>
                <label>
                  <input class="sepia2_check" type="checkbox" value="Sepia2">
                  <span>Sepia2</span>
                </label>
                <label>
                  <input class="blur_check" type="checkbox" value="Blur">
                  <span>Blur</span>
                </label>
                <label>
                  <input class="sharpen_check" type="checkbox" value="Sharpen">
                  <span>Sharpen</span>
                </label>
                <label>
                  <input class="emboss_check" type="checkbox" value="Emboss">
                  <span>Emboss</span>
                </label>
              </div>
            </li>
            <li>
              <div class="slide_filter">
                <div class="each_label two_labels">
                  <label>
                    <input class="remove_white_check" type="checkbox" >
                    <span>Remove White</span>
                  </label>
                  <div class="range_slider">
                    <span>Distance</span>
                    <input type="range" class="disabled" min="0" max="100" value="0" id="remove_white_check">
                  </div>
                </div>
                <div class="each_label">
                  <label>
                    <input class="brightness_check" type="checkbox" >
                    <span>Brightness</span>
                  </label>
                  <div class="range_slider">
                    <input type="range" class="disabled" min="0" max="100" value="0" id="brightness_check">
                  </div>
                </div>
                <div class="each_label two_labels">
                  <label>
                    <input class="color_check" type="checkbox" >
                    <span>Color Filter</span>
                  </label>
                  <div class="range_slider">
                    <span>Threshold</span>
                    <input type="range" class="disabled" min="0" max="100" value="0" id="color_check">
                  </div>
                </div>
                <div class="each_label">
                  <label>
                    <input class="noise_check" type="checkbox" >
                    <span>Noise</span>
                  </label>
                  <div class="range_slider">
                    <input type="range" class="disabled" min="0" max="100" value="0" id="noise_check">
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
    `;
      $(".tui-image-editor-submenu").append(filFields);
      const imageRef = this.editorRef.current.getInstance();
      $inputCheckBlur = $(".blur_check");
      $inputCheckEmboss = $(".emboss_check");
      $inputCheckInvert = $(".invert_check");
      $inputCheckSepia = $(".sepia_check");
      $inputCheckSepia2 = $(".sepia2_check");
      $inputCheckSharpen = $(".sharpen_check");
      $inputCheckGrayscale = $(".gray_check");
      $inputCheckRemoveWhite = $(".remove_white_check");
      $inputCheckBrightness = $(".brightness_check");
      $inputCheckNoise = $(".noise_check");
      $inputCheckColorFilter = $(".color_check");
      $inputSlideRemoveWhite = $("#remove_white_check");
      $inputSlideBrightness = $("#brightness_check");
      $inputSlideNoise = $("#noise_check");
      $inputSlideColorFilter = $("#color_check");

      $inputCheckBlur.on("change", (e) => {
        if (e.target.checked) {
          imageRef.applyFilter("Blur", { blur: 0.1 });
        } else {
          imageRef.removeFilter("Blur");
        }
      });
      $inputCheckEmboss.on("change", (e) => {
        if (e.target.checked) {
          imageRef.applyFilter("Emboss");
        } else {
          imageRef.removeFilter("Emboss");
        }
      });
      $inputCheckInvert.on("change", (e) => {
        if (e.target.checked) {
          imageRef.applyFilter("Invert");
        } else {
          imageRef.removeFilter("Invert");
        }
      });
      $inputCheckSepia.on("change", (e) => {
        if (e.target.checked) {
          imageRef.applyFilter("Sepia");
        } else {
          imageRef.removeFilter("Sepia");
        }
      });
      $inputCheckSepia2.on("change", (e) => {
        if (e.target.checked) {
          imageRef.applyFilter("vintage");
        } else {
          imageRef.removeFilter("vintage");
        }
      });
      $inputCheckSharpen.on("change", (e) => {
        if (e.target.checked) {
          imageRef.applyFilter("Sharpen");
        } else {
          imageRef.removeFilter("Sharpen");
        }
      });
      $inputCheckGrayscale.on("change", (e) => {
        if (e.target.checked) {
          imageRef.applyFilter("Grayscale");
        } else {
          imageRef.removeFilter("Grayscale");
        }
      });
      $inputCheckRemoveWhite.on("change", (e) => {
        if (e.target.checked) {
          $("#remove_white_check").removeClass("disabled");
          imageRef.applyFilter("removeColor", {
            color: "#FFFFFF",
            useAlpha: false,
            distance: parseInt($inputSlideRemoveWhite.val(), 10) / 255,
          });
        } else {
          $("#remove_white_check").addClass("disabled");
          imageRef.removeFilter("removeColor");
        }
      });
      $inputCheckBrightness.on("change", (e) => {
        if (e.target.checked) {
          $("#brightness_check").removeClass("disabled");
          imageRef.applyFilter("brightness", {
            brightness: parseInt($inputSlideBrightness.val(), 10) / 255,
          });
        } else {
          $("#brightness_check").addClass("disabled");
          imageRef.removeFilter("brightness");
        }
      });
      $inputCheckNoise.on("change", (e) => {
        if (e.target.checked) {
          $("#noise_check").removeClass("disabled");
          imageRef.applyFilter("noise", {
            noise: parseInt($inputSlideNoise.val(), 10),
          });
        } else {
          $("#noise_check").addClass("disabled");
          imageRef.removeFilter("noise");
        }
      });
      $inputCheckColorFilter.on("change", (e) => {
        if (e.target.checked) {
          $("#color_check").removeClass("disabled");
          imageRef.applyFilter("removeColor", {
            color: "#FFFFFF",
            distance: $inputSlideColorFilter.val() / 255,
          });
        } else {
          $("#color_check").addClass("disabled");
          imageRef.removeFilter("removeColor");
        }
      });
      $inputSlideRemoveWhite.on("input", (e) => {
        imageRef.applyFilter("removeColor", {
          color: "#FFFFFF",
          useAlpha: false,
          distance: parseInt(e.target.value, 10) / 255,
        });
      });
      $inputSlideBrightness.on("input", (e) => {
        imageRef.applyFilter("brightness", {
          brightness: parseInt(e.target.value, 10) / 255,
        });
      });
      $inputSlideNoise.on("input", (e) => {
        imageRef.applyFilter("noise", {
          noise: parseInt(e.target.value, 10),
        });
      });
      $inputSlideColorFilter.on("input", (e) => {
        imageRef.applyFilter("removeColor", {
          color: "#FFFFFF",
          distance: e.target.value / 255,
        });
      });
    }
  };

  removeFilters = () => {
    if ($(".tui-image-editor-menu-filter").html()) {
      $(".tui-image-editor-menu-filter").remove();
    }
  };

  initializeSizeCal = () => {
    document.querySelector(".preset-fixed").classList.add("active");
    this.customImageSizing();
  };

  updatingWidth = (e) => {
    if (parseInt(e.data) >= 0 && parseInt(e.data) <= 9) {
      inputWidth.value = e.target.value;
      const imageRef = this.editorRef.current.getInstance();
      imageRef.setCropzoneRect(inputWidth.value / inputHeight.value);
    }
  };

  updatingHeight = (e) => {
    if (parseInt(e.data) >= 0 && parseInt(e.data) <= 9) {
      inputHeight.value = e.target.value;
      const imageRef = this.editorRef.current.getInstance();
      imageRef.setCropzoneRect(inputWidth.value / inputHeight.value);
    }
  };

  onClose = () => {
    this.unBindActions();
    this.removeCropFields();
    $(".preset-fixed").remove();
    $(".image_editor_file_name").remove();
    this.handleImageEdit(null, null);
  };

  onSave = async () => {
    const imageRef = this.editorRef.current.getInstance();
    let filename = this.state.showImageEditor.data.data[this.state.showImageEditor.attribute.name];
    if (typeof filename === "object") {
      filename = filename.name;
    }
    const extension = filename.split(".").pop();
    const metadata = `image/${extension}`;
    const dataURL = imageRef.toDataURL(metadata, 1);
    let file = await this.dataURLtoFile(dataURL, filename, metadata);
    this.submitImage(file, this.state.showImageEditor);
  };

  dataURLtoFile = async (dataurl, filename, metadata) => {
    let response = await fetch(dataurl);
    let data = await response.blob();
    let bgImgFile = new File([data], filename, { type: metadata });
    return bgImgFile;
  };

  addNewDataLevel = () => {
    return new Promise((resolve, reject) => {
      const { mapped_uploaded_data, uploaded_data, showImageEditor } = this.state;
      // let newUploadedData = uploaded_data;
      // let formData = new FormData();
      let that = this;
      let newRow = {};
      if (((uploaded_data && uploaded_data.uploaded_data) || []).length == 0) {
        newRow.data = {};
        let data = {};
        uploaded_data.datalevel_attributes.forEach((att) => {
          data[att.name] = null;
        });
        newRow.data = data;
      } else {
        newRow = JSON.parse(
          JSON.stringify(
            uploaded_data &&
              uploaded_data.uploaded_data &&
              uploaded_data.uploaded_data[uploaded_data.uploaded_data.length - 1]
          )
        );
      }
      newRow.id = `add_new_${uploaded_data.uploaded_data.length}`;
      newRow.data &&
        Object.keys(newRow.data).length > 0 &&
        Object.keys(newRow.data).forEach((attribute) => {
          newRow.data[attribute] = showImageEditor.data.data[attribute];
        });
      mapped_uploaded_data[newRow.id] = newRow.data;
      uploaded_data.uploaded_data = [newRow, ...uploaded_data.uploaded_data];
      if (Object.keys(newRow.data).length > 0) {
        this.setState(
          {
            uploaded_data: uploaded_data,
            mapped_uploaded_data: mapped_uploaded_data,
          },
          () => {
            resolve(newRow.id);
          }
        );
      } else {
        alert("No datalevels attributes added");
        reject(false);
      }
    });
  };

  onSubmitFile = async () => {
    let val = await this.addNewDataLevel();
    if (val != false) {
      const { mapped_uploaded_data, showImageEditor } = this.state;
      let imageName = $(".save-as-input").val();
      const imageRef = this.editorRef.current.getInstance();
      let filename = showImageEditor.data.data[showImageEditor.attribute.name];
      if (typeof filename === "object") {
        filename = filename.name;
      }
      const extension = filename.split(".").pop();
      const metadata = `image/${extension}`;
      const dataURL = imageRef.toDataURL(metadata, 1);
      let file = await this.dataURLtoFile(dataURL, `${imageName}.${extension}`, metadata);
      if (await this.checkAvailSpace(file.size, 0)) {
        mapped_uploaded_data[val][showImageEditor.attribute.name] = file;
        this.setImageFromFile(file, val);
        this.setState(
          {
            mapped_uploaded_data: mapped_uploaded_data,
            currentDataId: val,
          },
          () => {
            this.handleSaveUploadData(val, true);
            this.onClose();
          }
        );
      } else {
        alert("Storage limit exceeded");
      }
    }
  };

  customImageSizing = () => {
    const imageRef = this.editorRef.current.getInstance();
    let imageMode = imageRef.getDrawingMode();
    if (imageMode === "CROPPER") {
      let imgCrop = imageRef.getCropzoneRect();
      if (imgCrop.width == 0.5 && imgCrop.height == 0.5) {
        let imageCanvs = imageRef.getCanvasSize();
        inputWidth.value = imageCanvs.width.toFixed(2);
        inputHeight.value = imageCanvs.height.toFixed(2);
      } else {
        inputWidth.value = imgCrop.width.toFixed(2);
        inputHeight.value = imgCrop.height.toFixed(2);
      }
    }
  };

  submitImage = (file, showImageEditor) => {
    const { mapped_uploaded_data } = this.state;
    mapped_uploaded_data[showImageEditor.data.id][showImageEditor.attribute.name] = file;
    this.setImageFromFile(file, showImageEditor.data.id);
    this.setState(
      {
        mapped_uploaded_data: mapped_uploaded_data,
        currentDataId: showImageEditor.data.id,
      },
      () => {
        this.handleSaveUploadData(showImageEditor.data.id, true);
        this.onClose();
      }
    );
  };

  setImageFromFile = (rawFile, dataID) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      let dataURL = e.target.result;
      let newEditedFiles = this.state.editedFiles;
      newEditedFiles[dataID] = dataURL;
      this.setState({ editedFiles: newEditedFiles });
    };
    reader.readAsDataURL(rawFile);
  };

  render() {
    const { uploaded_data, mapped_uploaded_data, isFileName, editedFiles, dlname } = this.state;
    return (
      <React.Fragment>
        <div
          title="Edit Data"
          variant="primary"
          onClick={() => {
            this.setState({ show: true });
            this.getUploadedData();
          }}
          style={{ marginLeft: "10px", marginRight: "8px", cursor: "pointer" }}
        >
          <img src="icons/edit.png" style={{ width: "30px" }} />
        </div>
        {this.state.showImageEditor && (
          <EditProductImage portalClass="portal_image_editor">
            <ImageEditor ref={this.editorRef} {...this.state.imageEditorOptions} />
          </EditProductImage>
        )}
        <Modal
          show={this.state.show}
          dialogClassName="modal-90w"
          centered="true"
          onHide={() => this.setState({ show: false })}
        >
          <Modal.Header closeButton className="filemanager_header">
            <Modal.Title>
              <div className="product_data_header">
                <div className="product_data_heading">
                  Edit Data: <span className="dlname">{dlname}</span>
                </div>
                <div className="product_data_info">
                  <ControlLabel>
                    Pages: {uploaded_data && uploaded_data.meta && uploaded_data.meta.total_pages}
                  </ControlLabel>
                  <ControlLabel>
                    Rows: {uploaded_data && uploaded_data.meta && uploaded_data.meta.total_count}
                  </ControlLabel>
                  <FormGroup controlId="per_page">
                    <ControlLabel>Per page:</ControlLabel>
                    <FormControl
                      componentClass="select"
                      required="required"
                      value={this.state.perPage}
                      // name={"lyslotfor[dlname]"}
                      onChange={({ target: { name, value } }) => {
                        this.setState({ perPage: value });
                      }}
                      className="product_data_control"
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="25">25 </option>
                    </FormControl>
                  </FormGroup>
                  <img
                    src="/icons/icon_head.png"
                    data-toggle="tooltip"
                    title="Go to Previous page"
                    alt="Prev"
                    className="product_page_prev"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setState({ page: uploaded_data.meta.prev_page });
                    }}
                  />
                  <FormGroup controlId="page">
                    <ControlLabel>Page:</ControlLabel>
                    <FormControl
                      componentClass="input"
                      required="required"
                      value={this.state.page}
                      // name={"lyslotfor[dlname]"}
                      onChange={({ target: { name, value } }) => {
                        this.setState({ page: value });
                      }}
                      className="product_data_input"
                    ></FormControl>
                  </FormGroup>
                  <img
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setState({ page: uploaded_data.meta.next_page });
                    }}
                    data-toggle="tooltip"
                    title="Go to Next page"
                    src="/icons/icon_head.png"
                    alt="Next"
                    className="product_page_next"
                  />
                </div>
              </div>
              <div
                className="product_data_header"
                style={{ borderTop: "2px solid gray", backgroundColor: "lightgray" }}
              >
                <div className="product_data_info" style={{ width: "100%" }}>
                  <table className="table override_form">
                    <tbody>
                      <tr>
                        <td className="hash_td"></td>
                        {uploaded_data &&
                          uploaded_data.datalevel_attributes &&
                          uploaded_data.datalevel_attributes.map((attribute) => {
                            return (
                              <td
                                className="side_padding"
                                key={`search_${attribute.name}`}
                                width={`${90 / uploaded_data.datalevel_attributes.length}%`}
                              >
                                <input
                                  type="text"
                                  className="edit_data_input"
                                  placeholder={`Search ${attribute.name}`}
                                  value={
                                    (this.state.searchData &&
                                      this.state.searchData[attribute.name]) ||
                                    ""
                                  }
                                  onChange={({ target: { name, value } }) => {
                                    this.setState({
                                      searchData: {
                                        ...this.state.searchData,
                                        [attribute.name]: value,
                                      },
                                    });
                                  }}
                                />
                              </td>
                            );
                          })}
                        <td
                          className="side_padding"
                          width={
                            uploaded_data && uploaded_data.datalevel_attributes
                              ? `${90 / uploaded_data.datalevel_attributes.length}%`
                              : "auto"
                          }
                        >
                          <div className="flex_align">
                            <button
                              className="btn btn-default search_btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.getUploadedData(true);
                              }}
                            >
                              Go
                            </button>
                            <button
                              className="btn btn-default search_btn btn-danger"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.getUploadedData(false);
                                this.setState({
                                  searchData: {},
                                });
                              }}
                            >
                              Clear
                            </button>
                            <button
                              className="btn btn-default product_add_btn"
                              onClick={(e) => {
                                this.handleAddNewData();
                              }}
                            >
                              Add New Record
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <a
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                this.props.getUploadedData({
                  product_id: this.state.product_id,
                  dlname: this.state.dlname,
                  page: page,
                  per_page: perPage,
                });
                // this.setState({
                //   page: uploaded_data.meta.prev_page,
                // });
              }}
            >
              Go
            </a> */}
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  {uploaded_data &&
                    uploaded_data.datalevel_attributes &&
                    uploaded_data.datalevel_attributes.map((attribute) => {
                      return (
                        <th scope="col" key={attribute.name}>
                          {attribute.name}
                        </th>
                      );
                    })}
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {uploaded_data && (
                <tbody>
                  {uploaded_data.uploaded_data &&
                    uploaded_data.uploaded_data.map((data, index) => {
                      let currentPage = (uploaded_data.meta.current_page - 1) * this.state.perPage;
                      return (
                        <React.Fragment>
                          {mapped_uploaded_data && mapped_uploaded_data[data.id] ? (
                            <tr key={data.id}>
                              <th scope="row">
                                {data.id && data.id.toString().startsWith("add_new_")
                                  ? 0
                                  : currentPage + (index + 1)}
                              </th>
                              {uploaded_data.datalevel_attributes &&
                                uploaded_data.datalevel_attributes.map((attribute) => {
                                  // let productKey = product_keys.find(
                                  //   (product_key) => product_key.attribute === attribute.name
                                  // );
                                  return (
                                    <td colSpan={1} key={attribute.name}>
                                      {attribute && attribute.type == "text" && (
                                        <input
                                          type="text"
                                          value={mapped_uploaded_data[data.id][attribute.name]}
                                          onClick={() => {
                                            if (this.state.currentDataId !== data.id)
                                              this.setState({ currentDataId: data.id });
                                          }}
                                          // readOnly={productKey ? true : false}
                                          name={attribute.name}
                                          onChange={({ target: { name, value } }) => {
                                            mapped_uploaded_data[data.id][name] = value;
                                            this.setState({
                                              mapped_uploaded_data: mapped_uploaded_data,
                                              currentDataId: data.id,
                                            });
                                          }}
                                        />
                                      )}
                                      {attribute && attribute.type == "image" && (
                                        <React.Fragment>
                                          {editedFiles[data.id] && (
                                            <img
                                              style={{ height: "123px", cursor: "pointer" }}
                                              onClick={() =>
                                                this.handleImageEdit({
                                                  image:
                                                    uploaded_data.base_image_url +
                                                    `/uploads/products/${
                                                      this.state.product_id
                                                    }/images/${
                                                      mapped_uploaded_data[data.id][
                                                        attribute.name
                                                      ] &&
                                                      typeof mapped_uploaded_data[data.id][
                                                        attribute.name
                                                      ] === "object"
                                                        ? mapped_uploaded_data[data.id][
                                                            attribute.name
                                                          ].name
                                                        : mapped_uploaded_data[data.id][
                                                            attribute.name
                                                          ]
                                                    }?${new Date()}`,
                                                  data,
                                                  attribute,
                                                })
                                              }
                                              src={editedFiles[data.id]}
                                            />
                                          )}
                                          {!editedFiles[data.id] &&
                                            mapped_uploaded_data[data.id][attribute.name] &&
                                            typeof mapped_uploaded_data[data.id][attribute.name] !==
                                              "object" && (
                                              <img
                                                style={{ height: "123px", cursor: "pointer" }}
                                                onClick={() =>
                                                  this.handleImageEdit({
                                                    image:
                                                      uploaded_data.base_image_url +
                                                      `/uploads/products/${
                                                        this.state.product_id
                                                      }/images/${
                                                        mapped_uploaded_data[data.id][
                                                          attribute.name
                                                        ] &&
                                                        typeof mapped_uploaded_data[data.id][
                                                          attribute.name
                                                        ] === "object"
                                                          ? mapped_uploaded_data[data.id][
                                                              attribute.name
                                                            ].name
                                                          : mapped_uploaded_data[data.id][
                                                              attribute.name
                                                            ]
                                                      }?${new Date()}`,
                                                    data,
                                                    attribute,
                                                  })
                                                }
                                                src={`${
                                                  uploaded_data.base_image_url
                                                }/uploads/products/${
                                                  this.state.product_id
                                                }/images/${
                                                  mapped_uploaded_data[data.id][attribute.name]
                                                }?${new Date()}`}
                                              />
                                            )}
                                          {isFileName && isFileName === data.id ? (
                                            <input
                                              type="file"
                                              name={attribute.name}
                                              onChange={async ({ target: { name, files } }) => {
                                                let file = files[0];
                                                mapped_uploaded_data[data.id][name] = files[0];
                                                let that = this;
                                                // let fileSize = this.formatBytes(file.size, 6)
                                                if (await this.checkAvailSpace(file.size, 0)) {
                                                  this.setState({
                                                    mapped_uploaded_data: mapped_uploaded_data,
                                                    currentDataId: data.id,
                                                  });
                                                } else {
                                                  alert("Storage limit exceeded");
                                                }
                                              }}
                                            />
                                          ) : (
                                            <input
                                              type="text"
                                              value={
                                                typeof mapped_uploaded_data[data.id][
                                                  attribute.name
                                                ] === "object"
                                                  ? mapped_uploaded_data[data.id][attribute.name] &&
                                                    mapped_uploaded_data[data.id][attribute.name]
                                                      .name
                                                  : mapped_uploaded_data[data.id][attribute.name]
                                              }
                                              onClick={() => {
                                                if (this.state.currentDataId !== data.id)
                                                  this.setState({ currentDataId: data.id });
                                              }}
                                              name={attribute.name}
                                              onChange={({ target: { name, value } }) => {
                                                mapped_uploaded_data[data.id][name] = value;
                                                this.setState({
                                                  mapped_uploaded_data: mapped_uploaded_data,
                                                  currentDataId: data.id,
                                                });
                                              }}
                                            />
                                          )}

                                          <p>
                                            {mapped_uploaded_data[data.id][
                                              attribute && attribute.name
                                            ] &&
                                            typeof mapped_uploaded_data[data.id][attribute.name] ===
                                              "object"
                                              ? mapped_uploaded_data[data.id][attribute.name].name
                                              : mapped_uploaded_data[data.id][attribute.name]}
                                            <img
                                              src="/icons/edit.png"
                                              style={{ width: "18px" }}
                                              onClick={(e) => {
                                                this.setState({
                                                  isFileName: isFileName ? null : data.id,
                                                });
                                              }}
                                            />
                                          </p>
                                        </React.Fragment>
                                      )}
                                    </td>
                                  );
                                })}
                              <td>
                                <input
                                  type="button"
                                  value={"Save"}
                                  onClick={() => {
                                    this.handleSaveUploadData(data.id);
                                  }}
                                ></input>
                                <input
                                  type="button"
                                  value="Delete"
                                  style={{ marginTop: "6px" }}
                                  onClick={() => {
                                    this.handleDeleteUploadData(data.id);
                                  }}
                                ></input>
                              </td>
                            </tr>
                          ) : null}
                        </React.Fragment>
                      );
                    })}
                </tbody>
              )}
            </table>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button onClick={() => this.setState({ show: false })}>Close</Button>
          </Modal.Footer> */}
        </Modal>
      </React.Fragment>
    );
  }
}
