import React from 'react';
import { Carousel, CarouselItem, Button } from 'react-bootstrap';

import slide00 from 'images/slider/Banner-0.jpg';
import slide01 from 'images/slider/Banner-1.jpg';
import slide02 from 'images/slider/Banner-2.jpg';
import slide03 from 'images/slider/Banner-3.jpg';
import slide04 from 'images/slider/Banner-4.jpg';
import slide05 from 'images/slider/Banner-5.jpg';
import slide06 from 'images/slider/Banner-6.jpg';
import slide07 from 'images/slider/Banner-7.jpg';
import slide08 from 'images/slider/Banner-8.jpg';
import slide09 from 'images/slider/Banner-9.jpg';
import slide09India from 'images/slider/Banner-9-India.jpg';
import slide10 from 'images/slider/Banner-10.jpg';
import slide11 from 'images/slider/Banner-11.jpg';
import WelcomeToComposz from 'images/slider/WelcomeToComposz.jpg';
import WelcomeToComposzMobile from 'images/slider/WelcomeToComposzMobile.jpg';
import WelcomeToComposzIndia from 'images/slider/WelcomeToComposzIndia.jpg';
import WelcomeToComposzMobileIndia from 'images/slider/WelcomeToComposzMobileIndia.jpg';
import WelcomeToComposzAudio from 'images/slider/WelcomeToComposzAudio.jpg';

//import 'stylesheets/ComposzHomePage.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

export class ComposzHomePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            countryCode: this.getCookie("countryCode")
            };
      }
    
    handleSignup = () => {
        const link = document.createElement('a');
        link.setAttribute('href', '/users/sign_up');
        document.body.appendChild(link);
        link.click();
    }
    
    handleLearn = () => {
        const link = document.createElement('a');
        //link.setAttribute('href', '/static/Learn.html');
        //link.setAttribute('href', 'https://www.youtube.com/watch?v=VbWrWD8rqe4&list=PLe2VUCyoX9EzbIF94L4yNO1F1e0ESy81u');
        link.setAttribute('href', 'https://www.youtube.com/watch?v=bvbJZB3luqg&list=PLe2VUCyoX9EzbIF94L4yNO1F1e0ESy81u&index=8');
        link.setAttribute('target', 'blank');
        link.click();
    }

    showAlan = () => {
        const link = document.createElement('a');
        link.setAttribute('href', 'https://composz.com/CompositeOfAlan.html');
        link.setAttribute('target', 'blank');
        //link.setAttribute('onmouseover', 'pointer');
        link.click();
    }

    handleResize = e => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        
        this.setState(prevState => {
            return {
            windowWidth,
            windowHeight,
            };
        });
      };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        
        // if (window.innerWidth < 450) {
        //     alert('Composz is best viewed on a Desktop!');
        // }

        
        if (this.state.countryCode === ''){
            //fetch country code of user
            //var url = 'https://api.ipregistry.co/?key=64qg4jmpagjha51m';
            var url = "https://ipinfo.io/?token=f7e161ea50651f";
            fetch(url)
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    countryCode: responseJson.country
                });
                document.cookie = "countryCode=" + responseJson.country + ";";
            })
            .catch((error) => {
            //console.error(error);
                this.setState({
                    countryCode: "US"
                });
            });
        }
        
        /*
        this.setState({
            countryCode: document.cookie.split("=")[1] // set the country code from cookie set in application_controller.rb
        });
        */
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }
    
    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    render() {

    if (window.innerWidth > 450) {
        
    return(

        
    <React.Fragment>

    <link rel="preload" as="image" href={WelcomeToComposzAudio}/>
    {/*<link rel="preload" as="image" href={slide01}/>*/}
    <link rel="preload" as="image" href={slide00}/>

    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: this.state.windowWidth * 0.1 , paddingTop: this.state.windowWidth * 0.018 }}>
    <div style={{ display: 'flex', flexDirection: 'column',  alignItems: 'center', paddingRight: 10}}>

    {this.state.countryCode === 'IN' ?  <img src={WelcomeToComposzAudio} width={this.state.windowWidth * 0.18} onClick={this.showAlan} style={{cursor:'pointer'}}></img> :
                            <img src={WelcomeToComposz} width={this.state.windowWidth * 0.18}></img>
                        }
        <button className='btn btn-success active btn-block' onClick={this.handleLearn}>Explore</button>
        <button className='btn btn-dark btn-block' onClick={this.handleSignup}>Build</button>
        
    </div>

    <div  style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Carousel controls={false} interval={6000} indicators={false} pauseOnHover={true}>
          <CarouselItem>
              <img src={slide00} ></img>
          </CarouselItem>
          <CarouselItem>
              <img src={slide01} ></img>
          </CarouselItem>
          {/* <CarouselItem>
              <img src={slide02} ></img>
          </CarouselItem>
          <CarouselItem>
              <img src={slide03}></img>
          </CarouselItem>
          <CarouselItem>
              <img src={slide04}></img>
          </CarouselItem>
          <CarouselItem>
              <img src={slide05}></img>
          </CarouselItem>
          <CarouselItem>
              <img src={slide06}></img>
          </CarouselItem>
          <CarouselItem>
              <img src={slide07}></img>
          </CarouselItem>
          <CarouselItem>
              <img src={slide08}></img>
          </CarouselItem>
          <CarouselItem>
          {this.state.countryCode === 'IN' ? <img src={slide09India}></img> : <img src={slide09}></img>}
          </CarouselItem>
          <CarouselItem>
              <img src={slide10}></img>
          </CarouselItem>
          <CarouselItem>
              <img src={slide11}></img>
          </CarouselItem> */}
          
        </Carousel>
        </div>
       </div>
       
       {this.state.countryCode === 'IN' ?
          <div>
            <br></br>
            <br></br>
            <br></br>
            <div style={{ background: 'lightgray', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column',  alignItems: 'center', paddingRight: 10, paddingLeft: 40}}>
                        <br></br>
                        <p><b> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Contact :</b><br></br>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<b><strong>LIFE COLORS</strong></b><br></br>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;#9, 4th Cross,<br></br>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Kothanur Main Road,<br></br>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Bangalore 560 062.<br></br>
                        <br></br>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<b><strong>email : </strong></b>composz-team@composz.com<br></br>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<b><strong>phone : </strong></b>+91 9620621884<br></br>
                        </p>
                    </div> 

                    <div style={{ display: 'flex', flexDirection: 'column',  alignItems: 'center', paddingRight: 10}}>
                        <br></br>
                        <p><b> &emsp;&emsp;&emsp;Policies :</b><br></br>
                            &emsp;&emsp;&emsp;&emsp;<a href="/static/Pricing.html" target="blank">Pricing</a><br></br>
                            &emsp;&emsp;&emsp;&emsp;<a href="/static/RefundAndReturn.html" target="blank">Refund & Return</a><br></br>
                            &emsp;&emsp;&emsp;&emsp;<a href="/static/RefundAndReturn.html" target="blank">Shipping</a><br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                        </p>
                    </div> 
                </div> 
              </div> : ''
            }  
        </React.Fragment>
      );
    }
    else {
        return(

            //{this.state.countryCode === 'IN' ?  <img src={WelcomeToComposzMobileIndia}></img> : <img src={WelcomeToComposzMobile}></img>}
            <React.Fragment>
        
            <link rel="preload" as="image" href={WelcomeToComposzAudio}/>
            <link rel="preload" as="image" href={slide00}/>
            
            <div style={{ display: 'flex', flexDirection: 'column',  alignItems: 'center', paddingRight: 5, paddingLeft: 5}}>
        
            {this.state.countryCode === 'IN' ?  <img src={WelcomeToComposzAudio} onClick={this.showAlan} style={{cursor:'pointer'}}></img> : <img src={WelcomeToComposzMobile}></img>}

                <button className='btn btn-success active btn-block btn-lg' onClick={this.handleLearn}>Explore</button>
                <button className='btn btn-dark active btn-block btn-lg' onClick={this.handleSignup}>Build</button>
                
            </div>
            <br></br>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Carousel controls={false} interval={5000} indicators={false}>
                  <CarouselItem>
                      <img src={slide00} ></img>
                  </CarouselItem>
                  <CarouselItem>
                      <img src={slide01} ></img>
                  </CarouselItem>
                  {/* <CarouselItem>
                      <img src={slide02} ></img>
                  </CarouselItem>
                  <CarouselItem>
                      <img src={slide03}></img>
                  </CarouselItem>
                  <CarouselItem>
                      <img src={slide04}></img>
                  </CarouselItem>
                  <CarouselItem>
                      <img src={slide05}></img>
                  </CarouselItem>
                  <CarouselItem>
                      <img src={slide06}></img>
                  </CarouselItem>
                  <CarouselItem>
                      <img src={slide07}></img>
                  </CarouselItem>
                  <CarouselItem>
                      <img src={slide08}></img>
                  </CarouselItem>
                  <CarouselItem>
                  {this.state.countryCode === 'IN' ? <img src={slide09India}></img> : <img src={slide09}></img>}
                  </CarouselItem>
                  <CarouselItem>
                      <img src={slide10}></img>
                  </CarouselItem>
                  <CarouselItem>
                      <img src={slide11}></img>
                  </CarouselItem> */}
                  
                </Carousel>
                </div>
  
               
                {this.state.countryCode === 'IN' ?
                 <div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div style={{ background: 'lightgray', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'column',  alignItems: 'center', paddingRight: 10}}>
                                <br></br>
                                <p><b>Contact :</b><br></br>
                                &nbsp;&nbsp;<b><strong>LIFE COLORS</strong></b><br></br>
                                &nbsp;&nbsp;#9, 4th Cross,<br></br>
                                &nbsp;&nbsp;Kothanur Main Road,<br></br>
                                &nbsp;&nbsp;Bangalore 560 062.<br></br>
                                <br></br>
                                &nbsp;&nbsp;<b><strong>email : </strong></b>composz-team@composz.com<br></br>
                                &nbsp;&nbsp;<b><strong>phone : </strong></b>+91 9620621884<br></br>
                                </p>
                            </div> 

                            <div style={{ display: 'flex', flexDirection: 'column',  alignItems: 'center', paddingRight: 10}}>
                                <br></br>
                                <p><b>Policies :</b><br></br>
                                    <a href="/static/Pricing.html" target="blank">Pricing</a><br></br>
                                    <a href="/static/RefundAndReturn.html" target="blank">Refund & Return</a><br></br>
                                    <a href="/static/RefundAndReturn.html" target="blank">Shipping</a><br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                </p>
                            </div> 
                        </div> 
                    </div> : ''
                }       
             </React.Fragment>
            );
    }
  }
}


export default ComposzHomePage 
