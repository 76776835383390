import React from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
  Fade,
  utils,
  Button,
  Table,
} from "react-bootstrap";
import serialize from "form-serialize";
import { ENDPOINTS } from "../../../constants/endpoints";

export default class AddLyDlRel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props, isAddKeyOpen: false };
    this._handleValidSubmit = this._handleValidSubmit.bind(this);
    this.hideOverlay = this.hideOverlay.bind(this);
  }

  _handleValidSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    let formData = serialize(e.target, { hash: true, empty: true });
    const layout = this.state.layout;
    const product = this.state.product;
    formData.cdlcount = 0;
    let endpoint = Object.assign({}, ENDPOINTS.layout_datalevels_rel.create, {});
    let url = endpoint.uri.replace(":layout_id", layout.id);
    formData.product_id = product.id;
    formData.layout_id = layout.id;
    formData.pdlname = this.props.type == "product_key" ? formData.cdlname : formData.pdlname;
    this.props.createLyDLRel(url, endpoint.method, formData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  hideOverlay(e) {
    this.setState(Object.assign({}, this.state, { isVisible: false }));
  }

  render() {
    const dlRel = this.state.dlRel;
    const left_dls = this.state.left_dls;
    const right_dls = this.state.right_dls;
    const isVisible = this.state.isVisible;
    const layout_datalevels = this.state.layout.datalevels;
    let dlRelStyle = dlRel.style;
    let popupTitle =
      this.props.type == "product_key"
        ? "Data Level Relationship via Product Set Keys"
        : "Data Level Relationship via non Product Set Keys";
    if (dlRelStyle == null) {
      if (this.props.type == "product_key") dlRelStyle = "P";
      else dlRelStyle = "N";
    }
    let left_view = [];
    if (this.props.type == "product_key") {
      left_view.push(
        <FormControl
          type="hidden"
          name={"pdlname"}
          // defaultValue={datalevel.dlname}
          defaultValue={left_dls[0] && left_dls[0].dlname}
          required="required"
        />
      );
    } else {
      left_view.push(
        <FormControl
          componentClass="select"
          placeholder="please select"
          required="required"
          defaultValue={""}
          name={"pdlname"}
        >
          <option value="">please select</option>
          {left_dls.map((datalevel, i) => {
            let ld = layout_datalevels.find((l) => l.dlname === datalevel.dlname);
            if (ld)
              return (
                <option key={i} value={datalevel.dlname}>
                  {datalevel.dlname}
                </option>
              );
          })}
        </FormControl>
      );
    }
    
    const a = (
      <Row>
        <Col md={!this.props.type ? 6 : 1}>
          {!this.props.type && (
            <label>Data Level with Product Set Keys & non Product Set Keys (Parent)</label>
          )}
          {left_view}
        </Col>
        <Col md={!this.props.type ? 6 : 10}>
          {!this.props.type && <label>Data Level with non Product Set Keys (Child)</label>}
          {this.props.type && <label>Add Data Level which has Product Set Key Attributes</label>}
          <FormControl
            componentClass="select"
            placeholder="Please select"
            required="required"
            defaultValue={""}
            name={"cdlname"}
          >
            <option value="">please select</option>
            {right_dls.map((datalevel, i) => {
              let ld = layout_datalevels.find((l) => l.dlname === datalevel.dlname);
              if (ld)
                return (
                  <option key={i} value={datalevel.dlname}>
                    {datalevel.dlname}
                  </option>
                );
            })}
          </FormControl>
        </Col>
        <Col md={1}>
          <FormControl type="hidden" name={"style"} defaultValue={dlRelStyle} required="required" />
        </Col>
      </Row>
    );

    return (
      <Modal
        size={"lg"}
        show={this.state.isAddVisible}
        onHide={() => {
          this.props.closeAddRel();
        }}
      >
        <Form onSubmit={this._handleValidSubmit} className="override_form">
          <Modal.Header closeButton>
            <Modal.Title>{popupTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{a}</Modal.Body>
          <Modal.Footer>
            <Button type="submit">Save</Button>
            {/* <Button onClick={this.props.closeAddRel}>Cancel</Button> */}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
