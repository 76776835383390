import React, { component, useEffect } from "react";
import LayoutContainer from "../../containers/orders/LayoutContainer";
import PaymentContainer from "../../containers/orders/PaymentContainer";
import { ENDPOINTS } from "../../constants/endpoints";
import CartContext from "../../context/cart/CartContext";




export default class ProductSet extends React.Component {
  
  constructor(props) {
    super(props);
  //  let customState = { ...props, isAddedToCart: false, myMainOccurenceCount: [], mydbKeyVals: [], filesCountBySet: [] };
    let customState = { ...props, isAddedToCart: false, myMainOccurenceCount: [], filesCountBySet: [] };
    customState.expand = false;
    this.state = customState;
    
    
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  UNSAFE_componentWillMount() {
    this.props.getPrice(this.props.product.id, this.state.setno);
  }
  
  componentDidMount() {
    // ** Important note: Bala - Compulsarily look at files generated rather than rowcounts from DB!!!
    this.getFinalFilesCountBySet();

    if (this.props.cart_product !== undefined && this.props.cart_product !== null){
      this.context.setqty(this.props.cart_product.num_files);  
    }
    if (this.props.cart_product == (undefined||null)){
      this.context.setqty(0);  
    }


    //    this.getKeyValsfromDB();  
//    this.getMainOccurenceCount();
    //this.mygetRowsCountBySet();
  }

  componentWillUnmount() {
    this.context.setqty(null)
  }
  
  setCartQty(){
    // Timeout is used as state updates are asyn! Important
    setTimeout(() => this.context.setqty(this.state.cart_product.num_files), 1000);
  }

  
// Bala Edit Start

getFinalFilesCountBySet = () => {
  let uri = ENDPOINTS.final_files.index.uri;
  var product_id = this.props.product.id;
  var setno = this.state.setno;
  uri = `${uri}?product_id=${product_id}&setno=${setno}`;
  
  let noOfFiles;
  (async () => {
     noOfFiles =  await fetch(uri, {
       method: "GET", headers: { "content-type": "application/json" }, 
      })
    .then((response) => response.json())
    .then((data) => { 
            this.setState({filesCountBySet: data});
    });
  })();
}



getMainOccurenceCount = () => {
  let counts_uri = ENDPOINTS.productsetcount.index.uri;
  var product_id = this.props.product.id;
  var setno = this.props.setno;
  
  var where =  "product_id=" + product_id + " and setno=" + setno;
  
  counts_uri = `${counts_uri}?where=${where}`;
  let noRecords;
  
  (async () => {
     noRecords = await  fetch(counts_uri, {
        method: "GET", headers: { "content-type": "application/json" }, 
       })
     .then((response) => response.json())
     .then((data) => { 
        this.setState({myMainOccurenceCount: data});
    });
  })();     
};



getKeyValsfromDB = () => {
  let keyVals_uri = ENDPOINTS.productkeyval.index.uri;
  var product_id = this.props.product.id;
  var setno = this.props.setno;
  
  var where =  "product_id=" + product_id + " and setno=" + setno;
  
  keyVals_uri = `${keyVals_uri}?where=${where}`;

  let noRecords;
  
 // (async () => {
     noRecords =   fetch(keyVals_uri, {
        method: "GET", headers: { "content-type": "application/json" }, 
       })
     .then((response) => response.json())
     .then((data) => { 
        this.setState({mydbKeyVals: data});
//        this.getRowsCountBySet();
    });
//  })();     
};

getRowsCountBySet = () => {
  let uri = ENDPOINTS.maindbrowcountforaset.index.uri;
  var product_id = this.props.product.id;
  var dlname = this.props.main_dlname;

  var where =  "product_id=" + product_id;
  
  let localKeyVals = this.props.product_set.keyvals;
  
  if (localKeyVals == undefined){
    localKeyVals = this.state.mydbKeyVals;
    localKeyVals = JSON.parse(localKeyVals);
  }
  
  // myKeyVals.map(keys =>  console.log("map Key = " + keys.keyname + " map Key Val = " + keys.keyval));
  // DataUpload.mainDbRowCountForAset("product_id = 4 and dlname='IMAGES' 
  // and JSON_CONTAINS(data, '\"ALL\"', '$.key')") 
  
  const jsonText="and JSON_CONTAINS(data, ";
  var allJson = '';
  let whereKeys = [];

  
  localKeyVals.map(keys => { 
     allJson = jsonText +  "'" + '"' + keys.keyval + '"' + "','$." + keys.keyname + "')" 
     whereKeys.push(allJson);
   });

   let finalWhereKeys = '';
   whereKeys.forEach(myConcat)

   function myConcat(item){
    finalWhereKeys = finalWhereKeys + " " + item;
   }

  where =  "product_id=" + product_id + " and dlname='" +  dlname + "'";

  where = where + " " + finalWhereKeys;

  uri = `${uri}?where=${where}`;

  let noOfFiles;

  (async () => {
    noOfFiles =  await fetch(uri, {
       method: "GET", headers: { "content-type": "application/json" }, 
      })
    .then((response) => response.json())
    .then((data) => { 
          this.setState({filesCountBySet: data});
    });
    
  })();
   
}


// Bala Edit End (2 functions)



  getCartDetails = (order, product_id, setno, seq_no) => {
    const { product_role, cart_product, product } = this.state;
    
    if (product_role == "viewer") return null;
    if (this.isProductAddToCart(product_id, setno, seq_no, "order")) {
      // Bala Edit return "Download";   
      return "Already Ordered";
    } else {
      return (
         <React.Fragment>
          {this.isProductAddToCart(product_id, setno, seq_no, "cart") ? (
            <a
              className="no-textcolor"
              onClick={() => {
                this.props.addToCart({
                  product_id: product_id,
                  setno: setno,
                  seq_no,
                  type: "remove",
                });
                
                if (seq_no == undefined) {
                 this.setState({
                   isAddedToCart: !this.state.isAddedToCart,
                 });
                 this.setCartQty();
                }
                if (seq_no != undefined) {
                  this.context.decrease();
                // this.context.setqty(this.state.cart_product.num_files);  too slow
                }
              }}
            >
              {seq_no!= undefined ? 'Remove from Cart' : 'Remove all records from Cart'}
            </a>
          ) : (
            <a
              className="no-textcolor"
              onClick={() => {
                  this.props.addToCart({
                  product_id: product_id,
                  setno: setno,
                  seq_no,
                });
                if (seq_no == undefined) {
                  this.setState({
                   isAddedToCart: !this.state.isAddedToCart,
                  });
                  this.setCartQty();
                }
                if (seq_no != undefined) {
                  this.context.increase();
                  //this.context.setqty(this.state.cart_product.num_files);  too slow
                }
              }}
            >
              {seq_no!= undefined ? 'Add Record to Cart' : 'Add all records to Cart'}
            </a>
          )}
         </React.Fragment>
      );
    }
  };

  isProductAddToCart = (product_id, setno, seq_no, type) => {
    const { cart_product: product_cart, product_set, order, product } = this.state;
    let cart_product = {};
    cart_product = type == "cart" ? [product_cart] : (order && order) || [];
    
    let isRecordAddedToCart = false;
    let orderedCount = 0;
    let NumFiles = 0;
    let counts =
      (product_set && product_set.counts && product_set.counts[this.state.main_dlname]) || [];
    if (product_set && Array.isArray(product_set.counts)) {
      counts = product_set.counts.filter((ps) => ps.dlname == this.state.main_dlname);
    }
    const count = (counts || []).find((f) => f.setno == setno) || {
      main_occurence_count: 0,
    };
    let addToCartCount = 0;

    //let correctCount = this.state.filesCountBySet.maindbrowcountforaset
    let correctCount = this.state.filesCountBySet.filesCount;
    
    if (count.main_occurence_count < correctCount) 
      correctCount = count.main_occurence_count;

    if (count.main_occurence_count == 0) {
        //count.main_occurence_count = this.state.myMainOccurenceCount[0];
        //correctCount = this.state.filesCountBySet.maindbrowcountforaset;
        correctCount = this.state.filesCountBySet.filesCount;
    }  
   

    if (cart_product && cart_product[0]) {
      for (let index = 0; index < cart_product.length; index++) {
        const cp = cart_product[index];

        if (cp.freeze_count != product.freeze_count || cp.status == "failed") {
          continue;
        }
        
        let sets = cp && cp.order_details && cp.order_details.sets;
        if (sets && Object.keys(sets).includes("all")) {
          isRecordAddedToCart =
            type == "cart"
              ? true
              : cp.status == "paid" && cp.freeze_count == product.freeze_count && true;
        } else if (
          setno &&
          sets[setno.toString()] &&
          Object.keys(sets[setno.toString()]).includes("all")
        ) {
          isRecordAddedToCart =
            type == "cart"
              ? true
              : cp.status == "paid" && cp.freeze_count == product.freeze_count && true;
        } else if ((seq_no || "0") && setno && sets[setno] && sets[setno][seq_no]) {
            isRecordAddedToCart =
              type == "cart" 
                ? true
                : cp.status == "paid" && cp.freeze_count == product.freeze_count && true;
        } else if (sets[setno]) {
            if (type == "order" && cp.status == "paid") {
              orderedCount += cp.order_details.sets[setno]
                ? Object.keys(cp.order_details.sets[setno]).length
                : 0;
            } else {
                addToCartCount += cp.order_details.sets[setno]
                  ? Object.keys(cp.order_details.sets[setno]).length
                  : 0;
              }
            
              
          } else if (seq_no == undefined) { // This is the only way to track Add all to Cart!!!!

            // can do sometign anout this area. Needs research.
            isRecordAddedToCart = isRecordAddedToCart;

          }
          
      }
      
      

      if (
          count &&
            (
              (type == "order" && orderedCount == correctCount) ||
              (type == "cart" && addToCartCount == correctCount) 
            )
         ) {
            isRecordAddedToCart = true;
           }
/*
      if (this.props.cart_product)
         NumFiles = this.props.cart_product.num_files;
      else
        NumFiles = 0;

      if (
          count &&
            (
              (type == "order" && orderedCount + NumFiles == correctCount)
            ) && seq_no == undefined

         ) {
            isRecordAddedToCart = true;
           }
  */        
            
      
    }
    return isRecordAddedToCart;
  };

  getOrderStatus = (product_id, setno) => {
    const { order } = this.state.product;
    let payment = null;
    let page_nos = order && order.page_no ? Object.keys(order.page_no) : null;
    if (
      order &&
      order.status == "paid" &&
      this.state.product.freeze_count == order.freeze_count &&
      page_nos.length == 0
    ) {
      // payment = <li> Download</li>;
      payment = <li> Already Ordered</li>;
      
    } else if (order && order.status == "pending" && page_nos) {
      payment = (
        <ul>
          
          <PaymentContainer product_id={product_id} setno={setno} pg_order_id={order.pg_order_id} />
        </ul>
      );
    } else {
      payment = (
        <ul>
          <PaymentContainer product_id={product_id} setno={setno} />
        </ul>
      );
    }
    return payment;
  };

  onExpandToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ expand: !this.state.expand });
  };

  getOrderDetails = (product_set) => {
    const pricing = product_set.pricing;
    const order = product_set.order;
    let page_nos = order && order.page_no ? Object.keys(order.page_no) : null;
    if (
      order &&
      order.status === "paid" &&
      (page_nos.length === 0 || page_nos.length === this.state.product.numpages)
    ) {
      return (
        <React.Fragment>
          <li>No. of files: {order.num_files}</li>
          <li>Total Amount: {order.total_amount}</li>
        </React.Fragment>
      );
    } else if (pricing) {
      return (
        <React.Fragment>
          <li>{pricing && pricing.pages_count && pricing.pages_count.total} Files</li>
          <li>Total Amount: {pricing.price_total}</li>
        </React.Fragment>
      );
    }
  };



  getExpandDetails(product, product_set) {
    const { order, product_role } = this.state.product;
    const product_order_status = this.state.product.order.status;
    let payment = null;
    let expandView = [];
    let t = [];
    
    let product_set_order_status = product_set && product_set.order.status === "paid";
    let page_nos =
      product_set.order && product_set.order.page_no
        ? Object.keys(product_set.order.page_no)
        : null;

      //product_set_order_status = product_set.order.status
      product_set_order_status = product_set_order_status
      ? page_nos.length === 0 || page_nos.length === product.numpages
      : product_set_order_status;
    
    if (this.isProductAddToCart(product.id, product_set.setno, null, "order")) {
    // payment = <li> Download</li>; 
    // payment = <li> Already Ordered</li>; 
    payment = <li> Already Ordered/Added to Cart</li>; 
      
    } else {
      if (product_role != "viewer") 
        payment = <li>{this.getCartDetails(this.state.order, product.id, this.state.setno)}</li>;
      
    }
    let counts =
      (product_set && product_set.counts && product_set.counts[this.state.main_dlname]) || [];
    if (product_set && Array.isArray(product_set.counts)) {
      counts = product_set.counts.filter((ps) => ps.dlname == this.state.main_dlname);
    }
    const count = (counts || []).find((f) => f.setno == this.state.setno) || {
      main_occurence_count: 0,
    };

   
  // For db Row Count
    
    //let correctCount = this.state.filesCountBySet.maindbrowcountforaset;
    let correctCount = this.state.filesCountBySet.filesCount;
    
    if (count.main_occurence_count < correctCount) 
      correctCount = count.main_occurence_count;

    if (count.main_occurence_count == 0) { // This is required because View/Pay user don't have the counts state properties!
        //count.main_occurence_count = this.state.myMainOccurenceCount[0];
        //correctCount = this.state.filesCountBySet.maindbrowcountforaset;
        correctCount = this.state.filesCountBySet.filesCount;
      }


// Bala Edit    for (let i = 1; i <= this.state.filesCountBySet.maindbrowcountforaset; i++) {  
      for (let i = 1; i <= correctCount; i++) {  

      let set_layout = this.state.layouts[i] || {};

      t.push(
        <li key={set_layout.layout_id}>
          <span>{"Record no " + i} | </span>
          <span>
            <a
              href={
                "/products/" +
                product.id +
                "/sets/" +
                product_set.setno +
                "/page_preview?pageno=1&sno=" +
                (i - 1)
              }
            >
              View
            </a>{" "}
            |{" "}
          </span>
          <span>{this.getCartDetails(this.state.order, product.id, this.state.setno, i - 1)}</span>
        </li>
      );
    }
    expandView.push(
      <React.Fragment>
        {t}
        {/* {this.getOrderDetails(product_set)} */}
        {payment}
      </React.Fragment>
    );
    return expandView;
  }

  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  render() {
    const expand = this.state.expand;
    const setno = this.state.setno;
    const product = this.state.product;
    const product_set = this.state.product_set;
    return (
      // <li key={setno}>
      <React.Fragment>
        <li>
          <a onClick={this.onExpandToggle} className="no-textcolor">
            {this.getExpandJSX(expand)} {product_set && product_set.desc}
          </a>
        </li>
        <ul>{expand ? this.getExpandDetails(product, product_set) : null}</ul>
      </React.Fragment>
    );
  }
}
ProductSet.contextType=CartContext