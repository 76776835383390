//import React, {useState, useEffect} from 'react';
import React, { Component } from "react";
import { ENDPOINTS } from "../constants/endpoints";
//import "babel-polyfill";
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from "react-bootstrap";
//import { Col, Modal, Button, Row } from "react-bootstrap";

class EventsHomePage extends Component {
    constructor(props) {
        super(props);
        let customState = { ...props, events: []  };
        this.state = customState;
        this.getEventsfromDB();
      }
    
    
      UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps !== this.state) {
          this.setState(nextProps);
        }
      }
    
     UNSAFE_componentWillMount() {
      }

    getEventsfromDB = () => {
        let events_uri = ENDPOINTS.events.list.uri;
        var user_id = this.props.user_id;
        
        var where =  "user_id=" + user_id;
        var where =  ""
        
        events_uri = `${events_uri}?${where}`;
      
        let noRecords;
        
        
//        (async () => {
//            noRecords =  await fetch(events_uri, {
            noRecords =  fetch(events_uri, {
              method: "GET", headers: { "content-type": "application/json" }, 
             })
           .then((response) => response.json())
           .then((data) => { 
              this.setState({events: data.events});
              console.log ("data: ", data.events);
          });
        
//      });
    }

    render() {
    
    let list
    let nolist

    console.log ("event.length: ", this.state.events.length)
    if (this.state.events.length > 0) {
        list = this.state.events.map( item =>{
            //return (<li key={item.id}>{item.id}, {item.user_id}, {item.event_type}, {item.title}, {item.img_url}</li>)
            //return (<li key={item.id}>{item.id}, {item.user_id}, {item.event_type}, {item.title}, 
            //          <img src={'uploads/events/1/'+item.img_url} alt="" height="300" width="200"  /></li>)

            return (
                <div style={{ display: 'flex', flexDirection: 'row' , boxShadow: '5px 10px 10px #888888', border: '1px solid black', marginBottom: '25px'}}>
                  <img src={'uploads/events/1/'+item.img_url} alt="" height="150" width="100"  />
                  <div style={{paddingLeft:'5px'}}>
                    <h3 style={{marginTop: '10px'}}>{item.title}</h3>
                    <h3>{item.event_dt}</h3>
                  </div>
                  
                </div>                
                

            )

        })
    }
    else {
        nolist = ["No Events Found! Create a New Event now!"]
    }
    
    

    //<ul>{list}</ul>
    return (
        <React.Fragment>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginLeft: '45px'}}>
                <h1>My Events</h1>
                {/* <Button variant="primary">+ Create New Event</Button>{' '} */}
                <Button style={{backgroundColor:"blue", color:"white", fontSize:"20px", marginBottom: '43px', boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.32)"}}  onClick={() => setshowModal(false)}>Create New Event</Button>
              </div>
              <ul>{this.state.events.length>0 ? list : nolist}</ul>

              


{/*
            <div style={{border:'2px solid green', padding:'20px'}}>
              <Panel style={{ width: '100rem', marginTop: '1rem' }}>
                <img src='uploads/events/1/wedding.jpg' alt="" height="150" width="100"  />
                <Panel.Heading>
                  <Panel.Title toggle>My Events</Panel.Title>
                </Panel.Heading>
                
                
                <Panel.Body>
                  This is some text within a card body.
                  </Panel.Body>
              </Panel>
            </div>
    */}


        </React.Fragment>
    );

  /*
    return (
      <Card>
        <Card.Body>This is some text within a card body.</Card.Body>
      </Card>
    );
  */


    }
}

export default EventsHomePage 
