import React, {Component, useState, useEffect, Fragment} from 'react';

export function SPLoader() {

    const [text, setText] = useState('')
    const [showImg, setShowImg] = useState(true)

    // useEffect(() => {
    
    //     setTimeout(() => {
    //         setShowImg(false)
    //         setText(
    //             'I waited for 3 seconds. Did you see the spinner?'
    //         )
    //     }, 3000)
    // }, [])

    return(
        <div style={{
            position: "fixed",    
            zIndex: "9999",
            top: "30vh",
            // left: "47vw",
            height: "100%",
            width: "100%", 
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }}>
            {
                showImg? 
                  <Fragment>
                     {/* <img src='./blueSpinner-sm.gif' ></img> */}
                     <img style={{marginLeft: "auto", marginRight: "auto"}} src='./blueSpinner-md.gif' ></img>
                     <h4 style={{color:'white'}}>Please wait...</h4>
                  </Fragment>
                : (
                    // <h3>{text}</h3>
                    <h3>Completed</h3>
                )
            }
        </div>
    )
}

export default SPLoader