import React, { Component } from "react";
import ReactDOM from "react-dom";

import { ENDPOINTS } from "../../constants/endpoints";

class RPEventPayment extends Component {
  constructor(props) {
    super(props);
    this.state = { no_of_wishers: 1, ...props };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {}

  
  componentDidMount() {
    // uncomment below line to generate a image in the background
    // this.props.generateImage(this.state.product_id, this.state.setno);
  }

  
  amount = this.props.amount
  
  render() {
    
    const style = {
      color: "green"
   };

   const style3 = {
    color: "white",
    fontSize: 17,
    background: "green"
 };

    return (
            <div style={style}>
              <form id="redirect" method="post" name="redirect" action="/rpeventtransactions" >
              <input type="hidden" name="amount" value={this.amount}/>
              <input type="hidden" name="no_of_wishers" value={this.props.no_of_wishers}/>
              <input type="hidden" name="event_id" value={this.props.event_id}/>
              <input type="hidden" name="price" value={this.props.price}/>
              <input type="hidden" name="extraWishersFlg" value={this.props.extraWishersFlg}/>
              <button type="submit" style={style3} className="btn btn-success">Razorpay Checkout</button>
              </form>
            </div>
          );
  }
}

export default RPEventPayment;
