import React from "react";
import PropTypes from "prop-types";
import ProductDLContainer from "../containers/ProductDLContainer";
import ProductDataContainer from "../containers/ProductDataContainer";
import ProductImageUploadContainer from "../containers/ProductImageUploadContainer";
import ProductKeysContainer from "../containers/product_keys/ProductKeyListContainer";
import ProductSetListContainer from "../containers/product_sets/ProductSetListContainer";
import ProductLayoutListContainer from "../containers/ProductLayoutListContainer";
import AddUserContainer from "../containers/AddUserContainer";
import { ENDPOINTS } from "../constants/endpoints";
import { formDataToJSON } from "../adukku_utils/array";
// "../../adukku_utils/array";
import { authorized, Authorized } from "../adukku_utils/authorizeUser";
import {
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
  Fade,
  utils,
  Button,
} from "react-bootstrap";
import { FieldGroup } from "./bootstrap/Forms.FieldGroup";
import FileMangerContainer from "../containers/FileManagerContainer";
import OrderContainer from "../containers/orders/OrderContainer";
import DownloadFilesContainer from "../containers/orders/DownloadFilesContainer";

import { TOGGLE_PRODUCT_EXPAND, TOGGLE, TOGGLE_EDIT_PRODUCT } from "../actions";

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: props.collapse,
      product: props.product,
      product_datalevels: props.product_datalevels,
      isEdit: false,
      dlExpand: false,
      role: props.role,
      isCopyProductClicked: false,
      name: props.product && props.product.name,
      numpages: props.product && props.product.numpages,
      isProductKeysExpanded: false,
      isProductSetsExpanded: false,
      isDataUploadExpanded: false,
      isImagesUploadExpanded: false,
      cloneable_sets: [],
      isEditProductClicked: false,
      voice: props.product && props.product.prtype,
      countryCode: this.getCookie("countryCode"),
      is_preset: props.product && props.product.is_preset,
      category: props.product && props.product.category,
      sub_category: props.product && props.product.sub_category,
      expiry:
        props.product && props.product.expiry
          ? new Date(props.product.expiry).toISOString().slice(0, 16)
          : "NOT SET",
    }; // isEdit: props.isEdit};
    this.onEdit = this.onEdit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onDataLevelsClick = this.onDataLevelsClick.bind(this);
    this.onExpandToggle = this.onExpandToggle.bind(this);
    this.newDatalevel = this.newDatalevel.bind(this);
    this.onProductKeysToggle = this.onProductKeysToggle.bind(this);
    this.onProductSetsToggle = this.onProductSetsToggle.bind(this);
  }
  onExpandToggle(e) {
    if (e !== undefined) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.props.toggleView({
      reducerKey: TOGGLE_PRODUCT_EXPAND,
      id: this.state.product.id,
    });
  }

  onEdit(e) {
    e.stopPropagation();
    e.preventDefault();
    this.props.toggleEdit({
      reducerKey: TOGGLE_EDIT_PRODUCT,
      id: this.state.product.id,
    });
  }
  handleSubmit(e) {
    e.stopPropagation();
    e.preventDefault();
    this.props.toggleEdit({
      reducerKey: TOGGLE_EDIT_PRODUCT,
      id: this.state.product.id,
    });
  }
  newDatalevel(e) {
    e.stopPropagation();
    e.preventDefault();
    this.props.newProductDatalevel(
      this.state.product.id,
      "DL" + this.state.product_datalevels.length
    );
  }
  onDataLevelsClick(e) {
    e.stopPropagation();
    e.preventDefault();
    const collapse = this.state.collapse;
    this.state.dlExpand = !this.state.dlExpand;
    this.props.getProductDatalevels(this.state.product.id);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
    if (nextProps.product.expiry) {
      this.setState({
        expiry:
          nextProps.product && nextProps.product.expiry
            ? new Date(nextProps.product.expiry).toISOString().slice(0, 16)
            : new Date().toISOString().slice(0, 16),
      });
    }
    // if (
    //   this.state.product !== nextProps.product ||
    //   this.state.product_datalevels !== nextProps.product_datalevels
    // ) {
    //   this.setState(nextProps);
    //   if (nextProps.product.expiry) {
    //     this.setState({
    //       expiry:
    //         nextProps.product && nextProps.product.expiry
    //           ? new Date(nextProps.product.expiry).toISOString().slice(0, 16)
    //           : new Date().toISOString().slice(0, 16),
    //     });
    //   }
    // }
  }

  onProductKeysToggle(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      isProductKeysExpanded: !this.state.isProductKeysExpanded,
    });
    if (!this.state.isProductKeysExpanded) this.props.getKeys(this.state.product.id);
  }

  onProductSetsToggle(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      isProductSetsExpanded: !this.state.isProductSetsExpanded,
    });
    if (!this.state.isProductSetsExpanded) this.props.getSets(this.state.product.id);
  }

  componentDidMount() {
    this.props.getProductDatalevels(this.state.product.id);
    let preset_product_id = localStorage.getItem("preset_product_id");
    if (
      preset_product_id &&
      this.state.product.id &&
      preset_product_id === this.state.product.id.toString()
    ) {
      this.onExpandToggle();
      // localStorage.removeItem("preset_product_id");
    }

    let preset_url_product_id = document.getElementById("preset_product_id");
    preset_url_product_id = preset_url_product_id ? preset_url_product_id.innerHTML : null;
    let user_role = document.getElementById("user_role");
    user_role = user_role.innerHTML;

    this.setState({
      role: user_role,
      preset_product_id: preset_product_id || null,
      preset_url_product_id: preset_url_product_id,
      voice : this.state.voice == 'NORMAL' ? 'NO-VOICE' : this.state.voice
    });

    let expandedProduct = localStorage.getItem("expandedProduct");
    expandedProduct = expandedProduct ? JSON.parse(expandedProduct) : {};
    this.props.getOrders({ status: "pending" });
    if (this.state.product.id == expandedProduct.productId) {
      this.setState({
        isProductSetsExpanded: true,
      });

      this.props.toggleView({
        reducerKey: TOGGLE_PRODUCT_EXPAND,
        id: expandedProduct.productId,
      });
    }
  }

  handleProductState = (productState) => {
    let { product } = this.state;
    if (productState !== "batch_process")
      productState = productState && productState === "edit" ? "freeze" : "edit";
    // let formData = new FormData();
    // formData.append("product_state", productState);
    let endpoint = ENDPOINTS.products.update;
    let url = endpoint.uri;
    let method = endpoint.method;
    this.props.updateProduct(product.id, url, method, {
      product_state: productState,
    });
    product.product_state = productState;
    this.setState({ product: product });
    if (productState == "batch_process") {
      alert("Your request is received, queued and will be serviced at the earliest\nCheck your mail for Files Generation Completion...");
    }

    let expandedProduct = {
      productId: product.id,
    };
    //localStorage.setItem("expandedProduct", JSON.stringify(expandedProduct));

    this.props.toggleView({
      reducerKey: TOGGLE_PRODUCT_EXPAND,
      id: expandedProduct.productId,
    });
  };

  _handleDeleteProduct(e) {
    e.preventDefault();
    e.stopPropagation();
    if (confirm("Are you sure you want to delete the Product set along with data?")) {
      let payload = {
        id: this.state.product.id,
        product_id: this.state.product.id,
      };
      let endpoint = ENDPOINTS["products"]["delete"];
      let url = endpoint.uri;
      let method = endpoint.method;
      this.props.deleteProduct(url, method, payload);
    } else {
      return;
    }
  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setCookie(cname, cvalue) {
    document.cookie = cname + "=" + cvalue + ";";
  }
  
  _handleCloneProduct(e, product_id, newName=this.state.name) {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.isCopyProductClicked) {
      if (!this.state.name) return alert("Please enter product name");
      let payload = {
        id: this.state.product.id,
        product_id: this.state.product.id,
        //name: this.state.name,
        name: newName,
        setnos: this.state.cloneable_sets,
        cloneType: this.state.role,
      };
      let endpoint = ENDPOINTS["products"]["clone"];
      let url = endpoint.uri;
      let method = endpoint.method;
      this.props.cloneProduct(url, method, payload);
      this.setState({
        isCopyProductClicked: false,
      });
    } else {
      this.setState({
        isCopyProductClicked: true,
      });
    }
  }

  getProductCategories = () => {
    return [
      { label: "SCHOOL", value: "SCHOOL" },
      { label: "COLLEGE", value: "COLLEGE" },
      { label: "UNIVERSITY", value: "UNIVERSITY" },
      { label: "PRE_SCHOOL", value: "PRE_SCHOOL" },
      { label: "PLAY_SCHOOL", value: "PLAY_SCHOOL" },
      { label: "INDIVIDUAL", value: "INDIVIDUAL" },
      { label: "STUDENT", value: "STUDENT" },
      { label: "ALUMNI", value: "ALUMNI" },
      { label: "TEACHER", value: "TEACHER" },
      { label: "CORPORATE", value: "CORPORATE" },
      { label: "DOCTORS-CLUB", value: "DOCTORS-CLUB" },
      { label: "SPORTS", value: "SPORTS" },
      { label: "BAR-ASSOCIATION", value: "BAR-ASSOCIATION" },
      { label: "MILITARY", value: "MILITARY" },
      { label: "NAVY", value: "NAVY" },
      { label: "NATURE-CLUB", value: "NATURE-CLUB" },
      { label: "WALLSTREET", value: "WALLSTREET" },
      { label: "MUSIC-CLUB", value: "MUSIC-CLUB" },
      { label: "AIRFORCE", value: "AIRFORCE" },
      { label: "GOVERNMENT", value: "GOVERNMENT" },
      { label: "EVENTS", value: "EVENTS" },
      { label: "OTHERS", value: "OTHERS" },
    ];
  };

  getProductSubCategories = () => {
    return [
      { label: "COMPOSITES", value: "COMPOSITES" },
      { label: "PICTURE_BOOKLET", value: "PICTURE_BOOKLET" },
      { label: "SPORTS_PICTURE_BOOKLET", value: "SPORTS_PICTURE_BOOKLET" },
      { label: "FOLDER", value: "FOLDER" },
      { label: "GRADUATION_FOLDER", value: "GRADUATION_FOLDER" },
      { label: "PORTRAIT", value: "PORTRAIT" },
      { label: "PASSPORT", value: "PASSPORT" },
      { label: "ID_CARD", value: "ID_CARD" },
      { label: "REPORT_CARD", value: "REPORT_CARD" },
      { label: "CERTIFICATES", value: "CERTIFICATES" },
      { label: "YEAR_BOOK", value: "YEAR_BOOK" },
      { label: "PAST_PRESENT", value: "PAST_PRESENT" },
      { label: "BUNDLE", value: "BUNDLE" },
      { label: "ALL_IN_ONE", value: "ALL_IN_ONE" },
      { label: "WEDDING", value: "WEDDING" },
      { label: "ANNIVERSARY", value: "ANNIVERSARY" },
      { label: "ENGAGEMENT", value: "ENGAGEMENT" },
      { label: "BIRTHDAY", value: "BIRTHDAY" },
      { label: "GRADUATION", value: "GRADUATION" },
      { label: "AUTOGRAPHS", value: "AUTOGRAPHS" },
      { label: "NEW JOB", value: "JOB" },
      { label: "NEW HOUSE", value: "HOUSE" },
      { label: "NEW BORN", value: "BABY" },
      { label: "BABY SHOWER", value: "SHOWER" },
      { label: "PROMOTION", value: "PROMOTION" },
      { label: "FAREWELL", value: "FAREWELL" },
      { label: "RETIREMENT", value: "RETIREMENT" },
      { label: "GET WELL", value: "GETWELL" },
      { label: "ACHIEVEMENT", value: "ACHIEVEMENT" },
      { label: "OTHER", value: "OTHER" },
    ];
  };

  // More sub categories for Events

  getVoiceOptions = () => {
    if (this.state.countryCode == 'IN') {
      return [
        { label: "NO-VOICE", value: "NORMAL" },
        { label: "VOICE-30SEC - Additional ₹200 per Record", value: "VOICE-30SEC"},
        { label: "VOICE-15SEC - Additional ₹127 per Record", value: "VOICE-15SEC"},
      ];
      
    }
    else {
      return [
        { label: "NO-VOICE", value: "NORMAL" },
        { label: "VOICE-30SEC - Additional $4 per Record", value: "VOICE-30SEC"},
      ];
    }
  };

  handleCancelLayout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isCopyProductClicked: false,
    });
  };

  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  handlePresetToggle = (e) => {
    this.setState({
      is_preset: !this.state.is_preset,
      category_id: this.state.is_preset ? this.state.category_id : "",
    });
  };

  getEditProductJSX = (isVisible) => {
    const product = this.state.product;
    const { name, is_preset, category, numpages, sub_category, expiry } = this.state;
    const style = {
      display: is_preset ? "block" : "none",
    };

    const productCategories = this.getProductCategories();
    const productSubCategories = this.getProductSubCategories();
    const voiceOptions = this.getVoiceOptions();

    if (isVisible) {
      return (
        <Modal
          show={this.state.isEditProductClicked}
          onHide={(e) => {
            this.setState({
              isEditProductClicked: false,
            });
          }}
        >
          <Form onSubmit={this.handleEditProduct} className="override_form">
            <Modal.Header closeButton>
              <Modal.Title>Edit Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FieldGroup
                id="formControlName"
                type="text"
                pattern="[a-zA-Z0-9_\-' ]*"
                title="Only Alphabets,numbers,',-,_ and space allowed"
                label="Product Name:"
                name="name"
                value={name}
                // disabled={true}
                placeholder="Product Name"
                required="required"
                onChange={({ target: { name, value } }) => {
                  this.setState({ [name]: value });
                }}
              />
              <FieldGroup
                id="formControlPages"
                type="number"
                label="Number of pages:"
                name="pages"
                value={numpages}
                disabled={true}
                placeholder="Number of pages"
                required="required"
              />
              {this.state.role === "admin" && (
                <FieldGroup
                  id="formControlIsPreset"
                  type="checkbox"
                  label="Preset:"
                  checked={is_preset}
                  name="is_preset"
                  placeholder="Preset"
                  onChange={this.handlePresetToggle}
                  className="override_checkbox"
                />
              )}
              <FieldGroup
                componentClass="select"
                id="formControlPreset"
                label="Select Product Category:"
                name="category"
                placeholder="Select Product Category"
                value={category}
                onChange={({ target: { name, value } }) => {
                  this.setState({ [name]: value });
                }}
              >
                <option key="None">{null}</option>
                {productCategories.map((category) => {
                  return (
                    <option key={category.value} value={category.id}>
                      {category.label}
                    </option>
                  );
                })}
              </FieldGroup>
              <FieldGroup
                componentClass="select"
                id="formControlPreset"
                label="Select Sub Category:"
                name="sub_category"
                placeholder="Select Product Category"
                value={sub_category}
                onChange={({ target: { name, value } }) => {
                  this.setState({ [name]: value });
                }}
              >
                <option key="None">{null}</option>
                {productSubCategories.map((scategory) => {
                  return (
                    <option key={scategory} value={scategory.id}>
                      {scategory.label}
                    </option>
                  );
                })}
              </FieldGroup>
              <FieldGroup
                componentClass="select"
                id="formControlVoice"
                label="Select Voice option(s):"
                name="voice"
                placeholder="Select Voice options"
                onChange={({ target: { name, value } }) => {
                  if (value != this.state.voice) {
                    if (this.state.voice!='NO-VOICE') {
                      if (value == 'NORMAL' || value == 'VOICE-15SEC') {
                        let alertText="*** WARNING ***\nChanging Audio options will delete any and ALL of Audio Recordings for the Product\nPlease confirm your choice carefully"
                        if (confirm(alertText)== false)
                          return
                          
                      }
                    }
                  }
                  this.setState({ [name]: value }, ()=>{value=this.state.voice});
                }}
                value={this.state.voice}
              >
                
                {voiceOptions.map((vo) => {
                  return (
                    <option key={vo} value={vo.value}>
                      {vo.label}
                    </option>
                  );
                })}
              </FieldGroup>
              {this.state.role === "admin" && (
              <FieldGroup
                id="formControlPages"
                type="datetime-local"
                label="Product Expiry:"
                name="expiry"
                value={expiry}
                placeholder="Set Product Expiry"
                required="required"
                onChange={({ target: { name, value } }) => {
                  this.setState({ [name]: value });
                }}
              />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit">Submit</Button>
              {/* <Button
                onClick={(e) => {
                  this.setState({
                    isEditProductClicked: false,
                  });
                }}
              >
                Cancel
              </Button> */}
            </Modal.Footer>
          </Form>
        </Modal>
      );
    }
  };

  handleEditProduct = (e) => {
    // this.se
    e.preventDefault();
    e.stopPropagation();
    const { product } = this.state;
    let endpoint = ENDPOINTS.products.update;
    let url = endpoint.uri;
    let method = endpoint.method;

    let formData = formDataToJSON(new FormData(e.target));
    formData.name = formData.name.trim()
    formData.is_preset = this.state.is_preset;
    formData.prtype = 
      this.state.voice == 'NO-VOICE' ? 'NORMAL' : this.state.voice;
    this.props.updateProduct(product.id, url, method, formData);
    this.setState({
      isEditProductClicked: false,
    });
  };

  render() {
    const product = this.state.product;
    const role = product.product_state == "edit" ? this.state.role : "viewer";
    const psets = product.setnos;
    const datalevels = this.state.product_datalevels;
    const collapse = this.state.collapse;
    const isEdit = this.state.isEdit;
    const dlExpand = this.state.dlExpand;
    const preset_url_product_id = this.state.preset_url_product_id;
    var datalevelsDOM = null;
    const { view_user, view_and_pay_user } = this.state.product;

    let user_role = document.getElementById("user_role");
    user_role = user_role.innerHTML;

    const cloneProductJSX = (
      <form
        className="form-inline override_form form_flex"
        onSubmit={(e) => this._handleCloneProduct(e, product.id, e.target.name.value)}
      >
        <div className="form-group">
          <input
            type="text"
            name="name"
            onChange={({ target: { name, value } }) => {
              this.setState({ name: value });
            }}
            className="form-control dlname"
            placeholder="Enter New Product Name"
            required="required"
            defaultValue={null}
          />
        </div>
        {psets &&
          Object.keys(psets).map((pset) => {
            return (
              <div className="form-group" key={pset}>
                <label>{pset}</label>
                <input
                  type="checkbox"
                  name="set_nos"
                  onChange={({ target: { name, value, checked } }) => {
                    let setnos = this.state.cloneable_sets;
                    if (checked) {
                      setnos.push(value);
                    } else {
                      setnos.pop(value);
                    }
                    this.setState({ cloneable_sets: setnos });
                  }}
                  value={psets[pset]}
                  className="form-control dlname override_checkbox"
                />
              </div>
            );
          })}
        <button type="submit" className="btn btn-default">
          Save
        </button>
        <a
          className="btn btn-default btn-danger"
          onClick={(e) => this.handleCancelLayout(e, product.id)}
        >
          Cancel
        </a>
      </form>
    );

    if (collapse == true) {
      let orders = product.order || [];
      let isAnyPaidOrders = orders.some((o) => o.status == "paid");
      datalevelsDOM = (
        <ul>
          <li>
            <a className="no-textcolor" onClick={this.onDataLevelsClick}>
              {this.getExpandJSX(dlExpand)} Data levels & Attributes
            </a>
            <ul>
              {dlExpand &&
                datalevels != null &&
                datalevels.length > 0 &&
                datalevels.map((datalevel) => {
                  return (
                    <ProductDLContainer
                      key={datalevel.dlname + "_" + datalevel.product_id}
                      role={role}
                      {...datalevel}
                      id={datalevel.product_id}
                      setExpand={() => {
                        this.setState(
                          {
                            dlExpand: true,
                          },
                          () => {
                            this.props.getProductDatalevels(this.state.product.id);
                          }
                        );
                      }}
                    />
                  );
                })}
              <Authorized
                userRoles={[
                  product.product_state == "edit" ? role : "viewer",
                  product.product_state == "edit" ? product.product_role : "viewer",
                ]}
                requiredRoles={["admin", "owner"]}
                roleType="ANY"
              >
                {dlExpand && (
                  <li>
                    <a onClick={this.newDatalevel}>Create New Data level</a>
                  </li>
                )}
              </Authorized>
            </ul>
          </li>
          <li>
            <a className="no-textcolor" onClick={(e) => this.onProductKeysToggle(e)}>
              {this.getExpandJSX(this.state.isProductKeysExpanded)} Product Set Keys
            </a>
            {this.state.isProductKeysExpanded && (
              <ProductKeysContainer product_id={product.id} role={role} />
            )}
          </li>
          {/* <Authorized
            userRoles={[role, product.product_role]}
            requiredRoles={["admin", "owner", "preset"]}
            roleType="ANY"
          >
            <ProductLayoutListContainer product_id={product.id} role={role} />
          </Authorized> */}
          <ProductLayoutListContainer product_id={product.id} role={role} />
          <li>
            <a className="no-textcolor" onClick={this.onProductSetsToggle}>
              {this.getExpandJSX(this.state.isProductSetsExpanded)} Product Sets
            </a>
            {this.state.isProductSetsExpanded && (
              <ProductSetListContainer product_id={product.id} role={role} />
            )}
          </li>

          <Authorized
            userRoles={[role, product.product_role]}
            requiredRoles={["admin", "owner", "editor"]}
            roleType="ANY"
          >
            {product.product_state === "edit" && (
              <li>
                <a
                  className="no-textcolor"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                      isDataUploadExpanded: !this.state.isDataUploadExpanded,
                    });
                  }}
                >
                  {this.getExpandJSX(this.state.isDataUploadExpanded)} Data{" "}
                </a>
                {this.state.isDataUploadExpanded && (
                  <ProductDataContainer product_id={product.id} />
                )}
              </li>
            )}
            {/* {product.product_state === "edit" && (
              <li>
                <a
                  className="no-textcolor"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                      isImagesUploadExpanded: !this.state
                        .isImagesUploadExpanded,
                    });
                  }}
                >
                  {this.getExpandJSX(this.state.isImagesUploadExpanded)} Images{" "}
                </a>
                {this.state.isImagesUploadExpanded && (
                  <ProductImageUploadContainer product_id={product.id} />
                )}
              </li>
            )} */}
            {product.product_state === "edit" && !preset_url_product_id && (
              <FileMangerContainer
                labelText="View Image Files"
                products={this.state.products}
                product_id={this.state.product.id}
              />
            )}
            
          </Authorized>
          <Authorized
            userRoles={[preset_url_product_id ? role : user_role, product.product_role]}
            requiredRoles={["admin", "owner"]}
            roleType="ANY"
          >
            {/*console.log(
              [preset_url_product_id ? role : user_role, product.product_role],
              "auth roles::::"
            )*/}
            {(product.product_state === "edit" || product.product_state === "completed") && (
              <AddUserContainer
                labelText="Add product User"
                product_id={this.state.product.id}
                show={false}
                is_password_set={product.is_password_set}
                edit_user={product.edit_user}
                role={role}
                isAddViewUser={false}
                isAddViewPayUser={false}
              />
            )}
{/* Bala */}
            {product.product_state === "completed" && (
              <AddUserContainer
                labelText="View User"
                user_id={this.state.user_id}
                product_id={this.state.product.id}
                // view_user_product_code={view_user && view_user.product_code}
                // view_user_password={view_user && view_user.password}
                // is_password_set={view_user ? true : false}
                view_user_user_type="viewer"
                view_user_product_code={product.add_view_product_user && product.add_view_product_user.product_code}
                view_user_password={product.add_view_product_user && product.add_view_product_user.password}
                isAddViewUser={true}
                
              />
              
            )}
            {product.product_state === "completed" && (
              <AddUserContainer
                labelText="View & Pay User"
                user_id={this.state.user_id}
                product_id={this.state.product.id}
                view_and_pay_user_user_type="view_and_pay"
                view_and_pay_user_product_code={product.add_view_and_pay_product_user && product.add_view_and_pay_product_user.product_code}
                view_and_pay_user_password={product.add_view_and_pay_product_user && product.add_view_and_pay_product_user.password}
                isAddViewPayUser={true}
              />
            )}
            {/* <li>
            <a className="no-textcolor" onClick={this.onDataLevelsClick}>
              Orders
            </a>
          </li> */}
            {(product.product_state == "edit" ||
              product.product_state == "freeze" ||
              product.product_state == "completed") && (
              <li>
                <a
                  className="no-textcolor"
                  onClick={() => {
                    this.handleProductState(product.product_state);
                  }}
                >
                  {this.state.product.product_state == "freeze" ||
                  this.state.product.product_state == "completed"
                    ? "Unfreeze Product"
                    : "Freeze Product"}
                </a>
              </li>
            )}
            {product.product_state == "freeze" && (
              <li>
                <a
                  className="no-textcolor"
                  onClick={() => {
                    this.handleProductState("batch_process");
                  }}
                >
                  Prepare Product Files for the Product
                </a>
              </li>
            )}
            {product.product_state == "batch_process" && (
              <li>
                <a className="no-textcolor">Batch process in progress</a>
              </li>
            )}
          </Authorized>
          {!this.state.is_preset && product.product_state == "completed" &&  (
            <React.Fragment>
              {/* <OrderContainer
                product_id={product.id}
                key={product.id}
                treeName={"View Final Product Files"}
              /> */}
              <OrderContainer product_id={product.id} key={product.id} treeName={"Orders"} />
            </React.Fragment>
          )}
          {product.product_state == "completed" &&
            product.product_role != "viewer" &&
            isAnyPaidOrders && (
              <React.Fragment>
                <DownloadFilesContainer
                  product_id={product.id}
                  type="download"
                  treeName="Download Product Files"
                />
              </React.Fragment>
            )}
          {product.product_state == "completed" && isAnyPaidOrders && (
            <React.Fragment>
              <DownloadFilesContainer
                product_id={product.id}
                type="invoice"
                treeName="Orders History"
              />
            </React.Fragment>
          )}
        </ul>
      );
    }
    const readOnly = (
      <React.Fragment>
        <a className="no-textcolor" onClick={this.onExpandToggle}>
          {this.getExpandJSX(collapse)} {product.name} 
        </a>
        
       <span> <a className="no-textcolor" style={{color: new Date(product.expiry) > new Date().getTime() + 3 * 86400000 ? "green" : "red"}} onClick={this.onExpandToggle}>
          {new Date(product.expiry) > new Date().getTime() + 3 * 86400000 ? "- expires on ( " : "- expires soon on ( "}
          {product.expiry ? new Date(product.expiry).toLocaleDateString() : "NOT SET"}
          {" )"}
        </a></span>

        {this.state.isCopyProductClicked && cloneProductJSX}

        {!this.state.isCopyProductClicked && (
          <a
            style={{ paddingLeft: "15px" }}
            title="Copy Product"
            onClick={(e) => {
              this._handleCloneProduct(e, product.id);
            }}
            // confirm="Are you sure you want to delete the layout?"
          >
            <img src="/icons/copy.png" style={{ width: "18px" }} />
          </a>
        )}
        <Authorized
          userRoles={[role, product.product_role]}
          requiredRoles={["admin", "owner"]}
          roleType="ANY"
        >
          {!this.state.isEditProductClicked && (
            <a
              style={{ paddingLeft: "15px" }}
              title="Edit Product Name"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                this.setState({
                  isEditProductClicked: !this.state.isEditProductClicked,
                });
              }}
            >
              <img src="/icons/edit.png" style={{ width: "18px" }} />
            </a>
          )}
          <a
            style={{ paddingLeft: "15px" }}
            title="Delete"
            onClick={(e) => {
              this._handleDeleteProduct(e);
            }}
          >
            <img src="/icons/delete.png" style={{ width: "18px" }} />
          </a>
        </Authorized>
        {this.state.isEditProductClicked && this.getEditProductJSX(this.state.isEditProductClicked)}
      </React.Fragment>
    );
    const editOnly = (
      <form className="form-inline" onSubmit={this.handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="name"
            placeholder="Enter product name"
            required="required"
            value={product.name}
          />
        </div>
        <button type="submit" className="btn btn-default">
          Save
        </button>
      </form>
    );
    if (product != null && (!preset_url_product_id || preset_url_product_id == product.id)) {
      // return <li key={product.id}>{isEdit ? editOnly : readOnly}
      //   { isEdit ? null : <a onClick={this.onEdit}> Edit</a> }
      //   {datalevelsDOM}
      // </li>;
      return (
        <li key={product.id}>
          {isEdit ? editOnly : readOnly}
          {datalevelsDOM}
        </li>
      );
    } else {
      return null;
    }
  }
}

Product.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  collapse: PropTypes.bool.isRequired,
  product_datalevels: PropTypes.array,
};

export default Product;
