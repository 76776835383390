import React, { Fragment } from 'react';
import { ENDPOINTS } from "../constants/endpoints";
import { FieldGroup } from "./bootstrap/Forms.FieldGroup";
import { formDataToJSON } from "../adukku_utils/array";
import { SPLoader } from "./Spinner.js";
import { Button, Modal, Form, FormGroup, ControlLabel, FormControl, Dropdown } from "react-bootstrap";

import {start_audio_Recording, stop_Recording, delete_Recording, save_Recording, get_Blob} from "./AudioHelpers.js"

export class Contribute extends React.Component {
    constructor(props) {
        super(props);
        let customState = { ...props, events: [], upld_aud_blob: "",
          birthday_uniqueImages: [], wedding_uniqueImages: [], mobile:false, recording:false,
          isCreatingContribute: false, edit_flg: false, bride: "", bridegroom: "", bday_name: "", noRecords: '',
          event_id: props.event_id, title: "", img_url: "", event_dt: props.event_dt, isLoading: true,
          c_name: "", message: "", c_img_url: "", c_file: "", c_place: "", c_aud_url: "", c_blob: "", c_page_no: "", c_seq_no: "",
        };
        this.state = customState
        this.getContributeEventfromDB();    
    }
    
    UNSAFE_componentWillMount() {
      this.checkDevice(); 
    }

    checkDevice = () => {
      /* Storing user's device details in a variable*/
      let details = navigator.userAgent; 
        
      /* Creating a regular expression  
      containing some mobile devices keywords  
      to search it in details string*/
      let regexp = /android|iphone|kindle|ipad/i; 
        
      /* Using test() method to search regexp in details 
      it returns boolean value*/
      let isMobileDevice = regexp.test(details); 
        
      if (isMobileDevice) { 
          this.setState({mobile: true})
      } else { 
          this.setState({mobile: false})
      }
    }

    getContributeEventfromDB = () => {
      
      let event_uri = ENDPOINTS.events.show.uri;
      event_uri = event_uri.replace(":event_id", this.props.event_id)
      event_uri = event_uri + "?event_dt=" + this.props.event_dt
      
      let noRecords;
      
      noRecords =  fetch(event_uri, {
        method: "GET", headers: { "content-type": "application/json" }, 
        })
      .then((response) => response.json())
      .then((data) => { 
        if (data.noRecords === 0) this.setState({noRecords: 0})
        else 
        this.setState({
          events: data.events, 
          isLoading: false});    
        });
  
  }

   getAudioFiles = (recorded_audio_file) => {
    let uri = "/api/audio_files?recorded_audio_file=" + recorded_audio_file
    return fetch(uri, {
      method: "GET",
      responseType:'blob',
      header: {"Content-Type": "audio/mpeg"},
    })
      .then((response) => {
        return response.blob()
      })
  }

  handleAudioSubmit = (event) => {
    var files = event.target.files;
    if (files.length > 0){
 
       //document.getElementById("RecNo").textContent="You are Record Number: " + (parseInt(window.sequenceNo) + 1).toString()
       
       
       var audioElement = document.getElementById("audio");
       const RecUrl = URL.createObjectURL(files[0]);
       audioElement.src = RecUrl
       audioElement.load();
       setTimeout(() => {
          // Code goes here
          var maxRectime = 30
          if (audioElement.duration > maxRectime + 1) {
             audioElement.setAttribute('src', '');
             alert("Max audio is only " + maxRectime + " Seconds! Audio File rejected")
          }
          else
            this.setState({
              upld_aud_blob: files[0],
            });
            
          //  else
          //    save_Recording(files[0], recorded_audio_file);
             //alert("Audio file successfully uploaded and saved")
       }, 100)
    }
 }

  _handleValidSubmit = (e) => {

    if (this.state.recording) {
      alert ("Recording in progress! Stop Recording befor proceeding...")
      return false
    }

    e.preventDefault();
    e.stopPropagation();

    //if (!get_Blob()) {
      if (!get_Blob() && !this.state.upld_aud_blob) {
      alert ("Kindly Upload/Record your Audio Wishes before proceeding...")
      return false
    }

    let aud_blob = this.state.upld_aud_blob != "" ? this.state.upld_aud_blob : get_Blob();

    if (aud_blob.size === 0) {  
      alert ("Kindly Record your Audio Wishes before proceeding...")
      return false
    }

    this.setState({c_blob: aud_blob})
    
    let spinner=document.getElementById("contributeSpinner")  
    spinner.style.display=""

    let formData = formDataToJSON(new FormData(e.target));

    let endpoint, url, method
    endpoint = ENDPOINTS.contributes.create;
    url = endpoint.uri;
    method = endpoint.method;

    if (formData.c_img_url.name === "") {
      formData.c_img_url = this.state.c_img_url.toLowerCase()
    }
    else {
      formData.c_img_url = formData.c_img_url.name.toLowerCase();
    }

    formData.event_id = this.props.event_id
    formData.message = this.state.message
    
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: method,
      body: JSON.stringify(formData),
    });
    return ajaxCall
    .then((response) => response.json())
    .then((data) => { 
          this.setState({
                          c_page_no: data.page_no,
                          c_seq_no: data.seq_no,
                        })
          this.closeModal(aud_blob, data.page_no, data.seq_no, this.props.event_id);
      });
  }

   save_contribute_image = (aud_blob, page_no, seq_no, event_id) => {
    let fileExt = this.state.c_img_url.split('.')[1].toLowerCase()
    let img_fileName = event_id + '-' + page_no + '-' + seq_no + '.' + fileExt

    let template_url = "/api/savecontribute_image?event_id=:event_id&contribute_image_file=:filename"
    template_url = template_url.replace(":event_id", event_id).replace(":filename", img_fileName);
  
    let formData = new FormData();
    formData.append("file", this.state.c_file)
    
    $.ajax({
      url: template_url,
      type: "POST",
      method: "POST",
      data: formData,
      processData: false,
      contentType: false,
    }).then(function (data) {
    if (data.message == "Image saved") {
        if (aud_blob.size > 0) {
          let aud_fileName = event_id + '-' + page_no + '-' + seq_no + '.mp3'
          
          let spinner=document.getElementById("contributeSpinner") 
          spinner.style.display="none"

          async function closeFinally (aud_blob, event_id, aud_fileName)  {
            const result = await save_Recording(aud_blob, event_id, aud_fileName)
            if (result === 0) {
              alert ("Thanks for your Wishes.\nYour Wishes have been saved and sent successfully.\nRedirecting to wishcolletor.com")
              window.location.href = "https://wishcollector.com"
            }
          }

          closeFinally(aud_blob, event_id, aud_fileName);
        }    
      }
      else {
        let spinner=document.getElementById("contributeSpinner") 
        spinner.style.display="none !important"
        alert("Image not saved\nKindly submit valid Image")
      }
    });
    }

  closeModal = (aud_blob, page_no, seq_no, event_id) => {
    if (this.state.c_img_url != '') {
      this.save_contribute_image(aud_blob, page_no, seq_no, event_id);
    }
  }

  

  render() {
    if (this.state.noRecords === 0) {
      return <h3>--------- No Event Found! ----------</h3>
    }
    else {

    if (!this.state.isLoading && this.state.events[0]) {

      if (this.state.events[0].status != 'ACTIVE') {
        
        return (
          <React.Fragment>
          <h5>    --------- This Event has been closed by the Creator ----------</h5>
          <h5>    -------------------- Kindly Wish Directly! -------------------</h5>
        </React.Fragment>
        )
        
      }
      else {
           
      return(  

        <React.Fragment>

<Modal show={this.state.isCreatingContribute} onHide={() => {this.setState({ isCreatingContribute: false }); document.querySelector('video').autoplay = true;}}
style={{marginTop:'45px', }}
>
            <Modal.Header closeButton>
              <Modal.Title>
                Submit Wishes
              </Modal.Title>
            </Modal.Header>

            <Form onSubmit={this._handleValidSubmit} className="override_form">
              <Modal.Body>
                
                <FieldGroup
                  type="text"
                  id="c_name"
                  label="Enter Your Name"
                  name="c_name"
                  placeholder="Name?"
                  required="required"
                  onChange={({ target: { name, value } }) => {
                    this.setState({ [name]: value });
                  }}
                  value={this.state.c_name}
                >
                </FieldGroup>

                <FieldGroup
                  type="text"
                  id="c_place"
                  label="Enter Your Place/Location"
                  name="c_place"
                  placeholder="Place/Location?"
                  required="required"
                  onChange={({ target: { name, value } }) => {
                    this.setState({ [name]: value });
                  }}
                  value={this.state.c_place}
                >
                </FieldGroup>



                {/* <FormGroup controlId="formControlsTextarea">
                  <ControlLabel>Enter your Text Message/Wish</ControlLabel>
                  <FormControl 
                    componentClass="textarea" 
                    as="textarea"
                    maxLength={1000}
                    value={this.state.message}
                    required="required"
                    onChange={e => this.setState({ message: e.target.value })}
                    placeholder="Best Wishes." 
                  />
                </FormGroup> */}
                  
                <img id="output" src='' alt="" height="150" width="auto" />

                <FieldGroup
                  type="file"
                  id="c_image"
                  label="Upload Selfie/Picture clearly showing face"
                  name="c_img_url"
                  placeholder="Picture/Selfie"
                  required="required"
                  onChange={(event) => {
                    if (event.target.value) {
                      this.setState({c_file: event.target.files[0]})
                      this.setState({c_img_url: event.target.value });
                      var output = document.getElementById('output');
                      output.src = URL.createObjectURL(event.target.files[0]);
                      output.style.display='block'
                      
                    }
                  }}
                  //value={this.state.c_img_url}
                  
                >

                </FieldGroup>




                


              {this.state.mobile?
              <Fragment>
                <span style={{color:'black', fontSize:"18px", fontWeight:"bold"}}>Record your Audio Message/Wish</span>
                <br></br>
                <span style={{color:'grey', fontSize:"12px", }}>- Max 30 secs</span>
                <br></br>
                <button type="button" id="aud_st" onClick={() => {
                  this.setState({recording:true, upld_aud_blob: ""})
                  start_audio_Recording('DummyFile.mp3')} 
                }
                // style='color:red;border: 1px solid #000;padding-right:5px;padding-left:5px;padding-top:16px;padding-bottom:4px'
                  style={{color:'red', border:'1px solid #000', marginLeft: '100px', paddingRight:'5px', paddingLeft:'5px', paddingTop:'5px', paddingBottom:'5px', marginTop: '10px' }}>
                  <i className='fa fa-circle fa-2x'></i>
                </button>

                <button type="button" id="aud_en" onClick={() => {
                  this.setState({recording:false,})
                  stop_Recording()}
                }
                //  style={"display:inline-block;vertical-align:middle;padding-right:3px;padding-left:2px;"}>
                style={{color:'black', border:'1px solid #000', marginLeft: '60px', paddingRight:'5px', paddingLeft:'5px', paddingTop:'5px', paddingBottom:'5px', marginTop: '10px' }}>
                  <i className='fa fa-stop fa-2x'></i>
                </button>

                <div style={{display: 'flex', flexDirection: 'row', margin: '5px'}}>
                  <h5 style={{color:'black', marginLeft: '104px'}}>Rec</h5>
                  <h5 style={{color:'black', marginLeft: '71px'}}>Stop</h5>
                </div>
                <h5 style={{color:'black', marginLeft: '98px', marginTop: '-25px'}}>Re-Rec</h5>

                <div style={{display:'inline-block', alignContent:'center', verticalAlign:'middle', marginLeft: '5px'}}>
                  <audio id="audio"  controls  
                  style={{display: 'inline-block', height:'30px',  verticalAlign:'middle', paddingTop:'0px', marginTop: '-10px', marginBottom: '20px', }}> 
                  </audio>
                  
                  <button type="button" id="aud_del"  onClick={() => delete_Recording()} 
                    style={{display:'inline-block', verticalAlign:'middle', color:'grey', marginLeft: '10px', marginTop:'-35px'}}>
                    <i className='fa fa-trash fa-lg'></i>
                  </button>
                </div>

                <h3 id="CountDown" tabIndex="-1" style={{marginTop:'2px', marginLeft:'25px', color: 'red'}}></h3>
                
              </Fragment>
              :
              <Fragment>
                <h4 style={{color:'grey'}}>Record your Audio Message/Wish - Max 30 secs</h4>

                <button type="button" id="aud_st" onClick={() => {
                  this.setState({recording:true,})
                  start_audio_Recording('DummyFile.mp3')} 
                }
                // style='color:red;border: 1px solid #000;padding-right:5px;padding-left:5px;padding-top:16px;padding-bottom:4px'
                  style={{color:'red', border:'1px solid #000', marginLeft: '30px', paddingRight:'5px', paddingLeft:'5px', paddingTop:'5px', paddingBottom:'5px', marginTop: '10px' }}>
                  <i className='fa fa-circle fa-2x'></i>
                </button>

                <button type="button" id="aud_en" onClick={() => {
                  this.setState({recording:false,})
                  stop_Recording()}
                }
                //  style={"display:inline-block;vertical-align:middle;padding-right:3px;padding-left:2px;"}>
                style={{color:'black', border:'1px solid #000', marginLeft: '40px', paddingRight:'5px', paddingLeft:'5px', paddingTop:'5px', paddingBottom:'5px', marginTop: '10px' }}>
                  <i className='fa fa-stop fa-2x'></i>
                </button>

                <audio id="audio"  src="" controls={true}
                  style={{display:'inline-block', verticalAlign:'middle', height:'30px', paddingLeft:'40px', paddingTop:'0px', marginTop: '-10px'}}>
                </audio>
                
                <div style={{display:'inline-block', verticalAlign:'middle', marginTop: '-11px'}}>
                <button type="button" id="aud_del"  onClick={() => delete_Recording()} 
                  style={{color:'grey', marginLeft: '10px'}}>
                  <i className='fa fa-trash fa-lg'></i>
                </button>
                </div>

                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <h5 style={{color:'black', marginLeft: '39px'}}>Rec</h5>
                  <h5 style={{color:'black', marginLeft: '53px'}}>Stop</h5>
                </div>
                <h5 style={{color:'black', marginLeft: '28px', marginTop: '-25px'}}>Re-Rec</h5>

                <h1 id="CountDown" tabIndex="-1" style={{marginTop:'5px', marginLeft:'25px', color: 'red'}}></h1>
                
              </Fragment>
              }  
                <div id="myProgress" style={{position: 'relative', marginBottom: '15px', marginTop: '-10px', width: '100%', height: '20px', backgroundColor: '#ddd'}}>
                  <div id="myBar" style={{position: 'absolute', width: '0%', height: '100%', backgroundColor: '#4CAF50'}}>
                    <div id="label" style={{textAlign: 'center', lineHeight: '20px', color: 'white'}}>
                      0%
                    </div>
                  </div>
                </div>

                <div id="uploadDiv"  style={{marginBottom: '10px', display:'flex', flexDirection:'column', alignItems:'center'}}>
                  <label htmlFor="upload" style={{width: "50%"}} className="btn btn-success btn-sm">Upload Audio (max 30 secs)</label>
                  <input type="file" id="upload" style={{display:'none'}}  accept=".mp3, .wav" onChange={(event)=>{this.handleAudioSubmit(event)}}/>
                </div>


                <FormGroup controlId="formControlsTextarea">
                  <ControlLabel>Enter your Text Message/Wish</ControlLabel>
                  <FormControl 
                    componentClass="textarea" 
                    as="textarea"
                    maxLength={1000}
                    value={this.state.message}
                    required="required"
                    onChange={e => this.setState({ message: e.target.value })}
                    placeholder="Best Wishes." 
                  />
                </FormGroup>



              </Modal.Body>

              <Modal.Footer>
                <Button type = "submit" style={{ backgroundColor: "blue", borderRadius: '25%', color: "white"}} >
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>



          



        {this.state.mobile?

          <div style={{paddingLeft:'10px', paddingRight:'10px'}}>
          <div key={this.state.event_id} style={{ display: 'flex', flexDirection: 'column' , boxShadow: '5px 10px 10px #888888', border: '1px solid black', marginBottom: '25px', paddingTop: '10px', }}>
            <h4 style={{marginTop: '5px', marginLeft:'10px'}}>{this.state.events[0].title}</h4>
            {this.state.events[0].video?
                  <video controls autoPlay={true} playsInline id="videoSaved" src={`uploads/events/${this.state.events[0].id}/${this.state.events[0].id}.mp4?${new Date().getTime()}}`} 
                    style={{width:'auto', boxShadow: '1px 1px 1px #888888', border: '5px solid black', marginBottom:'30px'}}>
                  </video>
                 : ''} 
            <img id="eventImage" src={`uploads/events/${this.state.events[0].id}/pictures/`+this.state.events[0].img_url} alt="" width="auto"  
            style={{cursor: 'pointer', marginBottom:"5px"}}
            onClick={(event) => {
              window.open(`uploads/events/${this.state.events[0].id}/pictures/`+this.state.events[0].img_url, "_blank")
            }}
            />
            <div style={{paddingLeft:'5px'}}>
              
                { this.state.events[0].event_type === "Wedding" || this.state.events[0].event_type === "Anniversary" || this.state.events[0].event_type === "Engagement"?
                  <span style={{fontSize:"15px", fontWeight:"bold"}}> {this.state.events[0].bride}</span>
                :
                  ''
                }
                <br></br>
                { this.state.events[0].event_type === "Wedding" || this.state.events[0].event_type === "Anniversary" || this.state.events[0].event_type === "Engagement"?
                    <span style={{fontSize:"15px", fontWeight:"bold"}}> {this.state.events[0].bridegroom}</span>
                :
                  ''
                }
                { this.state.events[0].event_type != "Wedding" && this.state.events[0].event_type != "Anniversary" && this.state.events[0].event_type != "Engagement"?
                  <span style={{fontSize:"15px", fontWeight:"bold"}}>Name: {this.state.events[0].bday_name}</span>
                :
                  ''
                }
                <br></br>
                <span style={{fontSize:"15px", fontWeight:"bold"}}>Date: {this.state.events[0].event_dt}</span>
                <br></br>
                <br></br>
              
                <Button bsStyle="success" bsSize="large" style={{margin:"3px"}}
                onClick={() => {
                  let proceed = confirm(
                    "Do you recognize this Event?\n\nDo you want to send your wishes?\n\nKindly press Cancel if you do not recognize this Event."
                    )
                  if (proceed) {
                    this.setState({isCreatingContribute: true})
                    document.querySelector('video').autoplay = false;
                  }
                }}
              >
                
                Confirm Event & Proceed to Wish
              </Button>
              <br></br>
              <br></br>
            </div>
          </div>            
</div>

        :

          <div style={{paddingLeft:'25px', paddingRight:'25px'}}>
            <div key={this.state.event_id} style={{ display: 'flex', flexDirection: 'row' , boxShadow: '5px 10px 10px #888888', border: '1px solid black', marginBottom: '25px', paddingLeft: '10px', paddingTop: '10px', paddingBottom: '70px'}}>
              {this.state.events[0].video?
                  <video controls autoPlay={false} playsInline id="videoSaved" src={`uploads/events/${this.state.events[0].id}/${this.state.events[0].id}.mp4?${new Date().getTime()}}`}
                    style={{height:'400px', width:'500px', boxShadow: '3px 3px 3px #888888', border: '5px solid black', marginRight:'10px', marginBottom:'30px'}}>
                  </video>
                 : ''} 
              <img id="eventImage" src={`uploads/events/${this.state.events[0].id}/pictures/`+this.state.events[0].img_url} alt="" height="400px" width="auto"  
               style={{cursor: 'pointer'}}
               onClick={(event) => {
                window.open(`uploads/events/${this.state.events[0].id}/pictures/`+this.state.events[0].img_url, "_blank")
               }}
              />
              <div style={{paddingLeft:'5px'}}>
                <h3 style={{marginTop: '10px'}}>{this.state.events[0].title}</h3>
                  { this.state.events[0].event_type === "Wedding" || this.state.events[0].event_type === "Anniversary" || this.state.events[0].event_type === "Engagement"?
                     <h4> {this.state.events[0].bride}</h4>
                  :
                    ''
                  }
                  { this.state.events[0].event_type === "Wedding" || this.state.events[0].event_type === "Anniversary" || this.state.events[0].event_type === "Engagement"?
                      <h4> {this.state.events[0].bridegroom}</h4>
                  :
                    ''
                  }
                  { this.state.events[0].event_type != "Wedding" && this.state.events[0].event_type != "Anniversary" && this.state.events[0].event_type != "Engagement"?
                      <h4>Name of the person: {this.state.events[0].bday_name}</h4>
                  :
                    ''
                  }
                    <h4>Date: {this.state.events[0].event_dt}</h4>
              </div>
              </div>
              <div>
                <Button style={{backgroundColor:"green", color:"white", fontSize:"20px", marginBottom: '25px', marginTop: '-120px', marginLeft: '10px' }}  
                  onClick={() => {
                    let proceed = confirm(
                      "Do you recognize this Event?\n\nDo you want to send your wishes?\n\nKindly press Cancel if you do not recognize this Event."
                      )
                    if (proceed) {
                      this.setState({isCreatingContribute: true})
                    }
                  }}
                >
                  
                  Confirm Event & Proceed to Wish
                </Button>

                
              {/* </div> */}
            </div>            
          </div>
        }

              <div id="contributeSpinner" style={{display:"none", zIndex:'9999'}}>
                <SPLoader></SPLoader>
              </div>

        </React.Fragment>
      )
    }
    }
    else {
      if (!this.state.isLoading && this.state.events.length === 0) {
        return <h3>--------- No Event Found! ----------</h3>
      }
      else
        return null
    }
  }
    

  }
}

export default Contribute;