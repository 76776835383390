import React, { useState } from 'react';
import { Navbar, Nav, NavItem, MenuItem, NavDropdown, Dropdown } from 'react-bootstrap';
import logo from 'images/Composz-logo-white-trans.png';
import Mynavbar from './WishCollectorNav';

export class WishCollectorNavbar extends React.Component {
  
  
  constructor(props) {
    super(props)
    this.state = {
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
        };
        document.cookie = "cartCounter=25;";
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
}

  handleResize = e => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    
    this.setState(prevState => {
        return {
        windowWidth,
        windowHeight,
        };
    });
  };

  
   Fnavbar = () => {
    let Usernavbar = Mynavbar(this.props.user_id, this.props.user_name, this.props.has_view_products)
    return Usernavbar
   }

  render() {

    return (
      <React.Fragment>
      {window.location.pathname!="/products" && 
        window.location.pathname!="/orders" &&
        window.location.pathname!="/orders/cart"? 
        <this.Fnavbar />
        : 
        <this.Fnavbar />
    }
      </React.Fragment>  
      );
  }
}

export default WishCollectorNavbar
