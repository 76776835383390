import React from "react";
import PropTypes from "prop-types";
import { ENDPOINTS } from "../../constants/endpoints";
import ProductSetKeyValListContainer from "../../containers/product_sets/ProductSetKeyValListContainer";
import ProductSetDLValListContainer from "../../containers/product_sets/ProductSetDLValListContainer";
import ProductSetLayoutContainer from "../../containers/product_sets/ProductSetLayoutContainer";
import ProductSetCountListContainer from "../../containers/product_sets/ProductSetCountListContainer";
import { authorized, Authorized } from "../../adukku_utils/authorizeUser";
import {
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
  Fade,
  utils,
  Button,
} from "react-bootstrap";
import { FieldGroup } from "../bootstrap/Forms.FieldGroup";
import { formDataToJSON } from "../../adukku_utils/array";

export default class ProductSet extends React.Component {
  constructor(props) {
    super(props);
    let customState = {
      ...props,
      isEditLayoutClicked: false,
      isProductSetLayoutExpanded: false,
    };
    customState.expand = false;
    this.state = customState;
    this.onExpandToggle = this.onExpandToggle.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state !== nextProps) {
      this.setState(nextProps);
    }
  }

  componentDidMount() {
    this.props.getSetPlaceholders(this.state.product_id, this.state.setno);
    let expandedProduct = localStorage.getItem("expandedProduct");
    expandedProduct = expandedProduct ? JSON.parse(expandedProduct) : {};

    if (
      expandedProduct.productId &&
      this.state.product.id == expandedProduct.productId &&
      this.state.setno == expandedProduct.setNo &&
      (expandedProduct.isSet || expandedProduct.pagePreview)
    ) {
      this.setState({
        expand: true,
        isProductSetLayoutExpanded: true,
      });
      localStorage.setItem("expandedProduct", JSON.stringify({}));
    }
  }

  onExpandToggle(e) {
    if (e == undefined) return;
    e.preventDefault();
    e.stopPropagation();
    this.setState({ expand: !this.state.expand }); // TODO set from reducer
  }

  _handleDeleteSet(e, setno) {
    e.preventDefault();
    e.stopPropagation();
    if (confirm("Are you sure you want to delete the Product set along with data?")) {
      let payload = {
        setno: setno,
        product_id: this.state.product.id,
      };
      let endpoint = ENDPOINTS["sets"]["delete"];
      let url = endpoint.uri;
      let method = endpoint.method;
      this.props.deleteSet(url, method, payload);
    } else {
      return;
    }
  }

  getEditLayoutJSX = (isVisible) => {
    const layout = this.state.layout;
    const { desc, height, width, is_preset_bg, is_preset_sticker } = this.state;
    const layoutCategories = this.state.layoutCategories || [];

    if (isVisible) {
      return (
        <Modal
          show={this.state.isEditLayoutClicked}
          onHide={(e) => {
            this.setState({
              isEditLayoutClicked: false,
            });
          }}
        >
          <Form onSubmit={this.handleEditLayout} className="override_form">
            <Modal.Header closeButton>
              <Modal.Title>Edit Set</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FieldGroup
                id="formControlName"
                type="text"
                label="Description:"
                name="desc"
                value={desc}
                onChange={({ target: { name, value } }) => {
                  this.setState({ [name]: value });
                }}
                // disabled={true}
                placeholder="Product Set Name"
                required="required"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit">Submit</Button>
              {/* <Button
                onClick={(e) => {
                  this.setState({
                    isEditLayoutClicked: false,
                  });
                }}
              >
                Cancel
              </Button> */}
            </Modal.Footer>
          </Form>
        </Modal>
      );
    }
  };

  getExpandJSX = (expandFlag) => {
    return (
      <span>
        {expandFlag && <i className="icon-collapse" aria-hidden="true"></i>}
        {!expandFlag && <i className="icon-expand" aria-hidden="true"></i>}
      </span>
    );
  };

  handleEditLayout = (e) => {
    // this.se
    e.preventDefault();
    e.stopPropagation();
    const { setno, product } = this.state;
    let endpoint = ENDPOINTS.sets.update;
    let url = endpoint.uri;
    let method = endpoint.method;

    let formData = formDataToJSON(new FormData(e.target));
    // formData.is_preset_bg = this.state.is_preset_bg;
    // formData.is_preset_sticker = this.state.is_preset_sticker;
    // formData.sticker_category =
    //   this.state.sticker_category == "other"
    //     ? this.state.other_category
    //     : this.state.sticker_category;
    //console.log(formData);
    formData.product_id = product.id;
    this.props.updateSet(setno, url, method, formData);
    this.setState({
      isEditLayoutClicked: false,
    });
  };

  render() {
    const { role, product_role } = this.state;
    const expand = this.state.expand;
    const setno = this.state.setno;
    const desc = this.state.desc;
    const keyvals = this.state.keyvals;
    const dlvals = this.state.dlvals;
    const counts = this.state.counts;
    const layouts = this.state.layouts;
    const product = this.state.product;
    const anchorDLDeleteStyle = {
      paddingLeft: "20px",
    };
    let expandView = [];
    let t = [];
    if (expand) {
      expandView.push(
        <ProductSetKeyValListContainer
          key="noval"
          setno={setno}
          keyvals={keyvals}
          role={role}
          product_role={product_role}
        />
      );
      expandView.push(
        <ProductSetCountListContainer
          key="noval"
          setno={setno}
          counts={counts}
          role={role}
          product_role={product_role}
        />
      );
      expandView.push(
        <ProductSetDLValListContainer
          key="noval"
          setno={setno}
          dlvals={dlvals}
          role={role}
          product_role={product_role}
        />
      );
      for (let i = 1; i <= product.numpages; i++) {
        t.push(
          <ProductSetLayoutContainer
            key="noval"
            page={i}
            product_id={product.id}
            setno={setno}
            layout={layouts[i] || {}}
            pageno={i}
            role={role}
            product_role={product_role}
          />
        );
      }
      expandView.push(
        <li>
          <a
            className="no-textcolor"
            onClick={(e) => {
              e.stopPropagation();
              this.setState({
                isProductSetLayoutExpanded: !this.state.isProductSetLayoutExpanded,
              });
            }}
          >
            {this.getExpandJSX(this.state.isProductSetLayoutExpanded)} Layouts
          </a>
          {/* {this.getExpandJSX(expand)} Layouts */}
          {this.state.isProductSetLayoutExpanded && <ul>{t}</ul>}
        </li>
      );
      // expandView.push(<li>Pre-View/Edit Product Set </li>);
      // expandView.push(<li>View Final Product Set </li>);
    }
    return (
      <li key={setno}>
        <a className="no-textcolor" onClick={this.onExpandToggle}>
          {this.getExpandJSX(expand)} {`${desc}`}
          {/* {`${desc} -- #${setno}`}
          --{" "} */}
        </a>

        <Authorized
          userRoles={[role, product.product_role]}
          requiredRoles={["admin", "owner"]}
          roleType="ANY"
        >
          <a
            onClick={(e) => {
              this._handleDeleteSet(e, setno);
            }}
            style={{ paddingLeft: "15px" }}
            title="Delete"
          >
            <img src="/icons/delete.png" style={{ width: "17px" }} />
          </a>
          {!this.state.isEditLayoutClicked && (
            // <Authorized
            //   userRoles={[role, product.product_role]}
            //   requiredRoles={["admin"]}
            //   roleType="ANY"
            // >
            // </Authorized>
            <a
              style={{ paddingLeft: "15px" }}
              title="Edit"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                this.setState({
                  isEditLayoutClicked: !this.state.isEditLayoutClicked,
                });
              }}
            >
              <img src="/icons/edit.png" style={{ width: "18px" }} />
            </a>
          )}

          {this.state.isEditLayoutClicked && this.getEditLayoutJSX(this.state.isEditLayoutClicked)}
        </Authorized>
        <ul>
          {expandView}
          <Authorized
            userRoles={[role, product.product_role]}
            requiredRoles={["admin", "owner", "viewer"]}
            roleType="ANY"
          >
            <li>
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  let expandedProduct = {
                    productId: product.id,
                    setNo: setno,
                    pagePreview: true,
                  };
                  localStorage.setItem("expandedProduct", JSON.stringify(expandedProduct));
                  window.location = "/products/" + product.id + "/sets/" + setno + "/page_preview";
                }}
                className="no-textcolor"
                // href={
                //   "/products/" + product.id + "/sets/" + setno + "/page_preview"
                // }
                // target="_blank"
              >
                Pre-View/Edit Product Set
              </a>
            </li>
            {/* <li>
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  let expandedProduct = {
                    productId: product.id,
                    setNo: setno,
                    pagePreview: true,
                  };
                  localStorage.setItem("expandedProduct", JSON.stringify(expandedProduct));
                  window.location = "/products/" + product.id + "/sets/" + setno + "/page_preview";
                }}
                className="no-textcolor"
                // href={
                //   "/products/" + product.id + "/sets/" + setno + "/page_preview"
                // }
                // target="_blank"
              >
                View Final Product Set
              </a>
            </li> */}
          </Authorized>
        </ul>
      </li>
    );
  }
}
