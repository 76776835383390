import React from "react";
import PropTypes from "prop-types";
import { ENDPOINTS } from "../constants/endpoints";


export default class ProductImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: props.product,
      product_datalevels: props.product_datalevels,
      uploadedImages: {},
      uploadedImageNames: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.product !== this.state.product) {
      this.setState({ product: nextProps.product });
    }
    if (nextProps.product_datalevels !== this.state.product_datalevels) {
      this.setState({ product_datalevels: nextProps.product_datalevels });
    }
  }

  componentDidMount() {
    const { product_datalevels } = this.state;
    if (product_datalevels && product_datalevels.length > 0) {
      let uploadedImages = {};
      let uploadedImageNames = {};
      product_datalevels.map((datalevel) => {
        uploadedImages[datalevel.dlname] = [];
        uploadedImageNames[datalevel.dlname] = [];
      });
      this.setState({
        uploadedImages,
        uploadedImageNames,
      });
    }
  }

  uploadImages = (dlname) => {
    const that = this;
    const product = this.state.product;
    const uploadedImages = this.state.uploadedImages;

    let template_url = ENDPOINTS.images.upload;
    template_url = template_url
      .replace(":product_id", product.id)
      .replace(":dlname", dlname);
    let formData = new FormData();
    for (let index = 0; index < uploadedImages[dlname].length; index++) {
      formData.append("files[]", uploadedImages[dlname][index]);
    }
    let $ajax = $.ajax({
      url: template_url,
      type: "POST",
      method: "POST",
      data: formData,
      processData: false,
      contentType: false,
    });
    $ajax.done(function () {
      alert("Successfully, uploaded file");
    });
    $ajax.fail(function (jqXHR, textStatus, errorThrown) {
      if (jqXHR.status == 406) {
          alert("File size is in excess of 5MB\n\nPlease Upload file again with size less than 5MB");
      }
      else
        alert("An internal server error occured");
    });
    $ajax.always(function () {
      // $f.find("input:file").val("");
      uploadedImages[dlname] = [];
      that.setState({ uploadedImages: uploadedImages });
    });
  };

  handleSubmit(e, dlname) {
    e.preventDefault();
    const product = this.state.product;
    let uri = ENDPOINTS.csv.uploaded_data;
    let uploadedImages = this.state.uploadedImages;
    let uploadedImageNames = this.state.uploadedImageNames;
    let imageNamesMatched = false;
    uri = uri.replace(":product_id", product.id).replace(":dlname", dlname);
    this.uploadImages(dlname);
    // return fetch(uri, {
    //   method: "GET",
    //   headers: {
    //     "content-type": "application/json"
    //   }
    // })
    //   .then(response => response.json())
    //   .then(response => {
    //     let image_attribute_name =
    //       response.image_attributes && response.image_attributes.name;
    //     if (
    //       image_attribute_name === null ||
    //       image_attribute_name === undefined
    //     ) {
    //       return alert("Add Data for " + dlname);
    //     }
    //     let studentImageNames = [];
    //     if (
    //       response.upload_data &&
    //       response.upload_data.length === uploadedImages[dlname].length
    //     ) {
    //       response.upload_data.forEach(item => {
    //         if (item.data[image_attribute_name]) {
    //           studentImageNames.push(item.data[image_attribute_name]);
    //         }
    //       });
    //       for (
    //         let index = 0;
    //         index < uploadedImageNames[dlname].length;
    //         index++
    //       ) {
    //         const uploadedImageName = uploadedImageNames[dlname][index];
    //         if (studentImageNames.includes(uploadedImageName)) {
    //           imageNamesMatched = true;
    //         }
    //       }
    //       if (imageNamesMatched) this.uploadImages(dlname);
    //     }
    //     else {
    //       return alert("no of students doesn't match no. of Images uploaded");
    //     }
    //     return response;
    //   });
  }

  fileSelectedHandler = (e, dlname) => {
    let uploadedImages = this.state.uploadedImages;
    let uploadedImageNames = this.state.uploadedImageNames;
    let files = e.target.files;
    for (let index = 0; index < files.length; index++) {
      let file_size = Math.floor(files[index].size / (1024 * 1024));
      if (file_size > 4) {
        // e.target.files = null;
        alert(
          "file size should be less than 4MB, Skippping File:" +
            files[index].name
        );
      } else {
        uploadedImages[dlname].push(files[index]);
        uploadedImageNames[dlname].push(files[index].name);
      }
    }
    this.setState({
      uploadedImages: uploadedImages,
      uploadedImageNames: uploadedImageNames,
    });
  };

  removeImage = (e, dlname) => {
    //console.log(e.target);
    let imageName = e.target.id;
    let { uploadedImages, uploadedImageNames } = this.state;
    uploadedImages[dlname] = uploadedImages[dlname].filter(
      (fag) => fag.name !== imageName
    );
    uploadedImageNames[dlname] = uploadedImageNames[dlname].filter(
      (fag) => fag.name !== imageName
    );
    this.setState({
      uploadedImages: uploadedImages,
      uploadedImageNames: uploadedImageNames,
    });
  };

  getUploadedImages = (dlname) => {
    const uploadedImages = this.state.uploadedImages;
    let imageList = [];
    if (uploadedImages && uploadedImages[dlname])
      for (let index = 0; index < uploadedImages[dlname].length; index++) {
        const element = uploadedImages[dlname][index];
        imageList.push(
          <div style={{ paddingLeft: "10px" }}>
            {element.name}
            <span>
              <img
                id={element.name}
                src="icons/delete.png"
                onClick={(e) => {
                  this.removeImage(e, dlname);
                }}
                style={{ width: "18px", marginLeft: "20px", cursor: "pointer" }}
              />
            </span>
          </div>
        );
      }
    return imageList;
  };

  render() {
    const product = this.state.product;
    const datalevels = this.state.product_datalevels;
    let template_url = ENDPOINTS.csv.download;

    template_url = template_url.replace(":product_id", product.id);
    if (datalevels == null || product == null) {
      return (
        <ul>
          <li>
            <strong>Internal error occured</strong>
          </li>
        </ul>
      );
    }
    return (
      <ul>
        {datalevels.map((p, i) => {
          return (
            <li>
              <table>
                <tbody>
                  <tr>
                    <td style={{ display: "flex" }}>{p.dlname}</td>
                    <td>
                      <form
                        onSubmit={(e) => {
                          this.handleSubmit(e, p.dlname);
                        }}
                        enctype="multipart/form-data"
                        style={{
                          display: "flex",
                          marginBottom: 0,
                          paddingLeft: "10px",
                        }}
                      >
                        <input
                          type="file"
                          multiple
                          value={this.state.studentImages}
                          onChange={(e) => {
                            this.fileSelectedHandler(e, p.dlname);
                          }}
                          accept="image/x-png,image/jpeg"
                          style={{ display: "none" }}
                          id={"upload_images" + p.dlname}
                        />
                        <label
                          htmlFor={"upload_images" + p.dlname}
                          style={{ cursor: "pointer" }}
                        >
                          <p>
                            Add images
                            <span>
                              <img src="https://img.icons8.com/windows/32/000000/add.png" />
                            </span>
                          </p>
                        </label>
                        <label title="Upload Images">
                          <input
                            type="submit"
                            value="upload"
                            required
                            style={{ display: "none" }}
                          />
                          <img
                            src="icons/UploadImagesIcon.png"
                            style={{
                              width: "25px",
                              marginLeft: "20px",
                              cursor: "pointer",
                            }}
                          />
                        </label>
                      </form>
                    </td>
                  </tr>
                  <tr>
                    <td>Uploaded Image files:</td>
                    <td>{this.getUploadedImages(p.dlname)}</td>
                  </tr>
                </tbody>
              </table>
            </li>
          );
        })}
      </ul>
    );
  }
}
