import {
  GET_PRODUCTS,
  PRODUCTS,
  GET_PRODUCT_DATA_LEVELS,
  PRODUCT_DATA_LEVELS,
  TOGGLE,
  UPDATE_OR_DELETE_RESOURCE,
  CREATE_RESOURCE,
  CREATE_PRODUCT,
  CLEAR_PRODUCT_DATA_LEVELS,
  GET_CATEGORIES,
  ADD_CATEGORIES,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SAGA,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SAGA,
  ADD_PRODUCT_USER,
  PRODUCT_USER,
  ADD_VIEW_PRODUCT_USER,
  GET_VIEW_PRODUCT_USER,
  GET_UPLOADED_DATA,
  GET_UPLOADED_DATA_SAGA,
  UPDATE_UPLOADED_DATA,
  UPDATE_UPLOADED_DATA_SAGA,
  CLONE_PRODUCT,
  DELETE_PRODUCT_DATA_LEVEL,
  TOGGLE_PRODUCT_EXPAND,
} from "../actions";
import { getSets } from "../actions/product_sets";
import { getKeys } from "../actions/product_keys";
import { hideOverlay } from "../actions/misc_actions";
import { getProducts, getProductDatalevels } from "../actions";
import { takeEvery } from "redux-saga/effects";
import { ENDPOINTS } from "../constants/endpoints";
import { put, call } from "redux-saga/effects";

// todo common logic for fetching end points
function* fetchDatalevels(action) {
  const apiCall = (action) => {
    let url = ENDPOINTS.datalevels.list.uri;
    url = url.replace(":id", action.product_id);
    return fetch(url)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    yield put({ type: GET_PRODUCT_DATA_LEVELS, payload: data.datalevels });
  } catch (error) {
    throw error;
  }
}

function* fetchProducts(action) {
  const apiCall = () => {
    return fetch(ENDPOINTS.products.list.uri + "?type=" + action.products_type)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall);
    yield put({ type: GET_PRODUCTS, payload: data.products });
  } catch (error) {
    throw error;
  }
}

function* updateResource(action) {
  const apiCall = (action) => {
    const endpoint = ENDPOINTS[action.resource][action.method];
    const url = action.id == null ? endpoint.uri : endpoint.uri + action.id;
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: endpoint.method,
      body: JSON.stringify(action.payload || {}),
    });
    return ajaxCall
      .then((response) => {
        if (response.status===400) {
          alert("Action not performed. Entity in use")
          window.location.reload()
        }
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  let data = null;
  let product_id = null;
  try {
    data = yield call(apiCall, action);
    data.attribute = data.attribute || {};
    data.datalevel = data.datalevel || {};
    data.datalevel.attribute = data.datalevel.attribute || {};
    if (data.errors == null || data.errors.length == 0) {
      yield put({ type: DELETE_PRODUCT_DATA_LEVEL, id: action.id });
      yield put({ type: action.payload.reducerKey, id: action.id });
      product_id =
        data.product_id || data.attribute.product_id || data.datalevel.attribute.product_id;
    } else {
      alert(data.errors.join("\n"));
    }
  } catch (error) {
    console.error(error);
  }
  try {
    if (product_id) {
      yield push(getProductDatalevels(product_id));
      yield put(getKeys(product_id));
      yield put(getSets(product_id));
    }
  } catch (error) {
    console.error(error);
    // alert("Error -> " + error.message);
  }
}

function* createResource(action) {
  const apiCall = (action) => {
    const endpoint = ENDPOINTS[action.resource]["create"];
    const url = endpoint.uri + action.id;
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: endpoint.method,
      body: JSON.stringify(action.payload || {}),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  let data = null;
  let product_id = null;
  try {
    data = yield call(apiCall, action);
    data.attribute = data.attribute || {};
    data.datalevel = data.datalevel || {};
    data.datalevel.attribute = data.datalevel.attribute || {};
    if (data.errors == null || data.errors.length == 0) {
      product_id =
        data.product_id || data.attribute.product_id || data.datalevel.attribute.product_id;
      yield put(
        Object.assign({}, action, {
          type: action.payload.reducerKey,
          payload: data,
        })
      );
    } else {
      alert(data.errors.join("\n"));
    }
  } catch (error) {
    console.error(error);
    console.error(error);
    // alert("Error -> "+error.message);
  }
  try {
    if (product_id) {
      yield put(getKeys(product_id));
      yield put(getSets(product_id));
      yield push(getProductDatalevels(product_id));
    }
  } catch (error) {
    console.error(error);
    // alert("Error -> " + error.message);
  }
}

function* createProduct(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield* fetchProducts({});
      yield put(hideOverlay("addProduct"));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
      yield* fetchProducts({});
      yield put(hideOverlay("addProduct"));
    }
  } catch (error) {
    throw error;
  }
}

function* cloneProduct(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        alert("Product copied successfully to 'My Products' under 'Dashboard'");
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield* fetchProducts({});
      yield put(hideOverlay("addProduct"));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
    // const data = yield call(apiCall, action);
    // const errors = data.errors;
    // if (
    //   (errors == null || errors.length == 0) &&
    //   payload.cloneType != "preset"
    // ) {
    //   yield* fetchProducts({});
    //   yield put(hideOverlay("addProduct"));
    // } else {
    //   setTimeout(function () {
    //     alert(errors.join("\n"));
    //   }, 100);
    // }
  } catch (error) {
    throw error;
  }
}

function* deleteProduct(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    url = url.replace(":id", action.payload.id);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      yield put({ type: DELETE_PRODUCT, id: action.id });
      yield* fetchProducts({});
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* fetchCategories(action) {
  const apiCall = () => {
    return fetch(ENDPOINTS.categories.index.uri)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall);
    yield put({
      type: ADD_CATEGORIES,
      payload: { categories: data.categories, sub_categories: data.sub_categories },
    });
  } catch (error) {
    throw error;
  }
}

function* updateProduct(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = action.url;
    let ajaxCall = fetch(url + action.id, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: action.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    const errors = data.errors;
    if (errors == null || errors.length == 0) {
      if (data.product.product_state !== "batch_process") {
        yield put({ type: UPDATE_PRODUCT, payload: data, id: action.id });
        yield* fetchProducts({});
        yield put({ type: TOGGLE_PRODUCT_EXPAND, id: action.id });
      } else {
        yield put({ type: DELETE_PRODUCT, id: action.id });
        yield* fetchProducts({});
      }
      // yield* fetchProducts(null);
      // yield put(hideOverlay("addProduct"));
    } else {
      setTimeout(function () {
        alert(errors.join("\n"));
      }, 100);
    }
  } catch (error) {
    throw error;
  }
}

function* toggle(action) {
  yield put({ type: action.payload.reducerKey, id: action.payload.id });
}

function* productUser(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = ENDPOINTS.products.add_user.uri;
    url = url.replace(":id", action.payload.product_id);
    //console.log("Payload = " + payload);
    //console.log("url = " + url);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: ENDPOINTS.products.add_user.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    yield put({
      type: ADD_PRODUCT_USER,
      payload: data,
      id: action.payload.product_id,
    });
  } catch (error) {
    throw error;
  }
}

// Bala start

function* addViewProductUser(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = ENDPOINTS.products.add_view_product_user.uri;
    url = url.replace(":id", action.payload.product_id);
    //console.log("Payload = " + payload);
    //console.log("url = " + url);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: ENDPOINTS.products.add_view_product_user.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    yield put({
      type: ADD_VIEW_PRODUCT_USER,
      payload: data,
      id: action.payload.product_id,
    });
  } catch (error) {
    throw error;
  }
}


function* getViewProductUser(action) {
  const apiCall = (action) => {
    let payload = action.payload;
    let url = ENDPOINTS.products.get_view_product_user.uri;
    url = url.replace(":id", action.payload.product_id);
    //console.log("Payload = " + payload);
    //console.log("url = " + url);
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: ENDPOINTS.products.get_view_product_user.method,
      body: JSON.stringify(payload),
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    yield put({
      type: GET_VIEW_PRODUCT_USER,
      payload: data,
      id: action.payload.product_id,
    });
  } catch (error) {
    throw error;
  }
}


// Bala end

function* getUploadedData(action) { 
  const apiCall = (action) => {
    let url = ENDPOINTS.csv.list.uri;
    url = url.replace(":product_id", action.product_id);
    url = url.replace(":dlname", action.dlname);
    url = url + `?page=${action.page || 0}&per_page=${action.per_page || 10}`;
    if (action.is_search) {
      let searchAtt = Object.keys(action.search_data || {});
      if (searchAtt.length > 0) {
        url = url + `&search=true`;
        for (let index = 0; index < searchAtt.length; index++) {
          let attribute = searchAtt[index];
          url = url + `&${attribute}=${action.search_data[attribute]}`;
        }
      }
    }
    let ajaxCall = fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: ENDPOINTS.csv.list.method,
    });
    return ajaxCall
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    if (data.no_results) {
      alert("No Matching results");
    }
    yield put({
      type: GET_UPLOADED_DATA,
      payload: data,
      id: action.product_id,
    });
  } catch (error) {
    throw error;
  }
}

function* updateUploadedData(action) { 
  const apiCall = (action) => {
    let url = ENDPOINTS.csv.update.uri;
    url = url.replace(":product_id", action.product_id);
    url = url.replace(":dlname", action.dlname);
    url = url.replace(":data_upload_id", action.id);
    let ajaxCall = fetch(url, {
      method: ENDPOINTS.csv.update.method,
      body: action.data, // JSON.stringify(action.data || {})
    });
    return ajaxCall
    .then((response) => response.json())
      .then((response) => {
        if (response.errors) {
          alert("File size is in excess of 5MB\n\nPlease Upload file again with size less than 5MB\n\nReturning to Dashboard");
          window.location.reload(false);
        }      
        // return response.json();
      })
      .catch((error) => {
        throw error;
      });
  };
  try {
    const data = yield call(apiCall, action);
    yield* getUploadedData(action);
    // yield put({
    //   type: GET_UPLOADED_DATA,
    //   payload: data,
    //   id: action.product_id,
    // });
  } catch (error) {
    throw error;
  }
}

export function* fetchDatalevelsSaga() {
  yield takeEvery(PRODUCT_DATA_LEVELS, fetchDatalevels);
}
export function* fetchProductsSaga() {
  yield takeEvery(PRODUCTS, fetchProducts);
}

export function* toggleSaga() {
  yield takeEvery(TOGGLE, toggle);
}

export function* updateResourceSaga() {
  yield takeEvery(UPDATE_OR_DELETE_RESOURCE, updateResource);
}

export function* createResourceSaga() {
  yield takeEvery(CREATE_RESOURCE, createResource);
}

export function* createProductSaga() {
  yield takeEvery(CREATE_PRODUCT, createProduct);
}

export function* cloneProductSaga() {
  yield takeEvery(CLONE_PRODUCT, cloneProduct);
}

export function* deleteProductSaga() {
  yield takeEvery(DELETE_PRODUCT_SAGA, deleteProduct);
}

export function* fetchCategoriesSaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories);
}

export function* updateProductSaga() {
  yield takeEvery(UPDATE_PRODUCT_SAGA, updateProduct);
}

export function* productUserSaga() {
  yield takeEvery(PRODUCT_USER, productUser);
}

export function* viewProductSaga() {
  yield takeEvery(VIEW_PRODUCT, productUser);
}

export function* addViewProductUserSaga() {
  yield takeEvery(ADD_VIEW_PRODUCT_USER, addViewProductUser);
}

export function* getViewProductUserSaga() {
  yield takeEvery(GET_VIEW_PRODUCT_USER, getViewProductUser);
}

export function* getUploadedDataSaga() {
  yield takeEvery(GET_UPLOADED_DATA_SAGA, getUploadedData);
}

export function* updateUploadedDataSaga() {
  yield takeEvery(UPDATE_UPLOADED_DATA_SAGA, updateUploadedData);
}
