import React, {Component, useState, useEffect, Fragment} from 'react';

export function ClipBoard(data) {

    const [showImg, setShowImg] = useState(false)

    var text = "http://adukku.dev.devathon.com/contribute?event_id=" + data.event_id + '&&event_dt=' + data.event_dt
    

    return(
        <div id="ClipBoard" style={{
            zIndex: "999",
            height: "100%",
            width: "100%", 
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
            {
                showImg? (
                     <img src='./Fadinglines.gif' ></img>
                )
                : (
                    <Fragment>
                        <div style={{display: 'flex', flexDirection: 'row', border:'solid 3px black', backgroundColor:'#aaa5a5', 
                        paddingTop:'30px', paddingRight:'20px', paddingLeft:'20px',
                                fontSize:'12px', fontWeight:'bold', }}>

                        
                    <h3 style={{fontSize:'15px', color:'blue',}}
                    >
                        http://adukku.dev.devathon.com/contribute?event_id={data.event_id}&&event_dt={data.event_dt}</h3>
                    <button style={{color:'white', marginLeft:'10px', marginTop:'-25px', marginRight:'5px' }}
                    onClick={() => {
                        navigator.clipboard.writeText(text)
                        alert("Contribute link copied to Clip Board")
                        let clipBoard=document.getElementById("myClip")
                        clipBoard.style.display="none"
                      }}
                    >
                  <i className='fa fa-copy fa-3x' style={{marginRight:'5px'}}></i>
                  Copy
                  </button>

                  
                  <button style={{color:'red',   marginTop:'-85px', marginLeft:'20px', marginRight:'-15px'}}
                  onClick={() => {
                    let clipBoard=document.getElementById("myClip") 
                    clipBoard.style.display="none"
                  }}
                  >
                  <i className='fa fa-close fa-2x'></i>            
                  </button>
                  </div>
                  </Fragment>
                )
            }
        </div>
    )
}

//export default ClipBoard