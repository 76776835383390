import React, { Component, Fragment } from "react";
import { FieldGroup } from "./bootstrap/Forms.FieldGroup";
import { Panel, PanelGroup, Radio, Button, Modal, Form, Dropdown, Row, Col, FormGroup } from "react-bootstrap";
import collage from 'images/WishCollage.jpg';
import {startMsg} from './AudioHelpers'


export class WishCollectorHomePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            countryCode: this.getCookie("countryCode"),
            events: '',
            index: 0,
            isContributions: false,
            contributes: [],
            mobile:false,
            colorPicker: '#293c80',
            activeKey: '',
            openItem: -1,
            sampleModal: false,
            modalTitle: 'I am the Title',
            modalVideo: '',
            };
        
      }

      handleSelect = (activeKey) => {
        this.setState({ activeKey });
      }  

    handleSignup = () => {
        const link = document.createElement('a');
        link.setAttribute('href', '/users/sign_up');
        document.body.appendChild(link);
        link.click();
    }
    
    checkDevice = () => {
      /* Storing user's device details in a variable*/
      let details = navigator.userAgent; 
        
      /* Creating a regular expression  
      containing some mobile devices keywords  
      to search it in details string*/
      let regexp = /android|iphone|kindle|ipad/i; 
        
      /* Using test() method to search regexp in details 
      it returns boolean value*/
      let isMobileDevice = regexp.test(details); 
        
      if (isMobileDevice) { 
          this.setState({mobile: true})
      } else { 
          this.setState({mobile: false})
      }
    }

    handleResize = e => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        
        this.setState(prevState => {
            return {
            windowWidth,
            windowHeight,
            };
        });
      };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.waitIntervalId = setInterval(this.wait, 4000)
        
        if (this.state.countryCode === ''){
            //fetch country code of user
            //var url = 'https://api.ipregistry.co/?key=64qg4jmpagjha51m';
            var url = "https://ipinfo.io/?token=f7e161ea50651f";
            fetch(url)
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    countryCode: responseJson.country
                });
                document.cookie = "countryCode=" + responseJson.country + ";";
            })
            .catch((error) => {
            //console.error(error);
                this.setState({
                    countryCode: "US"
                });
            });
        }
        
        /*
        this.setState({
            countryCode: document.cookie.split("=")[1] // set the country code from cookie set in application_controller.rb
        });
        */
    }

    UNSAFE_componentWillMount() {
      this.checkDevice();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
        clearInterval(this.waitIntervalId)
    }
    
    //.scrollIntoView({ behavior: "smooth", block: "start, inline: "nearest"});
    toggleAll = (newOpenKey) => {
      
      var carets = document.querySelectorAll('.up-down');
      if (this.state.openItem === -1) {
        // open newOpenKey
        carets[newOpenKey-1].getElementsByTagName('span')[0].classList.toggle('fa-caret-down');
        carets[newOpenKey-1].getElementsByTagName('span')[0].classList.toggle('fa-caret-up');
        // document.getElementById(`panel${newOpenKey}`).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest"});
        this.setState({ openItem: newOpenKey });
      } else if (this.state.openItem === newOpenKey) {
        carets[newOpenKey-1].getElementsByTagName('span')[0].classList.toggle('fa-caret-up');
        carets[newOpenKey-1].getElementsByTagName('span')[0].classList.toggle('fa-caret-down');
        this.setState({ openItem: -1 });
      } else {
        //close openItem
        carets[this.state.openItem-1].getElementsByTagName('span')[0].classList.toggle('fa-caret-up');
        carets[this.state.openItem-1].getElementsByTagName('span')[0].classList.toggle('fa-caret-down');
        //open newOpenKey
        carets[newOpenKey-1].getElementsByTagName('span')[0].classList.toggle('fa-caret-down');
        carets[newOpenKey-1].getElementsByTagName('span')[0].classList.toggle('fa-caret-up');
        if (this.state.mobile) {
          setTimeout(function () {
            document.getElementById(`panel${newOpenKey}-mobile`).scrollIntoView(true, { behavior: "smooth"});  
        },500);
        }
        else {
          setTimeout(function () {
          document.getElementById(`panel${newOpenKey}`).scrollIntoView(true, { behavior: "smooth"});
        },50);
      }
        this.setState({ openItem: newOpenKey });
      }
    }
    
    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    getContributions = (event_id) => {

      let template_url = "/api/contributes?event_id=:event_id"
      template_url = template_url.replace(":event_id", event_id);
  
      
      let noRecords;
      
      noRecords =  fetch(template_url, {
        method: "GET", headers: { "content-type": "application/json" }, 
        })
      .then((response) => response.json())
      .then((data) => { 
        this.setState({contributes: data.contributes});
      });
  
    }



    wait = () => {
      
      var events = [
                    "Marriage",    
                    "Birthday",
                    "Anniversary",
                    "Engagement",
                    "Baby Shower",
                    "New Baby",
                    "New House",
                    "New Vehicle",
                    "New Job",
                    "Promotion",
                    "Farewell",
                    "Retirement",
                    "Achievements",
                    "Get Well",
                    "Any occassion",
                    "Graduation",
                    "Student Autographs"
                   ]
      var steps = [
      "All you and wishers need is a Mobile\nCan use PC, Laptops & Tablets too",
      "Create an Account & Sign in\n",
      "Create an Event under 5 minutes\nRight from your mobile",
      "Upload Invitation \n(eg. a Marriage Invitation)",
      "Record Video Intive\neven from your mobile!",
      "Choose no of wishers & Pay for the event",
      "Share event link with video intive to Friends & Relatives via WhatsApp, SMS, Email, etc.",
      "Wishers can use Mobile to send their Name, Picture, Audio Wish & Text wishes \n(many languages)",
      "View & Hear wishes in your PC, Mobile, etc. in realtime as people wish",
      "Close event after event ends, even after few days",
      "Arrange wishes, so that related Wishers are next to each other",
      "Choose output format of your choice &\nDownload wishes to your PC, Mobile, etc. as simple html files",
      "Use existing Chrome/Edge browser on mobile to view and hear html files",
      "Click on wisher's picture to hear and see wishes (many languages). Usually 20 wishers per page",
      "Store html files locally on your PC, Mac, Mobile, etc. or send to recepient",
      "Carry your wishes even in Mobile. View and Hear wishes locally. Not online!",
      "Create an Event today! \nStart collecting wishes.\nPreserve your wishes."
      ]                   
      if (this.state.index > 16) {
        this.setState({index:0})
      }
      else {
        document.getElementById("eve").innerText = events[this.state.index]
        document.getElementById("steps").innerText = steps[this.state.index]
        this.setState({index:this.state.index+1})
      }
    }

    render() {
     
    if (window.innerWidth > 450) {
        
    return(
      <React.Fragment>
      <div  style={{display: 'flex', justifyContent: "space-between", flexDirection: 'row', backgroundColor:'rgb(189 220 233 / 24%)', width: "100vw", }}>

          <div style={{ margin:'3vw'}}>
          <p style={{ fontSize:'2.5vw', color:'black', fontWeight:'regular', fontFamily:'Impact'}}>
            Collect-wishes<br></br>
            for self or others<br></br>
            <span id="eve" style={{fontSize:'3vw',color:'#1f88d9', fontFamily:'Salsa'}}>Marriage</span><br></br>
          </p>
          
          <p style={{ marginRight:'5px', fontSize:'2.5vw',fontWeight:'regular', fontFamily:'Impact', color:'black'}}>
          Easy-to-use<br></br>
          <span id="steps" style={{marginRight:'5px', fontSize:'1.5vw',color:'green', fontFamily:'Salsa'}}>All you and wishers need is a Mobile<br></br>Can use PC, Laptops & Tablets too</span><br></br>
          </p>
        </div>
         <div style={{display: "flex", flexDirection: "row", justifyContent: "end",  marginRight: "30px", marginTop: "20px", marginBottom: "20px", alignContent: 'end'}}>
          <img style={{ backgroundColor:'rgb(93 175 216 / 79%)', height:'45vw', boxShadow: '5px 5px 5px darkgray'}} src={collage} />
        </div> 
      </div>
      <br></br>
      <Button style={{backgroundColor:"green", color:"white", fontSize:"20px", marginLeft: '3vw', marginTop:'.5vw', width:'30vw' }}  
        href="/users/sign_up"
      >
       Create Account
      </Button>

      <p style={{ marginLeft:'3vw', fontSize:'2.5vw',fontWeight:'regular', fontFamily:'Impact', color:'black', marginTop:'1vw', marginBottom:'0px'}}>
      Simple steps to collect wishes
      </p> 
      <PanelGroup
        accordion
        id="accordion"
        // activeKey={this.state.activeKey}
        // onSelect={this.handleSelect}
        style={{width:'95%', marginLeft:'3vw', marginRight:'3vw', marginBottom:'2vw'}}
      >
        <Panel eventKey="1">
          <Panel.Heading style={{cursor: 'pointer', padding: "0px"}} onClick={() => {this.toggleAll(1)}} >
            <Panel.Title id="panel1" toggle  data-toggle="collapse"  data-target="#Pan1" data-parent="#accordion" className="up-down" style={{ display: "block", padding: "10px 15px", fontFamily:'Salsa', fontWeight:'bold'}}>
              Create Event 
              <span className="fa fa-caret-down fa-lg" style={{float:'right'}}></span>
            </Panel.Title>
          </Panel.Heading>
          <div id="Pan1" className="panel-collapse collapse">
            <Panel.Body style={{fontFamily:'Salsa', color:'green'}}>
            <p style={{fontWeight:'bold'}}>Create Event :</p>
              <p>Use mobile, PC, MAC, Laptops or Tablets to create an Event under 5 minutes.</p>
              <p>Upload event invitation like a Marriage invitation or choose from preset pictures.</p>
              <p>Record a short Video Invite even from a mobile. Record, Preview & re-record as many times before final video invite is satisfactory.</p>
              <p>Provide Title & Date for the event. Can provide past event dates too for collecting wishes for past events!</p>
              <br></br>
              <p style={{fontWeight:'bold'}}>Pay for the event :</p>
              <p>After event creation, Pay for the event after choosing no of wishers.</p>
              <p>Minimum wishers count is 20.</p>
              <p>Choose wishers carefully as prepaid cost & postpaid cost per wisher is different and there is no refund for unused wishers count.</p>
            </Panel.Body>
          </div>
        </Panel>
        <Panel eventKey="2">
          <Panel.Heading style={{cursor: 'pointer', padding: "0px"}} onClick={() => {this.toggleAll(2)}} >
            <Panel.Title id = "panel2" toggle  data-toggle="collapse"  data-target="#Pan2" data-parent="#accordion" className="up-down" style={{ display: "block", padding: "10px 15px", fontFamily:'Salsa', fontWeight:'bold'}}>
                Share event link & Invite 
                <span className="fa fa-caret-down fa-lg" style={{float:'right'}}></span>
            </Panel.Title>
          </Panel.Heading >
          <div id="Pan2" className="panel-collapse collapse">
            <Panel.Body style={{fontFamily:'Salsa', color:'green'}}>
              <p>After paying for event, using a mobile or any device, copy the event link and send it to friends, relatives, co-workers, classmates, etc. via WhatsApp, 
                 SMS, Email, etc.</p>
              <p>Once wishers receive the event link, they can use their mobile, PC or any device to click on the event link. </p>
              <p>Event link shows the Video Invite, invitation picture and also the event title and date.</p>
              <p>Wishers look at "Video Invite" and can then "Confirm" that the event invite is intended for them.</p>
              <p>Then wishers can enter their Name & Place, Upload their picture, Record Audio wishes and also enter Text wishes in their preferred language!</p>
              <p>Once they "submit" their details, their wishes along with submit date & time will be automatically be shown to the creator of the event!</p>
              <p>Multiple wishers can submit their wishes simultaneously from different parts of the world!</p>
            </Panel.Body>
          </div>
        </Panel>
        <Panel eventKey="3">
        <Panel.Heading style={{cursor: 'pointer', padding: "0px"}} onClick={() => {this.toggleAll(3)}} >
            <Panel.Title id="panel3" toggle  data-toggle="collapse"  data-target="#Pan3" data-parent="#accordion" className="up-down" style={{ display: "block", padding: "10px 15px", fontFamily:'Salsa', fontWeight:'bold'}}>
                Manage collected wishes
                <span className="fa fa-caret-down fa-lg" style={{float:'right'}}></span>
            </Panel.Title>
          </Panel.Heading >
          <div id="Pan3" className="panel-collapse collapse">
            <Panel.Body style={{fontFamily:'Salsa', color:'green'}}>
              <p style={{fontWeight:'bold'}}>Watch collected wishes :</p>
              <p>Once wishers submit their wishes using the event link, event creator can watch and hear all the wishes as they arrive in realtime using their mobile, PC or any device.</p>
              <p>Keep collecting wishes as long as the event is not closed. Can wait few days before closing the event as well until all intended wishes arrive.</p>
              <p>After all intended wishes are collected, event can be closed. No more wishes will be collected after the event is closed. Wishers won't be able to submit wishes using the event link anymore.</p>
              <br></br>
              <p style={{fontWeight:'bold'}}>Pay for additional wishes or Reject :</p>
              <p>"Wish Collector" keeps collecting wishes even beyond what was paid for as wishes are priceless.</p>
              <p>Event creator has the choice of rejecting all additional wishes or pay for all additional wishes or pay for only partial of wishes.</p>
              <br></br>
              <p style={{fontWeight:'bold'}}>Exclude wishes :</p>
              <p>There may be times, certain wishes need to be excluded. "Wish Collector" easily provides a simple way to either include or exclude a wish.</p>
              <p>Excluded wishes will not be included in the output files.</p>
              <br></br>
              <p style={{fontWeight:'bold'}}>Arrange wishes :</p>
              <p>Easily drag and drop wishers so that related wishers are next to each other.</p>
              <p>Output files will be generated based on the sorted order.</p>
            </Panel.Body>
          </div>
        </Panel>
        <Panel eventKey="4">
        <Panel.Heading style={{cursor: 'pointer', padding: "0px"}} onClick={() => {this.toggleAll(4)}} >
            <Panel.Title id="panel4" toggle  data-toggle="collapse"  data-target="#Pan4" data-parent="#accordion" className="up-down" style={{ display: "block", padding: "10px 15px", fontFamily:'Salsa', fontWeight:'bold'}}>
                Generate & Download Wish files
                <span className="fa fa-caret-down fa-lg" style={{float:'right'}}></span>
            </Panel.Title>
          </Panel.Heading >
          <div id="Pan4" className="panel-collapse collapse">
            <Panel.Body style={{fontFamily:'Salsa', color:'green'}}>
              <p style={{fontWeight:'bold'}}>Choose an output Template :</p>
              <p>After wishes are arranged, choose an output template. Note: Each template accommodates only 20 wishers. If 100 wishes are collected, then 5 html files will be generated based on the template.</p>
              <p>Same template will be used for all 5 files. If the collected wishes are only say 90, the remaining 10 slots in the template will be blank without distorting the template look.</p>
              <br></br>
              <p style={{fontWeight:'bold'}}>Upload event pictures :</p>
              <p>Here, event pictures, say Marriage pictures of couple can be uploaded. This is event pictures and not the wishers pictures.</p>
              <p>For example, if there are 100 wishes, then 5 files will be generated each containing 20 wishers. So 5 different event pictures can be uploaded or upload one and choose to use the same picture for all 5 files.</p>
              <br></br>
              <p style={{fontWeight:'bold'}}>Generate Event Files :</p>
              <p>After a template had been chosen and event pictures are uploaded, Proceed to generate "Event Files".</p>
              <p>Event files generation is an asynchronous process and the request will be put in a queue to be processed.</p>
              <p>Queue is processed on first come first serve, basis. Wait for a minute or two for low number of wishes. If in 1000's may take just a little longer.</p>
              <p>Typically, event file generation takes under a minute or two only. Keep checking the status for completion of files generation. Creator will also receive an email once files are generated.</p>
              <br></br>
              <p style={{fontWeight:'bold'}}>Download Event Files :</p>
              <p>Once the event files are generated, immediately proceed to download the files.</p>
              <p>Files can be downloaded in mobile, PC, or any capable device. PC is recommended but not compulsory. There may be only limited time to download the files.</p>
              <p>Downloaded file is a single '.zip' file. Extract the .html files onto its own directory/folder.</p>
              <br></br>
              <p style={{fontWeight:'bold'}}>Watch and Hear wishes :</p>
              <p>If on PC, MAC, or Laptops, you can just double click the .html files to launch them in the web browser. Browser will show the output based upon the template chosen.</p>
              <p>If on Android Tablet or mobile, open the .html files using Chrome.</p>
              <p>If on iPad or iPhone, download microsoft Edge browser first. Send/Share the .html file to Edge browser to open the .html files.</p>
              <p>Once the .html file is opened, here you will see the Event picture and 20 wishers pictures based on the chosen template.</p>
              <p>Click on each wisher's picture to pop out the window where you can see their text messages and hear their audio wishes.</p>
              <p>If the wishes are intended for others, send the '.zip' file to the recepient via email, WhatsApp (doc attachment), google drive, etc.</p>
              <p>Make multiple copies of the '.zip' file and store them in multiple places like hard drive, pen drive, cloud, etc. as "Wish Collector" will delete all data related to the event as soon as event files are downloaded and cannot be recovered at all.</p>
              <p>Have fun and enjoy wishes for a lifetime!</p>
              <p>Good Luck!</p>
            </Panel.Body>
          </div>
        </Panel>
      </PanelGroup> 

      <p style={{ marginLeft:'3vw', fontSize:'2.5vw',fontWeight:'regular', fontFamily:'Impact', color:'black', marginTop:'1vw', marginBottom:'0px'}}>
      Sample Video Invites
      </p> 

      <div style={{display:'flex', flexDirection:'row', justifyContent: "space-between", marginTop:'.5vw', marginRight:'30px'}}    >
        <Button type="submit" style={{backgroundColor:"#5bacf0", color:"white", fontSize:"1vw", marginLeft: '3vw', width:'20vw', height: 'auto', boxShadow:'0px 0px 1px 0px black'}}  
          onClick={() => {
            this.setState({
              sampleModal: true,
              modalTitle: 'Sample Video - Wedding Invite',
              modalVideo: 'uploads/events/sample/weddingInvite.mp4'
            })
            this.getContributions(80)
          }}
        >
        Wedding Invite
        </Button>

        <Button type="submit" style={{backgroundColor:"#0a6e1b", color:"white", fontSize:"1vw", marginLeft: '3vw', width:'20vw', height: 'auto', boxShadow:'0px 0px 1px 0px black'}}  
          onClick={() => {
            this.setState({
              sampleModal: true,
              modalTitle: 'Sample Video - Birthday Invite',
              modalVideo: 'uploads/events/sample/birthdayInvite.mp4'
            })
            this.getContributions(80)
          }}
        >
        Birthday Invite
        </Button>

        <Button type="submit" style={{backgroundColor:"#ea6828", color:"white", fontSize:"1vw", marginLeft: '3vw', width:'20vw', height: 'auto', boxShadow:'0px 0px 1px 0px black'}}  
          onClick={() => {
            this.setState({
              sampleModal: true,
              modalTitle: 'Sample Video - Graduation Invite',
              modalVideo: 'uploads/events/sample/graduationInvite.mp4'
            })
            this.getContributions(80)
          }}
        >
        Graduation Invite
        </Button>

        <Button type="submit" style={{backgroundColor:"red", color:"white", fontSize:"1vw", marginLeft: '3vw', width:'20vw', height: 'auto', boxShadow:'0px 0px 1px 0px black'}}  
          onClick={() => {
            this.setState({
              sampleModal: true,
              modalTitle: 'Sample Video - Student Autograph Invite',
              modalVideo: 'uploads/events/sample/autographInvite.mp4'
            })
            this.getContributions(80)
          }}
        >
        Student Autograph Invite
        </Button>
      </div>    
        
      <p style={{ marginLeft:'3vw', fontSize:'2.5vw',fontWeight:'regular', fontFamily:'Impact', color:'black', marginTop:'1vw', marginBottom:'0px'}}>
        Sample Collected Wishes
      </p> 
      <div style={{display:'flex', flexDirection:'row', justifyContent: "space-between", marginTop:'.5vw', marginRight:'30px'}}  >
        <Button type="submit" style={{backgroundColor:"green", color:"white", fontSize:"1vw", marginLeft: '3vw', width:'20vw', height: 'auto', boxShadow:'0px 0px 1px 0px black'}}  
          onClick={() => {
            this.setState({
              isContributions: true,
            })
            this.getContributions(91)
          }}
        >
        Wedding Wishes
        </Button>
        <Button type="submit" style={{backgroundColor:"#ea6828", color:"white", fontSize:"1vw", marginLeft: '3vw', width:'20vw', height: 'auto', boxShadow:'0px 0px 1px 0px black'}}  
          onClick={() => {
            this.setState({
              isContributions: true,
            })
            this.getContributions(92)
          }}
        >
        Student Autographs
        </Button>
        <Button type="submit" style={{backgroundColor:"red", color:"white", fontSize:"1vw", marginLeft: '3vw', width:'20vw', height: 'auto', boxShadow:'0px 0px 1px 0px black'}}  
          onClick={() => {
            this.setState({
              isContributions: true,
            })
            this.getContributions(93)
          }}
        >
        Graduation Wishes
        </Button>
        <Button type="submit" style={{backgroundColor:"#5bacf0", color:"white", fontSize:"1vw", marginLeft: '3vw', width:'20vw', height: 'auto', boxShadow:'0px 0px 1px 0px black'}}  
          onClick={() => {
            this.setState({
              isContributions: true,
            })
            this.getContributions(80)
          }}
        >
        Anniversary Wishes
        </Button>
      </div>

      <p style={{ marginLeft:'3vw', fontSize:'2.5vw',fontWeight:'regular', fontFamily:'Impact', color:'black', marginTop:'1vw', marginBottom:'0px'}}>
        Sample downloaded 'html' files 
      </p> 
      <div style={{display:'flex', flexDirection:'row', justifyContent: "space-between", marginTop:'.5vw', marginRight:'30px'}}  >
        <Button type="submit" style={{backgroundColor:"red", color:"white", fontSize:"1vw", marginLeft: '3vw', width:'20vw', height: 'auto', boxShadow:'0px 0px 1px 0px black'}}  
          onClick={() => {
            const link = document.createElement('a');
            link.setAttribute('href', 'weddingWishes.html');
            link.setAttribute('target', 'blank');
            link.click();
          }}
          // onClick={() => {
          //   this.showWeddingWishes()
          // }}
        >
        Wedding Wishes
        </Button>
        <Button type="submit" style={{backgroundColor:"green", color:"white", fontSize:"1vw", marginLeft: '3vw', width:'20vw', height: 'auto', boxShadow:'0px 0px 1px 0px black'}}  
          onClick={() => {
            const link = document.createElement('a');
            link.setAttribute('href', 'autographWishes.html');
            link.setAttribute('target', 'blank');
            link.click();
          }}
        >
        Student Autographs
        </Button>
        <Button type="submit" style={{backgroundColor:"#ea6828", color:"white", fontSize:"1vw", marginLeft: '3vw', width:'20vw', height: 'auto', boxShadow:'0px 0px 1px 0px black'}}  
          onClick={() => {
            const link = document.createElement('a');
                link.setAttribute('href', 'graduationWishes.html');
                link.setAttribute('target', 'blank');
                link.click();
          }}
        >
        Graduation Wishes
        </Button>
 
      </div>




       <Modal show={this.state.isContributions} onHide={() => {this.setState({ isContributions: false })}}
                    style={{marginTop:'45px', }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Collected Wsihes
                </Modal.Title>
              </Modal.Header>
          <Form onSubmit={this._handleValidSubmit} className="override_form">
                <Modal.Body >
                <FieldGroup
                    type="color"
                    id="colorpicker"
                    name="colorPicker"
                    label="Choose Background Color"
                    //class="field-radio"
                    onChange={({ target: { name, value } }) => {
                      this.setState({ [name]: value });
                      this.changeColor()
                    }}
                    value={this.state.colorPicker}
                  >
                  </FieldGroup>
                {this.state.contributes.length > 0 ?
                  this.state.contributes.map( (item, index) =>{
                    const contributionNo = this.state.contributes.length - index
                    const date = new Date(item.created_at)
                    const formattedDate = date.toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric"
                      })
                    const formattedTime = date.toLocaleTimeString("en-US")
                      
                if (this.state.mobile){
                  return (
                    <Fragment>
                  <div id={"myContribute_"+item.id} key={item.id} style={{ display: 'flex', flexDirection: 'column', backgroundColor: this.state.colorPicker,  width: '100%', alignItems:'middle', boxShadow: '2px 5px 5px #888888', border: '3px solid black', marginTop:"5px", marginBottom: '15px'}}>
                  <h5 style={{color:'yellow', marginTop: '10px', marginLeft:'10px', marginBottom:'10px'}}>Wish No: {contributionNo} @ {formattedDate} {formattedTime}</h5>
                  
                    <img id="contributeImage" src={`uploads/events/${item.event_id}/contributes/`+item.c_img_url} alt="" width="100%"  
                    style={{boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.6)"}}/>
                  
                      <br></br>
                    <h5 style={{marginLeft:'5px', color: 'white',}}>Name: {item.c_name}</h5>                        
                    <h5 style={{marginLeft:'5px', color: 'grey', marginTop: '-10px'}}>Place: {item.c_place} </h5>                        
                    <br></br>
                    
                    <div style={{ alignSelf:'center', marginBottom:'20px'}}>
                      <h5 style={{marginLeft: '30px', color: 'white', marginTop:'-35px'}}>Audio Wishes (if recordered)</h5>
                      {/* <h5 style={{color: 'white', }}>Audio Wishes (if recordered)</h5> */}
                      
                      <audio id="audio"  src={`uploads/events/${item.event_id}/contributes/`+item.c_aud_url} controls={true}
                        style={{display:'inline-block', verticalAlign:'middle', height:'30px', paddingLeft:'15px', paddingTop:'0px', marginTop: '-15px', }}>
                      </audio>
                    </div>
                 
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', marginTop:'-10px'}}>
                      <textarea defaultValue={item.message} 
                          style={{ height: '110px', color: 'blue', fontFamily: 'Almendra', fontWeight: "bold", fontSize: '15px', 
                          display: 'flex', flexDirection: 'column', marginBottom: '5px', padding: '5px'}}
                        readOnly>
                      </textarea>
                    </div>
                </div>
                </Fragment>
                  )
                }
                else {
                  return (
                    <Fragment>
                  <div id={"myContribute_"+item.id} key={item.id} style={{ display: 'flex', flexDirection: 'column', backgroundColor: this.state.colorPicker,  width: '100%', alignItems:'middle', boxShadow: '2px 5px 5px #888888', border: '3px solid black', marginTop:"5px", marginBottom: '15px'}}>
                  <h5 style={{color:'yellow', marginTop: '10px', marginLeft:'10px', marginBottom:'20px'}}>Wish No: {contributionNo} @ {formattedDate} {formattedTime}</h5>
                  
                    <img id="contributeImage" src={`uploads/events/${item.event_id}/contributes/`+item.c_img_url} alt="" width="100%"   
                    style={{boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.6)", marginBottom:'20px'}}/>
                  
                      
                    <h5 style={{marginLeft:'5px', color: 'white',}}>Name: {item.c_name}</h5>                        
                    <h5 style={{marginLeft:'5px', color: 'grey', marginTop: '-10px'}}>Place: {item.c_place} </h5>                        
                    <br></br>
                  
                    <div style={{ alignSelf:'center', marginBottom:'20px'}}>
                      <h5 style={{marginLeft: '70px', color: 'white', marginTop:'-35px'}}>Audio Wishes (if recordered)</h5>
                      {/* <h5 style={{color: 'white', }}>Audio Wishes (if recordered)</h5> */}
                      
                      <audio id="audio"  src={`uploads/events/${item.event_id}/contributes/`+item.c_aud_url} controls={true}
                        style={{display:'inline-block', verticalAlign:'middle', height:'30px', paddingLeft:'15px', paddingTop:'0px', marginTop: '-15px', }}>
                      </audio>
                    </div>
                  
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', marginTop:'-10px'}}>
                    <textarea defaultValue={item.message} 
                        style={{ height: '110px', color: 'blue', fontFamily: 'Almendra', fontWeight: "bold", fontSize: '15px', 
                        display: 'flex', flexDirection: 'column', marginBottom: '5px', padding: '5px'}}
                       readOnly>
                    </textarea>
                    </div>
                </div>
                    </Fragment>
                  )
                }
                  })
                  :
                  <h3>No Wishes yet!</h3>
                }
                </Modal.Body>
              </Form>
            </Modal>

            <Modal show={this.state.sampleModal} onHide={() => {this.setState({ sampleModal: false })}}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {this.state.modalTitle}
                </Modal.Title>
              </Modal.Header>
                <Modal.Body>
                  <div style={{alignItems: 'center'}}>
                  <video playsInline autoPlay={true} controls src={`${this.state.modalVideo}`}
                    style={{width:'100%', maxHeight:'67vh', boxShadow: '5px 5px 5px #888888', border: '10px solid black', marginBottom:'15px', marginTop:'10px' }}>
                  </video>
                  </div>
                </Modal.Body>
            </Modal>

            {this.state.countryCode === 'IN' ?
          <div>
            <br></br>
            <br></br>
            <br></br>
            
            <div style={{ background: 'lightgray', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column',  alignItems: 'center', paddingRight: 10, paddingLeft: 40}}>
                        <br></br>
                        <p><b> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Contact :</b><br></br>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<b><strong>LIFE COLORS</strong></b><br></br>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;#9, 4th Cross,<br></br>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Kothanur Main Road,<br></br>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Bangalore 560 062.<br></br>
                        <br></br>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<b><strong>email : </strong></b>team@wishcollector.com<br></br>
                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;<b><strong>phone : </strong></b>+91 9620621884<br></br>
                        </p>
                    </div> 

                    <div style={{ display: 'flex', flexDirection: 'column',  alignItems: 'center', paddingRight: 10}}>
                        <br></br>
                        <p><b> &emsp;&emsp;&emsp;Policies :</b><br></br>
                            &emsp;&emsp;&emsp;&emsp;<a href="/static/Pricing.html" target="blank">Pricing</a><br></br>
                            &emsp;&emsp;&emsp;&emsp;<a href="/static/RefundAndReturn.html" target="blank">Refund & Return</a><br></br>
                            &emsp;&emsp;&emsp;&emsp;<a href="/static/Shipping.html" target="blank">Shipping</a><br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                        </p>
                    </div> 
                </div> 
              </div> : ''
            }  



      </React.Fragment>
    );
    }
    else { // mobile
        return(
          <React.Fragment>
            
            <p style={{marginTop: "5px", marginLeft:'15px', fontSize:'30px', color:'black', fontWeight:'regular', fontFamily:'Impact'}}>
              Collect-wishes<br></br>
              for self or others<br></br>
              <span id="eve" style={{fontSize:'40px',color:'#1f88d9', fontFamily:'Salsa'}}>Marriage</span><br></br>
            </p>
            <img style={{width: "100vw",  backgroundColor:'rgb(93 175 216 / 79%)', marginBottom:'2vh'}} src={collage} />
            
            <p style={{marginLeft:'15px', marginRight:'15px', marginBottom:'15px', fontSize:'30px',color:'black', fontWeight:'regular', fontFamily:'Impact'}}>
            Easy-to-use
        </p>
        <div style={{ height: "80px", marginLeft:'15px', marginRight:'10px', marginTop: "-10px"}}>
          <span id="steps" style={{ fontSize:'20px',color:'green', fontFamily:'Salsa'}}>All you and wishers need is a Mobile<br></br>Can use PC, Laptops & Tablets too</span><br></br>
        </div>
        <br></br>
        <Button bsStyle="primary" bsSize="small" style={{margin:"15px", width:'92%'}}
           href="/users/sign_up"
        >
          Create Account 
        </Button>
        

        <p style={{marginLeft:'15px', marginRight:'15px', marginBottom:'15px', fontSize:'30px',color:'black', fontWeight:'regular', fontFamily:'Impact'}}>  
          Simple steps to Collect wishes
        </p>

        <PanelGroup
        accordion
        id="accordion"
        // activeKey={this.state.activeKey}
        // onSelect={this.handleSelect}
      >
        <Panel eventKey="1">
          <Panel.Heading style={{cursor: 'pointer', padding: "0px"}} onClick={() => {this.toggleAll(1)}} >
            <Panel.Title id="panel1-mobile" toggle  data-toggle="collapse"  data-target="#Pan1" data-parent="#accordion" className="up-down" style={{ display: "block", padding: "10px 15px", fontFamily:'Salsa', fontWeight:'bold'}}>
              Create Event 
              <span className="fa fa-caret-down fa-lg" style={{float:'right'}}></span>
            </Panel.Title>
          </Panel.Heading>
          <div id="Pan1" className="panel-collapse collapse">
          <Panel.Body style={{fontFamily:'Salsa', color:'green'}}>
              <p style={{fontWeight:'bold'}}>Create Event :</p>
              <p>Use mobile, PC, MAC, Laptops or Tablets to create an Event under 5 minutes.</p>
              <p>Upload event invitation like a Marriage invitation or choose from preset pictures.</p>
              <p>Record a short Video Invite even from a mobile. Record, Preview & re-record as many times before final video invite is satisfactory.</p>
              <p>Provide Title & Date for the event. Can provide past event dates too for collecting wishes for past events!</p>
              <br></br>
              <p style={{fontWeight:'bold'}}>Pay for the event :</p>
              <p>After event creation, Pay for the event after choosing no of wishers.</p>
              <p>Minimum wishers count is 20.</p>
              <p>Choose wishers carefully as prepaid cost & postpaid cost per wisher is different and there is no refund for unused wishers count.</p>
            </Panel.Body>
          </div>
        </Panel>
        <Panel eventKey="2">
          <Panel.Heading style={{cursor: 'pointer', padding: "0px"}} onClick={() => {this.toggleAll(2)}} >
            <Panel.Title id="panel2-mobile" toggle  data-toggle="collapse"  data-target="#Pan2" data-parent="#accordion" className="up-down" style={{ display: "block", padding: "10px 15px", fontFamily:'Salsa', fontWeight:'bold'}}>
                Share event link & Invite
                <span className="fa fa-caret-down fa-lg" style={{float:'right'}}></span>
            </Panel.Title>
          </Panel.Heading >
          <div id="Pan2" className="panel-collapse collapse">
          <Panel.Body style={{fontFamily:'Salsa', color:'green'}}>
              <p>After paying for event, using a mobile or any device, copy the event link and send it to friends, relatives, co-workers, classmates, etc. via WhatsApp, 
                 SMS, Email, etc.</p>
              <p>Once wishers receive the event link, they can use their mobile, PC or any device to click on the event link. </p>
              <p>Event link shows the Video Invite, invitation picture and also the event title and date.</p>
              <p>Wishers look at "Video Invite" and can then "Confirm" that the event invite is intended for them.</p>
              <p>Then wishers can enter their Name & Place, Upload their picture, Record Audio wishes and also enter Text wishes in their preferred language!</p>
              <p>Once they "submit" their details, their wishes along with submit date & time will be automatically be shown to the creator of the event!</p>
              <p>Multiple wishers can submit their wishes simultaneously from different parts of the world!</p>
            </Panel.Body>
          </div>
        </Panel>
        <Panel eventKey="3">
        <Panel.Heading style={{cursor: 'pointer', padding: "0px"}} onClick={() => {this.toggleAll(3)}} >
            <Panel.Title id="panel3-mobile" toggle  data-toggle="collapse"  data-target="#Pan3" data-parent="#accordion" className="up-down" style={{ display: "block", padding: "10px 15px", fontFamily:'Salsa', fontWeight:'bold'}}>
                Manage collected Wishes 
                <span className="fa fa-caret-down fa-lg" style={{float:'right'}}></span>
            </Panel.Title>
          </Panel.Heading >
          <div id="Pan3" className="panel-collapse collapse">
            <Panel.Body style={{fontFamily:'Salsa', color:'green'}}>
            <p style={{fontWeight:'bold'}}>Watch collected wishes :</p>
              <p>Once wishers submit their wishes using the event link, event creator can watch and hear all the wishes as they arrive in realtime using their mobile, PC or any device.</p>
              <p>Keep collecting wishes as long as the event is not closed. Can wait few days before closing the event as well until all intended wishes arrive.</p>
              <p>After all intended wishes are collected, event can be closed. No more wishes will be collected after the event is closed. Wishers won't be able to submit wishes using the event link anymore.</p>
              <br></br>
              <p style={{fontWeight:'bold'}}>Pay for additional wishes or Reject :</p>
              <p>"Wish Collector" keeps collecting wishes even beyond what was paid for as wishes are priceless.</p>
              <p>Event creator has the choice of rejecting all additional wishes or pay for all additional wishes or pay for only partial of wishes.</p>
              <br></br>
              <p style={{fontWeight:'bold'}}>Exclude wishes :</p>
              <p>There may be times, certain wishes need to be excluded. "Wish Collector" easily provides a simple way to either include or exclude a wish.</p>
              <p>Excluded wishes will not be included in the output files.</p>
              <br></br>
              <p style={{fontWeight:'bold'}}>Arrange wishes :</p>
              <p>Easily drag and drop wishers so that related wishers are next to each other.</p>
              <p>Output files will be generated based on the sorted order.</p>
            </Panel.Body>
          </div>
        </Panel>
        <Panel eventKey="4">
        <Panel.Heading style={{cursor: 'pointer', padding: "0px"}} onClick={() => {this.toggleAll(4)}} >
            <Panel.Title id="panel4-mobile" toggle  data-toggle="collapse"  data-target="#Pan4" data-parent="#accordion" className="up-down" style={{ display: "block", padding: "10px 15px", fontFamily:'Salsa', fontWeight:'bold'}}>
                Generate & Download Wish files
                <span className="fa fa-caret-down fa-lg" style={{float:'right'}}></span>
            </Panel.Title>
          </Panel.Heading >
          <div id="Pan4" className="panel-collapse collapse">
            <Panel.Body style={{fontFamily:'Salsa', color:'green'}}>
              <p style={{fontWeight:'bold'}}>Choose an output Template :</p>
              <p>After wishes are arranged, choose an output template. Note: Each template accommodates only 20 wishers. If 100 wishes are collected, then 5 html files will be generated based on the template.</p>
              <p>Same template will be used for all 5 files. If the collected wishes are only say 90, the remaining 10 slots in the template will be blank without distorting the template look.</p>
              <br></br>
              <p style={{fontWeight:'bold'}}>Upload event pictures :</p>
              <p>Here, event pictures, say Marriage pictures of couple can be uploaded. This is event pictures and not the wishers pictures.</p>
              <p>For example, if there are 100 wishes, then 5 files will be generated each containing 20 wishers. So 5 different event pictures can be uploaded or upload one and choose to use the same picture for all 5 files.</p>
              <br></br>
              <p style={{fontWeight:'bold'}}>Generate Event Files :</p>
              <p>After a template had been chosen and event pictures are uploaded, Proceed to generate "Event Files".</p>
              <p>Event files generation is an asynchronous process and the request will be put in a queue to be processed.</p>
              <p>Queue is processed on first come first serve, basis. Wait for a minute or two for low number of wishes. If in 1000's may take just a little longer.</p>
              <p>Typically, event file generation takes under a minute or two only. Keep checking the status for completion of files generation. Creator will also receive an email once files are generated.</p>
              <br></br>
              <p style={{fontWeight:'bold'}}>Download Event Files :</p>
              <p>Once the event files are generated, immediately proceed to download the files.</p>
              <p>Files can be downloaded in mobile, PC, or any capable device. PC is recommended but not compulsory. There may be only limited time to download the files.</p>
              <p>Downloaded file is a single '.zip' file. Extract the .html files onto its own directory/folder.</p>
              <br></br>
              <p style={{fontWeight:'bold'}}>Watch and Hear wishes :</p>
              <p>If on PC, MAC, or Laptops, you can just double click the .html files to launch them in the web browser. Browser will show the output based upon the template chosen.</p>
              <p>If on Android Tablet or mobile, open the .html files using Chrome.</p>
              <p>If on iPad or iPhone, download microsoft Edge browser first. Send/Share the .html file to Edge browser to open the .html files.</p>
              <p>Once the .html file is opened, here you will see the Event picture and 20 wishers pictures based on the chosen template.</p>
              <p>Click on each wisher's picture to pop out the window where you can see their text messages and hear their audio wishes.</p>
              <p>If the wishes are intended for others, send the '.zip' file to the recepient via email, WhatsApp (doc attachment), google drive, etc.</p>
              <p>Make multiple copies of the '.zip' file and store them in multiple places like hard drive, pen drive, cloud, etc. as "Wish Collector" will delete all data related to the event as soon as event files are downloaded and cannot be recovered at all.</p>
              <p>Have fun and enjoy wishes for a lifetime!</p>
              <p>Good Luck!</p>
            </Panel.Body>
          </div>
        </Panel>
      </PanelGroup> 
      
      <p style={{marginLeft:'15px', marginRight:'15px', marginBottom:'15px', fontSize:'30px',color:'black', fontWeight:'regular', fontFamily:'Impact'}}>  
        Sample Video Invites
      </p>

      <div style={{}}    >
        <Button bsStyle="info" bsSize="small" style={{marginLeft:"15px", marginRight:'15px', marginBottom:'15px',  height:'auto', boxShadow:'0px 0px 2px 0px black'}}
            onClick={() => {
              this.setState({
                sampleModal: true,
              modalTitle: 'Sample Video - Wedding Invite',
              modalVideo: 'uploads/events/sample/weddingInvite.mp4'
              })
              this.getContributions(80)
            }}
          >
            Wedding Invite
          </Button>

          <Button bsStyle="success" bsSize="small" style={{marginLeft:"15px", marginRight:'15px', marginBottom:'15px', height:'auto', boxShadow:'0px 0px 2px 0px black'}}
            onClick={() => {
              this.setState({
                sampleModal: true,
              modalTitle: 'Sample Video - Birthday Invite',
              modalVideo: 'uploads/events/sample/birthdayInvite.mp4'
              })
              this.getContributions(80)
            }}
          >
            Birthday Invite
          </Button>

          <Button bsStyle="primary" bsSize="small" style={{marginLeft:"15px", marginRight:'15px', marginBottom:'15px', height:'auto', boxShadow:'0px 0px 2px 0px black'}}
            onClick={() => {
              this.setState({
                sampleModal: true,
              modalTitle: 'Sample Video - Graduation Invite',
              modalVideo: 'uploads/events/sample/graduationInvite.mp4'
              })
              this.getContributions(80)
            }}
          >
            Graduation Invite
          </Button>

          <Button bsStyle="danger" bsSize="small" style={{marginLeft:"15px", marginRight:'15px', marginBottom:'15px', height:'auto', boxShadow:'0px 0px 2px 0px black'}}
            onClick={() => {
              this.setState({
                sampleModal: true,
              modalTitle: 'Sample Video - Student Autograph Invite',
              modalVideo: 'uploads/events/sample/autographInvite.mp4'
              })
              this.getContributions(80)
            }}
          >
            Student Autograph Invite
          </Button>
        </div>
        <p style={{marginLeft:'15px', marginRight:'15px', marginBottom:'15px', fontSize:'30px',color:'black', fontWeight:'regular', fontFamily:'Impact'}}>  
          Sample Collected Wishes
        </p>
        <div style={{}}    >  
          <Button bsStyle="success" bsSize="small" style={{marginLeft:"15px", marginRight:'15px', marginBottom:'15px', height:'auto', boxShadow:'0px 0px 2px 0px black'}}
              onClick={() => {
                this.setState({
                  isContributions: true,
                })
                this.getContributions(91)
              }}
          >
            Wedding
          </Button>
          <Button bsStyle="primary" bsSize="small" style={{marginLeft:"15px", marginRight:'15px', marginBottom:'15px', height:'auto', boxShadow:'0px 0px 2px 0px black'}}
              onClick={() => {
                this.setState({
                  isContributions: true,
                })
                this.getContributions(92)
              }}
          >
            Student Autographs
          </Button>
          <Button bsStyle="danger" bsSize="small" style={{marginLeft:"15px", marginRight:'15px', marginBottom:'15px', height:'auto', boxShadow:'0px 0px 2px 0px black'}}
              onClick={() => {
                this.setState({
                  isContributions: true,
                })
                this.getContributions(93)
              }}
          >
            Graduation
          </Button>
          <Button bsStyle="info" bsSize="small" style={{marginLeft:"15px", marginRight:'15px', marginBottom:'15px', height:'auto', boxShadow:'0px 0px 2px 0px black'}}
            onClick={() => {
              this.setState({
                isContributions: true,
              })
              this.getContributions(80)
            }}
          >
            Anniversary
          </Button>
        </div>

        <p style={{marginLeft:'15px', marginRight:'15px', marginBottom:'15px', fontSize:'30px',color:'black', fontWeight:'regular', fontFamily:'Impact'}}>  
          Sample Downloaded 'html' files
        </p>
        <div style={{}}    >  
          <Button bsStyle="danger" bsSize="small" style={{marginLeft:"15px", marginRight:'15px', marginBottom:'15px', height:'auto', boxShadow:'0px 0px 2px 0px black'}}
              onClick={() => {
                const link = document.createElement('a');
                link.setAttribute('href', 'weddingWishes.html');
                link.setAttribute('target', 'blank');
                link.click();
                alert("'html' file opened in a New Tab")
              }}
          >
            Wedding Wishes
          </Button>
          <Button bsStyle="primary" bsSize="small" style={{marginLeft:"15px", marginRight:'15px', marginBottom:'15px', height:'auto', boxShadow:'0px 0px 2px 0px black'}}
              onClick={() => {
                const link = document.createElement('a');
                link.setAttribute('href', 'autographWishes.html');
                link.setAttribute('target', 'blank');
                link.click();
                alert("'html' file opened in a New Tab")
              }}
          >
            Student Autographs
          </Button>
          <Button bsStyle="success" bsSize="small" style={{marginLeft:"15px", marginRight:'15px', marginBottom:'15px', height:'auto', boxShadow:'0px 0px 2px 0px black'}}
              onClick={() => {
                const link = document.createElement('a');
                link.setAttribute('href', 'graduationWishes.html');
                link.setAttribute('target', 'blank');
                link.click();
                alert("'html' file opened in a New Tab")
              }}
          >
            Graduation Wishes
          </Button>
        </div>


        <Modal show={this.state.isContributions} onHide={() => {this.setState({ isContributions: false })}}
                    style={{marginTop:'45px', }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Collected Wishes
                </Modal.Title>
              </Modal.Header>
          <Form onSubmit={this._handleValidSubmit} className="override_form">
                <Modal.Body >
                <FieldGroup
                    type="color"
                    id="colorpicker"
                    name="colorPicker"
                    label="Choose Background Color"
                    //class="field-radio"
                    onChange={({ target: { name, value } }) => {
                      this.setState({ [name]: value });
                      this.changeColor()
                    }}
                    value={this.state.colorPicker}
                  >
                  </FieldGroup>
                {this.state.contributes.length > 0 ?
                  this.state.contributes.map( (item, index) =>{
                    const contributionNo = this.state.contributes.length - index
                    const date = new Date(item.created_at)
                    const formattedDate = date.toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric"
                      })
                    const formattedTime = date.toLocaleTimeString("en-US")
                      
                if (this.state.mobile){
                  return (
                    <Fragment>
                  <div id={"myContribute_"+item.id} key={item.id} style={{ display: 'flex', flexDirection: 'column', backgroundColor: this.state.colorPicker,  width: '100%', alignItems:'middle', boxShadow: '2px 5px 5px #888888', border: '3px solid black', marginTop:"5px", marginBottom: '15px'}}>
                  <h5 style={{color:'yellow', marginTop: '10px', marginLeft:'10px', marginBottom:'10px'}}>Wish No: {contributionNo} @ {formattedDate} {formattedTime}</h5>
                  
                    <img id="contributeImage" src={`uploads/events/${item.event_id}/contributes/`+item.c_img_url} alt="" width="100%"  
                    style={{boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.6)"}}/>
                  
                      <br></br>
                    <h5 style={{marginLeft:'5px', color: 'white',}}>Name: {item.c_name}</h5>                        
                    <h5 style={{marginLeft:'5px', color: 'grey', marginTop: '-10px'}}>Place: {item.c_place} </h5>                        
                    <br></br>
                    
                    <div style={{ alignSelf:'center', marginBottom:'20px'}}>
                      <h5 style={{marginLeft: '30px', color: 'white', marginTop:'-35px'}}>Audio Wishes (if recordered)</h5>
                      {/* <h5 style={{color: 'white', }}>Audio Wishes (if recordered)</h5> */}
                      
                      <audio id="audio"  src={`uploads/events/${item.event_id}/contributes/`+item.c_aud_url} controls={true}
                        style={{display:'inline-block', verticalAlign:'middle', height:'30px', paddingLeft:'15px', paddingTop:'0px', marginTop: '-15px', }}>
                      </audio>
                    </div>
                 
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', marginTop:'-10px'}}>
                      <textarea defaultValue={item.message} 
                          style={{ height: '110px', color: 'blue', fontFamily: 'Almendra', fontWeight: "bold", fontSize: '15px', 
                          display: 'flex', flexDirection: 'column', marginBottom: '5px', padding: '5px'}}
                        readOnly>
                      </textarea>
                    </div>
                </div>
                </Fragment>
                  )
                }
                else {
                  return (
                    <Fragment>
                  <div id={"myContribute_"+item.id} key={item.id} style={{ display: 'flex', flexDirection: 'column', backgroundColor: this.state.colorPicker,  width: '100%', alignItems:'middle', boxShadow: '2px 5px 5px #888888', border: '3px solid black', marginTop:"5px", marginBottom: '15px'}}>
                  <h5 style={{color:'yellow', marginTop: '10px', marginLeft:'10px', marginBottom:'20px'}}>Wish No: {contributionNo} @ {formattedDate} {formattedTime}</h5>
                  
                    <img id="contributeImage" src={`uploads/events/${item.event_id}/contributes/`+item.c_img_url} alt="" width="100%"   
                    style={{boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.6)", marginBottom:'20px'}}/>
                  
                      
                    <h5 style={{marginLeft:'5px', color: 'white',}}>Name: {item.c_name}</h5>                        
                    <h5 style={{marginLeft:'5px', color: 'grey', marginTop: '-10px'}}>Place: {item.c_place} </h5>                        
                    <br></br>
                  
                    <div style={{ alignSelf:'center', marginBottom:'20px'}}>
                      <h5 style={{marginLeft: '70px', color: 'white', marginTop:'-35px'}}>Audio Wishes (if recordered)</h5>
                      {/* <h5 style={{color: 'white', }}>Audio Wishes (if recordered)</h5> */}
                      
                      <audio id="audio"  src={`uploads/events/${item.event_id}/contributes/`+item.c_aud_url} controls={true}
                        style={{display:'inline-block', verticalAlign:'middle', height:'30px', paddingLeft:'15px', paddingTop:'0px', marginTop: '-15px', }}>
                      </audio>
                    </div>
                  
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', marginTop:'-10px'}}>
                    <textarea defaultValue={item.message} 
                        style={{ height: '110px', color: 'blue', fontFamily: 'Almendra', fontWeight: "bold", fontSize: '15px', 
                        display: 'flex', flexDirection: 'column', marginBottom: '5px', padding: '5px'}}
                       readOnly>
                    </textarea>
                    </div>
                </div>
                    </Fragment>
                  )
                }
                  })
                  :
                  <h3>No Wishes yet!</h3>
                }
                </Modal.Body>
              </Form>
            </Modal>

            <Modal show={this.state.sampleModal} onHide={() => {this.setState({ sampleModal: false })}}>
              <Modal.Header closeButton>
                <Modal.Title style={{fontSize:'15px'}}>
                  {this.state.modalTitle}
                </Modal.Title>
              </Modal.Header>
                <Modal.Body>
                  <div style={{alignItems: 'center'}}>
                  <video playsInline autoPlay={true} controls src={`${this.state.modalVideo}`}
                    style={{width:'100%', maxHeight:'65vh', boxShadow: '5px 5px 5px #888888', border: '10px solid black', marginTop:'5px' }}>
                  </video>
                  </div>
                </Modal.Body>
            </Modal>


            {this.state.countryCode === 'IN' ?
                 <div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div style={{ background: 'lightgray', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'column',  alignItems: 'center', paddingRight: 10}}>
                                <br></br>
                                <p><b>Contact :</b><br></br>
                                &nbsp;&nbsp;<b><strong>LIFE COLORS</strong></b><br></br>
                                &nbsp;&nbsp;#9, 4th Cross,<br></br>
                                &nbsp;&nbsp;Kothanur Main Road,<br></br>
                                &nbsp;&nbsp;Bangalore 560 062.<br></br>
                                <br></br>
                                &nbsp;&nbsp;<b><strong>email : </strong></b>team@composz.com<br></br>
                                &nbsp;&nbsp;<b><strong>phone : </strong></b>+91 9620621884<br></br>
                                </p>
                            </div> 

                            <div style={{ display: 'flex', flexDirection: 'column',  alignItems: 'center', paddingRight: 10}}>
                                <br></br>
                                <p><b>Policies :</b><br></br>
                                    <a href="/static/Pricing.html" target="blank">Pricing</a><br></br>
                                    <a href="/static/RefundAndReturn.html" target="blank">Refund & Return</a><br></br>
                                    <a href="/static/Shipping.html" target="blank">Shipping</a><br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                </p>
                            </div> 
                        </div> 
                    </div> : ''
                }       

    
          </React.Fragment>
        );
    }





  }
}


export default WishCollectorHomePage 
