//stop = false
var show=false
var vidblob ='NoVideo';
var recVidTime = 'none';

export function delete_Recording(){
  videoRecorded.src = ''
  vidblob = '';
  var buttonDelete = document.getElementById("vid_del")
  buttonDelete.disabled=true
  buttonDelete.innerHTML="<span style='color:grey;'><i class='fa fa-trash fa-2x'></i></span>"
  document.getElementById("myBar").style.width="0%";
  document.getElementById("label").innerHTML = '0%';
  document.getElementById("myProgress").style.width="100%";
  }

export function get_Blob(){
    return vidblob;
  }

  export function get_Time(){
    return recVidTime;
  }  

export function getRecordingState(){
  //alert("I am checking state")
  if (window.MediaRecorder && window.MediaRecorder.state === "recording") 
   return true
  else
   return false
}

export function start_video_Recording(){
 
  const buttonStart = document.querySelector('#vid_st')
  const buttonStop = document.querySelector('#vid_en')
  const videoLive = document.querySelector('#videoLive')
  const videoRecorded = document.querySelector('#videoRecorded')
  const videoLiveDiv = document.querySelector('#videoLiveDiv')
  const videoRecordedDiv = document.querySelector('#videoRecordedDiv')

  document.getElementById("myBar").style.width="0%";
  document.getElementById("label").innerHTML = '0%';
  document.getElementById("myProgress").style.width="100%";
  var maxRectime = 45;
  stop=false;
  //show=false;

  function move(mediaRecorder, stream) {
    var i=0;
    if (i == 0) {
      i = 1;
      var elem = document.getElementById("myBar");
      var width = 0;

  var id = setInterval(frame, maxRectime+1);
      
      function frame() {
        if (stop) {
          clearInterval(id);
          stop_Recording(mediaRecorder, stream);
        }
        if (width >= 100) {
          clearInterval(id);
          stop_Recording(mediaRecorder, stream);
          i = 0;
        } else {
          width = width + .1
          elem.style.width = width + "%";
          if (width.toFixed(2)%1==0) {
            document.getElementById("label").innerHTML = width.toFixed(2) * 1 + '%';
          }
        }
      }
    }
  }

  function stop_Recording(mediaRecorder, stream){
    mediaRecorder.stop() 
    var buttonDelete = document.getElementById("vid_del")
    buttonDelete.disabled=false
    buttonDelete.innerHTML="<span style='color:red;'><i class='fa fa-trash fa-2x'></i></span>"
    videoLiveDiv.style.display = "none"
    videoRecordedDiv.style.display = ""
    recVidTime = new Date()
    setTimeout(function() {
      document.getElementById("vid_st").disabled=false
      document.getElementById("vid_st").innerHTML="<span style='color:red;'><i class='fa fa-circle fa-2x'></i></span>"
    }, 100)
  }

  function start_Recording(stream)  {
    if (!show) return
    buttonStart.disabled=true
    buttonStart.innerHTML="<span style='color:grey;'><i class='fa fa-circle fa-2x'></i></span>"
    stop=false;
    videoLiveDiv.style.display = ""
    videoRecordedDiv.style.display = "none"


    // if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
    //   var options = {mimeType: 'video/webm; codecs=vp9'};
    // } else  if (MediaRecorder.isTypeSupported('video/webm')) {
    //     var options = {mimeType: 'video/webm'};
    // } else if (MediaRecorder.isTypeSupported('video/mp4')) {
    //     var options = {mimeType: 'video/mp4', videoBitsPerSecond : 100000};
    // } else {
    //     console.error("no suitable mimetype found for this device");
    // }
    //   window.MediaRecorder = new MediaRecorder(stream, options)

    window.mediaRecorder = new MediaRecorder(stream)

    window.mediaRecorder.start()
    window.mediaRecorder.stop()
    
    

    // function wait(secs){
    //   secs = secs - 1
    //   if (secs <= 0) {
    //     clearInterval(waitintervalId);
    //   }
    // }
    
    // const waitintervalId = setInterval(wait(6), 1000);

    var index = 6
    function countDown() {
      
      index = index - 1
      document.getElementById("CountDown").innerHTML=`Recording starts in ${index}`
      document.getElementById("CountDown").focus()
      if (index <= 0) {
        clearInterval(intervalId);
        document.getElementById("CountDown").innerHTML=''
        window.mediaRecorder.start()
        move(window.mediaRecorder, stream);
        window.mediaRecorder.addEventListener('dataavailable', event => {
          videoRecorded.src = URL.createObjectURL(event.data) 
          vidblob = event.data;
          videoRecorded.play()
        })
      }
    }


    const intervalId = setInterval(countDown, 1000);

  }
    
  if (show) {
    if (window.mediaRecorder && window.mediaRecorder.state === "recording") return
    window.localStream.getTracks().forEach(function(track) {
       track.stop();
    });
      videoLiveDiv.style.display = "none"
      videoRecordedDiv.style.display = ""
      show=false;
  }
  else {
    show=true
    if(navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({audio: true, video:true}).then(function(stream) {
        window.localStream = stream;
        document.getElementById("videoLive").srcObject = stream;

        if (!MediaRecorder.isTypeSupported('video/webm')) { 
          console.warn('video/webm is not supported')
        }
        //  mediaRecorder = new MediaRecorder(stream, { 
        //    mimeType: 'video/mp4',
        //  })
        videoLiveDiv.style.display = ""
        videoRecordedDiv.style.display = "none"
        document.getElementById("vid_st").disabled=false
        document.getElementById("vid_st").innerHTML="<span style='color:red;'><i class='fa fa-circle fa-2x'></i></span>"
        var buttonDelete = document.getElementById("vid_del")
        buttonDelete.disabled=true
        buttonDelete.innerHTML="<span style='color:grey;'><i class='fa fa-trash fa-2x'></i></span>"
        buttonStart.addEventListener('click', () => {
          start_Recording(window.localStream)
        })
        buttonStop.addEventListener('click', () => {
          stop=true
        })
      });
    }
  }//else
}

export function save_video_Recording(blob, event_id, recorded_video_file) {
  //let uri = "/api/savecontribute_audio?event_id="+event_id+"&recorded_audio_file=" + recorded_audio_file
  
  let template_url = "/api/savecontribute_audio?event_id=:event_id&recorded_video_file=:filename"
  template_url = template_url.replace(":event_id", event_id).replace(":filename", recorded_video_file);
  console.log("blob : ", blob)
  let status = ''
  fetch(template_url, {
    method: "POST",
    body: blob,
    header: {"Content-Type": 'video/webm', "Accept":'video/webm'},
  })
  .then((response) => response.json())
  .then((response) => {
    
    if (response.data==="available") {
       alert("Video successfully saved")
       status = 'ok'
       return status
    }
    else {
      alert("Save of Video recording failed. Kindly Record again.")
      status = 'not-ok'
      return status
    }
  });
}

