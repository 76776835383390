import React from "react";
import { Form, Col, Row, Modal, Button } from "react-bootstrap";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { FieldGroup } from "../bootstrap/Forms.FieldGroup";
import { ENDPOINTS } from "../../constants/endpoints";
import ThumbnailPreview from "../layouts/ThumbnailPreview";
//import PaymentModal from "./PaymentModal";
//import CCPaymentModal from "./CCPaymentModal";
import RPEventPaymentModal from "./RPEventPaymentModal";
import { formatCurrency } from "../../adukku_utils/formatCurrency";

export default class FileManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      no_of_gb:0,
      no_of_wishers: 0,
      event_id: this.props.event_id,
      price: this.props.price,
      overlay: props.overlay || { visible: false },
      is_preset_display: false,
      categories: props.categories,
      role: null,
      isFileManagerOpen: false,
      folders: [],
      user_products: {},
      folderHierarchy: {},
      basePath: "",
      show: false,
      currentFile: {},
      uploadedImages: { none: [] },
      uploadedImageNames: { none: [] },
      purchaseHistory: [],
      expiries: {},
      storage_unit: "GIGA_BYTE",
      currentOrder: null,
      isEditProductClicked: false,
    };
  }

  componentDidMount() {
     if (this.state.isFileManagerOpen) this.getPurchaseHistory();
     this.getPurchaseHistory();
     
     
  }

  
  getPurchaseHistory = () => {

    let uri = ENDPOINTS.user_events.list.uri;
    let that = this;
    return fetch(uri, {
      method: "GET",
      headers: { "content-type": "application/json" },
    })
      .then((response) => response.json())
      .then((response) => {
        that.setState({
          purchaseHistory: response.user_events,
          convenienceCharges: parseFloat(response.convience_charges.transaction_fee || 0.15),
          host_url: response.host_url,
        });
        console.log("user events purchased: ", response.user_events)
      });
  };

  openFileManager = () => {
    this.setState({ isFileManagerOpen: !this.state.isFileManagerOpen });
    //this.getPurchaseHistory();
  };

  handleSaveExpiry = (id) => {
    // if (!id) return;
    // let { expiries } = this.state;
    // let formData = new FormData();
    // formData.append("expires_on", expiries[id]);
    // let url = ENDPOINTS.user_storages.update.uri;
    // url = url.replace(":user_storage_id", id);
    // let ajaxCall = fetch(url, {
    //   method: "PUT",
    //   body: formData,
    // });
    // return ajaxCall
    //   .then((response) => response.json())
    //   .then((response) => {
    //     if (response.errors) {
    //       alert(response.errors);
    //     } else {
    //     }
    //   })
    //   .catch((error) => {
    //     throw error;
    //   });
  };

  getOrderInvoice = (order) => {
    let user = {};
    // let storageAmount = (order.total_amount - order.pg_transaction_fee) / 100;

    return (
      <Modal
        dialogClassName="modal-90w"
        centered="true"
        show={this.state.isEditProductClicked}
        onHide={(e) => {
          this.setState({ currentOrder: null, isEditProductClicked: false });
        }}
      >
        <Form className="override_form" onSubmit={this.handleEditProduct}>
          <Modal.Header closeButton>
            <Modal.Title>Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="divToPrint" style={{ justifyContent: "center" }}>
              <div
                id="divToPrint2"
                // className="mt4"
                style={{
                  backgroundColor: "#fff",
                  width: "60%",
                  padding: "50px",
                  minHeight: "297mm",
                  marginLeft: "auto",
                  marginRight: "auto",
                  border: "1px solid",
                  boxShadow: `0 10px 25px rgba(0, 0, 0, 0.5)`,
                }}
              >
                <table style={{ width: "100%" }}>
                  <tr>
                    <td>
                      <img
                        src={`${this.state.host_url}/Composz-logo-black-trans.png`}
                        style={{ height: "70px" }}
                      />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <span style={{ fontSize: "34px", color: "#0070c0", fontWeight: "34px" }}>
                        <b>INVOICE</b>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>COMPOSZ LLC,</td>{" "}
                    <td style={{ textAlign: "right" }}>Invoice #: INVSTO00000{order.id}</td>
                  </tr>
                  <tr>
                    <td>#121 HAUT BRION AVENUE</td>{" "}
                    <td style={{ textAlign: "right" }}>Invoice Date: {order.updated_at}</td>
                  </tr>
                  <tr>
                    <td>NEWARK, DE-19702.</td>{" "}
                    <td style={{ textAlign: "right" }}>
                      Invoice Amount:
                      {formatCurrency(order.total_amount, 100, "USD")} 
                      {/* {(order.total_amount / 100).toFixed(2)} */}
                    </td>
                  </tr>
                  <tr>
                    <td></td>{" "}
                    <td style={{ textAlign: "right" }}>
                      Status: <span style={{ color: "#92d050", fontSize: "20px" }}>PAID</span>
                    </td>
                  </tr>
                </table>
                <span>
                  <b>BILLED TO</b>
                </span>
                <p>
                  <span>{order.full_name}</span>
                  <br />
                  <span>{order.email}</span>
                  <br />
                  <span>{order.pg_user_email}</span>
                  <br />
                  <span>{order.address}</span>
                  <br />
                  {/* <span>Bangalore, 560076.</span> */}
                </p>
                <br />
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr
                      style={{
                        borderBottom: "0.5px solid black",
                        borderTop: "0.5px solid black",
                      }}
                    >
                      <th>Description</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Storage</td>
                      <td>${"1.00"}</td>
                      <td>{order.no_of_gb}</td>
                      <td>
                        {formatCurrency(order.total_amount - order.pg_transaction_fee, 100, "USD")}
                        {/* ${storageAmount.toFixed(2)} */}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>PAYMENT DETAILS</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Paypal Transaction ID: {order.paypal_capture_id}</td>
                      <td style={{ textAlign: "right" }}>
                        Item (s) Subtotal:{" "}
                        {formatCurrency(order.total_amount - order.pg_transaction_fee, 100, "USD")}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td style={{ textAlign: "right" }}>
                        Convenience charges: {formatCurrency(order.pg_transaction_fee, 100, "USD")}
                        {/* {(order.pg_transaction_fee / 100).toFixed(2)} */}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td style={{ textAlign: "right" }}>
                        Tax to be collected: {formatCurrency(order.pg_fixed_fee, 100, "USD")}
                        {/* ${(order.pg_fixed_fee / 100).toFixed(2)} */}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td style={{ textAlign: "right" }}>
                        <b>
                          GRAND TOTAL: {formatCurrency(order.total_amount, 100, "USD")}
                          {/* {(order.total_amount / 100).toFixed(2)} */}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p> To check the expiry date of storage purchased, login to composz.com</p>
                <p>Thanks for your purchase</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              onClick={() => {
                this.printDocument(`INVSTO00000${order.id}`);
              }}
            >
              Download
            </Button>
            <Button
              onClick={(e) => {
                this.setState({ isEditProductClicked: false });
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  getccavenueOrderInvoice = (order) => {
    let user = {};
    // let storageAmount = (order.total_amount - order.pg_transaction_fee) / 100;

    return (
      <Modal
        dialogClassName="modal-90w"
        centered="true"
        show={this.state.isEditProductClicked}
        onHide={(e) => {
          this.setState({ currentOrder: null, isEditProductClicked: false });
        }}
      >
        <Form className="override_form" onSubmit={this.handleEditProduct}>
          <Modal.Header closeButton>
            <Modal.Title>Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="divToPrint" style={{ justifyContent: "center" }}>
              <div
                id="divToPrint2"
                // className="mt4"
                style={{
                  backgroundColor: "#fff",
                  width: "60%",
                  padding: "50px",
                  minHeight: "297mm",
                  marginLeft: "auto",
                  marginRight: "auto",
                  border: "1px solid",
                  boxShadow: `0 10px 25px rgba(0, 0, 0, 0.5)`,
                }}
              >
                <table style={{ width: "100%" }}>
                  <tr>
                    <td>
                      <img
                        src={`${this.state.host_url}/Composz-logo-black-trans.png`}
                        style={{ height: "70px" }}
                      />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <span style={{ fontSize: "34px", color: "#0070c0", fontWeight: "34px" }}>
                        <b>INVOICE</b>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>LIFE COLORS,</td>{" "}
                    <td style={{ textAlign: "right" }}>Invoice #: INVSTO00000{order.id}</td>
                  </tr>
                  <tr>
                    <td>#9, 4th Cross, Kothanur Main Road,</td>{" "}
                    <td style={{ textAlign: "right" }}>Invoice Date: {order.updated_at}</td>
                  </tr>
                  <tr>
                    <td>Bangalore 560 062.</td>{" "}
                    <td style={{ textAlign: "right" }}>
                      Invoice Amount: {" "}
                      {formatCurrency(order.total_amount, 1, "INR")} 
                      {/* {(order.total_amount / 100).toFixed(2)} */}
                    </td>
                  </tr>
                  <tr>
                    <td>GSTIN: 29AUTPB5544P1ZS; GST SAC Code: 998434</td>{" "}
                    <td style={{ textAlign: "right" }}>
                      Status: <span style={{ color: "#92d050", fontSize: "20px" }}>PAID</span>
                    </td>
                  </tr>
                </table>
                <span>
                  <b>BILLED TO</b>
                </span>
                <p>
                  <span>{order.order_details.billing_name}</span>
                  <br />
                  <span>{order.order_details.billing_address}</span>
                  <br />
                  <span>{order.order_details.billing_city}, {order.order_details.billing_state} - {order.order_details.billing_zip}</span>
                  <br />
                  <span>{order.email}, {order.order_details.billing_tel}</span>
                  <br />
                </p>
                <br />
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr
                      style={{
                        borderBottom: "0.5px solid black",
                        borderTop: "0.5px solid black",
                      }}
                    >
                      <th>Description</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Storage</td>
                      <td>₹{"75.00"}</td>
                      <td>{order.no_of_gb}</td>
                      <td>
                        {formatCurrency(order.no_of_gb * 75.00, 1, "INR")}
                        {/* ${storageAmount.toFixed(2)} */}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>PAYMENT DETAILS</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>CCAvenue Tracking ID: {order.paypal_capture_id}</td>
                      <td style={{ textAlign: "right" }}>
                        Item (s) Subtotal:{" "}
                        {formatCurrency(order.no_of_gb * 75.00, 1, "INR")}
                      </td>
                    </tr>
                    <tr>
                      <td>Payment Mode: {order.order_details.payment_mode} {"-"} {order.order_details.card_name}</td>
                      <td style={{ textAlign: "right" }}>
                        Convenience charges: {formatCurrency(order.no_of_gb * order.pg_transaction_fee, 1, "INR")}
                        {/* {(order.pg_transaction_fee / 100).toFixed(2)} */}
                      </td>
                    </tr>
                    <tr>
                      <td>Bank Ref No: {order.order_details.bank_ref_no}</td>
                      <td style={{ textAlign: "right" }}>
                        GST 18%: {formatCurrency((order.no_of_gb * 75.00 + order.no_of_gb * order.pg_transaction_fee) * 0.18, 1, "INR")}
                        {/* ${(order.pg_fixed_fee / 100).toFixed(2)} */}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td style={{ textAlign: "right" }}>
                        <b>
                          GRAND TOTAL : {formatCurrency(order.total_amount, 1, "INR")}
                          {/* {(order.total_amount / 100).toFixed(2)} */}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p> To check the expiry date of storage purchased, login to https://composz.com</p>
                <p> Thanks for your purchase</p>
                <p> --team Composz</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              onClick={() => {
                this.printDocument(`INVSTO00000${order.id}`);
              }}
            >
              Download
            </Button>
            <Button
              onClick={(e) => {
                this.setState({ isEditProductClicked: false });
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  getrazorpayOrderInvoice = (order) => {
    let user = {};
    // let storageAmount = (order.total_amount - order.pg_transaction_fee) / 100;

    return (
      <Modal
        dialogClassName="modal-90w"
        centered="true"
        show={this.state.isEditProductClicked}
        onHide={(e) => {
          this.setState({ currentOrder: null, isEditProductClicked: false });
        }}
      >
        <Form className="override_form" onSubmit={this.handleEditProduct}>
          <Modal.Header closeButton>
            <Modal.Title>Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="divToPrint" style={{ justifyContent: "center" }}>
              <div
                id="divToPrint2"
                // className="mt4"
                style={{
                  backgroundColor: "#fff",
                  width: "60%",
                  padding: "50px",
                  minHeight: "297mm",
                  marginLeft: "auto",
                  marginRight: "auto",
                  border: "1px solid",
                  boxShadow: `0 10px 25px rgba(0, 0, 0, 0.5)`,
                }}
              >
                <table style={{ width: "100%" }}>
                  <tr>
                    <td>
                      <img
                        src={`${this.state.host_url}/Composz-logo-black-trans.png`}
                        style={{ height: "70px" }}
                      />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <span style={{ fontSize: "34px", color: "#0070c0", fontWeight: "34px" }}>
                        <b>INVOICE</b>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>LIFE COLORS,</td>{" "}
                    <td style={{ textAlign: "right" }}>Invoice #: INVEVTO00000{order.id}</td>
                  </tr>
                  <tr>
                    <td>#9, 4th Cross, Kothanur Main Road,</td>{" "}
                    <td style={{ textAlign: "right" }}>Invoice Date: {order.updated_at}</td>
                  </tr>
                  <tr>
                    <td>Bangalore 560 062.</td>{" "}
                    <td style={{ textAlign: "right" }}>
                      Invoice Amount: {" "}
                      {formatCurrency(order.total_amount, 1, "INR")} 
                      {/* {(order.total_amount / 100).toFixed(2)} */}
                    </td>
                  </tr>
                  <tr>
                    <td>GSTIN: 29AUTPB5544P1ZS; GST SAC Code: 998434</td>{" "}
                    <td style={{ textAlign: "right" }}>
                      Status: <span style={{ color: "#92d050", fontSize: "20px" }}>PAID</span>
                    </td>
                  </tr>
                </table>
                <span>
                  <b>BILLED TO</b>
                </span>
                <p>
                  <br></br>
                  <span>{order.user_name}</span>
                  <br></br>
                  <span>{order.razorpay_capture.email}</span>
                  <br></br>
                  <span>{order.razorpay_capture.contact}</span>
                  <br />
                </p>
                <br />
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr
                      style={{
                        borderBottom: "0.5px solid black",
                        borderTop: "0.5px solid black",
                      }}
                    >
                      <th>Description</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Wish Collection</td>
                      <td>₹{order.price}</td>
                      <td>{order.no_of_wishers}</td>
                      <td>
                        {formatCurrency(order.no_of_wishers * order.price, 1, "INR")}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>PAYMENT DETAILS</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Razorpay Payment ID: {order.razorpay_capture.id}</td>
                      <td style={{ textAlign: "right" }}>
                        Item (s) Subtotal:{" "}
                        {formatCurrency(order.no_of_wishers * order.price, 1, "INR")}
                      </td>
                    </tr>
                    <tr>
                      <td>Payment Mode: {order.razorpay_capture.method} 
                      {order.razorpay_capture.method == 'netbanking' ? "-" + order.razorpay_capture.bank : ''}
                      {order.razorpay_capture.method == 'card' ? "-" + order.razorpay_capture.card.network + "-xxxx-" + order.razorpay_capture.card.last4 : ''}
                      {order.razorpay_capture.method == 'upi' ? "-" + order.razorpay_capture.vpa + "-Ph:" + order.razorpay_capture.acquirer_data.rrn : ''}
                      {order.razorpay_capture.method == 'wallet' ? "-" + order.razorpay_capture.wallet : ''}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        Convenience charges: {formatCurrency(order.no_of_wishers * order.pg_transaction_fee, 1, "INR")}
                        {/* {(order.pg_transaction_fee / 100).toFixed(2)} */}
                      </td>
                    </tr>
                    <tr>
                      <td>Transaction ID: 
                        {order.razorpay_capture.method == 'netbanking' ? order.razorpay_capture.acquirer_data.bank_transaction_id : ''}
                        {order.razorpay_capture.method == 'card' ? order.razorpay_capture.acquirer_data.auth_code : ''}
                        {order.razorpay_capture.method == 'upi' ? order.razorpay_capture.acquirer_data.upi_transaction_id : ''}
                        {order.razorpay_capture.method == 'wallet' ? " N/A" : ''}
                        </td>
                      <td style={{ textAlign: "right" }}>
                        GST 18%: {formatCurrency((order.no_of_wishers * 10.00 + order.no_of_wishers * order.pg_transaction_fee) * 0.18, 1, "INR")}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td style={{ textAlign: "right" }}>
                        <b>
                          GRAND TOTAL : {formatCurrency(order.total_amount, 1, "INR")}
                        </b>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p> Thanks for your purchase</p>
                <p> --team Composz</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              onClick={() => {
                this.printDocument(`INVEVTO00000${order.id}`);
              }}
            >
              Download
            </Button>
            <Button
              onClick={(e) => {
                this.setState({ isEditProductClicked: false });
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  };

  printDocument = (orderId) => {
    const input = document.getElementById("divToPrint2");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const pdf = new jsPDF();
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      // pdf.output('dataurlnewwindow');
      pdf.save(`${orderId}.pdf`);
    });
  };

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  //countryCode = this.getCookie("countryCode");
  countryCode = "IN";

  render() {
    const {
      isFileManagerOpen,
      purchaseHistory,
      user_products,
      basePath,
      current_path,
      prev_path,
      expiries,
      currentOrder,
    } = this.state;
    return (
      <li>
 
        <Modal id="Invoices"
          dialogClassName="modal-90w"
          centered
          show={!isFileManagerOpen}
          onHide={this.openFileManager}
        >
          <Modal.Header closeButton>
            <Modal.Title>Event Purchases</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="flex_align">
               <FieldGroup
                id="formControlPages"
                type="number"
                label="Number of Giga Bytes"
                name="no_of_gb"
                placeholder="Number of GB"
                required="required"
                onChange={({ target: { name, value } }) => {
                  this.setState({ [name]: parseInt(value) });
                }}
                value={this.state.no_of_gb ? this.state.no_of_gb : 0}
              />
              <strong style={{ marginLeft: "15px" }}>Price for 1GB: {this.countryCode!=="IN"?'$1.00':' ₹75.00'}</strong> */}
              {/* <FieldGroup
                    componentClass="select"
                    id="formControlPreset"
                    label="Select Storage unit"
                    name="storage_unit"
                    placeholder="Select Product Category"
                    value={this.state.storage_unit ? this.state.storage_unit : ""}
                    onChange={({ target: { name, value } }) => {
                      this.setState({ [name]: value });
                    }}
                  >
                    <option key="GIGA_BYTE" value="GIGA_BYTE">
                      {"GB"}
                    </option>
                    <option key="MEGA_BYTE" value="MEGA_BYTE">
                      {"MB"}
                    </option>
                    <option key="KILO_BYTE" value="KILO_BYTE">
                      {"KB"}
                    </option>
                  </FieldGroup> */}
            {/* </div> */}
            {purchaseHistory && purchaseHistory.length > 0 && (
              <Row>
                <Col md={12}>
                  <table className="table table-bordered">
                    <caption className="event_history">Purchase Event - History</caption>
                    <thead>
                      <tr>
                      <th scope="col">Event ID</th>
                        <th scope="col">No of Wishers</th>
                        <th scope="col">Purchased On</th>
                        {/* <th scope="col">Expires On</th> */}
                        {/* <th scope="col">Credits Added</th> */}
                        <th scope="col">Payment ID</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseHistory &&
                        purchaseHistory.map((event) => {
                          return (
                            <tr>
                              <td>{event.event_id}</td>
                              <td>{event.no_of_wishers}</td>
                              <td>{`${new Date(event.purchased_on).toLocaleString()}`}</td>
                              {/* <td>
                                <FieldGroup
                                  id="formControlPages"
                                  type="datetime-local"
                                  name="expiry"
                                  value={
                                    expiries[storage.id] ||
                                    new Date(storage.expires_on).toISOString().slice(0, 16)
                                  }
                                  readOnly
                                  required="required"
                                  onChange={({ target: { name, value } }) => {
                                    expiries[storage.id] = value;
                                    this.setState({ expiries: expiries });
                                  }}
                                />
                              </td> */}
                              {/* <td>{`${new Date(storage.expires_on).toLocaleString()}`}</td> */}
                              {/* <td>{storage.credits}</td> */}
                              {/* <td>{storage.pg_reference_id}</td> */}
                              <td>{event.razorpay_capture.id}</td>
                              <td>
                                <Button className="btnsmall btn-success"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    this.setState({
                                      currentOrder: event,
                                      isEditProductClicked: !this.state.isEditProductClicked,
                                    });
                                  }}
                                >
                                  Download Invoice
                                </Button>
                              </td>
                              {/* <td>
                                <input
                                  type="button"
                                  value={"Save"}
                                  onClick={() => {
                                    this.handleSaveExpiry(storage.id);
                                  }}
                                ></input>
                              </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </Col>
              </Row>
            )}
          </Modal.Body>
          {/* <Modal.Footer>
            <Button onClick={this.openFileManager}>Cancel</Button>
          </Modal.Footer> */}
        </Modal>
       {/*
        {this.state.isEditProductClicked && (this.countryCode!=="IN"?this.getOrderInvoice(this.state.currentOrder):this.getccavenueOrderInvoice(this.state.currentOrder))}
       */}
       {this.state.isEditProductClicked && (this.countryCode!=="IN"?this.getOrderInvoice(this.state.currentOrder):this.getrazorpayOrderInvoice(this.state.currentOrder))}
      </li>
    );
  }
}
